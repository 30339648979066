import React from 'react';

import { TaskType } from '../types/task/TaskType.ts';
import { TaskStatus, TaskStatusNew } from '~/types/task/TaskStatus.ts';
import { getStatusColor } from '~/utils/taskStatusUtils.ts';

import MilestoneSVG from '~/assets/icons/milestone.svg?react';
import DefaultSVG from '~/assets/icons/default.svg?react';

interface TaskIconProps {
    type?: TaskType;
    status?: TaskStatus;
    color?: string;
    size?: number | string;
}

interface TaskIconNewProps {
    type?: TaskType;
    status: TaskStatusNew;
}

const TaskIcon: React.FC<TaskIconProps> = ({ type, status, color, size }) => {
    const currentColor = status ? getStatusColor(status) : color;

    switch (type) {
        case TaskType.MILESTONE:
            return <MilestoneSVG style={{ color: currentColor, width: size, height: size }} />;
        case TaskType.DEFAULT:
        default:
            return <DefaultSVG style={{ color: currentColor, width: size, height: size }} />;
    }
};

const TaskIconNew: React.FC<TaskIconNewProps> = ({ status, type }) => {
    const color = status.color;

    switch (type) {
        case TaskType.MILESTONE:
            return <MilestoneSVG style={{ color }} />;
        case TaskType.DEFAULT:
        default:
            return <DefaultSVG style={{ color }} />;
    }

    return <DefaultSVG style={{ color }} />;
};

export { TaskIcon, TaskIconNew };
