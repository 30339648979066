import { ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { openCreationModal } from '~/store/slices/taskCreationModalSlice';
import { RootState } from '~/store/store';

import PlusIcon from '../assets/icons/plus.svg?react';

interface AddTaskButtonShortProps {
    isHome: boolean;
    className?: string;
    onClick?: () => void;
}

export const AddTaskButtonShort = ({ isHome, className, onClick }: AddTaskButtonShortProps) => {
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.user.currentUser);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick();
        } else {
            dispatch(
                openCreationModal({
                    dueDate: null,
                    selectedUsers: isHome && user ? [user] : null,
                }),
            );
        }

        event.stopPropagation();
    };

    return (
        <ActionIcon
            className={className}
            variant="transparent" 
            size="md"
            onClick={handleClick}
        >
            <PlusIcon style={{ width: '24px', height: '24px' }} />
        </ActionIcon>
    );
};
