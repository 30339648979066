import { useNavigate, useLocation } from 'react-router-dom';

export function useTaskNavigation() {
    const navigate = useNavigate();
    const location = useLocation();

    const getTaskRoute = (taskId: string) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('taskId', taskId);
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const navigateToTask = (taskId: string) => {
        navigate(getTaskRoute(taskId));
    };

    return { getTaskRoute, navigateToTask };
}
