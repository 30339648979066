import styled from 'styled-components';
import { CommentCard } from './CommentCard';
import { ActivityComment } from '~/types/task/TaskFull';
import { ActivityItem } from './ActivityItem';

export interface Attachment {
    id: string;
    type: string;
    name: string;
}

export interface Comment {
    id: string;
    author: string;
    avatar: string | null;
    date: string;
    content: string;
    user: {
        id: string;
        name: string;
        role: string;
    };
}

interface ActivityContentProps {
    activityItems: Array<{
        description: string;
        date: string;
        statusChange?: { from: string; to: string };
    }>;
    comments: ActivityComment[];
    onEdit: (id: string, newContent: string) => void;
    onDelete: (id: string) => void;
}

export const ActivityContent: React.FC<ActivityContentProps> = ({ activityItems, comments, onEdit, onDelete }) => {
    return (
        <StyledActivityContent>
            {activityItems.map((item, index) => (
                <ActivityItem key={index} {...item} />
            ))}
            {comments.map(comment => (
                <CommentCard
                    key={comment.comment.id}
                    authorId={comment.comment.author.id}
                    comment={comment.comment}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    canEdit={comment.canBeEdited}
                    canDelete={comment.canBeDeleted}
                />
            ))}
        </StyledActivityContent>
    );
};

const StyledActivityContent = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    justify-content: flex-start;
    flex: 1;
    padding: 24px 16px;

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;
