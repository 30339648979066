import { TreeNodeData as BaseTreeNodeData } from '@mantine/core';

export enum ItemType {
    Space = 'space',
    Folder = 'folder',
    Project = 'project',
}

export interface NodeState {
    isTemp: boolean;
    isEditing: boolean;
    isLoading: boolean;
}

export interface NodeRef {
    id: string;
    type: ItemType;
}

export interface SpaceParentNodeRef {
    type: ItemType.Space;
    spaceId: string;
}

export interface FolderParentNodeRef {
    type: ItemType.Folder;
    spaceId: string;
    folderId: string;
}

export type ParentNodeRef = SpaceParentNodeRef | FolderParentNodeRef;

export interface SpaceTreeNodeProps {
    id: string;
    type: ItemType.Space;
    state: NodeState;
    image?: string;
}

export interface FolderTreeNodeProps {
    id: string;
    type: ItemType.Folder;
    state: NodeState;
    parent: SpaceParentNodeRef;
}

export interface ProjectTreeNodeProps {
    id: string;
    type: ItemType.Project;
    state: NodeState;
    parent: ParentNodeRef;
}

export type TreeNodeProps = SpaceTreeNodeProps | FolderTreeNodeProps | ProjectTreeNodeProps;

export interface TreeNodeData extends BaseTreeNodeData {
    label: string;
    nodeProps: TreeNodeProps;
    children?: TreeNodeData[];
}
