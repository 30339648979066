import type { KeyboardEventHandler, KeyboardEvent } from 'react';

export const composeHotKeys =
    <T extends HTMLElement>(...handlers: KeyboardEventHandler<T>[]) =>
    (event: KeyboardEvent<T>) => {
        handlers.forEach(handler => handler(event));
    };

export const hasNoModifiers = <T>(event: KeyboardEvent<T>) => !event.ctrlKey && !event.shiftKey && !event.metaKey;

export const hasShiftModifier = <T>(event: KeyboardEvent<T>) => !event.ctrlKey && !event.metaKey && event.shiftKey;
export const hasMetaOrCtrlModifier = <T>(event: KeyboardEvent<T>) => !event.shiftKey && (event.metaKey || event.ctrlKey);

type KeyboardEventPredicate = <T extends HTMLElement>(event: KeyboardEvent<T>) => boolean;

export const createKeyboardHandler =
    (predicate: KeyboardEventPredicate) =>
    <T extends HTMLElement>(action: (event: KeyboardEvent<T>) => void, prevent = true) =>
    (event: KeyboardEvent<T>) => {
        if (predicate(event)) {
            if (prevent) {
                event.preventDefault();
                event.stopPropagation();
            }
            action(event);
        }
    };

export const logKeyboardEvent = (event: KeyboardEvent) => console.log(event);

export const enter = createKeyboardHandler(event => event.code === 'Enter' && hasNoModifiers(event));
export const shiftEnter = createKeyboardHandler(event => event.code === 'Enter' && hasShiftModifier(event));
export const multiPlatformSpecialEnter = createKeyboardHandler(event => event.code === 'Enter' && hasMetaOrCtrlModifier(event));
export const multiPlatformSpecialBackspace = createKeyboardHandler(event => event.code === 'Backspace' && hasMetaOrCtrlModifier(event));

export const anyExceptEnter = createKeyboardHandler(event => event.code !== 'Enter');
