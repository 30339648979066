import React, { useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Select, Textarea, Button, TextInput, getRadius, Loader } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '~/store/store';
import { DatePickerButton } from './DatePickerButton';
// import { CustomFieldsSection } from './CustomFieldsSection';
// import { AttachmentSection } from './AttachmentSection';
import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { SharedControlStyles } from '../shared/SharedControlStyles';
import { useGetUsersQuery } from '~/store/api/userListApi';
import {
    setSelectedUsers,
    setAllUsers,
    setName,
    setDescription,
    setSelectedProject,
    setAllProjects,
    setStatus,
    createTask,
    setDates,
} from '~/store/slices/taskCreationModalSlice';
import { UserAvatarGroup } from '~/components/UserAvatarGroup';
import { useGetProjectsQuery } from '~/store/api/projectApi';
import UserGroupIcon from '~/assets/icons/user-group.svg?react';
import ChevronDownIcon from '~/assets/icons/chevron-down.svg?react';
import { CustomDatePicker } from '~/components/CustomDatepicker';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { getDateRangeString } from '~/utils/dateUtils';
import { format, parseISO } from 'date-fns';
import { NewTaskStatusButton } from './NewTaskStatusButton';
import { getProjectAvailableUsersType, ProjectUserType } from '~/utils/projectUtils';
import { getErrorText } from '~/utils/ErrorHandler';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';

interface TaskCreationModalProps {
    isOpen: boolean;
    onClose: () => void;
    projectId: string | null;
    assigneeIds: string[];
}

export const TaskCreationModal: React.FC<TaskCreationModalProps> = ({ isOpen, onClose, projectId }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { selectedUsers, allUsers, name, description, status, allProjects, selectedProject, startDate, dueDate, isLoading, allowedStatuses } =
        useSelector((state: RootState) => state.taskCreation);

    const { currentUser } = useSelector((state: RootState) => state.user);

    const { data: remoteProjects, isLoading: isProjectsLoading, error: projectsError } = useGetProjectsQuery();
    const { data: users, isLoading: isUsersLoading, error: usersError } = useGetUsersQuery();

    const { navigateToTask } = useTaskNavigation();

    useEffect(() => {
        if (remoteProjects) {
            dispatch(setAllProjects(remoteProjects));
            if (projectId) {
                const selectedProject = remoteProjects.find(p => p.id === projectId);
                if (selectedProject) {
                    dispatch(setSelectedProject(selectedProject));
                }
            } else if (remoteProjects.length > 0) {
                dispatch(setSelectedProject(remoteProjects[0]));
            }
        }
    }, [remoteProjects, projectId, dispatch]);

    useEffect(() => {
        if (users) {
            dispatch(setAllUsers(users));
        }
    }, [users, dispatch]);

    useEffect(() => {
        if (selectedProject) {
            if (getProjectAvailableUsersType(selectedProject.isPrivate, selectedProject.space.isPrivate) === ProjectUserType.MEMBER) {
                const projectMemberIds = selectedProject.members.map(member => member.id);
                const validUsers = selectedUsers.filter(user => projectMemberIds.includes(user.id));
                dispatch(setSelectedUsers(validUsers));
            } else {
                const validUsers = selectedUsers.filter(user => allUsers.some(u => u.id === user.id));
                dispatch(setSelectedUsers(validUsers));
            }
        } else {
            dispatch(setSelectedUsers([]));
        }
    }, [selectedProject, dispatch]);

    const isFormValid = useMemo(() => {
        return !!selectedProject && !!status && name.trim() !== '';
    }, [selectedProject, status, name]);

    const handleProjectChange = useCallback(
        (value: string | null) => {
            if (value) {
                const projectId = value;
                const selectedProject = allProjects.find(p => p.id === projectId);
                if (selectedProject) {
                    dispatch(setSelectedProject(selectedProject));
                }
            } else {
                dispatch(setSelectedProject(null));
            }
        },
        [allProjects, dispatch],
    );

    const handleUserChange = useCallback(
        (value: ShortUserInfo[]) => {
            dispatch(setSelectedUsers(value));
        },
        [dispatch],
    );

    const handleCreateTask = async () => {
        try {
            if (!currentUser) {
                throw new Error('Не найден текущий пользователь.');
            }
            const result = await dispatch(createTask()).unwrap();
            if (result) {
                enqueueSnackbar('Таск успешно создан.', {
                    variant: 'success',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
                onClose();
                navigateToTask(result.id);
            }
        } catch (error) {
            enqueueSnackbar(`Ошибка при создании таска: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    if (!isOpen) return null;

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            size="lg"
            styles={{
                header: {
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingTop: '12px',
                },
                body: {
                    paddingTop: '0px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                },
            }}
        >
            <ModalContent>
                <Header>
                    {isProjectsLoading ? (
                        <Loader size="xs" />
                    ) : projectsError ? (
                        <div>Error loading projects: {projectsError.toString()}</div>
                    ) : (
                        <Select
                            required
                            allowDeselect={false}
                            searchable={true}
                            data={allProjects.map(project => ({ value: project.id.toString(), label: project.name }))}
                            placeholder="Проект"
                            value={selectedProject?.id?.toString() || null}
                            onChange={handleProjectChange}
                            rightSection={<ChevronDownIcon />}
                            styles={{
                                input: {
                                    ...SharedControlStyles,
                                },
                            }}
                        />
                    )}
                </Header>
                <TextInput
                    value={name}
                    onChange={e => {
                        dispatch(setName(e.target.value));
                    }}
                    placeholder="Название задачи..."
                    required
                    styles={{
                        input: {
                            fontSize: '32px',
                            fontWeight: '600',
                            lineHeight: '32px',
                            border: 'none',
                            padding: '0',
                        },
                    }}
                />
                <Textarea
                    value={description}
                    onChange={e => dispatch(setDescription(e.target.value))}
                    placeholder="Описание…"
                    minRows={3}
                    styles={{
                        input: {
                            borderRadius: getRadius('sm'),
                            borderColor: '#DEE2E6',
                            padding: '10px 12px 10px 12px',
                        },
                    }}
                />
                <ActionRow>
                    {allowedStatuses.length > 0 ? (
                        <NewTaskStatusButton
                            status={status ? status : allowedStatuses[0]}
                            onChange={newStatus => {
                                dispatch(setStatus(newStatus));
                            }}
                            allowedStatuses={allowedStatuses}
                        />
                    ) : (
                        <Loader size="xs" />
                    )}
                    {isProjectsLoading || isUsersLoading ? (
                        <Loader size="xs" />
                    ) : projectsError || usersError ? (
                        <div>Error loading users: {projectsError?.toString() || usersError?.toString()}</div>
                    ) : (
                        <EnhancedPickUserCombobox
                            data={
                                getProjectAvailableUsersType(selectedProject?.isPrivate ?? false, selectedProject?.space.isPrivate ?? false) ===
                                ProjectUserType.MEMBER
                                    ? (selectedProject?.members ?? [])
                                    : (allUsers ?? [])
                            }
                            value={selectedUsers}
                            onChange={handleUserChange}
                        >
                            {({ toggleDropdown }) => (
                                <FilterButton $active={selectedUsers.length > 0} onClick={() => toggleDropdown()}>
                                    {selectedUsers.length > 0 ? (
                                        <UserAvatarGroup users={selectedUsers} size="xs" max={1} onUserRemoved={handleUserChange} />
                                    ) : (
                                        <>
                                            <UserGroupIcon style={{ width: '12px', height: '12px' }} />
                                            Исполнители
                                        </>
                                    )}
                                </FilterButton>
                            )}
                        </EnhancedPickUserCombobox>
                    )}
                    <CustomDatePicker
                        initialDateFrom={startDate ? parseISO(startDate) : null}
                        initialDateTo={dueDate ? parseISO(dueDate) : null}
                        onDateRangeSelected={dateRange => {
                            dispatch(
                                setDates({
                                    startDate: dateRange[0] ? format(dateRange[0], 'yyyy-MM-dd') : null,
                                    dueDate: dateRange[1] ? format(dateRange[1], 'yyyy-MM-dd') : null,
                                }),
                            );
                        }}
                    >
                        <DatePickerButton value={getDateRangeString(startDate ? parseISO(startDate) : null, dueDate ? parseISO(dueDate) : null)} />
                    </CustomDatePicker>
                </ActionRow>
                {/* <CustomFieldsSection /> */}
                <BottomActions>
                    {/* <AttachmentSection /> */}
                    <Button onClick={handleCreateTask} loading={isLoading} disabled={!isFormValid}>
                        Создать таск
                    </Button>
                </BottomActions>
            </ModalContent>
        </Modal>
    );
};

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ActionRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const BottomActions = styled.div`
    border-top: 1px solid #dee2e6;
    padding-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

interface FilterButtonProps {
    $active?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  min-height: 24px;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  justify-content: center;
  margin: auto 0;
  padding: 0 10px 0 8px;
  border: 1px solid #dee2e6;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:not(:disabled) {
    &:hover {
      background-color: rgba(34, 139, 230, 0.08);
      color: rgba(76, 110, 245, 0.8);
      border-color: rgba(34, 139, 230, 0.8);
    }

    ${({ $active }) =>
        $active &&
        css`
            background-color: rgba(34, 139, 230, 0.12);
            color: rgba(76, 110, 245, 1);
            border-color: rgba(34, 139, 230, 1);
        `}
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
    height: 32px
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    border-width: 1px;
    background-color: transparent;
    color: #495057;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
`;
