import React from 'react';

import { Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';

import { ConditionComparisonDeadline, ConditionType, ConditionTypeLabels, OperatorComparisonOptions } from '~/types/project/ProjectFilters.ts';
import { NullableISODateIso } from '~/utils/opaque';
import { OperatorComparison } from '~/store/dto/task-filters.dto.ts';

interface DeadlineConditionProps {
    condition: ConditionComparisonDeadline;
    onChange: (condition: ConditionComparisonDeadline) => void;
}

export const DeadlineCondition: React.FC<DeadlineConditionProps> = ({ condition, onChange }) => {
    return (
        <>
            <Select
                w={110}
                comboboxProps={{ width: 'fit-content', position: 'bottom-start', withinPortal: false }}
                checkIconPosition="right"
                data={OperatorComparisonOptions}
                value={condition.operator}
                onChange={(_, option) => onChange({ ...condition, operator: option.value as OperatorComparison })}
            />
            <DatePickerInput
                flex={1}
                popoverProps={{ withinPortal: false }}
                valueFormat="DD.MM.YYYY"
                placeholder={ConditionTypeLabels[ConditionType.Deadline]}
                value={NullableISODateIso.from(condition.value)}
                onChange={date => onChange({ ...condition, value: NullableISODateIso.to(date) })}
            />
        </>
    );
};
