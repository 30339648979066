import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../store/store.ts';
import TaskPage from '~/pages/TaskPage.tsx';
import { TaskCreationModalWrapper } from '~/components/TaskCreationModalWrapper.tsx';
import { SharingModalWrapper } from '~/components/Sharing/SharingModalWrapper.tsx';
import { SearchModalWrapper } from '../components/SearchModal/SearchModalWrapper.tsx';

export const PrivateRoute = () => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <Outlet />
            <TaskPage />
            <TaskCreationModalWrapper />
            <SharingModalWrapper />
            <SearchModalWrapper />
        </>
    );
};
