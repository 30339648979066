import { gql } from '@apollo/client';
import { SHARING_PROJECT_FIELDS } from './projects';

export const CREATE_FOLDER = gql`
    mutation CreateFolder($input: CreateFolderInput!) {
        createFolder(input: $input) {
            id
            space_id
            name
            is_archived
        }
    }
`;

export const UPDATE_FOLDER = gql`
    mutation UpdateFolder($id: String!, $input: UpdateFolderInput!) {
        updateFolder(id: $id, input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_SPACE = gql`
    mutation UpdateSpace($id: String!, $input: UpdateSpaceInput!) {
        updateSpace(id: $id, input: $input) {
            id
            name
        }
    }
`;

export const CREATE_PROJECT = gql`
    mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            id
            name
            folder_id
            space_id
            is_archived
            is_private
        }
    }
`;

export const CREATE_SPACE = gql`
    mutation CreateSpace($input: CreateSpaceInput!) {
        createSpace(input: $input) {
            id
            name
            is_private
            is_archived
        }
    }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: String!, $input: UpdateProjectInput) {
    updateProject(id: $id, input: $input) {
      ${SHARING_PROJECT_FIELDS}
    }
  }
`;

export const DELETE_TASK = gql`
    mutation DeleteTask($id: String!) {
        deleteTask(id: $id)
    }
`;

export const SET_TASK_ASSIGNEES = gql`
    mutation SetTaskAssignees($task_id: String!, $user_ids: [String!]!) {
        setTaskAssignees(task_id: $task_id, user_ids: $user_ids) {
            assignees {
                id
                name
                avatar_url
            }
            project_id
        }
    }
`;

export const CREATE_TIME_RECORD = gql`
    mutation CreateTimeRecord($input: CreateTimeRecordInput!) {
        createTimeRecord(input: $input) {
            id
            user_id
            task_id
            time_in_seconds
            date
        }
    }
`;

export const UPDATE_TIME_RECORD = gql`
    mutation UpdateTimeRecord($input: UpdateTimeRecordInput!) {
        updateTimeRecord(input: $input) {
            id
            user_id
            task_id
            time_in_seconds
        }
    }
`;

export const ADD_USER_TO_PROJECT = gql`
    mutation AddUserToProject($project_id: String!, $user_id: String!) {
        addUserToProject(project_id: $project_id, user_id: $user_id) {
            id
            name
        }
    }
`;
