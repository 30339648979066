import { Space, SpaceFolder, SpaceProject } from '~/store/api/spacesApi';
import { TreeNodeData, ItemType, NodeState } from './types';

const makeDefaultNodeState = (): NodeState => ({
    isTemp: false,
    isEditing: false,
    isLoading: false,
});

// TODO: add supprt for space -> project
export const foldSpaces = (spaces: Space[]) =>
    spaces.map(
        (space: Space): TreeNodeData => ({
            label: space.name,
            value: `space-${space.id}`,
            nodeProps: {
                id: space.id,
                type: ItemType.Space,
                state: makeDefaultNodeState(),
            },
            children: space.folders
                .map(
                    (folder: SpaceFolder): TreeNodeData => ({
                        label: folder.name,
                        value: `folder-${folder.id}`,
                        nodeProps: {
                            id: folder.id,
                            type: ItemType.Folder,
                            state: makeDefaultNodeState(),
                            parent: {
                                spaceId: space.id,
                                type: ItemType.Space,
                            },
                        },
                        children: folder.projects.map(
                            (project: SpaceProject): TreeNodeData => ({
                                label: project.name,
                                value: `project-${project.id}`,
                                nodeProps: {
                                    id: project.id,
                                    type: ItemType.Project,
                                    state: makeDefaultNodeState(),
                                    parent: {
                                        type: ItemType.Folder,
                                        spaceId: space.id,
                                        folderId: folder.id,
                                    },
                                },
                            }),
                        ),
                    }),
                )
                .concat(
                    space.projects.map(
                        (project: SpaceProject): TreeNodeData => ({
                            label: project.name,
                            value: `project-${project.id}`,
                            nodeProps: {
                                id: project.id,
                                type: ItemType.Project,
                                state: makeDefaultNodeState(),
                                parent: {
                                    type: ItemType.Space,
                                    spaceId: space.id,
                                },
                            },
                        }),
                    ),
                ),
        }),
    );

interface TreeNodeManipulator {
    findNode: (predicate: (node: TreeNodeData) => boolean) => TreeNodeData | undefined;
    findPath: (predicate: (node: TreeNodeData) => boolean) => TreeNodeData[];
    updateNode: (predicate: (node: TreeNodeData) => boolean, updater: (node: TreeNodeData) => TreeNodeData) => void;
    addChild: (parentPredicate: (node: TreeNodeData) => boolean, newChild: TreeNodeData) => void;
    addRoot: (newChild: TreeNodeData) => void;
    removeNode: (predicate: (node: TreeNodeData) => boolean) => void;
    moveNode: (nodePredicate: (node: TreeNodeData) => boolean, newParentPredicate: (node: TreeNodeData) => boolean) => void;
    filterTree: (predicate: (node: TreeNodeData) => boolean) => TreeNodeData[];
}

export const createTreeNodeManipulator = (
    data: TreeNodeData[],
    setData: React.Dispatch<React.SetStateAction<TreeNodeData[]>>,
): TreeNodeManipulator => {
    const findNodeRecursive = (nodes: TreeNodeData[], predicate: (node: TreeNodeData) => boolean): TreeNodeData | undefined => {
        for (const node of nodes) {
            if (predicate(node)) return node;
            if (node.children) {
                const found = findNodeRecursive(node.children, predicate);
                if (found) return found;
            }
        }
        return undefined;
    };

    const updateNodeRecursive = (
        nodes: TreeNodeData[],
        predicate: (node: TreeNodeData) => boolean,
        updater: (node: TreeNodeData) => TreeNodeData,
    ): TreeNodeData[] => {
        return nodes.map(node => {
            if (predicate(node)) {
                return updater(node);
            }
            if (node.children) {
                return { ...node, children: updateNodeRecursive(node.children, predicate, updater) };
            }
            return node;
        });
    };

    return {
        findNode: predicate => findNodeRecursive(data, predicate),

        updateNode: (predicate, updater) => {
            setData(prevData => updateNodeRecursive(prevData, predicate, updater));
        },

        addChild: (parentPredicate, newChild) => {
            setData(prevData =>
                updateNodeRecursive(prevData, parentPredicate, node => ({
                    ...node,
                    children: node.children ? [...node.children, newChild] : [newChild],
                })),
            );
        },

        addRoot: newChild => {
            setData(prevData => [...prevData, newChild]);
        },

        removeNode: predicate => {
            const removeNodeRecursive = (nodes: TreeNodeData[]): TreeNodeData[] => {
                return nodes.reduce<TreeNodeData[]>((acc, node) => {
                    if (!predicate(node)) {
                        if (node.children) {
                            node = { ...node, children: removeNodeRecursive(node.children) };
                        }
                        acc.push(node);
                    }
                    return acc;
                }, []);
            };

            setData(prevData => removeNodeRecursive(prevData));
        },

        moveNode: (nodePredicate, newParentPredicate) => {
            let nodeToMove: TreeNodeData | undefined;
            setData(prevData => {
                const newData = prevData.filter(node => {
                    if (nodePredicate(node)) {
                        nodeToMove = node;
                        return false;
                    }
                    return true;
                });
                if (nodeToMove) {
                    return updateNodeRecursive(newData, newParentPredicate, node => ({
                        ...node,
                        children: node.children ? [...node.children, nodeToMove!] : [nodeToMove!],
                    }));
                }
                return newData;
            });
        },

        filterTree: predicate => {
            const filterNodeRecursive = (nodes: TreeNodeData[]): TreeNodeData[] => {
                return nodes.reduce<TreeNodeData[]>((acc, node) => {
                    if (predicate(node)) {
                        const filteredNode: TreeNodeData = { ...node };
                        if (node.children) {
                            filteredNode.children = filterNodeRecursive(node.children);
                        }
                        acc.push(filteredNode);
                    }
                    return acc;
                }, []);
            };

            return filterNodeRecursive(data);
        },

        findPath: predicate => {
            const search = (nodes: TreeNodeData[], path: TreeNodeData[] = []): TreeNodeData[] | null => {
                for (const node of nodes) {
                    if (predicate(node)) {
                        return [...path, node];
                    }
                    if (node.children) {
                        const result = search(node.children, [...path, node]);
                        if (result) return result;
                    }
                }
                return null;
            };

            const result = search(data);
            return result || [];
        },
    };
};
