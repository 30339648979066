import React from 'react';
import { Button, Group } from '@mantine/core';
import styled from 'styled-components';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { TaskFull } from '~/types/task/TaskFull';

import ToUpperIcon from '~/assets/icons/to-upper.svg?react';
import WeeekIcon from '~/assets/icons/weeek.svg?react';

const makeWeeekUrl = (id: string) => `https://app.weeek.net/ws/645568/task/${id}`;

interface TaskNavigationProps {
    task: TaskFull;
}

export const TaskNavigation: React.FC<TaskNavigationProps> = ({ task }) => {
    const { navigateToTask } = useTaskNavigation();

    const handleCopyTaskId = () => {
        navigator.clipboard.writeText(`#${task.id}`);
        enqueueSnackbar('Номер задачи скопирован в буфер обмена', {
            variant: 'success',
            action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
        });
    };


    return (
        <NavigationWrapper>
            {task.parent !== null && (
                <Button variant="transparent"
                    color="gray" size="xs"
                    leftSection={<ToUpperIcon />}
                    onClick={() => navigateToTask(task.parent!.id)}>
                    {task.parent.name}
                </Button>
            )}
            <Group gap={5} ml="auto">
                <Button size="xs"
                    color="gray"
                    variant="transparent"
                    onClick={handleCopyTaskId}>
                    #{task.id}
                </Button>
                {task.weeekId !== null && (
                    <>
                        <Separator>|</Separator>
                        <Button
                            size="xs"
                            color="gray"
                            variant="transparent"
                            leftSection={<WeeekIcon />}
                            onClick={() => window.open(makeWeeekUrl(task.weeekId!), '_blank')}
                        >
                            Открыть задачу в WEEEK
                        </Button>
                    </>
                )}
            </Group>
        </NavigationWrapper>
    );
};

const NavigationWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    margin-top: 16px;
`;

const Separator = styled.span`
    color: #ced4da;
    font-size: 14px;
`;
