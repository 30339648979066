import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '../graphqlBaseQuery';
import {
    CREATE_PROJECT,
    DELETE_PROJECT,
    GET_PROJECT_INFO,
    GET_PROJECT_SHARING,
    GET_PROJECT_TASKS,
    GET_PROJECTS,
    GET_PROJECTS_BY_NAME,
} from '~/graphql/projects';

import { ADD_USER_TO_PROJECT, UPDATE_PROJECT } from '~/graphql/mutations';

import { Task } from '~/types/task/Task.ts';
import { TaskType } from '~/types/task/TaskType.ts';
import { ProjectInfo, ProjectSharingInfo } from '~/types/project/ProjectInfo.ts';
import { mapTaskStatus } from './tasksApi';

import { spacesApi } from './spacesApi';
import { Condition, ConditionType } from '~/types/project/ProjectFilters.ts';
import { OperatorSet, TaskFiltersDto } from '~/store/dto/task-filters.dto.ts';

interface ProjectTaskUserDTO {
    id: string;
    name: string;
    avatar_url: string | null;
}

interface ProjectSpaceDTO {
    id: string;
    name: string;
    is_private: boolean;
}

interface ProjectTaskItemDTO {
    id: string;
    name: string;
    status: string;
    start_date: string;
    due_date: string;
    created_at: string;
    estimate: number | null;
    total_time_recorded: number | null;
    assignees: ProjectTaskUserDTO[];
    children: ProjectTaskItemDTO[];
    parent_id: string | null;
    project: {
        allowedTaskStatuses: string[];
    };
}

export interface ProjectBaseDTO {
    id: string;
    name: string;
    space: ProjectSpaceDTO;
    folder: { id: string; name: string } | null;
    allowedTaskStatuses: string[];
    members: ProjectTaskUserDTO[];
    is_private: boolean;
}

interface ProjectDTO extends ProjectBaseDTO {
    tasks: ProjectTaskItemDTO[];
}

interface ProjectSearchDTO {
    id: string;
    name: string;
    space: ProjectSpaceDTO;
}

interface ProjectInfoResponse {
    projects: {
        data: ProjectDTO[];
    };
}

interface ProjectSharingResponse {
    projects: {
        data: ProjectBaseDTO[];
    };
}

interface ProjectInfoResponse {
    projects: {
        data: ProjectDTO[];
    };
}

const foldTaskDto = (task: ProjectTaskItemDTO, allowedTaskStatuses: string[]): Task => {
    const taskInfo: Task = {
        id: task.id,
        name: task.name,
        type: task.children && task.children.length > 0 ? TaskType.MILESTONE : TaskType.DEFAULT,
        status: mapTaskStatus(task.status, allowedTaskStatuses),
        startDate: task.start_date,
        createdAt: task.created_at,
        dueDate: task.due_date,
        estimate: task.estimate,
        timeSpent: task.total_time_recorded,
        totalTime: 0,
        assignee:
            task.assignees?.map(assignee => ({
                id: assignee.id,
                name: assignee.name,
                avatarUrl: assignee.avatar_url,
                guild: null,
            })) || [],
        children: [],
        parentId: task.parent_id,
        parent: null,
        allowedTaskStatuses: allowedTaskStatuses.map(status => mapTaskStatus(status, allowedTaskStatuses)),
    };

    if (task.children && task.children.length > 0) {
        taskInfo.children = task.children.map(child => foldTaskDto(child, allowedTaskStatuses));
    }

    return taskInfo;
};

interface CreateProjectInFolder {
    type: 'folder';
    name: string;
    folder_id: string;
}

interface CreateProjectInSpace {
    type: 'space';
    name: string;
    space_id: string;
}

interface AddUserToProjectPayload {
    project_id: string;
    user_id: string;
}

interface AddUserToProjectResponse {
    addUserToProject: {
        id: string;
        name: string;
    };
}

export const transformProjectBaseIntoProjectSharingInfo = (project: ProjectBaseDTO): ProjectSharingInfo => ({
    id: project.id,
    name: project.name,
    space: {
        id: project.space.id,
        name: project.space.name,
        isPrivate: project.space.is_private,
    },
    folder: project.folder ?? null,
    allowedTaskStatuses: project.allowedTaskStatuses?.map(status => mapTaskStatus(status, project.allowedTaskStatuses)) ?? [],
    isPrivate: project.is_private,
    members: project.members.map(member => ({
        id: member.id,
        name: member.name,
        avatarUrl: member.avatar_url,
        guild: null,
    })),
});

export type CreateProjectPayload = CreateProjectInFolder | CreateProjectInSpace;

export const projectApi = createApi({
    baseQuery: graphqlBaseQuery,
    reducerPath: 'projectApi',
    tagTypes: ['ProjectTasks', 'Projects'], // Добавляем новый тип тега 'Projects'
    endpoints: builder => ({
        getProjectInfo: builder.query<ProjectInfo, string>({
            query: (projectId: string) => {
                return {
                    document: GET_PROJECT_INFO,
                    variables: { id: projectId },
                };
            },
            transformResponse: (response: { projects: { data: ProjectBaseDTO[] } }): ProjectInfo => {
                const project = response.projects.data[0];
                return {
                    id: project.id,
                    name: project.name,
                    space: {
                        id: project.space.id,
                        name: project.space.name,
                        isPrivate: project.space.is_private,
                    },
                    folder: project.folder ?? null,
                    allowedTaskStatuses: project.allowedTaskStatuses?.map(status => mapTaskStatus(status, project.allowedTaskStatuses)) ?? [],
                    isPrivate: project.is_private,
                    members: project.members.map(member => ({
                        id: member.id,
                        name: member.name,
                        avatarUrl: member.avatar_url,
                        guild: null,
                    })),
                };
            },
        }),
        getProjectTasks: builder.query<Task[], { projectId: string; showClosed: boolean; conditions: Condition[] }>({
            query: ({ projectId, showClosed, conditions }) => {
                const filters: TaskFiltersDto = { project_id: [projectId] };

                if (!showClosed) filters.status = { operator: OperatorSet.NotIn, values: ['Closed'] };
                for (const condition of conditions) {
                    if (condition.type === null || condition.value === null) continue;

                    switch (condition.type) {
                        case ConditionType.Status:
                            filters.status = { operator: condition.operator, values: condition.value };
                            break;
                        case ConditionType.Assignee:
                            filters.assignee_id = condition.value;
                            break;
                        case ConditionType.Author:
                            filters.author_id = condition.value;
                            break;
                        case ConditionType.Deadline:
                            filters.due_date = { operator: condition.operator, date: condition.value };
                            break;
                        case ConditionType.CreatedAt:
                            filters.start_date = { operator: condition.operator, date: condition.value };
                            break;
                        case ConditionType.Salesman:
                            filters.cf_people_salesman = condition.value;
                            break;
                        case ConditionType.Manager:
                            filters.cf_people_manager = condition.value;
                            break;
                        default:
                            console.error('Unknown condition type', condition);
                            break;
                    }
                }

                return {
                    document: GET_PROJECT_TASKS,
                    variables: { filters, projectId },
                };
            },
            transformResponse: (response: {
                tasks: { data: ProjectTaskItemDTO[] };
                projects: { data: Array<{ allowedTaskStatuses: string[] }> };
            }): Task[] => response.tasks.data.map(task => foldTaskDto(task, response.projects.data[0].allowedTaskStatuses)),
            providesTags: (result, _a, { projectId }) =>
                result
                    ? [{ type: 'ProjectTasks', id: projectId }, ...result.map(({ id }) => ({ type: 'ProjectTasks' as const, id }))]
                    : [{ type: 'ProjectTasks', id: projectId }],
        }),
        getProjects: builder.query<ProjectInfo[], void>({
            query: () => ({
                document: GET_PROJECTS,
            }),
            transformResponse: (response: ProjectInfoResponse): ProjectInfo[] => {
                return response.projects.data.map(project => ({
                    id: project.id,
                    name: project.name,
                    space: {
                        id: project.space.id,
                        name: project.space.name,
                        isPrivate: project.space.is_private,
                    },
                    folder: project.folder ?? null,
                    allowedTaskStatuses: project.allowedTaskStatuses?.map(status => mapTaskStatus(status, project.allowedTaskStatuses)) ?? [],
                    isPrivate: project.is_private,
                    members: project.members.map(member => ({
                        id: member.id,
                        name: member.name,
                        avatarUrl: member.avatar_url,
                        guild: null,
                    })),
                }));
            },
            providesTags: [{ type: 'Projects', id: 'LIST' }], // Добавляем тег для этого запроса
        }),
        createProject: builder.mutation<ProjectInfo, CreateProjectPayload>({
            query: data => {
                return {
                    document: CREATE_PROJECT,
                    variables:
                        data.type === 'folder'
                            ? { input: { name: data.name, folder_id: data.folder_id, is_archived: false, is_private: false } }
                            : { input: { name: data.name, space_id: data.space_id, is_archived: false, is_private: false } },
                };
            },
            transformResponse: (response: { createProject: ProjectInfo }) => response.createProject,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags([{ type: 'Spaces', id: 'LIST' }]));
                dispatch(projectApi.util.invalidateTags([{ type: 'Projects', id: 'LIST' }])); // Инвалидируем список проектов
            },
        }),
        // TODO: Use updateProject
        renameProject: builder.mutation<ProjectInfo, { id: string; name: string }>({
            query: ({ id, name }) => ({
                document: UPDATE_PROJECT,
                variables: { id, input: { name } },
            }),
            transformResponse: (response: { renameProject: ProjectInfo }) => response.renameProject,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags([{ type: 'Spaces', id: 'LIST' }]));
                dispatch(projectApi.util.invalidateTags([{ type: 'Projects', id: 'LIST' }]));
            },
        }),
        updateProject: builder.mutation<ProjectSharingInfo, { id: string; name?: string; is_private?: boolean }>({
            query: ({ id, name, is_private }) => {
                const input: { name?: string; is_private?: boolean } = {};
                if (name !== undefined) input.name = name;
                if (is_private !== undefined) input.is_private = is_private;
                return {
                    document: UPDATE_PROJECT,
                    variables: { id, input },
                };
            },
            transformResponse: (response: { updateProject: ProjectBaseDTO }) => transformProjectBaseIntoProjectSharingInfo(response.updateProject),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags([{ type: 'Spaces', id: 'LIST' }]));
                dispatch(projectApi.util.invalidateTags([{ type: 'Projects', id: 'LIST' }]));
            },
        }),
        deleteProject: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                document: DELETE_PROJECT,
                variables: { id },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags([{ type: 'Spaces', id: 'LIST' }]));
                dispatch(projectApi.util.invalidateTags([{ type: 'Projects', id: 'LIST' }]));
            },
        }),
        getProjectSharing: builder.query<ProjectSharingInfo, string>({
            query: (projectId: string) => ({
                document: GET_PROJECT_SHARING,
                variables: { id: projectId },
            }),
            transformResponse: (response: ProjectSharingResponse): ProjectSharingInfo => {
                const project = response.projects.data[0];
                return transformProjectBaseIntoProjectSharingInfo(project);
            },
        }),
        addUserToProject: builder.mutation<AddUserToProjectResponse, AddUserToProjectPayload>({
            query: ({ project_id, user_id }) => ({
                document: ADD_USER_TO_PROJECT,
                variables: { project_id, user_id },
            }),
            transformResponse: (response: AddUserToProjectResponse) => response,
            invalidatesTags: ['ProjectTasks'],
        }),
        getProjectsByName: builder.query<ProjectSearchDTO[], string>({
            query: name => ({
                document: GET_PROJECTS_BY_NAME,
                variables: { name },
            }),
            transformResponse: (response: { projects: { data: ProjectSearchDTO[] } }): ProjectSearchDTO[] => {
                return response.projects.data;
            },
        }),
    }),
});

export const {
    useGetProjectTasksQuery,
    useGetProjectInfoQuery,
    useGetProjectsQuery,
    useCreateProjectMutation,
    useRenameProjectMutation,
    useDeleteProjectMutation,
    useGetProjectsByNameQuery,
    useUpdateProjectMutation,
} = projectApi;
