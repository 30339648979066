import * as P from 'fp-ts/Predicate';
import * as A from 'fp-ts/Array';
import * as M from 'fp-ts/Monoid';
import { pipe } from 'fp-ts/function';

export const KeyboardSymbolsMap: Record<string, string> = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    '[': 'х',
    ']': 'ъ',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    ';': 'ж',
    "'": 'э',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    ',': 'б',
    '.': 'ю',
    '/': '.',
    й: 'q',
    ц: 'w',
    у: 'e',
    к: 'r',
    е: 't',
    н: 'y',
    г: 'u',
    ш: 'i',
    щ: 'o',
    з: 'p',
    х: '[',
    ъ: ']',
    ф: 'a',
    ы: 's',
    в: 'd',
    а: 'f',
    п: 'g',
    р: 'h',
    о: 'j',
    л: 'k',
    д: 'l',
    ж: ';',
    э: "'",
    я: 'z',
    ч: 'x',
    с: 'c',
    м: 'v',
    и: 'b',
    т: 'n',
    ь: 'm',
    б: ',',
    ю: '.',
};

export const searchPredicate = (searchTerm: string, value: string) => {
    const haystack = value.toLowerCase();
    const searchLower = searchTerm.toLowerCase();
    const alternateSearchTerm = searchTerm
        .split('')
        .map(char => KeyboardSymbolsMap[char.toLowerCase()] || char)
        .join('')
        .toLowerCase();

    return haystack.includes(searchLower) || haystack.includes(alternateSearchTerm);
};

export const searchPredicateC = (value: string) => (searchTerm: string) => searchPredicate(searchTerm, value);

export const searchPredicateByAny = (searchTerm: string, values: string[]) => {
    const grandPredicate = pipe(values, A.map(searchPredicateC), M.concatAll(P.getMonoidAny()));
    return grandPredicate(searchTerm);
};
