import { useEffect, useRef, useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetProjectInfoQuery } from '~/store/api/projectApi';
import { RootState } from '~/store/store';

type Project = RootState['project']['projectInfo'];
type Task = RootState['taskDetails']['currentTask'];

interface RouteConfig {
    path: string;
    title: (param?: any) => string;
}

const routeConfig: Record<string, RouteConfig> = {
    base: {
        path: '/',
        title: () => 'Task Manager'
    },
    home: {
        path: '/home',
        title: () => 'Мои задачи'
    },
    login: {
        path: '/login',
        title: () => 'Вход'
    },
    loginConfirm: {
        path: '/login/confirm',
        title: () => 'Подтверждение входа'
    },
    project: {
        path: '/projects/:projectId',
        title: (projectInfo?: Project) => 
            projectInfo?.name ?? 'Проект не найден'
    },
    task: {
        path: 'task',
        title: (task?: Task) => 
            task?.name ?? 'Задача не найдена'
    }
};

export const PageTitleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const match = useMatch('/projects/:projectId');
    const projectId = match?.params?.projectId;
    const taskId = new URLSearchParams(location.search).get('taskId');
    const prevTitle = useRef(document.title);
    
    const { data: projectInfo, isError: isProjectError } = useGetProjectInfoQuery(projectId ?? '', { 
        skip: !projectId
    });
    const { currentTask } = useSelector((state: RootState) => state.taskDetails);
    
    const title = useMemo(() => {
        let newTitle = routeConfig.base.title();

        if (taskId && currentTask?.id === taskId) {
            newTitle = routeConfig.task.title(currentTask);
        } else if (projectId) {
            if (isProjectError) {
                newTitle = 'Ошибка загрузки проекта';
            } else if (projectInfo?.id === projectId) {
                newTitle = routeConfig.project.title(projectInfo);
            } else {
                return prevTitle.current;
            }
        } else {
            const route = Object.values(routeConfig).find(r => r.path === location.pathname);
            if (route) {
                newTitle = route.title();
            }
        }
        
        return `${newTitle} | ${routeConfig.base.title()}`;
    }, [location.pathname, taskId, projectId, projectInfo, currentTask, isProjectError]);

    useEffect(() => {
        document.title = title;
        prevTitle.current = title;
    }, [title]);

    return <>{children}</>;
};