import React from 'react';

import { InputBase } from '@mantine/core';

import { useSelector } from 'react-redux';
import { RootState } from '~/store/store.ts';

import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox.tsx';
import { UserAvatarGroup } from '~/components/UserAvatarGroup.tsx';

import { ConditionSetManager } from '~/types/project/ProjectFilters.ts';

interface ManagerConditionProps {
    condition: ConditionSetManager;
    onChange: (condition: ConditionSetManager) => void;
}

export const ManagerCondition: React.FC<ManagerConditionProps> = ({ condition, onChange }) => {
    const members = useSelector((state: RootState) => state.project.users);
    const selectedUsers = members.filter(u => condition.value.includes(u.id));

    return (
        <EnhancedPickUserCombobox
            data={members}
            value={selectedUsers}
            withinPortal={false}
            singleChoice={false}
            onChange={users => onChange({ ...condition, value: users.map(u => u.id) })}
        >
            {({ toggleDropdown }) => (
                <InputBase role="button" component="button" pointer onClick={toggleDropdown} flex={1}>
                    <UserAvatarGroup users={selectedUsers} onUserRemoved={users => onChange({ ...condition, value: users.map(u => u.id) })} />
                </InputBase>
            )}
        </EnhancedPickUserCombobox>
    );
};
