import { Badge, Text, Flex } from '@mantine/core';

import { Task } from '~/types/task/Task';
import { ProjectTaskGroup } from '~/types/project/ProjectTaskGroup';
import { TaskGroupingType } from '~/types/TaskTypes';
import { getStringStatusText } from '~/utils/taskStatusUtils';
import { SortDirection } from '~/types/SortDirection';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';

import { UserAvatar } from '~/components/UserAvatar';
import { TaskStatusNew } from '~/types/task/TaskStatus';

const getTimeInfo = (tasks: Task[]) => {
    return {
        timeSpent: tasks.reduce((acc, task) => acc + (task.timeSpent || 0), 0),
        estimate: tasks.reduce((acc, task) => acc + (task.estimate || 0), 0),
    };
};

export const foldTasksByStatus = (tasks: Task[], direction: SortDirection, statuses: TaskStatusNew[], showClosed: boolean): ProjectTaskGroup[] => {
    const tasksByStatus: Record<string, Task[]> = statuses.reduce(
        (acc, status) => {
            if (status.name !== 'Closed' || showClosed) {
                acc[status.name] = [];
            }
            return acc;
        },
        {} as Record<string, Task[]>,
    );

    for (const task of tasks) {
        if (task.status && tasksByStatus.hasOwnProperty(task.status.name)) {
            tasksByStatus[task.status.name].push(task);
        } else {
            console.warn(`Invalid task status: ${task.status}`);
        }
    }

    return Object.entries(tasksByStatus)
        .map(([status, tasks]) => ({
            tasks,
            id: status,
            title: status,
            kind: TaskGroupingType.ByStatus,
            widget: <Badge style={{ backgroundColor: statuses.find(found => found.name === status)?.color }}>{getStringStatusText(status)}</Badge>,
            props: statuses.find(found => found.name === status)!,
            timeInfo: getTimeInfo(tasks),
        }))
        .sort((a, b) => {
            if (direction === SortDirection.ASC) {
                return statuses.findIndex(status => status.name === a.id) - statuses.findIndex(status => status.name === b.id);
            } else {
                return statuses.findIndex(status => status.name === b.id) - statuses.findIndex(status => status.name === a.id);
            }
        });
};

export const foldTasksByAssignee = (tasks: Task[], direction: SortDirection): ProjectTaskGroup[] => {
    const tasksByAssignee: Record<string, { tasks: Task[]; user: ShortUserInfo | null }> = {};

    tasksByAssignee['Unassigned'] = { tasks: [], user: null };

    for (const task of tasks) {
        if (task.assignee.length === 0) {
            tasksByAssignee['Unassigned'].tasks.push(task);
        } else {
            for (const assignee of task.assignee) {
                const key = assignee.name;
                if (!tasksByAssignee[key]) {
                    tasksByAssignee[key] = { tasks: [], user: assignee };
                }
                tasksByAssignee[key].tasks.push(task);
            }
        }
    }

    return Object.entries(tasksByAssignee)
        .map(([id, { tasks, user }]) => {
            if (user) {
                return {
                    id,
                    kind: TaskGroupingType.ByAssignee,
                    title: user.name,
                    tasks,
                    widget: (
                        <Flex gap="sm" align="center">
                            <UserAvatar name={user.name} src={user.avatarUrl} />
                            <Text size="sm" fw={500}>
                                {user.name}
                            </Text>
                        </Flex>
                    ),
                    props: user,
                    timeInfo: getTimeInfo(tasks),
                };
            } else {
                return {
                    id: 'Unassigned',
                    kind: TaskGroupingType.ByAssignee,
                    title: 'Unassigned',
                    tasks,
                    widget: <UserAvatar />,
                    props: null,
                    timeInfo: getTimeInfo(tasks),
                };
            }
        })
        .sort((a, b) => {
            if (direction === SortDirection.ASC) {
                return a.id.localeCompare(b.id);
            } else {
                return b.id.localeCompare(a.id);
            }
        });
};

const groupByNone = (tasks: Task[]): ProjectTaskGroup[] => {
    return [
        {
            id: 'All',
            kind: TaskGroupingType.ByStatus,
            title: 'All',
            tasks,
            props: null,
            timeInfo: getTimeInfo(tasks),
        },
    ];
};

export const useTaskPresenter = (
    tasks: Task[],
    groupBy: TaskGroupingType | null,
    direction: SortDirection | null,
    selectedUserIds: string[],
    statuses: TaskStatusNew[],
    showClosed: boolean,
): ProjectTaskGroup[] => {
    let activeTasks = tasks;
    if (selectedUserIds.length > 0) {
        activeTasks = activeTasks.filter(task => task.assignee.some(assignee => selectedUserIds.includes(assignee.id.toString())));
    }

    if (!showClosed) {
        activeTasks = activeTasks.filter(task => task.status.name !== 'Closed');
    }

    if (groupBy === null || direction === null) {
        return groupByNone(activeTasks);
    }

    switch (groupBy) {
        case TaskGroupingType.ByAssignee:
            return foldTasksByAssignee(activeTasks, direction);
        case TaskGroupingType.ByStatus:
        default:
            return foldTasksByStatus(activeTasks, direction, statuses, showClosed);
    }
};
