import React from 'react';
import styled from 'styled-components';

import { Select } from '@mantine/core';

export const ConditionLogicOperand: React.FC<{ isFirst: boolean }> = ({ isFirst }) =>
    isFirst ? <ConditionLabel>Если</ConditionLabel> : <Select data={['И']} value="И" disabled w={84} />;

const ConditionLabel = styled.span`
    padding: 10px;
    color: var(--Text-dimmed, #868e96);
    width: 84px;
    font:
        400 12px/1 Inter,
        sans-serif;
`;
