import React, { useState } from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/stringUtils';

interface AvatarSwitcherProps {
    avatar: string | null;
    author: string;
    size?: number;
}

export const AvatarSwitcher: React.FC<AvatarSwitcherProps> = ({ avatar, author, size = 24 }) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    if (!avatar || imageError) {
        return <InitialsAvatar size={size}>{getInitials(author)}</InitialsAvatar>;
    }

    return <Avatar src={avatar} alt={author} size={size} onError={handleImageError} />;
};

const Avatar = styled.img<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
`;

const InitialsAvatar = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    background-color: #d9d9d9;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.size * 0.4}px;
    font-weight: 400;
`;
