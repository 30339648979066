import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { TaskFull } from '~/types/task/TaskFull';

import { Subtasks } from './Subtasks';
import { TaskDetails } from './TaskDetails';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { mapCustomFieldToViewModel } from '~/types/task/CustomField';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { RootState } from '~/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomField } from '~/store/slices/taskDetails/taskCustomFieldsSlice';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '../shared/CloseSnackbarAction';
import { updateTaskDetails } from '~/store/slices/taskDetails/taskDetailsSlice';
import { UpdateTaskFields } from '~/graphql/tasks';

interface TaskTabsProps {
    task: TaskFull;
    allUsers: ShortUserInfo[];
    assignableUsers: ShortUserInfo[];
    onSubtaskDatesUpdated: (taskId: string, newDateRange: [Date | null, Date | null]) => void;
    onSubtaskStatusUpdated: (status: TaskStatusNew[], taskId: string) => void;
}

export const TaskTabs: React.FC<TaskTabsProps> = ({ task, allUsers, assignableUsers, onSubtaskDatesUpdated, onSubtaskStatusUpdated }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('details');
    const { customFields } = useSelector((state: RootState) => state.taskCustomFields);
    const { taskId } = useSelector((state: RootState) => state.taskDetails);
    const hasCustomFields = customFields && customFields.length > 0;

    const handleUpdateTask = useCallback(
        async (updatedFields: Partial<UpdateTaskFields>) => {
            if (!taskId) {
                return;
            }
            try {
                await dispatch(updateTaskDetails({ taskId: taskId, updatedFields: updatedFields }) as any);
            } catch (error: unknown) {
                if (error instanceof Error && error.name !== 'AbortError') {
                    enqueueSnackbar('Ошибка при обновлении таска.', {
                        variant: 'error',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                }
            }
        },
        [taskId, dispatch],
    );

    // Если нет пользовательских полей, устанавливаем вкладку "Задачи" по умолчанию
    useEffect(() => {
        if (!hasCustomFields) {
            setSelectedTab('subtasks');
        }
    }, [hasCustomFields]);

    const subtasks = task.children ?? [];
    const canDisplaySubtasks = subtasks.length > 0 || task.parentId === null;
    const subtasksCount = subtasks.length;

    const customFieldsViewModel = useMemo(() => {
        return customFields?.map(field => mapCustomFieldToViewModel(field, allUsers, assignableUsers)) || [];
    }, [customFields, allUsers, assignableUsers]);

    const handleCustomFieldChange = async (fieldName: string, value: string | null) => {
        if (value === null) {
            enqueueSnackbar('Поле не может быть пустым', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            return;
        }

        dispatch(updateCustomField({ fieldName, value }));
    };

    const handleCustomFieldBlur = async (fieldName: string, value: string | null) => {
        console.log('handleCustomFieldBlur', fieldName, value);
        if (value === null) {
            enqueueSnackbar('Поле не может быть пустым', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            return;
        }
        try {
            await handleUpdateTask({ [fieldName]: value });
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении поля таска.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    return (
        <StyledTaskTabs>
            <Tabs>
                {hasCustomFields && (
                    <Tab $isSelected={selectedTab === 'details'} onClick={() => setSelectedTab('details')}>
                        Детали
                    </Tab>
                )}
                {canDisplaySubtasks && (
                    <Tab $isSelected={selectedTab === 'subtasks'} onClick={() => setSelectedTab('subtasks')}>
                        Подзадачи
                        {subtasksCount > 0 && <SubtaskCount $isSelected={selectedTab === 'subtasks'}>{subtasksCount}</SubtaskCount>}
                    </Tab>
                )}
            </Tabs>
            {selectedTab === 'details' && hasCustomFields ? (
                <TaskDetails
                    customFields={customFieldsViewModel}
                    onCustomFieldChange={handleCustomFieldChange}
                    onCustomFieldBlur={handleCustomFieldBlur}
                />
            ) : (
                canDisplaySubtasks && (
                    <Subtasks
                        subtasks={subtasks}
                        canAddSubtasks={task.parentId === null}
                        onSubtaskDatesUpdated={onSubtaskDatesUpdated}
                        onSubtaskStatusUpdated={onSubtaskStatusUpdated}
                    />
                )
            )}
        </StyledTaskTabs>
    );
};

const StyledTaskTabs = styled.div`
    display: flex;
    width: 100%;
    padding-top: 16px;
    flex-direction: column;
    justify-content: flex-start;
`;

const Tabs = styled.div`
    border-bottom: 1px solid #dee2e6;
    display: flex;
    width: 100%;
    align-items: flex-end;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Tab = styled.button<{ $isSelected: boolean }>`
    border: none;
    background: none;
    display: inline-flex;
    align-items: center;
    color: ${props => (props.$isSelected ? '#000' : '#868e96')};
    padding: 16px 12px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
        transform: scaleX(${props => (props.$isSelected ? 1 : 0)});
        transition: transform 0.3s ease;
    }

    &:hover {
        color: #000;
    }
`;

const SubtaskCount = styled.span<{ $isSelected: boolean }>`
    background-color: ${props => (props.$isSelected ? '#000' : '#868e96')};
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 8px;
    transition: background-color 0.3s ease;
    position: relative;
    top: -1px; // Небольшая корректировка для выравнивания с текстом

    ${Tab}:hover & {
        background-color: #000;
    }
`;
