import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format, eachWeekOfInterval, endOfYear, startOfWeek, endOfWeek, differenceInDays, addDays } from 'date-fns';
import { UserAvatarGroup } from '~/components/UserAvatarGroup';
import { Task } from '~/types/task/Task';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';

interface TimelineProps {
    tasks: Task[];
}

const Timeline: React.FC<TimelineProps> = ({ tasks }) => {
    const sortedTasks = useMemo(
        () => [...tasks].sort((a, b) => new Date(a.startDate || '').getTime() - new Date(b.startDate || '').getTime()),
        [tasks],
    );

    const startDate = useMemo(() => {
        const firstTaskDate = sortedTasks[0]?.startDate;
        return firstTaskDate ? startOfWeek(new Date(firstTaskDate)) : startOfWeek(new Date());
    }, [sortedTasks]);

    const { navigateToTask } = useTaskNavigation();

    const endDate = endOfYear(startDate);

    const weeks = eachWeekOfInterval({ start: startDate, end: endDate });

    const totalDays = differenceInDays(endDate, startDate);

    return (
        <TimelineWrapper>
            <TimelineContainer weekCount={weeks.length}>
                <DateRow>
                    {weeks.map(week => (
                        <WeekCell key={week.toISOString()}>
                            {format(week, 'MMM d')} - {format(endOfWeek(week), 'MMM d')}
                        </WeekCell>
                    ))}
                </DateRow>
                {sortedTasks.map(task => (
                    <TaskRow key={task.id}>
                        <TaskBarWrapper onClick={() => navigateToTask(task.id)}>
                            <TaskBar
                                style={{
                                    left: `${getDatePosition(task.startDate!, startDate, totalDays)}%`,
                                    width: `${getDateWidth(task.startDate!, task.dueDate!, totalDays)}%`,
                                }}
                            >
                                <TaskContent>
                                    <UserAvatarGroup users={task.assignee} max={3} size={24} />
                                    <TaskName>{task.name}</TaskName>
                                </TaskContent>
                            </TaskBar>
                        </TaskBarWrapper>
                    </TaskRow>
                ))}
            </TimelineContainer>
        </TimelineWrapper>
    );
};

const getDatePosition = (date: string, startDate: Date, totalDays: number) => {
    const taskDate = new Date(date);
    const position = differenceInDays(taskDate, startDate);
    return (position / totalDays) * 100;
};

const getDateWidth = (startDate: string, endDate: string, totalDays: number) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const duration = differenceInDays(addDays(end, 1), start); // Add 1 day to include the end date
    return (duration / totalDays) * 100;
};

const TimelineWrapper = styled.div`
    overflow-x: auto;
    width: 100%;
`;

const TimelineContainer = styled.div<{ weekCount: number }>`
    min-width: 100%;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(${props => props.weekCount}, 150px);
`;

const DateRow = styled.div`
    display: contents;
`;

const WeekCell = styled.div`
    text-align: center;
    font-size: 12px;
    padding: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #eee;
    &:last-child {
        border-right: none;
    }
`;

const TaskRow = styled.div`
    display: contents;
`;

const TaskBarWrapper = styled.div`
    position: relative;
    height: 40px;
    grid-column: 1 / -1;
    border-bottom: 1px solid #eee;
    cursor: pointer;
`;

const TaskBar = styled.div`
    position: absolute;
    height: 32px;
    top: 4px;
    background-color: #007bff;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    overflow: hidden;
`;

const TaskContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0 4px;
    height: 100%;
`;

const TaskName = styled.span`
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default Timeline;
