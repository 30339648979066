import {
    Modal,
    Text,
    Button,
    Accordion,
    AccordionControlProps,
    AccordionPanelProps,
    // Select,
    // SelectProps,
    // getRadius,
    Flex,
    // Badge,
    Autocomplete,
    AutocompleteProps,
} from '@mantine/core';

import styled from 'styled-components';

// import LinkIcon from '~/assets/icons/link.svg?react';
import UserGroupIcon from '~/assets/icons/user-group.svg?react';
import LockClosedIcon from '~/assets/icons/lock-closed.svg?react';

import { useState, useEffect } from 'react';
import { RootState, AppDispatch } from '~/store/store';
import { useDispatch, useSelector } from 'react-redux';

import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { UserAvatarGroup } from '~/components/UserAvatarGroup';
import { UserAvatar } from '~/components/UserAvatar.tsx';
import { pluralizeRu } from '~/utils/stringUtils';
import { getProjectSharing, setUserToShareWith, addUserToProject, updateProjectPrivacy, LoadingState } from '~/store/slices/sharingModalSlice';
import { UserDTO } from '~/types/UserTypes';
import { Loader } from '~/components/LoginScreen/Loader';
import { getErrorText } from '~/utils/ErrorHandler';

interface SharingModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SharingModal: React.FC<SharingModalProps> = ({ isOpen, onClose }) => {
    const [accordionValue, setAccordionValue] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { projectInfo, allUsers, loading, sharingLoading, error, userToShareWith, isPrivateLoading } = useSelector(
        (state: RootState) => state.sharingModal,
    );

    useEffect(() => {
        if (isOpen) {
            dispatch(getProjectSharing());
        }
    }, [isOpen, dispatch]);

    const sharedWith = projectInfo?.members ?? [];

    const mappedAlLUsersIntoDict = allUsers.map(user => ({ value: user.id, label: user.name, avatarUrl: user.avatar_url }));

    function AccordionControl(props: AccordionControlProps) {
        return (
            <Flex justify="space-between" align="center" style={{ width: '100%' }}>
                <Accordion.Control {...props}>
                    <ControlLeftContent>
                        <UserGroupIcon />
                        <Text>Участникам</Text>
                    </ControlLeftContent>
                </Accordion.Control>
                <UserAvatarGroup
                    users={sharedWith.map(user => ({ id: user.id, name: user.name, avatarUrl: user.avatarUrl, guild: user.guild }))}
                    size="sm"
                    max={6}
                />
            </Flex>
        );
    }

    // function StyledSelect(props: SelectProps) {
    //     return (
    //         <Select
    //             {...props}
    //             styles={{
    //                 input: {
    //                     backgroundColor: '#F1F3F5',
    //                     border: 'none',
    //                     borderRadius: getRadius('sm'),
    //                 },
    //             }}
    //         />
    //     );
    // }

    function AccordionPanel(props: AccordionPanelProps) {
        return (
            <StyledAccordionPanel {...props}>
                <ActionRow>
                    <Text
                        style={{
                            flexGrow: 1,
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#868e96',
                        }}
                    >
                        {pluralizeRu(sharedWith.length, ['человек', 'человека', 'человек'])}
                    </Text>
                    {/* <StyledSelect
                        data={['Назначить права всем', 'Себе', 'Тест']}
                        defaultValue="Назначить права всем"
                        style={{ width: '200px', flexShrink: 0 }}
                    /> */}
                </ActionRow>
                {props.children}
            </StyledAccordionPanel>
        );
    }

    const renderAutocompleteOption: AutocompleteProps['renderOption'] = ({ option }) => {
        const isSelected = userToShareWith && userToShareWith.id === option.value;
        const user = mappedAlLUsersIntoDict.find(user => user.value === option.value);
        return (
            <Flex align="center" gap="sm">
                <UserAvatar active={isSelected ?? false} src={user?.avatarUrl} name={user?.label} />
                <Text size="xs">{user?.label}</Text>
            </Flex>
        );
    };

    const handleShareWithUser = async (user: UserDTO | null) => {
        if (user) {
            try {
                const result = await dispatch(addUserToProject({ user_id: user.id }));
                if (addUserToProject.fulfilled.match(result)) {
                    enqueueSnackbar('Пользователь добавлен в проект', {
                        variant: 'success',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                    dispatch(setUserToShareWith(null));
                } else {
                    enqueueSnackbar('Ошибка при добавлении пользователя в проект.', {
                        variant: 'error',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                }
            } catch (error) {
                console.error('Error adding user to project:', error);
                enqueueSnackbar('Ошибка при добавлении пользователя в проект.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        }
    };

    const handleUpdateProjectPrivacy = async (is_private: boolean) => {
        try {
            console.log('Attempting to update project privacy to:', is_private);
            const result = await dispatch(updateProjectPrivacy({ is_private }));
            console.log('Update project privacy result:', result);
            if (updateProjectPrivacy.fulfilled.match(result)) {
                enqueueSnackbar(is_private ? 'Проект стал приватным.' : 'Проект стал публичным.', {
                    variant: 'success',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            } else if (updateProjectPrivacy.rejected.match(result)) {
                console.error('Error updating project privacy:', result.error);
                enqueueSnackbar(`Ошибка при обновлении приватности проекта: ${getErrorText(result.error)}`, {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        } catch (error) {
            console.error('Caught error updating project privacy:', error);
            enqueueSnackbar(`Ошибка при обновлении приватности проекта: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            size="xl"
            styles={{
                header: {
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingTop: '12px',
                },
                body: {
                    paddingTop: '0px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                },
            }}
        >
            {loading === LoadingState.Pending && !projectInfo ? (
                <Flex justify="center" align="center" style={{ height: '300px' }}>
                    <Loader />
                </Flex>
            ) : (
                <ModalContent>
                    <Header>
                        <HeaderContent>
                            <Title>Доступ к {projectInfo?.name}</Title>
                            <Subtitle>Поделиться списком {projectInfo?.name} с другими пользователями</Subtitle>
                        </HeaderContent>
                    </Header>
                    <ActionRow>
                        <StyledAutocomplete
                            placeholder="Имя или эл. почта пользователя"
                            data={allUsers.map(user => ({ value: user.id, label: user.name, avatarUrl: user.avatar_url }))}
                            onChange={(value: string) => {
                                const user = allUsers.find(user => user.name === value);
                                if (user) {
                                    dispatch(setUserToShareWith(user));
                                }
                            }}
                            renderOption={renderAutocompleteOption}
                        />
                        <Button
                            style={{ flexShrink: 0 }}
                            onClick={() => handleShareWithUser(userToShareWith)}
                            loading={sharingLoading === LoadingState.Pending}
                            disabled={sharingLoading === LoadingState.Pending || !userToShareWith}
                        >
                            Поделиться
                        </Button>
                    </ActionRow>
                    {/* <PrivateLinkRow>
                        <LinkIcon />
                        <PrivateLinkContent>
                            <PrivateLinkTitle>Приватная ссылка</PrivateLinkTitle>
                            <PrivateLinkSubtitle>Будет доступна всем, кого вы пригласите в это пространство</PrivateLinkSubtitle>
                        </PrivateLinkContent>
                        <SecondaryButton>Скопировать ссылку</SecondaryButton>
                    </PrivateLinkRow> */}
                    <ActionRow style={{ paddingTop: '16px' }}>
                        <Text style={{ fontSize: '12px', fontWeight: '400', color: '#868e96' }}>Предоставить доступ всем:</Text>
                    </ActionRow>
                    <ActionRow>
                        {error && <Text c="red">{error}</Text>}
                        <StyledAccordion
                            value={accordionValue}
                            onChange={(value: string | string[] | null) => setAccordionValue(value as string | null)}
                            chevronPosition="left"
                        >
                            <Accordion.Item value="item-1">
                                <AccordionControl />

                                <Separator />
                                <AccordionPanel>
                                    <Accordion.Item value="item-2">
                                        {sharedWith.map((user, _a) => (
                                            <UserRow key={user.id}>
                                                <UserAvatar src={user.avatarUrl} size="sm" />
                                                <UserInfo>
                                                    <UserName>{user.name}</UserName>
                                                    {/* {index === 0 && (
                                                        <Badge color="#4C6EF51A" tt="none">
                                                            <BadgeText>Владелец</BadgeText>
                                                        </Badge>
                                                    )} */}
                                                </UserInfo>
                                                {/* <StyledSelect
                                                    data={['Все права', 'Редактирование', 'Просмотр']}
                                                    defaultValue="Все права"
                                                    style={{ width: '160px', flexShrink: 0 }}
                                                /> */}
                                            </UserRow>
                                        ))}
                                    </Accordion.Item>
                                </AccordionPanel>
                            </Accordion.Item>
                        </StyledAccordion>
                    </ActionRow>

                    {projectInfo?.isPrivate ? (
                        <ActionRow>
                            <Text style={{ fontSize: '12px', fontWeight: '400', color: '#868e96', flexGrow: 1 }}>
                                Это проект с приватным доступом
                            </Text>
                            <Button
                                leftSection={<LockClosedIcon color="#fff" />}
                                onClick={() => handleUpdateProjectPrivacy(false)}
                                loading={isPrivateLoading === LoadingState.Pending}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    padding: '8px 12px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px',
                                }}
                            >
                                Сделать публичным
                            </Button>
                        </ActionRow>
                    ) : (
                        <Button
                            leftSection={<LockClosedIcon />}
                            onClick={() => handleUpdateProjectPrivacy(true)}
                            loading={isPrivateLoading === LoadingState.Pending}
                            loaderProps={{ color: 'black' }}
                            style={{
                                backgroundColor: 'transparent',
                                color: 'black',
                                fontWeight: '400',
                                fontSize: '14px',
                                padding: '8px 12px',
                                border: '1px solid #ced4da',
                                borderRadius: '4px',
                            }}
                        >
                            Сделать приватным
                        </Button>
                    )}
                </ModalContent>
            )}
        </Modal>
    );
};

const ActionRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
`;

const StyledAutocomplete = styled(Autocomplete)`
    flex-grow: 1;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled(Text)`
    font-size: 24px;
    font-weight: 600;
`;

const Subtitle = styled(Text)`
    font-size: 16px;
    font-weight: 400;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

// const PrivateLinkRow = styled.div`
//     padding-top: 16px;
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     border-radius: 8px;
// `;

// const PrivateLinkContent = styled.div`
//     flex-grow: 1;
// `;

// const PrivateLinkTitle = styled(Text)`
//     font-weight: 600;
//     font-size: 14px;
// `;

// const PrivateLinkSubtitle = styled(Text)`
//     font-size: 10px;
//     color: #6c757d;
// `;

const StyledAccordion = styled(Accordion)`
    width: 100%;
    .mantine-Accordion-control {
        padding-right: 16px;
    }
`;

const ControlLeftContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const StyledAccordionPanel = styled(Accordion.Panel)`
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    padding: 20px 60px;
`;

const Separator = styled.div`
    height: 1px;
    background-color: #e9ecef;
    margin: 0;
    margin-top: 19px;
`;

const UserRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 8px 0;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 8px;
`;

const UserName = styled(Text)`
    font-weight: 400;
    font-size: 14px;
`;

// const BadgeText = styled(Text)`
//     font-weight: 400;
//     font-size: 10px;
//     color: #4c6ef5;
// `;

// const SecondaryButton = styled(Button)`
//     background-color: transparent;
//     color: black;
//     font-weight: 400;
//     font-size: 14px;
//     padding: 8px 12px;
//     border: 1px solid #ced4da;
//     border-radius: 4px;
// ` as typeof Button; // This ensures all Button props are properly typed
