import React from 'react';
import styled from 'styled-components';

import AddIcon from '~/assets/icons/plus.svg?react';

const AddFilterButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => {
    return (
        <StyledButton {...props}>
            <AddIcon style={{ width: 16, height: 16 }} />
            <ButtonText>Добавить фильтр</ButtonText>
        </StyledButton>
    );
};

const StyledButton = styled.button`
    border-radius: 4px;
    align-self: start;
    display: flex;
    min-height: 24px;
    align-items: center;
    gap: 4px;
    color: rgba(73, 80, 87, 1);
    text-align: center;
    justify-content: center;
    padding: 0 10px 0 8px;
    font:
        500 12px/1 Inter,
        sans-serif;
    border: 1px solid rgba(222, 226, 230, 1);
    background: none;
    cursor: pointer;
`;

const ButtonText = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

export default AddFilterButton;
