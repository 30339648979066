import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '../graphqlBaseQuery';
import { CREATE_TIME_RECORD, UPDATE_TIME_RECORD } from '../../graphql/mutations';
import { tasksApi } from './tasksApi';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { enqueueSnackbar } from 'notistack';

interface CreateTimeRecordInput {
    task_id: string;
    time_in_seconds: number;
    date: string;
    user: ShortUserInfo | null;
}

interface UpdateTimeRecordInput {
    id: string;
    time_in_seconds: number;
    user_id: string;
    task_id: string;
}

interface TimeRecord {
    id: string;
    user_id: string;
    task_id: string;
    time_in_seconds: number;
    date: string;
}

export const timeTrackingApi = createApi({
    reducerPath: 'timeTrackingApi',
    baseQuery: graphqlBaseQuery,
    endpoints: builder => ({
        createTimeRecord: builder.mutation<TimeRecord, CreateTimeRecordInput>({
            query: ({ task_id, time_in_seconds, date, user }) => ({
                document: CREATE_TIME_RECORD,
                variables: { input: { task_id, time_in_seconds, date, user_id: user?.id } },
            }),
            transformResponse: (response: { createTimeRecord: TimeRecord }) => response.createTimeRecord,
            async onQueryStarted({ task_id, time_in_seconds, user: user }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: task_id.toString() }, draft => {
                            const userTime = draft.time_recorded_by_users.find(time => time.user.id === user?.id);
                            if (userTime) {
                                userTime.total_time_in_seconds += time_in_seconds;
                            } else {
                                if (user) {
                                    draft.time_recorded_by_users.push({
                                        total_time_in_seconds: time_in_seconds,
                                        user: {
                                            id: user.id,
                                            name: user.name,
                                            avatarUrl: user.avatarUrl,
                                            guild: user.guild,
                                        },
                                    });
                                }
                            }
                        }),
                    );
                } catch (error) {
                    console.error('Error updating time record:', error);
                    enqueueSnackbar('Не удалось добавить время', { variant: 'error' });
                }
            },
        }),
        updateTimeRecord: builder.mutation<TimeRecord, UpdateTimeRecordInput>({
            query: ({ id, time_in_seconds, user_id }) => ({
                document: UPDATE_TIME_RECORD,
                variables: { input: { id, time_in_seconds, user_id } },
            }),
            transformResponse: (response: { updateTimeRecord: TimeRecord }) => response.updateTimeRecord,
            async onQueryStarted({ time_in_seconds, user_id, task_id }, { dispatch }) {
                try {
                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: task_id }, draft => {
                            const userTime = draft.time_recorded_by_users.find(time => time.user.id === user_id);
                            if (userTime) {
                                userTime.total_time_in_seconds = time_in_seconds;
                            } else {
                                // If the user time entry doesn't exist, we might want to add it
                                // This would require fetching the user info, which is not available in this context
                                console.warn(`User time entry not found for user_id: ${user_id}`);
                            }
                        }),
                    );
                } catch (error) {
                    console.error('Error updating time record:', error);
                    enqueueSnackbar('Не удалось обновить время', { variant: 'error' });
                }
            },
        }),
    }),
});

export const { useCreateTimeRecordMutation, useUpdateTimeRecordMutation } = timeTrackingApi;
