import { createApi } from '@reduxjs/toolkit/query/react';

import { CREATE_SPACE, UPDATE_SPACE, DELETE_SPACE, GET_PROJECTS_WITH_FOLDERS } from '~/graphql/spaces';

import { graphqlBaseQuery } from '~/store/graphqlBaseQuery';

import { ProjectSpace } from '~/types/project/ProjectInfo';

export interface SpaceProject {
    id: string;
    name: string;
}

export interface SpaceFolder {
    id: string;
    name: string;
    projects: SpaceProject[];
}

export interface Space {
    id: string;
    name: string;
    folders: SpaceFolder[];
    projects: SpaceProject[];
}

export const spacesApi = createApi({
    baseQuery: graphqlBaseQuery,
    reducerPath: 'spacesApi',
    tagTypes: ['Spaces'],
    endpoints: builder => ({
        createSpace: builder.mutation<ProjectSpace, { name: string }>({
            query: ({ name }) => ({
                document: CREATE_SPACE,
                variables: { input: { name, is_private: false, is_archived: false } },
            }),
            transformResponse: (response: { createSpace: ProjectSpace }) => response.createSpace,
            invalidatesTags: [{ type: 'Spaces', id: 'LIST' }],
        }),
        updateSpace: builder.mutation<ProjectSpace, { id: string; name: string }>({
            query: ({ id, name }) => ({
                document: UPDATE_SPACE,
                variables: { id, input: { name } },
            }),
            transformResponse: (response: { updateSpace: ProjectSpace }) => response.updateSpace,
            invalidatesTags: [{ type: 'Spaces', id: 'LIST' }],
        }),
        deleteSpace: builder.mutation<void, { id: string }>({
            invalidatesTags: [{ type: 'Spaces', id: 'LIST' }],
            query: ({ id }) => ({
                document: DELETE_SPACE,
                variables: { id },
            }),
        }),
        getSpaces: builder.query<Space[], void>({
            providesTags: [{ type: 'Spaces', id: 'LIST' }],
            query: () => ({ document: GET_PROJECTS_WITH_FOLDERS }),
            transformResponse: (response: { spaces: { data: Space[] } }) => response.spaces.data,
        }),
    }),
});

export const { useCreateSpaceMutation, useUpdateSpaceMutation, useDeleteSpaceMutation, useGetSpacesQuery } = spacesApi;
