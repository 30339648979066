import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from '~/store/api/authApi';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';

interface UserState {
    isLoggedIn: boolean;
    currentUser: ShortUserInfo | null;
}

const initialState: UserState = {
    isLoggedIn: true,
    currentUser: {
        id: '-1',
        name: 'Unknown',
        avatarUrl: null,
        guild: null,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: state => {
            state.isLoggedIn = false;
            state.currentUser = null;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.googleSSOAuth.matchFulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.currentUser = action.payload;
        });
    },
});

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['currentUser', 'isLoggedIn'],
};

const persistedUserReducer = persistReducer(userPersistConfig, userSlice.reducer);

export const { logout } = userSlice.actions;
export default persistedUserReducer;
