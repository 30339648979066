import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomField } from '~/types/task/CustomField';

interface CustomFieldsState {
    customFields: CustomField[];
}

const initialState: CustomFieldsState = {
    customFields: [],
};

const taskCustomFieldsSlice = createSlice({
    name: 'taskCustomFields',
    initialState,
    reducers: {
        setCustomFields: (state, action: PayloadAction<CustomField[]>) => {
            state.customFields = action.payload;
        },
        updateCustomField: (state, action: PayloadAction<{ fieldName: string; value: string }>) => {
            state.customFields = (state.customFields || []).map(field =>
                field.name === action.payload.fieldName ? { ...field, value: action.payload.value } : field,
            );
        },
    },
});

export const { setCustomFields, updateCustomField } = taskCustomFieldsSlice.actions;

export default taskCustomFieldsSlice.reducer;
