import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { TextEditor, TextEditorRef } from '../shared/TextEditor';
import SendIcon from '../../assets/icons/send.svg?react';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/store';
import { multiPlatformSpecialEnter } from '~/utils/keyboard';

export interface Attachment {
    id: string;
    name: string;
    type: string;
    url: string;
}

interface CommentSectionProps {
    onAddComment: (comment: { content: string; attachments: Attachment[]; author: string; avatar: string | null; date: string }) => void;
}

export const CommentSection: React.FC<CommentSectionProps> = ({ onAddComment }) => {
    const [commentText, setCommentText] = useState('');
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [isSending, setIsSending] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const textEditorRef = useRef<TextEditorRef>(null);
    const currentUser = useSelector((state: RootState) => state.user.currentUser);

    const handleTextChange = (value: string) => {
        const visibleText = countVisibleCharacters(value);
        if (visibleText.length <= 10000) {
            setCommentText(value);
        }
    };

    const countVisibleCharacters = (html: string) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    const isCommentEmpty = () => {
        return countVisibleCharacters(commentText).trim().length === 0;
    };

    const handleSendComment = async () => {
        if ((!isCommentEmpty() || attachments.length > 0) && !isSending) {
            setIsSending(true);

            try {
                await onAddComment({
                    content: commentText.trim(),
                    attachments,
                    author: currentUser?.name ?? '',
                    avatar: currentUser?.avatarUrl ?? null,
                    date: new Date().toLocaleString(),
                });

                setCommentText('');
                textEditorRef.current?.clearContent();
                setAttachments([]);
            } catch (error) {
                console.error('Error sending comment:', error);
                enqueueSnackbar('Ошибка при отправке комментария', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            } finally {
                setIsSending(false);
            }
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newAttachments = Array.from(files).map(file => ({
                id: Date.now().toString(),
                name: file.name,
                type: file.type,
                url: URL.createObjectURL(file),
            }));
            setAttachments([...attachments, ...newAttachments]);
        }
    };

    const handleTextEditorKeyDown = multiPlatformSpecialEnter(handleSendComment);

    return (
        <StyledCommentSection>
            <StyledCommentInput>
                <TextEditorWrapper>
                    <TextEditor
                        ref={textEditorRef}
                        value={commentText}
                        onChange={handleTextChange}
                        placeholder="Комментарий"
                        handleKeyDown={handleTextEditorKeyDown}
                    />
                </TextEditorWrapper>
                {/* {attachments.length > 0 && (
          <AttachmentsList>
            {attachments.map((attachment) => (
              <AttachmentItem key={attachment.id}>
                <img src={`/assets/icons/${attachment.type}.svg`} alt={attachment.name} />
                <span>{attachment.name}</span>
              </AttachmentItem>
            ))}
          </AttachmentsList>
        )} */}
                <ButtonsWrapper>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                    <SendButton onClick={handleSendComment} disabled={(isCommentEmpty() && attachments.length === 0) || isSending}>
                        {isSending ? (
                            <LoaderWrapper>
                                <Loader />
                            </LoaderWrapper>
                        ) : (
                            <SendIcon color="#fff" />
                        )}
                    </SendButton>
                </ButtonsWrapper>
            </StyledCommentInput>
        </StyledCommentSection>
    );
};

const StyledCommentSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px 16px;

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const StyledCommentInput = styled.div`
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 12px 16px;
    border: 1px solid #e9ecef;
    gap: 8px;
    width: 100%; // Добавляем ширину 100%

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

// const CharCounter = styled.div`
//     font-size: 12px;
//     color: #868e96;
//     text-align: right;
//     margin-top: 4px;
// `;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    align-self: self-end;
`;

const SendButton = styled.button<{ disabled: boolean }>`
    border-radius: 8px;
    background-color: ${props => (props.disabled ? '#E9ECEF' : '#868E96')};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Loader = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

// const AttachmentsList = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
//   margin-top: 8px;
// `;

// const AttachmentItem = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #f1f3f5;
//   border-radius: 4px;
//   padding: 4px 8px;
//   font-size: 12px;

//   img {
//     width: 16px;
//     height: 16px;
//     margin-right: 8px;
//   }

//   span {
//     color: #212529;
//     font-weight: 500;
//   }
// `;

const TextEditorWrapper = styled.div`
    flex-grow: 1;
    min-width: 0; // Это важно для корректной работы flex-grow
`;
