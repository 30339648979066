import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { EstimateSelector } from './EstimateSelector';
import { TaskStatusNew } from '~/types/task/TaskStatus.ts';
import { format, isToday, isTomorrow, isYesterday, isBefore, isThisYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { UserAvatarGroup } from '../UserAvatarGroup';
import { CustomDatePicker } from '../CustomDatepicker';
import ClearIcon from '~/assets/icons/clear.svg?react';
import { NewTaskStatusButton } from '../TaskCreationModal/NewTaskStatusButton';
import { EnhancedPickUserCombobox } from '../EnhancedPickUserCombobox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/store';
import { UpdateTaskFields } from '~/graphql/tasks';
import { setAssignees, setEstimateEditing, updateAssignees, updateDetailsState } from '~/store/slices/taskDetails/taskPropertiesSlice';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '../shared/CloseSnackbarAction';
import { debounce } from 'lodash';
import { updateTaskDetails } from '~/store/slices/taskDetails/taskDetailsSlice';

interface TaskPropertiesProps {}

export const TaskProperties: React.FC<TaskPropertiesProps> = ({}) => {
    const dispatch = useDispatch();

    const { taskId, assignableUsers, users } = useSelector((state: RootState) => state.taskDetails);
    const { detailsState } = useSelector((state: RootState) => state.taskProperties);

    if (!detailsState) {
        return null;
    }

    const [localDateRange, setLocalDateRange] = useState<[Date | null, Date | null]>([
        detailsState.dates[0] ? new Date(detailsState.dates[0]) : null,
        detailsState.dates[1] ? new Date(detailsState.dates[1]) : null,
    ]);
    const abortControllerRef = useRef<AbortController | null>(null);

    const handleUpdateTask = useCallback(
        async (updatedFields: Partial<UpdateTaskFields>) => {
            if (!taskId) {
                return;
            }

            const cleanedFields = Object.fromEntries(
                Object.entries(updatedFields).filter(([]) => {
                    return true;
                }),
            );

            if (Object.keys(cleanedFields).length === 0) {
                return;
            }

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            abortControllerRef.current = new AbortController();

            try {
                await dispatch(updateTaskDetails({ taskId: taskId, updatedFields: cleanedFields }) as any);
            } catch (error: unknown) {
                if (error instanceof Error && error.name !== 'AbortError') {
                    enqueueSnackbar('Ошибка при обновлении таска.', {
                        variant: 'error',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                }
            }
        },
        [taskId, dispatch],
    );

    const handleDateRangeChange = async (newDateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = newDateRange;

        const updatedFields: Partial<UpdateTaskFields> = {
            due_date: newEndDate ? newEndDate.toISOString() : null,
            start_date: newStartDate ? newStartDate.toISOString() : null,
        };

        dispatch(updateDetailsState(updatedFields));
        try {
            await handleUpdateTask(updatedFields);
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении дат таска.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleStatusChange = async (newStatus: TaskStatusNew) => {
        dispatch(updateDetailsState({ status: newStatus }));
        try {
            await handleUpdateTask({ status: newStatus });
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении статуса таска.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleEstimateChange = (value: number | null) => {
        dispatch(updateDetailsState({ estimate: value }));
    };

    const handleEstimateEditStart = () => {
        dispatch(setEstimateEditing(true));
    };

    const handleEstimateEditEnd = async () => {
        dispatch(setEstimateEditing(false));
        if (detailsState.estimate !== null) {
            try {
                await handleUpdateTask({ estimate: detailsState.estimate || null });
            } catch (error) {
                enqueueSnackbar('Ошибка при обновлении эстимейта таска.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        }
    };

    const debouncedUpdateAssignees = useCallback(
        debounce(async (taskId: string, userIds: string[]) => {
            try {
                await dispatch(updateAssignees({ taskId, userIds }) as any).unwrap();
            } catch (error) {
                enqueueSnackbar('Ошибка при обновлении исполнителей.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        }, 500),
        [dispatch, enqueueSnackbar],
    );

    const handleAssigneesChange = useCallback(
        (newAssignees: string[]) => {
            const assignedUsers = users.filter(user => newAssignees.includes(user.id));
            dispatch(setAssignees(assignedUsers));

            if (taskId) {
                debouncedUpdateAssignees(taskId, newAssignees);
            }
        },
        [assignableUsers, users, dispatch, taskId, debouncedUpdateAssignees],
    );

    const formatDate = (date: Date | null) => {
        if (!date) return '';
        if (isToday(date)) return 'Сегодня';
        if (isTomorrow(date)) return 'Завтра';
        if (isYesterday(date)) return 'Вчера';

        const formatStr = isThisYear(date) ? 'd MMM' : 'd MMM yy';
        return format(date, formatStr, { locale: ru });
    };

    const isDateOverdue = (startDate: Date | null, endDate: Date | null) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (endDate) {
            return isBefore(endDate, today);
        } else if (startDate) {
            return isBefore(startDate, today);
        }

        return false;
    };

    const customInput = (
        <DeadlineButton
            $isOverdue={isDateOverdue(localDateRange[0], localDateRange[1])}
            style={{
                padding: '4px 8px 4px 0px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '14px',
                width: '100%',
                textAlign: 'left',
            }}
        >
            {localDateRange[1]
                ? localDateRange[0]
                    ? `${formatDate(localDateRange[0])} - ${formatDate(localDateRange[1])}`
                    : formatDate(localDateRange[1])
                : localDateRange[0]
                  ? formatDate(localDateRange[0])
                  : 'Выберите дату'}
        </DeadlineButton>
    );

    const handleDateChange = (dates: [Date | null, Date | null]) => {
        setLocalDateRange(dates);
        handleDateRangeChange(dates);
    };

    const getDeadlineLabel = () => {
        if (localDateRange[0] && localDateRange[1]) {
            return 'Даты';
        } else if (localDateRange[0]) {
            return 'Дата начала';
        } else if (localDateRange[1]) {
            return 'Дата окончания';
        } else {
            return 'Даты';
        }
    };

    const handleClearDateRange = () => {
        console.log('handleClearDateRange');
        handleDateChange([null, null]);
        setLocalDateRange([null, null]);
    };

    return (
        <StyledTaskProperties>
            <Column>
                <Status>
                    <Label>Статус</Label>
                    <NewTaskStatusButton
                        status={detailsState.status}
                        allowedStatuses={detailsState.allowedStatuses}
                        onChange={newStatus => handleStatusChange(newStatus)}
                    />
                </Status>
                <Deadline>
                    <Label>{getDeadlineLabel()}</Label>
                    <CustomDatePicker initialDateFrom={localDateRange[0]} initialDateTo={localDateRange[1]} onDateRangeSelected={handleDateChange}>
                        {customInput}
                    </CustomDatePicker>
                    {(localDateRange[0] || localDateRange[1]) && (
                        <ClearButton onClick={handleClearDateRange}>
                            <ClearIcon width={16} height={16} />
                        </ClearButton>
                    )}
                </Deadline>
            </Column>
            <Column>
                <StyledAssignee>
                    <Label>Исполнитель</Label>
                    <EnhancedPickUserCombobox
                        data={assignableUsers}
                        value={detailsState.assignees}
                        onChange={newAssignees => handleAssigneesChange(newAssignees.map(user => user.id))}
                    >
                        {({ toggleDropdown }) => (
                            <FilterButton $active={detailsState.assignees.length > 0} onClick={() => toggleDropdown()}>
                                {detailsState.assignees.length > 0 ? (
                                    <UserAvatarGroup
                                        users={detailsState.assignees}
                                        max={3}
                                        onUserRemoved={newAssignees => handleAssigneesChange(newAssignees.map(user => user.id))}
                                    />
                                ) : (
                                    'Не выбрано'
                                )}
                            </FilterButton>
                        )}
                    </EnhancedPickUserCombobox>
                </StyledAssignee>
                <TimeEstimate>
                    <Label>Эстимейт</Label>
                    <EstimateSelector
                        value={detailsState.estimate}
                        onChange={handleEstimateChange}
                        isEditing={detailsState.isEstimateEditing}
                        onEditStart={handleEstimateEditStart}
                        onEditEnd={handleEstimateEditEnd}
                        onSave={() => handleUpdateTask({ estimate: detailsState.estimate })}
                    />
                </TimeEstimate>
            </Column>
        </StyledTaskProperties>
    );
};

const StyledTaskProperties = styled.div`
    display: flex;
    padding-bottom: 32px;
    align-items: flex-start;
    gap: 16px;
    font-weight: 400;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Column = styled.div`
    display: flex;
    min-width: 320px;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    flex-basis: 0%;
`;

const Status = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const Label = styled.label`
    width: 120px;
    min-width: 120px;
    color: #868e96;
    font-size: 14px;
    line-height: 1;
    margin: auto 0;
`;

const Deadline = styled.div`
    display: flex;
    margin-top: 16px;
    width: 100%;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    justify-content: flex-start;
`;

const StyledAssignee = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    color: #868e96;
    justify-content: flex-start;

    @media (max-width: 991px) {
        white-space: initial;
    }
`;

const TimeEstimate = styled.div`
    display: flex;
    margin-top: 16px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 991px) {
        white-space: initial;
    }
`;

const DeadlineButton = styled.button<{ $isOverdue: boolean }>`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
    color: ${props => (props.$isOverdue ? '#dc3545' : '#212529')};
    padding-right: 30px;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: #f1f3f5;
    }
`;

interface FilterButtonProps {
    $active?: boolean;
}

const FilterButton = styled.div<FilterButtonProps>`
  align-self: center;
  display: flex;
  min-height: 24px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  margin: auto 0;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:not(:disabled) {
    &:hover {
      color: rgba(76, 110, 245, 0.8);
    }

    ${({ $active }) =>
        $active &&
        css`
            color: rgba(76, 110, 245, 1);
        `}
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
    height: 32px
    background-color: transparent;
    color: #495057;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
`;

const ClearButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
