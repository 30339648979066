import { ShortUserInfo } from '../user/ShortUserInfo';

export enum CustomFieldType {
    Dropdown = 'Dropdown',
    Phone = 'Phone',
    ShortText = 'ShortText',
    Url = 'Url',
    Number = 'Number',
    Money = 'Money',
    Email = 'Email',
    People = 'People',
}

export interface CustomField {
    type: CustomFieldType;
    name: string; // name of affecting field
    value: string;
    options: Array<{ label: string; value: string }>;
}

export interface CustomFieldViewModel {
    type: CustomFieldType;
    name: string;
    value: string | ShortUserInfo[];
    label: string;
    options: Array<{ label: string; value: string }> | ShortUserInfo[];
}

export const mapCustomFieldToViewModel = (field: CustomField, allUsers: ShortUserInfo[], assignableUsers: ShortUserInfo[]): CustomFieldViewModel => ({
    type: field.type,
    name: field.name,
    value:
        field.type === CustomFieldType.People
            ? (() => {
                  try {
                      const parsedValue = JSON.parse(field.value);
                      return parsedValue.map((userId: string) => allUsers.find(user => user.id === userId)).filter(Boolean);
                  } catch (error) {
                      console.error(`Error parsing JSON for People field: ${error}`);
                      return [];
                  }
              })()
            : (field.value ?? ''),
    label:
        field.type === CustomFieldType.Dropdown && field.options
            ? field.options.find(option => option.value === field.value)?.label || field.value
            : '',
    options: field.type === CustomFieldType.People ? assignableUsers : field.options,
});
