import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
    query GetProjects {
        projects(all: true) {
            data {
                id
                name
                allowedTaskStatuses
                members {
                    id
                    name
                    avatar_url
                }
                is_private
                space {
                    id
                    name
                    is_private
                }
            }
        }
    }
`;

export const CREATE_PROJECT = gql`
    mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            id
            name
            folder_id
            space_id
            is_archived
            is_private
        }
    }
`;

export const DELETE_PROJECT = gql`
    mutation DeleteProject($id: String!) {
        deleteProject(id: $id)
    }
`;

export const GET_PROJECT_INFO = gql`
    query GetProjectInfo($id: String!) {
        projects(id: $id) {
            data {
                id
                name
                folder {
                    id
                    name
                }
                space {
                    name
                    id
                }
                members {
                    id
                    name
                    avatar_url
                }
                allowedTaskStatuses
                is_private
            }
        }
    }
`;

export const GET_PROJECT_TASKS = gql`
    fragment UserFields on User {
        id
        name
        avatar_url
    }

    fragment TaskFields on Task {
        id
        name
        status
        start_date
        parent_id
        due_date
        estimate
        assignees {
            ...UserFields
        }
        total_time_recorded
    }

    query GetProjectTasks($filters: TaskFilters!, $projectId: String!) {
        tasks(all: true, filters: $filters) {
            data {
                ...TaskFields
                children {
                    ...TaskFields
                }
            }
        }
        projects(id: $projectId) {
            data {
                allowedTaskStatuses
            }
        }
    }
`;

export const SHARING_PROJECT_FIELDS = `
  id
  name
  is_private
  folder {
    id
    name
  }
  space {
    id
    name
  }
  allowedTaskStatuses
  members {
    id
    name
    avatar_url
  }
`;

export const GET_PROJECT_SHARING = gql`
  query GetProjectSharing($id: String!) {
    projects(id: $id) {
      data {
         ${SHARING_PROJECT_FIELDS}
      }
    }
  }
`;
export const GET_PROJECTS_BY_NAME = gql`
    query GetProjectsByName($name: String!) {
        projects(all: true, filters: { name: $name }) {
            data {
                id
                name
                space {
                    id
                    name
                }
            }
        }
    }
`;
