import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TaskFull } from '~/types/task/TaskFull';
import { tasksApi } from '../../api/tasksApi';
import { assigneesApi } from '../../api/assigneesApi';
import { UpdateTaskFields } from '../../../graphql/tasks';
import { TaskStatusNew } from '../../../types/task/TaskStatus';
import { AppDispatch, RootState } from '../../store';
import { userListApi } from '../../api/userListApi';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { format, parseISO } from 'date-fns';
import { Task } from '~/types/task/Task';
import { TaskType } from '~/types/task/TaskType';
import { CreateTaskInput } from '~/graphql/tasks';
import { getProjectAvailableUsersType, ProjectUserType } from '~/utils/projectUtils';
import { setHeadingState } from './taskHeadingSlice';
import { setDetailsState } from './taskPropertiesSlice';
import { setCustomFields } from './taskCustomFieldsSlice';

type DefaultThunkConfig = {
    state: RootState;
};

interface SubtaskState {
    isSubtaskInputVisible: boolean;
    newSubtaskName: string;
}

interface UserTimeSpentEntry {
    formattedTime: string;
    user: ShortUserInfo;
}

interface TaskDetailsState {
    taskId: string | null;
    syncedTask: TaskFull | null;
    currentTask: TaskFull | null;
    isLoading: boolean;
    error: string | null;
    isUpdating: boolean;
    updateError: string | null;
    localDescription: string;
    users: ShortUserInfo[];
    assignableUsers: ShortUserInfo[];
    isUsersLoading: boolean;
    isSubtaskInputVisible: boolean;
    subtasksState: SubtaskState;
}

const initialState: TaskDetailsState = {
    taskId: null,
    syncedTask: null,
    currentTask: null,
    isLoading: false,
    error: null,
    isUpdating: false,
    updateError: null,
    localDescription: '',
    users: [],
    assignableUsers: [],
    isUsersLoading: false,
    isSubtaskInputVisible: false,
    subtasksState: {
        isSubtaskInputVisible: false,
        newSubtaskName: '',
    },
};

const taskDetailsSlice = createSlice({
    name: 'taskDetails',
    initialState,
    reducers: {
        setTaskId: (state, action: PayloadAction<string | null>) => {
            state.taskId = action.payload;
        },
        setCurrentTask: (state, action: PayloadAction<TaskFull | null>) => {
            state.currentTask = action.payload;
            state.syncedTask = action.payload;
            if (action.payload) {
                state.localDescription = action.payload.description || '';
            }
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setUpdating: (state, action: PayloadAction<boolean>) => {
            state.isUpdating = action.payload;
        },
        setUpdateError: (state, action: PayloadAction<string | null>) => {
            state.updateError = action.payload;
        },
        updateTaskComments: (state, action: PayloadAction<TaskFull['comments']>) => {
            if (state.currentTask) {
                state.currentTask.comments = action.payload;
            }
        },
        setLocalDescription: (state, action: PayloadAction<string>) => {
            state.localDescription = action.payload;
        },
        setUsers: (state, action: PayloadAction<{ allUsers: ShortUserInfo[]; assignableUsers: ShortUserInfo[] }>) => {
            state.users = action.payload.allUsers;
            state.assignableUsers = action.payload.assignableUsers;
        },
        setUsersLoading: (state, action: PayloadAction<boolean>) => {
            state.isUsersLoading = action.payload;
        },
        setSyncedAssignees: (state, action: PayloadAction<ShortUserInfo[]>) => {
            if (state.syncedTask) {
                state.syncedTask.assignee = action.payload;
            }
        },
        updateTaskTimeSpent: (state, action: PayloadAction<{ taskId: string; timeSpent: number; user: ShortUserInfo }>) => {
            if (state.currentTask?.id === action.payload.taskId) {
                const existingEntry = state.currentTask.time_recorded_by_users.find(entry => entry.user.id === action.payload.user.id);
                if (existingEntry) {
                    existingEntry.total_time_in_seconds += action.payload.timeSpent;
                } else {
                    state.currentTask.time_recorded_by_users.push({
                        user: action.payload.user,
                        total_time_in_seconds: action.payload.timeSpent,
                    });
                }

                const existingSyncedEntry = state.syncedTask?.time_recorded_by_users.find(entry => entry.user.id === action.payload.user.id);
                if (existingSyncedEntry) {
                    existingSyncedEntry.total_time_in_seconds += action.payload.timeSpent;
                } else {
                    state.syncedTask?.time_recorded_by_users.push({
                        user: action.payload.user,
                        total_time_in_seconds: action.payload.timeSpent,
                    });
                }
            }
        },
        setSubtaskInputVisible: (state, action: PayloadAction<boolean>) => {
            state.subtasksState.isSubtaskInputVisible = action.payload;
        },
        setNewSubtaskName: (state, action: PayloadAction<string>) => {
            state.subtasksState.newSubtaskName = action.payload;
        },
        addSubtask: (state, action: PayloadAction<Task>) => {
            if (state.currentTask) {
                state.currentTask.children = [...(state.currentTask.children || []), action.payload];
            }
        },
        updateSubtask: (state, action: PayloadAction<Task>) => {
            if (state.currentTask) {
                state.currentTask.children = (state.currentTask.children || []).map(subtask =>
                    subtask.id === action.payload.id ? action.payload : subtask,
                );
            }
        },
        updateSubtaskField: (state, action: PayloadAction<{ subtaskId: string; fields: Partial<UpdateTaskFields> }>) => {
            if (state.currentTask && state.currentTask.children) {
                state.currentTask.children = state.currentTask.children.map(subtask => {
                    if (subtask.id === action.payload.subtaskId) {
                        return {
                            ...subtask,
                            startDate: action.payload.fields.start_date !== undefined ? action.payload.fields.start_date : subtask.startDate,
                            dueDate: action.payload.fields.due_date !== undefined ? action.payload.fields.due_date : subtask.dueDate,
                            status: action.payload.fields.status ? (action.payload.fields.status as TaskStatusNew) : subtask.status,
                        };
                    }
                    return subtask;
                });
            }
        },
        deleteSubtask: (state, action: PayloadAction<string>) => {
            if (state.currentTask) {
                state.currentTask.children = (state.currentTask.children || []).filter(subtask => subtask.id !== action.payload);
            }
        },
        replaceTask: (state, action: PayloadAction<{ fakeId: string; realTask: TaskFull }>) => {
            if (state.currentTask) {
                state.currentTask.children = (state.currentTask.children || []).map(subtask =>
                    subtask.id === action.payload.fakeId ? action.payload.realTask : subtask,
                );
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(assigneesApi.endpoints.setTaskAssignees.matchFulfilled, (state, action) => {
            if (state.currentTask && state.currentTask.id === action.meta.arg.originalArgs.task_id) {
                state.syncedTask = {
                    ...state.syncedTask!,
                    assignee: action.payload.assignees,
                };
            }
        });
    },
});

export const {
    setTaskId,
    setCurrentTask,
    setLoading,
    setError,
    setUpdating,
    setUpdateError,
    updateTaskComments,
    setLocalDescription,
    setUsers,
    setUsersLoading,
    setSyncedAssignees,
    setSubtaskInputVisible,
    setNewSubtaskName,
    addSubtask,
    updateSubtask,
    updateSubtaskField,
    deleteSubtask,
    replaceTask,
    updateTaskTimeSpent,
} = taskDetailsSlice.actions;

export const fetchTaskDetails = (taskId: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
        const result = await dispatch(tasksApi.endpoints.getTaskById.initiate({ id: taskId }, { subscribe: false, forceRefetch: true }));
        if ('data' in result && result.data) {
            dispatch(setCurrentTask(result.data));
            dispatch(setHeadingState(result.data?.name || ''));
            dispatch(
                setDetailsState(
                    result.data
                        ? {
                              detailsState: {
                                  status: result.data.status,
                                  allowedStatuses: result.data.allowedTaskStatuses,
                                  dates: [result.data.startDate, result.data.dueDate],
                                  assignees: result.data.assignee,
                                  estimate: result.data.estimate,
                                  isEstimateEditing: false,
                              },
                          }
                        : {
                              detailsState: null,
                          },
                ),
            );
            dispatch(setCustomFields(result.data?.customFields ? result.data?.customFields : []));
            dispatch(
                fetchAvailableAssignees(
                    getProjectAvailableUsersType(result.data.project.isPrivate, result.data.project.space.isPrivate),
                    result.data.project.id,
                ),
            );
        } else if ('error' in result) {
            dispatch(setError((result.error as Error).message || 'Произошла ошибка при загрузке задачи'));
        }
    } catch (error) {
        dispatch(setError((error as Error).message || 'Произошла неизвестная ошибка'));
    } finally {
        dispatch(setLoading(false));
    }
};

const fetchAvailableAssignees = (type: ProjectUserType, projectId: string) => async (dispatch: AppDispatch) => {
    try {
        const allUsers = (await dispatch(userListApi.endpoints.getUsers.initiate())).data || [];
        let assignableUsers = allUsers;
        if (type === ProjectUserType.MEMBER) {
            assignableUsers = (await dispatch(userListApi.endpoints.getUsersForProject.initiate(projectId))).data || [];
        }
        dispatch(setUsers({ allUsers, assignableUsers }));
    } catch (error) {
        dispatch(setError((error as Error).message || 'Произошла неизвестная ошибка'));
    } finally {
        dispatch(setLoading(false));
    }
};

export type UpdateTaskDetailsParams = { taskId: string; updatedFields: Partial<UpdateTaskFields> };
export const updateTaskDetails = createAsyncThunk<TaskFull | undefined, UpdateTaskDetailsParams, DefaultThunkConfig>(
    'taskDetails/updateTaskDetails',
    async ({ taskId, updatedFields }, { dispatch, rejectWithValue, getState }) => {
        const state = getState();
        const syncedTask = state.taskDetails.syncedTask;

        try {
            if (!syncedTask) {
                return rejectWithValue('No synced task found');
            }

            // Check if any fields have actually changed compared to the synced task
            const hasChanges = Object.entries(updatedFields).some(([key, value]) => {
                return syncedTask[key as keyof TaskFull] !== value;
            });

            if (!hasChanges) {
                console.log('No changes detected compared to synced task. Skipping update request.');
                return syncedTask;
            }

            // Format dates to YYYY-MM-DD
            const formattedFields = {
                ...updatedFields,
                due_date: updatedFields.due_date !== undefined ? formatDateToYYYYMMDD(updatedFields.due_date) : updatedFields.due_date,
                start_date: updatedFields.start_date !== undefined ? formatDateToYYYYMMDD(updatedFields.start_date) : updatedFields.start_date,
                status: updatedFields.status !== undefined ? updatedFields.status!.name : updatedFields.status,
            };

            dispatch(setUpdating(true));
            const result = await dispatch(tasksApi.endpoints.updateTask.initiate({ id: taskId, input: formattedFields }));

            if ('data' in result) {
                dispatch(setCurrentTask(result.data ?? null));
                dispatch(setHeadingState(result.data?.name || ''));
                dispatch(
                    setDetailsState(
                        result.data
                            ? {
                                  detailsState: {
                                      status: result.data.status,
                                      allowedStatuses: result.data.allowedTaskStatuses,
                                      dates: [result.data.startDate, result.data.dueDate],
                                      assignees: result.data.assignee,
                                      estimate: result.data.estimate,
                                      isEstimateEditing: false,
                                  },
                              }
                            : {
                                  detailsState: null,
                              },
                    ),
                );
                dispatch(setCustomFields(result.data?.customFields ? result.data?.customFields : []));
                return result.data;
            } else if ('error' in result) {
                return rejectWithValue(result.error);
            }
        } catch (error) {
            return rejectWithValue(error);
        } finally {
            dispatch(setUpdating(false));
        }
    },
);

export type UpdateSubtaskDetailsParams = { subtaskId: string; updatedFields: Partial<UpdateTaskFields> };
export const updateSubtaskDetails = createAsyncThunk<Task | undefined, UpdateSubtaskDetailsParams, DefaultThunkConfig>(
    'taskDetails/updateSubtaskTaskDetails',
    async ({ subtaskId, updatedFields }, { dispatch, rejectWithValue, getState }) => {
        const state = getState();
        const syncedTask = state.taskDetails.syncedTask;
        try {
            if (!syncedTask) {
                return rejectWithValue('No synced task found');
            }

            const subtask = syncedTask.children?.find(task => task.id == subtaskId);
            if (!subtask) {
                return rejectWithValue('No subtask found');
            }

            // Check if any fields have actually changed compared to the synced task
            const hasChanges = Object.entries(updatedFields).some(([key, value]) => {
                return subtask[key as keyof Task] !== value;
            });

            if (!hasChanges) {
                console.log('No changes detected compared to synced task. Skipping update request.');
                return subtask;
            }

            // Format dates to YYYY-MM-DD
            const formattedFields = {
                ...updatedFields,
                due_date: updatedFields.due_date !== undefined ? formatDateToYYYYMMDD(updatedFields.due_date) : updatedFields.due_date,
                start_date: updatedFields.start_date !== undefined ? formatDateToYYYYMMDD(updatedFields.start_date) : updatedFields.start_date,
                status: updatedFields.status !== undefined ? updatedFields.status!.name : updatedFields.status,
            };

            dispatch(setUpdating(true));
            const result = await dispatch(tasksApi.endpoints.updateTask.initiate({ id: subtaskId, input: formattedFields, parentId: syncedTask.id }));
            if ('data' in result && result.data) {
                dispatch(updateSubtask(result.data));
                return result.data;
            } else if ('error' in result) {
                return rejectWithValue(result.error);
            }
        } catch (error) {
            return rejectWithValue(error);
        } finally {
            dispatch(setUpdating(false));
        }
    },
);

function formatDateToYYYYMMDD(date: Date | string | null): string | null {
    if (date === null) {
        return null;
    }
    if (typeof date === 'string') {
        return format(parseISO(date), 'yyyy-MM-dd');
    }
    return format(date, 'yyyy-MM-dd');
}

const formatRecordedTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += `${hours}h `;
    }
    if (minutes > 0 || (hours === 0 && minutes === 0)) {
        formattedTime += `${minutes}m`;
    }

    return formattedTime.trim();
};

export const selectMappedComments = (state: RootState) => {
    const { currentTask } = state.taskDetails;
    const currentUser = state.user.currentUser;

    if (!currentTask || !currentUser) return [];

    return currentTask.comments.map(comment => ({
        comment,
        canBeEdited: currentUser.id === comment.author.id,
        canBeDeleted: currentUser.id === comment.author.id, // || currentUser.role === 'admin'
    }));
};

export const addSubtaskAndCreate = createAsyncThunk<TaskFull, string, DefaultThunkConfig>(
    'taskDetails/addSubtaskAndCreate',
    async (taskName: string, { dispatch, getState }) => {
        const state = getState();

        if (!state.taskDetails.currentTask) {
            throw new Error('No current task found');
        }
        if (!state.user.currentUser) {
            throw new Error('No current user found');
        }
        const parentId = state.taskDetails.currentTask?.id ?? null;
        const currentTaskStatus = state.taskDetails.currentTask.allowedTaskStatuses[0];
        const allowedTaskStatuses = state.taskDetails.currentTask!.project.allowedTaskStatuses;
        if (!parentId) {
            throw new Error('No parent task found');
        }

        const fakeSubtask: Task = {
            id: Date.now().toString(),
            name: taskName,
            type: TaskType.DEFAULT,
            status: currentTaskStatus!,
            startDate: null,
            dueDate: null,
            estimate: null,
            createdAt: null,
            timeSpent: 0,
            totalTime: 0,
            assignee: [],
            children: [],
            parentId: parentId,
            parent: state.taskDetails.currentTask,
            allowedTaskStatuses: allowedTaskStatuses,
        };

        dispatch(addSubtask(fakeSubtask));
        dispatch(setNewSubtaskName(''));
        dispatch(setSubtaskInputVisible(false));

        const createTaskInput: CreateTaskInput = {
            name: taskName,
            project_id: state.taskDetails.currentTask?.project.id,
            description: null,
            status: currentTaskStatus!.name,
            due_date: null,
            start_date: null,
            estimate: null,
            parent_id: parentId,
        };

        const result = await dispatch(tasksApi.endpoints.createTask.initiate(createTaskInput));

        if ('error' in result) {
            dispatch(deleteSubtask(fakeSubtask.id));
            throw new Error('Failed to create task on server');
        }
        dispatch(replaceTask({ fakeId: fakeSubtask.id, realTask: result.data }));
        return result.data;
    },
);

export const deleteSubtaskAsync = createAsyncThunk<boolean, string, DefaultThunkConfig>(
    'taskDetails/deleteSubtaskAndDelete',
    async (subtaskId: string, { dispatch, getState }) => {
        const state = getState();
        if (!state.taskDetails.currentTask) {
            throw new Error('No current task found');
        }
        try {
            const result = await dispatch(
                tasksApi.endpoints.deleteTask.initiate({ id: subtaskId, projectId: state.taskDetails.currentTask.project.id }),
            );
            if ('error' in result) {
                return false;
            }
            dispatch(deleteSubtask(subtaskId));
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
);

export type RenameSubtaskAndUpdateParams = { subtaskId: string; subtaskName: string };
export const renameSubtaskAndUpdate = createAsyncThunk<Task, RenameSubtaskAndUpdateParams, DefaultThunkConfig>(
    'taskDetails/renameSubtaskAndUpdate',
    async ({ subtaskId, subtaskName }: { subtaskId: string; subtaskName: string }, { dispatch, getState }) => {
        const state = getState();
        if (!state.taskDetails.currentTask) {
            throw new Error('No current task found');
        }
        const subtask = state.taskDetails.currentTask.children?.find(child => child.id === subtaskId);
        if (!subtask) {
            throw new Error('No subtask found');
        }
        const result = await dispatch(tasksApi.endpoints.updateTask.initiate({ id: subtaskId, input: { name: subtaskName } }));
        if ('error' in result) {
            throw new Error('Failed to update subtask');
        }
        dispatch(replaceTask({ fakeId: subtaskId, realTask: result.data }));
        return result.data;
    },
);

export const selectMyTimeSpent = (state: RootState): UserTimeSpentEntry | null => {
    const { currentTask } = state.taskDetails;
    const currentUser = state.user.currentUser;

    if (!currentTask || !currentUser) return null;

    console.log(currentTask.time_recorded_by_users);

    const myTimeEntries = currentTask.time_recorded_by_users.filter(entry => entry.user.id === currentUser.id);
    const totalTimeInSeconds = myTimeEntries.reduce((total, entry) => total + entry.total_time_in_seconds, 0);

    return {
        formattedTime: formatRecordedTime(totalTimeInSeconds),
        user: {
            id: currentUser.id,
            name: currentUser.name,
            avatarUrl: currentUser.avatarUrl,
            guild: currentUser.guild,
        },
    };
};

export const selectUsersTimeSpent = (state: RootState): UserTimeSpentEntry[] => {
    const { currentTask } = state.taskDetails;
    const { currentUser } = state.user;

    if (!currentTask) return [];

    return currentTask.time_recorded_by_users
        .filter(entry => entry.user.id !== currentUser?.id)
        .map(entry => ({
            formattedTime: formatRecordedTime(entry.total_time_in_seconds),
            user: {
                id: entry.user.id,
                name: entry.user.name,
                avatarUrl: entry.user.avatarUrl,
                guild: entry.user.guild,
            },
        }));
};

export default taskDetailsSlice.reducer;
