import React from 'react';
import styled from 'styled-components';

import { CustomFieldViewModel } from '~/types/task/CustomField';

import { CustomFieldIcon } from './CustomField/CustomFieldIcon';
import { EnhancedCustomFieldControl } from './CustomField/CustomFieldControl';
import { getEnhancedCustomFieldLabel } from './CustomField/utils';

interface TaskDetailsProps {
    customFields: CustomFieldViewModel[];
    onCustomFieldChange: (fieldName: string, value: string | null) => void;
    onCustomFieldBlur: (fieldName: string, value: string | null) => void;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ customFields, onCustomFieldChange, onCustomFieldBlur }) => {
    return (
        <Table>
            {customFields.map((field: CustomFieldViewModel) => (
                <TableRow key={field.name}>
                    <TableItemTitle>
                        <CustomFieldIcon type={field.type} />
                        {getEnhancedCustomFieldLabel(field)}
                    </TableItemTitle>
                    <TableItem>
                        <EnhancedCustomFieldControl field={field} onChange={onCustomFieldChange} onBlur={onCustomFieldBlur} />
                    </TableItem>
                </TableRow>
            ))}
        </Table>
    );
};

const Table = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: fit-content;
    margin-top: 32px;

    border-radius: 4px;
    border: 1px solid #dee2e6;

    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    > :not(:last-child) {
        border-bottom: 1px solid #dee2e6;
    }
`;

const TableRow = styled.div`
    display: flex;

    > :first-child {
        padding: 12px;
        border-right: 1px solid #dee2e6;
    }
`;

const TableItemTitle = styled.div`
    display: flex;
    flex-basis: 250px;
    align-items: center;
    gap: 8px;
`;

const TableItem = styled.div`
    flex-grow: 1;
`;
