import React from 'react';
import styled from 'styled-components';
import logoSvg from '../../assets/google-logo.svg';

interface SSOButtonProps {
    onClick: () => void;
}

export const SSOButton: React.FC<SSOButtonProps> = ({ onClick }) => {
    return (
        <StyledSSOButton onClick={onClick}>
            <ButtonContent>
                <ButtonIcon loading="lazy" src={logoSvg} />
                <ButtonText>Войти через SSO</ButtonText>
            </ButtonContent>
        </StyledSSOButton>
    );
};

const StyledSSOButton = styled.button`
    width: fit-content;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.084);
    display: flex;
    margin-top: 56px;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 58px;
    font:
        20px Roboto,
        sans-serif;
    border: none;
    cursor: pointer;

    @media (max-width: 991px) {
        margin-top: 40px;
        padding: 0 20px;
    }
`;

const ButtonContent = styled.div`
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-start;
    padding: 15px;
`;

const ButtonIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
`;

const ButtonText = styled.span`
    flex-grow: 1;
`;
