import styled, { css } from 'styled-components';

export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    padding: 8px 16px;
`;

export const ColumnHeader = styled.div`
    color: var(--Text-dimmed, #868e96);
    font:
        400 14px/1 Inter,
        sans-serif;
`;

export const AddTaskButton = styled.button<{ $asRowItem?: boolean }>`
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    margin: auto 0;
    background: none;
    border: none;
    color: #868e96;
    font:
        500 14px/1 Inter,
        sans-serif;
    cursor: pointer;

    ${({ $asRowItem }) =>
        $asRowItem &&
        css`
            padding: 12px;
            margin-left: 38px;
        `}
`;

export const TaskListContainer = styled.div`
    width: 100%;
    overflow-x: hidden;
`;

export const TaskGroupContainer = styled.section`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 16px;
`;
