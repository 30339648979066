import React from 'react';
import ReactDOM from 'react-dom/client';

import { Button, createTheme, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/ru';

import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import { store, persistor } from './store/store';

import { ErrorBoundary } from '~/components/ErrorBoundary';
import App from './App';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';

import buttonStyles from '~/styles/Button.module.scss';

import './index.css';

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_APP_VERSION,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                stickySession: false,
                maskAllInputs: false,
                maskAllText: false,
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const theme = createTheme({
    components: {
        Button: Button.extend({
            classNames: buttonStyles,
        }),
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <ErrorBoundary>
        <React.StrictMode>
            <MantineProvider theme={theme}>
                <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, timezone: 'UTC' }}>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000}>
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <App />
                            </PersistGate>
                        </Provider>
                    </SnackbarProvider>
                </DatesProvider>
            </MantineProvider>
        </React.StrictMode>
    </ErrorBoundary>,
);
