import { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';

import * as Sentry from '@sentry/react';

import { ErrorFallback } from '~/components/ErrorFallback';

const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const ErrorContent = styled.div`
    width: 800px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f8f8f8;
`;

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        Sentry.captureException(error, { extra: errorInfo as Record<string, unknown> });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorContainer>
                    <ErrorContent>
                        <ErrorFallback error={this.state.error!} />
                    </ErrorContent>
                </ErrorContainer>
            );
        }

        return this.props.children;
    }
}
