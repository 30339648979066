import React from 'react';
import styled, { css } from 'styled-components';
import { Avatar, AvatarProps } from '@mantine/core';

type UserAvatarProps = Pick<AvatarProps, 'size' | 'name' | 'src' | 'color'> & {
    active?: boolean;
};

// TODO: use ShortUserData instead of name and src
export const UserAvatar = React.forwardRef<HTMLDivElement, UserAvatarProps>(({ name, src, active, size = 'sm', color = 'initials' }, ref) => {
    return (
        <div ref={ref}>
            <StyledAvatar $active={active} size={size} name={name} src={src} color={color as AvatarProps['color']} />
        </div>
    );
});

const StyledAvatar = styled(Avatar) <AvatarProps & { $active?: boolean }>`
    ${({ $active }) =>
        $active &&
        css`
            box-shadow: #228be6 0 0 0 1px;
        `}
`;
