import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { useGetProjectInfoQuery, useGetProjectTasksQuery } from '~/store/api/projectApi';
import { useGetUsersQuery } from '~/store/api/userListApi';
import { deleteTaskAsync, setProjectData } from '~/store/slices/projectSlice';
import { selectProjectFilters } from '~/store/slices/projectFiltersSlice.ts';
import { AppDispatch, RootState } from '~/store/store';

import { getProjectAvailableUsersType, ProjectUserType } from '~/utils/projectUtils';

import TaskGroupSkeleton from '~/components/ProjectList/TaskGroupSkeleton';
import TaskGroup from '~/components/ProjectList/TaskGroup';
import FilterBar from '~/components/ProjectList/FilterBar';
import Header from '~/components/ProjectList/Header';
import Timeline from '~/components/ProjectList/Timeline';
import { TaskDeleteModal } from '~/components/shared/TaskDeleteModal';
import { useTaskPresenter } from '~/components/ProjectList/utils/groupBy';

import '../styles/ProjectList.css';

export const ProjectPage: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const navigate = useNavigate();

    const [taskToDelete, setTaskToDelete] = useState<string | null>(null);

    const dispatch = useDispatch<AppDispatch>();
    const isVacationsEnabled = useSelector((state: RootState) => state.commands.isVacationsEnabled);
    const filters = useSelector((state: RootState) => selectProjectFilters(state, projectId));

    const {
        data: projectInfo,
        isFetching: isProjectInfoFetching,
        error: projectInfoError,
    } = useGetProjectInfoQuery(projectId!, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,
    });

    const {
        data: projectTasks,
        isFetching: isProjectTasksFetching,
        error: projectTasksError,
    } = useGetProjectTasksQuery(
        {
            projectId: projectId!,
            showClosed: filters.showClosed,
            conditions: filters.conditions,
        },
        {
            skip: !projectId,
            refetchOnMountOrArgChange: true,
        },
    );

    const {
        data: allUsers,
        isFetching: isAllUsersFetching,
        error: allUsersError,
    } = useGetUsersQuery(undefined, {
        skip: !projectInfo || getProjectAvailableUsersType(projectInfo.isPrivate, projectInfo.space.isPrivate) !== ProjectUserType.ALL,
    });

    const { users } = useSelector((state: RootState) => state.project);

    const rootTasks = useMemo(() => {
        if (!projectTasks || !projectInfo) return [];
        const taskIds = new Set(projectTasks.map(task => task.id));
        const hasClosedStatus = projectInfo.allowedTaskStatuses.some(status => status.name === 'Closed');
        const filteredTasks = projectTasks.filter(task => {
            const isRoot = !task.parentId || !taskIds.has(task.parentId);
            const shouldShow = !hasClosedStatus || filters.showClosed || task.status.name !== 'Closed';
            return (isRoot || filters.showAsSeparateTasks) && shouldShow;
        });
        return filteredTasks;
    }, [projectTasks, filters.showClosed, filters.showAsSeparateTasks, projectInfo]);

    const taskGroups = useTaskPresenter(
        rootTasks,
        filters.groupBy || null,
        filters.sortOrder || null,
        filters.selectedUserIds,
        projectInfo?.allowedTaskStatuses ?? [],
        filters.showClosed,
    );

    const handleTaskDelete = (taskId: string) => {
        setTaskToDelete(taskId);
    };

    const confirmDelete = async () => {
        if (taskToDelete && projectId) {
            try {
                await dispatch(deleteTaskAsync({ taskId: taskToDelete, projectId }));
                enqueueSnackbar('Задача и все её подзадачи успешно удалены', {
                    variant: 'success',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            } catch (error) {
                console.error('Error deleting task:', error);
                enqueueSnackbar('Ошибка при удалении задачи', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
            setTaskToDelete(null);
        }
    };

    useEffect(() => {
        if (!projectInfo || !projectTasks || !projectId) {
            return;
        }
        const projectUsersType = getProjectAvailableUsersType(projectInfo.isPrivate, projectInfo.space.isPrivate);
        const users = projectUsersType === ProjectUserType.ALL ? allUsers || [] : projectInfo.members;
        dispatch(setProjectData({ projectInfo, tasks: projectTasks, users: users }));
    }, [projectId, projectInfo, projectTasks, allUsers, dispatch]);

    useEffect(() => {
        if (projectInfoError || projectTasksError || allUsersError) {
            enqueueSnackbar('Ошибка при загрузке данных проекта', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            navigate('/home');
        }
    }, [projectInfoError, projectTasksError, allUsersError, navigate]);

    if (isProjectInfoFetching || isProjectTasksFetching || (projectInfo && !projectInfo.isPrivate && isAllUsersFetching)) {
        return (
            <div className="project-list">
                <Header />
                <FilterBar />
                <div className="task-groups-container">
                    <TaskGroupSkeleton />
                    <TaskGroupSkeleton />
                    <TaskGroupSkeleton />
                    <TaskGroupSkeleton />
                </div>
            </div>
        );
    }

    if (!projectInfo || !projectTasks) {
        return null;
    }

    // ПРИКОЛ
    const shouldDisplayVacations = isVacationsEnabled && projectInfo.name.toLowerCase().includes('vacations');

    return (
        <div className="project-list">
            <Header projectInfo={projectInfo} />
            <FilterBar />
            {shouldDisplayVacations ? (
                <Timeline tasks={projectTasks} />
            ) : (
                <div className="task-groups-container">
                    {taskGroups.map(group => (
                        <TaskGroup
                            key={`${group.kind}-${group.id}`}
                            data={group}
                            groupingType={filters.groupBy}
                            userList={users}
                            onTaskDelete={handleTaskDelete}
                        />
                    ))}
                </div>
            )}
            <TaskDeleteModal isOpen={taskToDelete !== null} onClose={() => setTaskToDelete(null)} onDelete={confirmDelete} />
        </div>
    );
};
