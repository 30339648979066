import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { useGoogleSSOAuthMutation } from '~/store/api/authApi';

import { Logo } from '~/components/LoginScreen/Logo';
import { Loader } from '~/components/LoginScreen/Loader';
import { DeveloperInfo } from '~/components/LoginScreen/DeveloperInfo';

export const LoginConfirm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [googleSSOAuth, { isError }] = useGoogleSSOAuthMutation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        if (code) {
            googleSSOAuth({ code })
                .unwrap()
                .then(() => {
                    navigate('/');
                });
        } else {
            navigate('/login');
        }
    }, [location]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Ошибка при SSO авторизации.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    }, [isError]);

    return (
        <LoginScreenWrapper>
            <LoginContainer>
                <Logo />
                <Loader />
            </LoginContainer>
            <DeveloperInfo />
        </LoginScreenWrapper>
    );
};

const LoginScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f7f7f5;
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-sizing: border-box;
`;
