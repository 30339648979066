import React from 'react';
import styled, { css } from 'styled-components';
import { Popover, Checkbox } from '@mantine/core';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/store';
import { selectProjectFilters, setSelectedUserIds, setShowAsSeparateTasks, setShowClosed } from '~/store/slices/projectFiltersSlice.ts';

import { TaskGroupingTypeView } from '~/types/TaskTypes';
import { SortDirection } from '~/types/SortDirection';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { ConditionType } from '~/types/project/ProjectFilters.ts';

import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { GroupByForm } from './GroupByForm/GroupByForm';
import { FilterForm } from './FiltersForm/FilterForm';

import AddIcon from '~/assets/icons/plus.svg?react';
import UserIcon from '~/assets/icons/user.svg?react';
import UserGroupIcon from '~/assets/icons/user-group.svg?react';

const FilterBar: React.FC = () => {
    const dispatch = useDispatch();

    const currentUser = useSelector((state: RootState) => state.user.currentUser!);
    const { projectInfo, users } = useSelector((state: RootState) => state.project);
    const { showClosed, showClosedAvailable, hasActiveConditions, showAsSeparateTasks, selectedUserIds, groupBy, sortOrder } = useSelector(
        (state: RootState) => {
            const filters = selectProjectFilters(state, projectInfo.id);
            return {
                showClosed: filters.showClosed,
                showClosedAvailable: !filters.conditions.some(condition => condition.type === ConditionType.Status),
                hasActiveConditions: filters.conditions.length > 0,
                showAsSeparateTasks: filters.showAsSeparateTasks,
                selectedUserIds: filters.selectedUserIds,
                groupBy: filters.groupBy,
                sortOrder: filters.sortOrder,
            };
        },
    );

    const hasClosedStatus = projectInfo?.allowedTaskStatuses.some(status => status.name === 'Closed') ?? false;
    const isOnlyMyTasksActive = selectedUserIds.length === 1 && selectedUserIds.some(id => id === currentUser.id);

    const handleUserChange = (value: ShortUserInfo[]) => {
        dispatch(setSelectedUserIds({ projectId: projectInfo.id, payload: value.map(user => user.id) }));
    };

    const handleShowClosedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setShowClosed({ projectId: projectInfo.id, payload: event.currentTarget.checked }));
    };

    const handleShowAsSeparateTasks = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setShowAsSeparateTasks({ projectId: projectInfo.id, payload: event.currentTarget.checked }));
    };

    const handleOnlyMyTasks = () => {
        handleUserChange(isOnlyMyTasksActive ? [] : [currentUser]);
    };

    return (
        <FilterContainer>
            <Popover position="bottom-start">
                <Popover.Target>
                    <FilterButton $active={groupBy !== null || sortOrder !== null}>
                        Группировка: {groupBy !== null ? TaskGroupingTypeView[groupBy] : 'Нет'}
                        {sortOrder !== null && ` (${sortOrder === SortDirection.ASC ? 'По возрастанию' : 'По убыванию'})`}
                    </FilterButton>
                </Popover.Target>
                <StyledPopoverDropdown>
                    <GroupByForm />
                </StyledPopoverDropdown>
            </Popover>

            <Popover position="bottom-start">
                <Popover.Target>
                    <FilterButton $active={hasActiveConditions}>
                        <AddIcon style={{ width: '12px', height: '12px' }} />
                        <span>Фильтры</span>
                    </FilterButton>
                </Popover.Target>
                <StyledPopoverDropdown $padding="16px 20px" w={720}>
                    <FilterForm />
                </StyledPopoverDropdown>
            </Popover>

            <FilterButton $active={isOnlyMyTasksActive} onClick={handleOnlyMyTasks}>
                <UserIcon style={{ width: '12px', height: '12px' }} />
                <span>Только мои</span>
            </FilterButton>

            <EnhancedPickUserCombobox
                singleChoice={false}
                data={users}
                value={users.filter(user => selectedUserIds.includes(user.id))}
                onChange={handleUserChange}
            >
                {({ toggleDropdown }) => (
                    <FilterButton $active={!isOnlyMyTasksActive && selectedUserIds.length > 0} onClick={() => toggleDropdown()}>
                        <UserGroupIcon style={{ width: '12px', height: '12px' }} />
                        <span>Исполнители</span>
                    </FilterButton>
                )}
            </EnhancedPickUserCombobox>

            {hasClosedStatus && showClosedAvailable && (
                <Checkbox
                    label="Показать закрытые"
                    checked={showClosed}
                    onChange={handleShowClosedChange}
                    styles={{
                        root: {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        label: {
                            paddingLeft: '8px',
                            fontSize: '14px',
                            color: '#495057',
                        },
                    }}
                />
            )}

            <Checkbox
                label="Показать как отдельные задачи"
                checked={showAsSeparateTasks}
                onChange={handleShowAsSeparateTasks}
                styles={{
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    label: {
                        paddingLeft: '8px',
                        fontSize: '14px',
                        color: '#495057',
                    },
                }}
            />
        </FilterContainer>
    );
};

const StyledPopoverDropdown = styled(Popover.Dropdown)<{ $padding?: string }>`
    display: flex;
    gap: 12px;
    padding: ${({ $padding }) => $padding ?? '16px'};
    border-radius: 8px;
    border: 1px solid var(--Gray-30, #e9ecef);
    background: var(--Background-body, #fff);
    box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.05),
        0px 10px 15px -5px rgba(0, 0, 0, 0.1),
        0px 7px 7px -5px rgba(0, 0, 0, 0.04);
`;

const FilterContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 11px;
    color: #495057;
    text-align: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px 32px 24px;
    font:
        500 12px/1 Inter,
        sans-serif;
`;

interface FilterButtonProps {
    $active?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
    align-self: stretch;
    border-radius: 4px;
    display: flex;
    min-height: 24px;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    justify-content: center;
    margin: auto 0;
    padding: 0 10px 0 8px;
    border: 1px solid #dee2e6;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;

    &:not(:disabled) {
        &:hover {
            background-color: rgba(34, 139, 230, 0.08);
            color: rgba(76, 110, 245, 0.8);
            border-color: rgba(34, 139, 230, 0.8);
        }

        ${({ $active }) =>
            $active &&
            css`
                background-color: rgba(34, 139, 230, 0.12);
                color: rgba(76, 110, 245, 1);
                border-color: rgba(34, 139, 230, 1);
            `}
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export default FilterBar;
