import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateDetailsState } from '~/graphql/tasks';
import { RootState } from '~/store/store';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { setSyncedAssignees, setUpdating } from './taskDetailsSlice';
import { assigneesApi } from '~/store/api/assigneesApi';

interface DetailsState {
    status: TaskStatusNew;
    allowedStatuses: TaskStatusNew[];
    dates: [string | null, string | null];
    assignees: ShortUserInfo[];
    estimate: number | null;
    isEstimateEditing: boolean;
}

type StateType = { detailsState: DetailsState | null };

const initialState: StateType = { detailsState: null };

const taskPropertiesSlice = createSlice({
    name: 'taskProperties',
    initialState,
    reducers: {
        setDetailsState: (_state: StateType, action: PayloadAction<StateType>) => {
            return action.payload;
        },
        updateDetailsState: (state, action: PayloadAction<Partial<UpdateDetailsState>>) => {
            if (state.detailsState) {
                state.detailsState = {
                    ...state.detailsState,
                    ...action.payload,
                    status: action.payload.status ? (action.payload.status as TaskStatusNew) : state.detailsState.status,
                };
            }
        },
        setEstimateEditing: (state, action: PayloadAction<boolean>) => {
            if (state.detailsState) {
                state.detailsState = {
                    ...state.detailsState,
                    isEstimateEditing: action.payload,
                };
            }
        },
        setAssignees: (state, action: PayloadAction<ShortUserInfo[]>) => {
            if (state.detailsState) {
                state.detailsState.assignees = action.payload;
            }
        },
    },
});

export const updateAssignees = createAsyncThunk(
    'taskDetails/updateAssignees',
    async ({ taskId, userIds }: { taskId: string; userIds: string[] }, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const syncedTask = state.taskDetails.syncedTask;

        if (!syncedTask) {
            return rejectWithValue('No synced task found');
        }

        const currentAssigneeIds = syncedTask.assignee.map(user => user.id);
        const areAssigneesEqual = currentAssigneeIds.length === userIds.length && currentAssigneeIds.every(id => userIds.includes(id));

        if (areAssigneesEqual) {
            return;
        }

        try {
            dispatch(setUpdating(true));
            const result = await dispatch(assigneesApi.endpoints.setTaskAssignees.initiate({ task_id: taskId, user_ids: userIds }));
            if ('data' in result) {
                dispatch(setSyncedAssignees(result.data?.assignees ?? []));
                return result.data;
            } else {
                dispatch(setAssignees(syncedTask.assignee));
                return rejectWithValue('Failed to update assignees');
            }
        } catch (error) {
            return rejectWithValue('An error occurred while updating assignees');
        } finally {
            dispatch(setUpdating(false));
        }
    },
);

export const { setDetailsState, updateDetailsState, setEstimateEditing, setAssignees } = taskPropertiesSlice.actions;

export default taskPropertiesSlice.reducer;
