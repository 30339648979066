import { isToday, isTomorrow, isYesterday } from 'date-fns';
import { stringToDate } from './stringUtils';

export const getColorByDate = (date: string | Date | null): string => {
    const dateObj = typeof date === 'string' ? stringToDate(date) : date;
    if (!dateObj) {
        return '';
    }

    const today = new Date();

    // Сегодня
    if (dateObj.getFullYear() === today.getFullYear() && dateObj.getMonth() === today.getMonth() && dateObj.getDate() === today.getDate()) {
        return '#212529'; // Оранжевый цвет для сегодняшней даты
    }

    // Просроченная задача
    if (
        dateObj.getTime() < today.getTime() &&
        (dateObj.getFullYear() !== today.getFullYear() || dateObj.getMonth() !== today.getMonth() || dateObj.getDate() !== today.getDate())
    ) {
        return '#E23131';
    }

    return '#212529';
};

export const getDateString = (date: Date | null): string => {
    if (!date) {
        return '';
    }

    // YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}.${month}.${year}`;
};

export const getBackendDateString = (date: Date | null): string => {
    if (!date) {
        return '';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const formatDate = (date: Date | null): string => {
    if (!date) {
        return '';
    }

    if (isYesterday(date)) {
        return 'Вчера';
    }

    if (isToday(date)) {
        return 'Сегодня';
    }

    if (isTomorrow(date)) {
        return 'Завтра';
    }

    return getDateString(date);
};

export const formatDateString = (date: string | null): string => {
    if (!date) {
        return '';
    }

    return formatDate(stringToDate(date));
};

export const getDateRangeString = (dateFrom: Date | null, dateTo: Date | null): string => {
    if (!dateFrom && !dateTo) {
        return '';
    }

    if (dateFrom && dateTo) {
        return `${formatDate(dateFrom)} - ${formatDate(dateTo)}`;
    }

    if (dateFrom) {
        return formatDate(dateFrom);
    }

    if (dateTo) {
        return formatDate(dateTo);
    }

    return '';
};
