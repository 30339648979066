import React, { Suspense, useEffect } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSearchModal } from '~/store/slices/searchModalSlice';
import { SearchModalWrapper } from '~/components/SearchModal/SearchModalWrapper';
import { PrivateRoute } from '~/router/PrivateRoute';
import { Layout } from '~/components/shared/Layout';
import { Login } from '~/pages/Login';
import { LoginConfirm } from '~/pages/LoginConfirm';
import { ProjectPage } from '~/pages/ProjectPage';
import HomePage from '~/pages/HomePage';
import NotFound from '~/pages/NotFound';
import { GuestRoute } from '~/router/GuestRoute';
import { PageTitleProvider } from '~/components/PageTitleProvider';

const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                dispatch(openSearchModal());
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [dispatch]);

    return (
        <BrowserRouter>
            <PageTitleProvider>
                <Suspense fallback={<div>Загрузка...</div>}>
                    <Routes>
                        <Route element={<GuestRoute />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/login/confirm" element={<LoginConfirm />} />
                        </Route>

                        <Route element={<PrivateRoute />}>
                            <Route element={<Layout />}>
                                <Route path="/home" element={<HomePage />} />
                                <Route path="/projects/:projectId" element={<ProjectPage />} />
                            </Route>
                        </Route>

                        <Route path="/" element={<Navigate to="/home" replace />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <SearchModalWrapper />
                </Suspense>
            </PageTitleProvider>
        </BrowserRouter>
    );
};

export default App;
