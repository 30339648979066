import React from 'react';
import styled from 'styled-components';
import { Logo } from './Logo';
import { SSOButton } from './SSOButton';
import { DeveloperInfo } from './DeveloperInfo';
import { Loader } from './Loader';

interface LoginScreenProps {
    onLogin: () => void;
    isLoading: boolean;
}

export const LoginScreen: React.FC<LoginScreenProps> = ({ onLogin, isLoading }) => {
    return (
        <LoginScreenWrapper>
            <LoginContainer>
                <Logo />
                {isLoading ? <Loader /> : <SSOButton onClick={onLogin} />}
            </LoginContainer>
            <DeveloperInfo />
        </LoginScreenWrapper>
    );
};

const LoginScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f7f7f5;
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-sizing: border-box;
`;

export default LoginScreen;
