import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { GET_GOOGLE_REDIRECT_URL, GOOGLE_SSO_AUTH } from '../../graphql/auth';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { UserDTO } from '~/types/UserTypes';

interface GoogleSSORedirectResponse {
    googleSSORedirect: { redirect_url: string };
}

interface GoogleSSOAuthResponse {
    googleSSOAuth: UserDTO;
}

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: graphqlRequestBaseQuery({
        url: '/graphql/guest',
        prepareHeaders: headers => {
            headers.set('Accept', 'application/json');
            return headers;
        },
    }),
    endpoints: builder => ({
        getGoogleRedirectUrl: builder.query<string, void>({
            query: () => ({ document: GET_GOOGLE_REDIRECT_URL }),
            transformResponse: (response: GoogleSSORedirectResponse) => response.googleSSORedirect.redirect_url,
        }),
        googleSSOAuth: builder.mutation<ShortUserInfo, { code: string }>({
            query: variables => ({
                document: GOOGLE_SSO_AUTH,
                variables,
            }),
            transformResponse: (response: GoogleSSOAuthResponse) => ({
                id: response.googleSSOAuth.id,
                name: response.googleSSOAuth.name,
                avatarUrl: response.googleSSOAuth.avatar_url,
                guild: response.googleSSOAuth.guild ? { id: response.googleSSOAuth.guild.id, name: response.googleSSOAuth.guild.name } : null,
            }),
        }),
    }),
});

export const { useGoogleSSOAuthMutation, useLazyGetGoogleRedirectUrlQuery } = authApi;
