import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';

import { useDispatch, useSelector } from 'react-redux';
import { selectProjectFilters, setFilterConditions } from '~/store/slices/projectFiltersSlice.ts';
import { RootState } from '~/store/store.ts';

import { defaultConditionBlockStateConst, defaultConditionStateConst } from './utils';
import { Condition, ConditionBlock } from '~/types/project/ProjectFilters.ts';

import FilterCondition from './components/FilterCondition/FilterCondition';
import AddFilterButton from './components/AddFilterButton';
import FilterHeader from './components/FilterHeader';

interface ConditionApi {
    onAdd: () => void;
    onRemove: (id: string) => void;
    onChange: (condition: Condition) => void;
}

export const FilterForm: React.FC = () => {
    const dispatch = useDispatch();

    const projectId = useSelector((state: RootState) => state.project.projectInfo.id);
    const { conditions: storeConditions } = useSelector((state: RootState) => selectProjectFilters(state, projectId));

    const [blocks, setBlocks] = useState<ConditionBlock[]>(defaultConditionBlockStateConst(storeConditions));

    const handleClear = () => {
        setBlocks(defaultConditionBlockStateConst([]));
    };

    const handleAddBlock = () => {
        setBlocks(b => [...b, { id: nanoid(), conditions: defaultConditionStateConst() }]);
    };

    const makeConditionApi = (blockId: string): ConditionApi => {
        const setConditions = (fn: (conditions: Condition[]) => Condition[]) => {
            setBlocks(s => s.map(block => (block.id === blockId ? { ...block, conditions: fn(block.conditions) } : block)));
        };

        const onAdd = () => {
            setConditions(s => [...s, ...defaultConditionStateConst()]);
        };

        const onRemove = (id: string) => {
            setConditions(s => {
                const nextValue = s.filter(condition => condition.id !== id);
                return nextValue.length === 0 ? defaultConditionStateConst() : nextValue;
            });
        };

        const onChange = (condition: Condition) => {
            setConditions(s => s.map(c => (c.id === condition.id ? condition : c)));
        };

        return { onAdd, onRemove, onChange };
    };

    useEffect(() => {
        // NOTE: backend is not ready for apply many blocks
        const [block] = blocks;
        const { conditions } = block;

        const payload = conditions.filter(
            condition => condition.type !== null && (Array.isArray(condition.value) ? condition.value.length > 0 : condition.value !== null),
        );

        dispatch(setFilterConditions({ projectId, payload }));
    }, [blocks]);

    return (
        <StyledFilterForm>
            <FilterHeader onClear={handleClear} />
            <FilterContent>
                {blocks.map(({ id, conditions }) => (
                    <FilterCondition key={id} conditions={conditions} {...makeConditionApi(id)} />
                ))}
                <AddFilterButton style={{ display: 'none' }} onClick={handleAddBlock} />
            </FilterContent>
        </StyledFilterForm>
    );
};

const StyledFilterForm = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FilterContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
`;
