import React from 'react';
import styled from 'styled-components';
import { ActivityContent } from './ActivityContent';
import { CommentSection } from './CommentSection';
import { Attachment } from '../TaskScreen/ActivityContent';
import { GetTaskByIdData } from '../../graphql/tasks';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { useAddCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation } from '../../store/api/commentsApi';
import { updateTaskComments, selectMappedComments } from '../../store/slices/taskDetails/taskDetailsSlice';
import { getErrorText } from '~/utils/ErrorHandler';
import { formatDateTime } from '../../utils/stringUtils';

interface ActivityProps {
    task: GetTaskByIdData['tasks']['data'][0];
}

export const Activity: React.FC<ActivityProps> = ({ task }) => {
    const { currentTask } = useSelector((state: RootState) => state.taskDetails);
    const mappedComments = useSelector(selectMappedComments);
    const [addComment] = useAddCommentMutation();
    const [updateComment] = useUpdateCommentMutation();
    const [deleteComment] = useDeleteCommentMutation();
    const dispatch = useDispatch();
    const currentUser = useSelector((state: RootState) => state.user.currentUser);

    const taskCreator = currentTask?.author?.name || 'Overseer';
    console.log(currentTask);
    const taskCreationDate = formatDateTime(currentTask?.createdAt ?? null) || ' ';

    const activityItems = [{ description: `${taskCreator} создал(-а) таск`, date: taskCreationDate }];

    const handleAddComment = async (comment: { content: string; attachments: Attachment[] }) => {
        try {
            const result = await addComment({
                taskId: task.id,
                content: comment.content,
            }).unwrap();

            if (currentTask) {
                const updatedComments = [...currentTask.comments, result];
                dispatch(updateTaskComments(updatedComments));
            }
        } catch (error) {
            enqueueSnackbar(`Ошибка при создании комментария: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleEditComment = async (id: string, newContent: string) => {
        if (!currentUser) {
            enqueueSnackbar('Не найден пользователь', {
                variant: 'info',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            return;
        }

        try {
            const result = await updateComment({ id, content: newContent, taskId: task.id }).unwrap();

            if (currentTask) {
                const updatedComments = currentTask.comments.map(comment => (comment.id === id ? result : comment));
                dispatch(updateTaskComments(updatedComments));
            }
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении комментария.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleDeleteComment = async (id: string) => {
        try {
            await deleteComment({ id, taskId: task.id }).unwrap();

            if (currentTask) {
                const updatedComments = currentTask.comments.filter(comment => comment.id !== id);
                dispatch(updateTaskComments(updatedComments));
            }
        } catch (error) {
            enqueueSnackbar(`Ошибка при удалении комментария: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    if (!currentTask) return <div>Loading task details...</div>;

    return (
        <StyledActivity>
            <ContentWrapper>
                <ActivityContent activityItems={activityItems} comments={mappedComments} onEdit={handleEditComment} onDelete={handleDeleteComment} />
            </ContentWrapper>
            <CommentSection onAddComment={handleAddComment} />
        </StyledActivity>
    );
};

const StyledActivity = styled.section`
    background-color: #f7f7f5;
    border-left: 1px solid #e9ecef;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    min-width: 0;
`;

const ContentWrapper = styled.div`
    flex: 1;
    overflow-y: auto;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow-x: hidden;
`;

// Remove ActivityContentWrapper as it's no longer needed
