import React from 'react';
import styled from 'styled-components';

interface ActivityItemProps {
    description: string;
    date: string;
    statusChange?: {
        from: string;
        to: string;
    };
}

export const ActivityItem: React.FC<ActivityItemProps> = ({ description, date, statusChange }) => {
    return (
        <StyledActivityItem>
            <Description>
                <span style={{ lineHeight: '1.8' }}>{description}</span>
                {statusChange && (
                    <StatusChange>
                        <Status>{statusChange.from}</Status>
                        <Arrow>→</Arrow>
                        <Status>{statusChange.to}</Status>
                    </StatusChange>
                )}
            </Description>
            <Date>{date}</Date>
        </StyledActivityItem>
    );
};

const StyledActivityItem = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: #868e96;
    font-weight: 400;
    line-height: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 18px;
    margin: 8px 0px;
    padding: 0px 16px;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Date = styled.div`
    align-self: stretch;
    gap: 8px;
`;

const StatusChange = styled.div`
    display: flex;
    align-items: center;
    /* gap: 4px; */
    /* margin-top: 4px; */
`;

const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #868e96;
    }

    &:last-child::before {
        background-color: #e78945;
    }
`;

const Arrow = styled.span`
    margin: 0 4px;
`;
