import React, { useCallback, useState } from 'react';
import { CheckIcon, Combobox, Group, Input, InputBase, useCombobox } from '@mantine/core';

import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as I from 'fp-ts/Identity';
import * as A from 'fp-ts/Array';

import { ConditionType, ConditionTypeLabels, ConditionTypeOption, ConditionTypeOptions, makeConditionType } from '~/types/project/ProjectFilters.ts';
import { searchPredicateByAny } from '~/utils/searchUtils.ts';
import { isNonEmptyString } from '~/utils/stringUtils.ts';

import SearchIcon from '~/assets/icons/search.svg?react';

const conditionOptionPredicateC =
    (searchTerm: string) =>
    (option: ConditionTypeOption): boolean =>
        searchPredicateByAny(searchTerm, [option.label, option.value]);

type ConditionTypeValue = ConditionType | null;

interface ConditionTypeSelectProps {
    selected: ConditionType[];
    value: ConditionTypeValue;
    onChange: (type: ConditionTypeValue) => void;
}

export const ConditionTypeSelect: React.FC<ConditionTypeSelectProps> = ({ selected, value, onChange }) => {
    const [search, setSearch] = useState('');

    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            setSearch('');
        },
        onDropdownOpen: eventSource => {
            combobox.focusSearchInput();
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });

    const handleChange = useCallback(flow(makeConditionType, onChange), [onChange]);

    const { options } = pipe(
        I.Do,
        I.bind('searchBy', () => O.fromPredicate(isNonEmptyString)(search)),
        I.bind('selectBy', () => O.fromPredicate(A.isNonEmpty)(selected)),
        I.bind('freeOptions', ({ selectBy }) =>
            pipe(
                selectBy,
                O.match(
                    () => ConditionTypeOptions,
                    selected => ConditionTypeOptions.filter(option => !selected.includes(option.value)),
                ),
            ),
        ),
        I.bind('options', ({ searchBy, freeOptions }) =>
            pipe(
                searchBy,
                O.match(
                    () => freeOptions,
                    searchTerm => freeOptions.filter(conditionOptionPredicateC(searchTerm)),
                ),
            ),
        ),
    );

    return (
        <Combobox
            position="bottom-start"
            store={combobox}
            withinPortal={false}
            resetSelectionOnOptionHover
            onOptionSubmit={val => {
                handleChange(val);
                combobox.updateSelectedOptionIndex('active');
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target targetType="button">
                <InputBase
                    component="button"
                    type="button"
                    pointer
                    w={200}
                    rightSection={<Combobox.Chevron />}
                    rightSectionPointerEvents="none"
                    onClick={() => combobox.toggleDropdown()}
                >
                    {value ? ConditionTypeLabels[value] : <Input.Placeholder>Выберите фильтр</Input.Placeholder>}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Search
                    value={search}
                    onChange={event => setSearch(event.currentTarget.value)}
                    placeholder="Поиск"
                    leftSection={<SearchIcon style={{ width: 16, height: 16 }} />}
                />
                <Combobox.Options>
                    {options.map(option => (
                        <Combobox.Option value={option.value} key={option.value} active={option.value === value}>
                            <Group gap="xs" justify="space-between">
                                <span>{option.label}</span>
                                {option.value === value && <CheckIcon size={12} />}
                            </Group>
                        </Combobox.Option>
                    ))}
                    {options.length === 0 && <Combobox.Empty>Ничего не найдено</Combobox.Empty>}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
