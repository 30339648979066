import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TaskCreationModal } from './TaskCreationModal/TaskCreationModal';
import { closeCreationModal } from '../store/slices/taskCreationModalSlice';
import { RootState } from '../store/store';

export const TaskCreationModalWrapper: React.FC = () => {
    const isOpen = useSelector((state: RootState) => state.taskCreation.isOpen);
    const dispatch = useDispatch();

    return <TaskCreationModal isOpen={isOpen} onClose={() => dispatch(closeCreationModal())} projectId={null} assigneeIds={[]} />;
};
