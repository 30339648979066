import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/store';
import Sidebar from '~/components/shared/Sidebar/Sidebar';
import { toggleSidebar } from '~/store/slices/settingsSlice';
import { useDispatch } from 'react-redux';

export const Layout: React.FC = () => {
    const isSidebarCollapsed = useSelector((state: RootState) => state.settings.isSidebarCollapsed);
    const { isLoggedIn, currentUser } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <AppWrapper>
            {isLoggedIn && currentUser && <Sidebar isCollapsed={isSidebarCollapsed} onToggle={handleToggleSidebar} user={currentUser} />}
            <MainContent>
                <Outlet />
            </MainContent>
        </AppWrapper>
    );
};

const AppWrapper = styled.div`
    display: flex;
    height: 100vh;
`;

const MainContent = styled.div`
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    box-sizing: border-box;
    background-color: #f7f7f5;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
`;
