import React from 'react';
import styled from 'styled-components';

import { Condition, ConditionTypeOptions } from '~/types/project/ProjectFilters.ts';

import ConditionRow from './components/ConditionRow/ConditionRow.tsx';
import NestedFilterButton from './components/NestedFilterButton.tsx';

interface FilterConditionProps {
    conditions: Condition[];
    onAdd: () => void;
    onRemove: (id: string) => void;
    onChange: (condition: Condition) => void;
}

const AvailableConditionCount = ConditionTypeOptions.length;

const FilterCondition: React.FC<FilterConditionProps> = ({ conditions, onChange, onAdd, onRemove }) => {
    const selectedConditions = conditions.filter(condition => condition.type !== null).map(condition => condition.type);

    return (
        <ConditionContainer>
            {conditions.map((condition, index) => (
                <ConditionRow
                    key={condition.id}
                    isFirst={index === 0}
                    selectedConditions={selectedConditions}
                    data={condition}
                    onRemove={onRemove}
                    onChange={onChange}
                />
            ))}
            {conditions.length < AvailableConditionCount && <NestedFilterButton onClick={onAdd} />}
        </ConditionContainer>
    );
};

const ConditionContainer = styled.section`
    border-radius: 6px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    gap: 8px;
`;

export default FilterCondition;
