import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';

// Extend dayjs with necessary plugins
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

// Set the locale to Russian
dayjs.locale('ru');

export const makeMyWeekWorkLink = (userName: string) => {
    const today = dayjs();
    const startOfWeek = today.startOf('week');
    const endOfWeek = today.endOf('week');

    const payload = {
        dataset_query: {
            database: 2,
            type: 'query',
            query: {
                'source-table': 58,
                joins: [
                    {
                        strategy: 'left-join',
                        alias: 'Users',
                        condition: [
                            '=',
                            ['field', 529, { 'base-type': 'type/BigInteger' }],
                            ['field', 468, { 'base-type': 'type/BigInteger', 'join-alias': 'Users' }],
                        ],
                        'source-table': 12,
                    },
                    {
                        strategy: 'left-join',
                        alias: 'Tasks',
                        condition: [
                            '=',
                            ['field', 527, { 'base-type': 'type/BigInteger' }],
                            ['field', 126, { 'base-type': 'type/BigInteger', 'join-alias': 'Tasks' }],
                        ],
                        'source-table': 29,
                    },
                ],
                expressions: { hours: ['/', ['field', 530, { 'base-type': 'type/BigInteger' }], 3600] },
                aggregation: [['sum', ['expression', 'hours', { 'base-type': 'type/Float' }]]],
                breakout: [
                    ['field', 105, { 'base-type': 'type/Text', 'join-alias': 'Tasks' }],
                    ['field', 532, { 'base-type': 'type/Date', 'temporal-unit': 'day' }],
                ],
                'order-by': [['asc', ['field', 532, { 'base-type': 'type/Date', 'temporal-unit': 'day' }]]],
                filter: [
                    'and',
                    ['between', ['field', 532, { 'base-type': 'type/Date' }], startOfWeek.format('YYYY-MM-DD'), endOfWeek.format('YYYY-MM-DD')],
                    ['=', ['field', 466, { 'base-type': 'type/Text', 'join-alias': 'Users' }], userName],
                ],
            },
        },
        display: 'pivot',
        displayIsLocked: true,
        parameters: [],
        visualization_settings: {
            'table.pivot': true,
            'table.pivot_column': 'date',
            'table.cell_column': 'date',
            'pivot_table.column_split': {
                rows: [['field', 105, { 'base-type': 'type/Text', 'join-alias': 'Tasks' }]],
                columns: [['field', 532, { 'base-type': 'type/Date', 'temporal-unit': 'day' }]],
                values: [['aggregation', 0]],
            },
            'pivot_table.column_widths': { leftHeaderWidths: [240], totalLeftHeaderWidths: 240, valueHeaderWidths: { '0': 88 } },
            'pivot.show_column_totals': true,
            column_settings: { '["ref",["field",532,{"base-type":"type/Date"}]]': { 'pivot_table.column_sort_order': 'ascending' } },
        },
        original_card_id: 82,
        type: 'question',
    };

    return 'https://metabase.feip.dev/question#' + btoa(unescape(encodeURIComponent(JSON.stringify(payload))));
};
