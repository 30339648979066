import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import { JSXMonoid } from '~/utils/jsxUtils';

import SlashIcon from '~/assets/icons/slash.svg?react';

interface BreadcrumbItemProps {
    name: string;
    link: string | null;
}

interface BreadcrumbsProps {
    pathItems: BreadcrumbItemProps[];
    onDismiss?: () => void;
}

const ellipsis = '\u2026';

const trimTextDynamically = (text: string, maxWidth: number, font: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return text;

    context.font = font;

    if (context.measureText(text).width <= maxWidth) return text;

    let left = 0;
    let right = text.length;
    let mid;

    while (left < right) {
        mid = Math.floor((left + right) / 2);
        const truncated = text.slice(0, mid) + ellipsis + text.slice(-mid);
        if (context.measureText(truncated).width <= maxWidth) {
            left = mid + 1;
        } else {
            right = mid;
        }
    }

    return text.slice(0, left - 1) + ellipsis + text.slice(-(left - 1));
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pathItems, onDismiss }) => {
    const [trimmedItems, setTrimmedItems] = useState(pathItems);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateTrimmedItems = () => {
            if (!containerRef.current) return;

            const containerWidth = containerRef.current.offsetWidth;
            const itemCount = pathItems.length;
            const separatorWidth = 10; // Approximate width of the slash icon
            const maxItemWidth = (containerWidth - (itemCount - 1) * separatorWidth) / itemCount;

            const newTrimmedItems = pathItems.map(item => ({
                ...item,
                trimmedName: trimTextDynamically(item.name, maxItemWidth, '400 14px Inter, sans-serif'),
            }));

            setTrimmedItems(newTrimmedItems);
        };

        updateTrimmedItems();
        window.addEventListener('resize', updateTrimmedItems);

        return () => window.removeEventListener('resize', updateTrimmedItems);
    }, [pathItems]);

    const breadcrumbs = pipe(
        trimmedItems,
        A.mapWithIndex((index, item) => (
            <React.Fragment key={index}>
                {item.link ? (
                    <StyledLink to={item.link} onClick={() => onDismiss?.()} title={item.name}>
                        <Label>{item.name}</Label>
                    </StyledLink>
                ) : (
                    <Label title={item.name}>{item.name}</Label>
                )}
            </React.Fragment>
        )),
        A.intercalate(JSXMonoid)(<SlashIcon />),
    );

    return <StyledBreadcrumbs ref={containerRef}>{breadcrumbs}</StyledBreadcrumbs>;
};

const StyledBreadcrumbs = styled.nav`
    display: flex;
    align-items: center;
    gap: 2px;
    width: 100%;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    font:
        400 14px/1 Inter,
        sans-serif;

    &:hover {
        text-decoration: underline;
    }
`;

const Label = styled.span`
    color: #868e96;
    font:
        400 14px/1 Inter,
        sans-serif;
    white-space: nowrap;
`;
