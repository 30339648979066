import React from 'react';
import { Flex, Input, InputBase, Select } from '@mantine/core';

import { useSelector } from 'react-redux';
import { RootState } from '~/store/store.ts';
import { OperatorSet } from '~/store/dto/task-filters.dto.ts';

import { ConditionSetStatus, OperatorSetOptions } from '~/types/project/ProjectFilters.ts';
import { StatusCombobox } from '~/components/StatusCombobox.tsx';
import { TaskIconNew } from '~/components/TaskIcon.tsx';

interface StatusConditionProps {
    condition: ConditionSetStatus;
    onChange: (condition: ConditionSetStatus) => void;
}

export const StatusCondition: React.FC<StatusConditionProps> = ({ condition, onChange }) => {
    const allowedStatuses = useSelector((state: RootState) => state.project.projectInfo.allowedTaskStatuses);

    const currentValue = allowedStatuses.filter(status => condition.value.includes(status.name));

    return (
        <>
            <Select
                w={110}
                comboboxProps={{ width: 'fit-content', position: 'bottom-start', withinPortal: false }}
                checkIconPosition="right"
                data={OperatorSetOptions}
                value={condition.operator}
                onChange={(_, option) => onChange({ ...condition, operator: option.value as OperatorSet })}
            />
            <StatusCombobox
                withinPortal={false}
                singleChoice={false}
                value={currentValue}
                allowedStatuses={allowedStatuses}
                onChange={statuses => onChange({ ...condition, value: statuses.map(status => status.name) })}
            >
                {({ toggleDropdown }) => (
                    <InputBase role="button" component="button" pointer onClick={toggleDropdown} flex={1}>
                        {condition.value.length === 0 ? (
                            <Input.Placeholder>Выберите статус</Input.Placeholder>
                        ) : (
                            <Flex align="center">
                                {currentValue.map(status => (
                                    <TaskIconNew key={status.name} status={status} />
                                ))}
                            </Flex>
                        )}
                    </InputBase>
                )}
            </StatusCombobox>
        </>
    );
};
