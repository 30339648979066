import { Task } from '~/types/task/Task';
import { TaskType } from '~/types/task/TaskType';

export const formatTaskTime = (totalTimeMinutes: number | null, estimateMinutes: number | null): string => {
    if ((!totalTimeMinutes || totalTimeMinutes === 0) && (!estimateMinutes || estimateMinutes === 0)) {
        return '';
    }
    if (totalTimeMinutes && estimateMinutes) {
        return `${formatMinutesToHours(totalTimeMinutes)} из ${formatMinutesToHours(estimateMinutes)}`;
    }
    if (totalTimeMinutes) {
        return `${formatMinutesToHours(totalTimeMinutes)}`;
    }
    return `0ч из ${formatMinutesToHours(estimateMinutes)}`;
};

export const formatMinutesToHours = (minutesToFormat: number | null): string => {
    if (!minutesToFormat) {
        return '';
    }

    const hours = Math.floor(minutesToFormat / 60);
    const minutes = minutesToFormat % 60;

    if (hours > 0 && minutes > 0) {
        return `${hours}ч ${minutes}м`;
    } else if (hours > 0) {
        return `${hours}ч`;
    } else {
        return `${minutes}м`;
    }
};

// Оболочка над базовым правилом
export const getTaskType = (task: Task): TaskType => {
    return getTaskTypeBase(task.parentId, task.children ?? []);
};

// Базовое правило
export const getTaskTypeBase = (parentId: any | null, children: any[]): TaskType => {
    return parentId === null && children && children.length > 0 ? TaskType.MILESTONE : TaskType.DEFAULT;
};
