import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UserDTO } from '~/types/UserTypes';
import { projectApi } from '~/store/api/projectApi'; // Убедитесь, что путь импорта правильный
import { userListApi } from '~/store/api/userListApi';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { ProjectSharingInfo } from '~/types/project/ProjectInfo';
import { RootState } from '../store';

// Создаем enum для состояний загрузки
export enum LoadingState {
    Idle = 'idle',
    Pending = 'pending',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

interface SharingModalState {
    isOpen: boolean;
    projectInfo: ProjectSharingInfo | null;
    allUsers: UserDTO[];
    loading: LoadingState;
    sharingLoading: LoadingState; // New property for sharing button loading state
    userToShareWith: UserDTO | null;
    error: string | null;
    projectId: string | null;
    isPrivateLoading: LoadingState;
}

const initialState: SharingModalState = {
    isOpen: false,
    projectInfo: null,
    allUsers: [],
    loading: LoadingState.Idle,
    sharingLoading: LoadingState.Idle,
    error: null,
    userToShareWith: null,
    projectId: null,
    isPrivateLoading: LoadingState.Idle,
};

export const sharingModalSlice = createSlice({
    name: 'sharingModal',
    initialState,
    reducers: {
        openSharingModal: (state, action: PayloadAction<string>) => {
            state.isOpen = true;
            state.projectId = action.payload;
        },
        setProjectInfo: (state, action: PayloadAction<ProjectSharingInfo>) => {
            state.projectInfo = action.payload;
        },
        setUserToShareWith: (state, action: PayloadAction<UserDTO | null>) => {
            state.userToShareWith = action.payload;
        },
        closeSharingModal: state => {
            state.isOpen = false;
        },
        setAllUsers: (state, action: PayloadAction<UserDTO[]>) => {
            state.allUsers = action.payload;
        },
        setLoading: (state, action: PayloadAction<LoadingState>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setSharingLoading: (state, action: PayloadAction<LoadingState>) => {
            state.sharingLoading = action.payload;
        },
        setIsPrivateLoading: (state, action: PayloadAction<LoadingState>) => {
            state.isPrivateLoading = action.payload;
        },
    },
});

export const {
    openSharingModal,
    closeSharingModal,
    setAllUsers,
    setUserToShareWith,
    setProjectInfo,
    setLoading,
    setError,
    setSharingLoading,
    setIsPrivateLoading,
} = sharingModalSlice.actions;

export default sharingModalSlice.reducer;

export const addUserToProject = createAsyncThunk(
    'sharingModal/addUserToProject',
    async ({ user_id }: { user_id: string }, { dispatch, getState }) => {
        try {
            dispatch(setSharingLoading(LoadingState.Pending));
            dispatch(setError(null));

            const state = getState() as RootState;
            const projectId = state.sharingModal.projectId;
            const response = await dispatch(projectApi.endpoints.addUserToProject.initiate({ project_id: projectId ?? '', user_id }));
            if (response.data) {
                const updatedProjectInfo = await dispatch(projectApi.endpoints.getProjectSharing.initiate(projectId ?? '', { forceRefetch: true }));
                if (updatedProjectInfo.data) {
                    dispatch(setProjectInfo(updatedProjectInfo.data));
                }
            }

            dispatch(setSharingLoading(LoadingState.Succeeded));
            return response;
        } catch (error) {
            dispatch(setSharingLoading(LoadingState.Failed));
            dispatch(setError(error instanceof Error ? error.message : 'An unknown error occurred'));
            throw error;
        }
    },
);

export const getProjectSharing = createAsyncThunk('sharingModal/getProjectSharing', async (_, { dispatch, getState }) => {
    try {
        dispatch(setLoading(LoadingState.Pending));
        dispatch(setError(null));

        const state = getState() as RootState;
        const projectId = state.sharingModal.projectId;

        const response = await dispatch(projectApi.endpoints.getProjectSharing.initiate(projectId ?? '', { forceRefetch: true }));
        if (response.data) {
            const projectInfo = response.data;
            dispatch(setProjectInfo(projectInfo));
        }

        const allUsers = await dispatch(userListApi.endpoints.getUsers.initiate(undefined, { forceRefetch: true }));
        if (allUsers.data) {
            const mapped = allUsers.data.map(
                (user: ShortUserInfo): UserDTO => ({
                    id: user.id,
                    name: user.name,
                    avatar_url: user.avatarUrl || '',
                    guild: user.guild,
                }),
            );
            dispatch(setAllUsers(mapped));
        }

        dispatch(setLoading(LoadingState.Succeeded));
        return response;
    } catch (error) {
        dispatch(setLoading(LoadingState.Failed));
        dispatch(setError(error instanceof Error ? error.message : 'An unknown error occurred'));
        throw error;
    }
});

export const updateProjectPrivacy = createAsyncThunk(
    'sharingModal/updateProjectPrivacy',
    async ({ is_private }: { is_private: boolean }, { dispatch, getState }) => {
        try {
            dispatch(setIsPrivateLoading(LoadingState.Pending));
            dispatch(setError(null));

            const state = getState() as RootState;
            const projectId = state.sharingModal.projectId;
            console.log('Updating project privacy. Project ID:', projectId, 'Is Private:', is_private);

            const response = await dispatch(projectApi.endpoints.updateProject.initiate({ id: projectId ?? '', is_private }));

            if (response.data) {
                dispatch(setProjectInfo(response.data));
            } else if (response.error) {
                console.error('Error updating project:', response.error);
                throw new Error(response.error.message || 'Unknown error occurred');
            }

            dispatch(setIsPrivateLoading(LoadingState.Succeeded));
            return response;
        } catch (error) {
            console.error('Error in updateProjectPrivacy:', error);
            dispatch(setIsPrivateLoading(LoadingState.Failed));
            dispatch(setError(error instanceof Error ? error.message : 'An unknown error occurred'));
            throw error;
        }
    },
);
