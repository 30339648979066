import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import TimeInput from '../TimeInput';

interface EstimateSelectorProps {
    value: number | null;
    onChange: (value: number | null) => void;
    isEditing: boolean;
    onEditStart: () => void;
    onEditEnd: () => void;
    onSave: () => void;
}

export const EstimateSelector: React.FC<EstimateSelectorProps> = ({ value, onChange, isEditing, onEditStart, onEditEnd, onSave }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const handleInputChange = (newValue: { hours: number; minutes: number } | { error: string }) => {
        if ('error' in newValue) {
            // Handle error if needed
            return;
        }
        const totalMinutes = newValue.hours * 60 + newValue.minutes;
        onChange(totalMinutes);
    };

    const handleInputBlur = () => {
        onEditEnd();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSave();
            onEditEnd();
        }
    };

    const formatEstimate = (minutes: number | null): string => {
        console.log('minutes', minutes);
        if (minutes === null || minutes === undefined || minutes === 0) return '—';
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        if (remainingMinutes === 0) {
            return `${hours}h`;
        } else {
            return `${hours}h ${remainingMinutes}m`;
        }
    };

    return (
        <EstimateWrapper onClick={onEditStart} $isEditing={isEditing}>
            {isEditing ? (
                <StyledTimeInput
                    ref={inputRef}
                    value={value && value > 0 ? { hours: Math.floor(value / 60), minutes: value % 60 } : undefined}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                <EstimateDisplay>{formatEstimate(value)}</EstimateDisplay>
            )}
        </EstimateWrapper>
    );
};

const EstimateWrapper = styled.div<{ $isEditing: boolean }>`
    display: inline-block;
    cursor: pointer;
    width: 80px;
    height: 24px;
    line-height: 24px;
    background-color: ${props => (props.$isEditing ? '#f1f3f5' : 'transparent')};
    border-radius: 4px;
    transition: background-color 0.2s ease;
`;

const StyledTimeInput = styled(TimeInput)`
    width: 100%;
    height: 24px;
    padding: 0 4px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #000000;
    line-height: 24px;
    text-align: left;

    &:focus {
        outline: none;
    }

    /* Override the default styles from TimeInput */
    && {
        border: none;
        border-radius: 0;
        height: 24px;
        min-height: 24px;
        background-color: transparent;
    }

    && input {
        height: 24px;
        min-height: 24px;
        padding: 0 4px;
        background-color: transparent;
    }
`;

const EstimateDisplay = styled.span`
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0px;
    font-size: 14px;
    color: #495057;
    line-height: 24px;
    text-align: left;
`;

export default EstimateSelector;
