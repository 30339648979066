import { ISODate } from '~/utils/opaque';
import { OperatorComparison, OperatorSet } from '~/store/dto/task-filters.dto.ts';

export enum ConditionType {
    Status = 'Status',
    Tag = 'Tag',
    Deadline = 'Deadline',
    Priority = 'Priority',
    Assignee = 'Assignee',
    Archived = 'Archived',
    CreatedAt = 'CreatedAt',
    Author = 'Author',
    ClosedAt = 'ClosedAt',
    Salesman = 'Salesman',
    Manager = 'Manager',
}

export const makeConditionType = (value: string | null): ConditionType | null => {
    if (value === null) return null;
    return ConditionType[value as keyof typeof ConditionType] ?? null;
};

export const ConditionTypeLabels = {
    [ConditionType.Status]: 'Статус',
    [ConditionType.Tag]: 'Тег',
    [ConditionType.Deadline]: 'Дедлайн',
    [ConditionType.Priority]: 'Приоритет',
    [ConditionType.Assignee]: 'Исполнитель',
    [ConditionType.Archived]: 'Архивный',
    [ConditionType.CreatedAt]: 'Дата создания',
    [ConditionType.Author]: 'Автор',
    [ConditionType.ClosedAt]: 'Дата закрытия',
    [ConditionType.Salesman]: 'Salesman',
    [ConditionType.Manager]: 'Manager',
};

export type ConditionTypeOption = { value: ConditionType; label: string };

// NOTE: Uncomment option when backend is ready
export const ConditionTypeOptions: ConditionTypeOption[] = [
    { value: ConditionType.Status, label: ConditionTypeLabels[ConditionType.Status] },
    // { value: ConditionType.Tag, label: ConditionTypeLabels[ConditionType.Tag] },
    { value: ConditionType.Deadline, label: ConditionTypeLabels[ConditionType.Deadline] },
    // { value: ConditionType.Priority, label: ConditionTypeLabels[ConditionType.Priority] },
    { value: ConditionType.Assignee, label: ConditionTypeLabels[ConditionType.Assignee] },
    // { value: ConditionType.Archived, label: ConditionTypeLabels[ConditionType.Archived] },
    { value: ConditionType.CreatedAt, label: ConditionTypeLabels[ConditionType.CreatedAt] },
    { value: ConditionType.Author, label: ConditionTypeLabels[ConditionType.Author] },
    // { value: ConditionType.ClosedAt, label: ConditionTypeLabels[ConditionType.ClosedAt] },
    { value: ConditionType.Salesman, label: ConditionTypeLabels[ConditionType.Salesman] },
    { value: ConditionType.Manager, label: ConditionTypeLabels[ConditionType.Manager] },
];

export type OperatorOption = { value: string; label: string };

export const OperatorSetLabels = {
    [OperatorSet.In]: 'Является',
    [OperatorSet.NotIn]: 'НЕ',
};

export const OperatorComparisonLabels = {
    [OperatorComparison.Equal]: 'Равно',
    [OperatorComparison.NotEqual]: 'Не равно',
    [OperatorComparison.Greater]: 'Больше',
    [OperatorComparison.Less]: 'Меньше',
    [OperatorComparison.GreaterOrEqual]: 'Больше или равно',
    [OperatorComparison.LessOrEqual]: 'Меньше или равно',
};

export const OperatorSetOptions: OperatorOption[] = [
    { value: OperatorSet.In, label: OperatorSetLabels[OperatorSet.In] },
    { value: OperatorSet.NotIn, label: OperatorSetLabels[OperatorSet.NotIn] },
];

export const OperatorComparisonOptions: OperatorOption[] = [
    { value: OperatorComparison.Equal, label: OperatorComparisonLabels[OperatorComparison.Equal] },
    { value: OperatorComparison.NotEqual, label: OperatorComparisonLabels[OperatorComparison.NotEqual] },
    { value: OperatorComparison.Greater, label: OperatorComparisonLabels[OperatorComparison.Greater] },
    { value: OperatorComparison.Less, label: OperatorComparisonLabels[OperatorComparison.Less] },
    { value: OperatorComparison.GreaterOrEqual, label: OperatorComparisonLabels[OperatorComparison.GreaterOrEqual] },
    { value: OperatorComparison.LessOrEqual, label: OperatorComparisonLabels[OperatorComparison.LessOrEqual] },
];

export interface ConditionEmpty {
    id: string;
    type: null;
}

export interface ConditionSetStatus {
    id: string;
    type: ConditionType.Status;
    operator: OperatorSet;
    value: string[];
}

export interface ConditionSetAssignee {
    id: string;
    type: ConditionType.Assignee;
    operator: null;
    value: string[];
}

export interface ConditionSetAuthor {
    id: string;
    type: ConditionType.Author;
    operator: null;
    value: string[];
}

export interface ConditionSetSalesman {
    id: string;
    type: ConditionType.Salesman;
    operator: null;
    value: string[];
}

export interface ConditionSetManager {
    id: string;
    type: ConditionType.Manager;
    operator: null;
    value: string[];
}

export interface ConditionComparisonDeadline {
    id: string;
    type: ConditionType.Deadline;
    operator: OperatorComparison;
    value: ISODate | null;
}

export interface ConditionComparisonCreatedAt {
    id: string;
    type: ConditionType.CreatedAt;
    operator: OperatorComparison;
    value: ISODate | null;
}

export type ConditionNotEmpty =
    | ConditionSetStatus
    | ConditionSetAssignee
    | ConditionSetAuthor
    | ConditionComparisonDeadline
    | ConditionComparisonCreatedAt
    | ConditionSetSalesman
    | ConditionSetManager;

export type Condition = ConditionEmpty | ConditionNotEmpty;

export interface ConditionBlock {
    id: string;
    conditions: Condition[];
}
