import React from 'react';
import styled from 'styled-components';
import logoSvg from '../../assets/app-logo-full-width.svg';

interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
    return <StyledLogo src={logoSvg} alt="Logo" />;
};

const StyledLogo = styled.img`
    aspect-ratio: 2.02;
    object-fit: contain;
    object-position: center;
    width: 480px;
    max-width: 100%;
`;
