import React from 'react';
import styled from 'styled-components';

import Slash from '~/assets/icons/slash.svg?react';
import List from '~/assets/icons/list.svg?react';

import { ProjectInfo } from '~/types/project/ProjectInfo.ts';

interface HeaderProps {
    projectInfo?: ProjectInfo;
}

const Header: React.FC<HeaderProps> = ({ projectInfo }) => {
    const showSkeleton = !projectInfo;

    if (showSkeleton) {
        return (
            <HeaderContainer>
                <Breadcrumbs>
                    <BreadcrumbItemSkeleton $width={120} $height={14} />
                    <Separator>
                        <Slash />
                    </Separator>
                    <BreadcrumbItemSkeleton $width={80} $height={14} />
                </Breadcrumbs>
                <Heading>
                    <List />
                    <BreadcrumbItemSkeleton $width={180} $height={24} />
                </Heading>
            </HeaderContainer>
        );
    }

    return (
        <HeaderContainer>
            <Breadcrumbs>
                <BreadcrumbItem>{projectInfo.space.name}</BreadcrumbItem>
                {projectInfo.folder && (
                    <>
                        <Separator>
                            <Slash />
                        </Separator>
                        <BreadcrumbItem>{projectInfo.folder.name}</BreadcrumbItem>
                    </>
                )}
            </Breadcrumbs>
            <Heading>
                <List />
                <ProjectTitle>{projectInfo.name}</ProjectTitle>
            </Heading>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    padding: 28px 32px 16px;
    border-bottom: 1px solid #e9ecef;
    flex-shrink: 0; // Добавлено: предотвращает сжатие хедера
    width: 100%; // Добавлено: обеспечивает полную ширину
`;

const Breadcrumbs = styled.nav`
    display: flex;
    min-height: 16px;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const BreadcrumbItem = styled.span`
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
    margin: auto 0;
    color: var(--Text-dimmed, #868e96);
    font:
        400 14px/1 Inter,
        sans-serif;
`;

const BreadcrumbItemSkeleton = styled(BreadcrumbItem)<{ $width: number; $height: number }>`
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;

    @keyframes loading {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }
`;

const Separator = styled.span`
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    margin: auto 0;
`;

const Heading = styled.h1`
    align-self: flex-start;
    display: flex;
    margin-top: 16px;
    align-items: center;
    gap: 10px;
    color: #000;
    justify-content: flex-start;
    flex-wrap: nowrap;
    font:
        600 24px/1 Inter,
        sans-serif;
    width: 100%;
    overflow: hidden;
`;

const ProjectTitle = styled.span`
    align-self: stretch;
    margin: auto 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default Header;
