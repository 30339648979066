import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query GetUsers {
        users(all: true) {
            data {
                id
                name
                avatar_url
            }
        }
    }
`;

export const GET_USER_FOR_PROJECT = gql`
    query GetUserForProject($projectId: String!) {
        projects(id: $projectId) {
            data {
                members {
                    id
                    name
                    avatar_url
                }
            }
        }
    }
`;
