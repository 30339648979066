export function getErrorText(error: any): string {
    console.error(error);
    //хз как правильно/лучше лучше, пока костылями
    return getRtkErrorText(error) || 'Неизвестная ошибка';
}

function getRtkErrorText(error: any): string | undefined {
    if (error.response && error.response.errors && error.response.errors.length > 0) {
        return error.response.errors[0].message;
    }
    return undefined;
}
