import React from 'react';
import styled from 'styled-components';

type StatusAreaProps = {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
};

export const StatusArea = ({ children, onClick }: StatusAreaProps) => <Container onClick={onClick}>{children}</Container>;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
