import React from 'react';
import styled from 'styled-components';
import { HomeTask } from '../../types/task/Task';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { TaskIconNew } from '../TaskIcon';
import { formatTaskTime } from '~/utils/taskUtils';
import { formatDateString, getColorByDate } from '~/utils/dateUtils';
import { Tooltip } from '@mantine/core';
import { getStringStatusText } from '~/utils/taskStatusUtils';

interface SearchTaskItemProps {
    task: HomeTask;
}

const SearchTaskItem: React.FC<SearchTaskItemProps> = ({ task }) => {
    const { navigateToTask } = useTaskNavigation();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigateToTask(task.id);
        (e.target as HTMLElement).blur();
    };

    return (
        <TaskWrapper onClick={handleClick}>
            <TaskInfo>
                <Tooltip
                    label={getStringStatusText(task.status.name)}
                    withArrow
                    transitionProps={{ transition: 'fade', duration: 200 }}
                    position="top"
                >
                    <TaskIconWrapper>
                        <TaskIconNew status={task.status} type={task.type} />
                    </TaskIconWrapper>
                </Tooltip>
                <TaskDetails>
                    <TaskName>{task.name}</TaskName>
                    <TaskTime>{formatTaskTime(task.totalTime ? Math.floor(task.totalTime / 60) : null, task.estimate)}</TaskTime>
                </TaskDetails>
            </TaskInfo>
            {task.dueDate && <DueDate color={getColorByDate(task.dueDate)}>{formatDateString(task.dueDate)}</DueDate>}
        </TaskWrapper>
    );
};

const TaskWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    cursor: pointer;
    border-bottom: 1px solid #e9ecef;

    &:hover {
        background-color: #f8f9fa;
    }
`;

const TaskInfo = styled.div`
    display: flex;
    align-items: center;
`;

const TaskIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`;

const TaskDetails = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-left: 8px;
`;

const TaskName = styled.span`
    color: #212529;
    font: 400 14px/1 'Inter', sans-serif;
`;

const TaskTime = styled.span`
    color: #868e96;
    font: 400 14px/1 'Inter', sans-serif;
`;

const DueDate = styled.span<{ color: string }>`
    font: 400 14px/1 'Inter', sans-serif;
    color: ${({ color }) => color};
`;

export default SearchTaskItem;