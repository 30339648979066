import { createApi } from '@reduxjs/toolkit/query/react';
import { TaskComment } from '~/types/task/TaskFull';
import { CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT } from '../../graphql/comments';
import { tasksApi } from './tasksApi';
import { graphqlBaseQuery } from '../graphqlBaseQuery';

export const commentsApi = createApi({
    reducerPath: 'commentsApi',
    baseQuery: graphqlBaseQuery,
    tagTypes: ['Comment'],
    endpoints: builder => ({
        addComment: builder.mutation<TaskComment, { taskId: string; content: string }>({
            query: ({ taskId, content }) => ({
                document: CREATE_COMMENT,
                variables: {
                    input: {
                        task_id: taskId,
                        text: content,
                    },
                },
            }),
            transformResponse: (response: { createComment: TaskComment }) => response.createComment,
            async onQueryStarted({ taskId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newComment } = await queryFulfilled;
                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: taskId }, draft => {
                            draft.comments.push(newComment);
                        }),
                    );
                } catch {
                    // If the mutation fails, we don't need to do anything
                }
            },
            invalidatesTags: ['Comment'],
        }),
        updateComment: builder.mutation<TaskComment, { id: string; content: string; taskId: string }>({
            query: ({ id, content }) => ({
                document: UPDATE_COMMENT,
                variables: {
                    id,
                    input: {
                        text: content,
                    },
                },
            }),
            transformResponse: (response: { updateComment: TaskComment }) => response.updateComment,
            async onQueryStarted({ id, taskId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedComment } = await queryFulfilled;
                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: taskId }, draft => {
                            const commentIndex = draft.comments.findIndex(comment => comment.id === id);
                            if (commentIndex !== -1) {
                                draft.comments[commentIndex] = updatedComment;
                            }
                        }),
                    );
                } catch {
                    // If the mutation fails, we don't need to do anything
                }
            },
            invalidatesTags: ['Comment'],
        }),
        deleteComment: builder.mutation<boolean, { id: string; taskId: string }>({
            query: ({ id }) => ({
                document: DELETE_COMMENT,
                variables: { id },
            }),
            transformResponse: (response: { deleteComment: boolean }) => response.deleteComment,
            async onQueryStarted({ id, taskId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: taskId }, draft => {
                            draft.comments = draft.comments.filter(comment => comment.id !== id);
                        }),
                    );
                } catch {
                    // If the mutation fails, we don't need to do anything
                }
            },
            invalidatesTags: ['Comment'],
        }),
    }),
});

export const { useAddCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation } = commentsApi;
