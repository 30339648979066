import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface SettingsState {
    isSidebarCollapsed: boolean;
    isAirEnabled: boolean; // увеличить отступ между задачами
}

const initialState: SettingsState = {
    isSidebarCollapsed: false,
    isAirEnabled: false,
};

const settingsPersistConfig = {
    key: 'settings',
    storage,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleSidebar: state => {
            state.isSidebarCollapsed = !state.isSidebarCollapsed;
        },
        setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
            state.isSidebarCollapsed = action.payload;
        },
        setAirEnabled: (state, action: PayloadAction<boolean>) => {
            state.isAirEnabled = action.payload;
        },
    },
});

const persistedSettingsReducer = persistReducer(settingsPersistConfig, settingsSlice.reducer);

export const { toggleSidebar, setSidebarCollapsed, setAirEnabled } = settingsSlice.actions;
export default persistedSettingsReducer;
