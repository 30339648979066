import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { projectApi } from './api/projectApi';
import { userListApi } from './api/userListApi';
import { tasksApi } from './api/tasksApi';
import { commentsApi } from './api/commentsApi';
import { authApi } from './api/authApi';
import { spacesApi } from './api/spacesApi';
import { foldersApi } from './api/foldersApi';
import { assigneesApi } from './api/assigneesApi'; // Import the new API
import { timeTrackingApi } from './api/timeTrackingApi';

import tasksReducer from './slices/tasksSlice';
import projectReducer from './slices/projectSlice.ts';
import projectFiltersReducer from './slices/projectFiltersSlice.ts';
import userReducer from './slices/userSlice';
import settingsReducer from './slices/settingsSlice';
import taskDetailsReducer from './slices/taskDetails/taskDetailsSlice';
import taskHeadingReducer from './slices/taskDetails/taskHeadingSlice';
import taskPropertiesReducer from './slices/taskDetails/taskPropertiesSlice.ts';
import taskCustomFieldsReducer from './slices/taskDetails/taskCustomFieldsSlice.ts';
import taskCreationModalReducer from './slices/taskCreationModalSlice.ts';
import sidebarReducer from './slices/sidebarSlice';
import sharingModalReducer from './slices/sharingModalSlice';
import searchModalReducer from './slices/searchModalSlice';
import commandsReducer from './slices/commandsSlice';

import { ThunkAction, Action } from '@reduxjs/toolkit';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const store = configureStore({
    reducer: {
        [projectApi.reducerPath]: projectApi.reducer,
        [userListApi.reducerPath]: userListApi.reducer,
        [tasksApi.reducerPath]: tasksApi.reducer,
        [commentsApi.reducerPath]: commentsApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [assigneesApi.reducerPath]: assigneesApi.reducer,
        [spacesApi.reducerPath]: spacesApi.reducer,
        [foldersApi.reducerPath]: foldersApi.reducer,
        [timeTrackingApi.reducerPath]: timeTrackingApi.reducer,
        settings: settingsReducer,
        taskDetails: taskDetailsReducer,
        taskHeading: taskHeadingReducer,
        taskProperties: taskPropertiesReducer,
        taskCustomFields: taskCustomFieldsReducer,
        user: userReducer,
        project: projectReducer,
        projectFilters: projectFiltersReducer,
        tasks: tasksReducer,
        taskCreation: taskCreationModalReducer,
        sidebar: sidebarReducer,
        sharingModal: sharingModalReducer,
        searchModal: searchModalReducer,
        commands: commandsReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'],
            },
        })
            .concat(userListApi.middleware)
            .concat(projectApi.middleware)
            .concat(tasksApi.middleware)
            .concat(commentsApi.middleware)
            .concat(authApi.middleware)
            .concat(spacesApi.middleware)
            .concat(foldersApi.middleware)
            .concat(authApi.middleware)
            .concat(assigneesApi.middleware)
            .concat(timeTrackingApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
