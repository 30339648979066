import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { useDispatch, useSelector } from 'react-redux';
import { updateTaskDetails, setLocalDescription, updateSubtaskDetails, updateSubtaskField } from '~/store/slices/taskDetails/taskDetailsSlice';
import { AppDispatch, RootState } from '~/store/store';

import { UpdateTaskFields } from '~/graphql/tasks';

import { TaskStatusNew } from '~/types/task/TaskStatus';

import { TextEditor } from '../shared/TextEditor';

import { EditableHeading } from './EditableHeading';
import { TaskNavigation } from './TaskNavigation';
import { TaskProperties } from './TaskProperties';
// import { Attachments } from './Attachments';
import { TaskTabs } from './TaskTabs';
import { getErrorText } from '~/utils/ErrorHandler';
import { useDebouncedCallback } from '@mantine/hooks';

export const TaskInfo: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { currentTask, isUpdating, updateError, localDescription, users, assignableUsers } = useSelector((state: RootState) => state.taskDetails);

    const throwTaskMutationErrorSnackbar = useCallback((message: string) => {
        enqueueSnackbar(message, {
            variant: 'error',
            action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
        });
    }, []);

    const handleUpdateTask  = useCallback(
        async (updatedFields: Partial<UpdateTaskFields>) => {
            if (currentTask === null) return;

            try {
                await dispatch(updateTaskDetails({ taskId: currentTask!.id, updatedFields }));
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throwTaskMutationErrorSnackbar('Ошибка при обновлении таска.');
                }
            }
        },
        [currentTask, dispatch, throwTaskMutationErrorSnackbar],
    );

    const handleUpdateSubtask = useCallback(
        async (subtaskId: string, updatedFields: Partial<UpdateTaskFields>) => {
            try {
                await dispatch(updateSubtaskDetails({ subtaskId: subtaskId, updatedFields }));
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throwTaskMutationErrorSnackbar('Ошибка при обновлении таска.');
                }
            }
        },
        [dispatch, throwTaskMutationErrorSnackbar],
    );

    const handleDescriptionUpdate = useCallback(
        async (value: string) => {
            if (value === currentTask?.description) return;

            dispatch(setLocalDescription(value));

            try {
                await handleUpdateTask({ description: value });
            } catch {
                throwTaskMutationErrorSnackbar('Ошибка при обновлении описания таска.');
            }
        },
        [currentTask, dispatch, handleUpdateTask, throwTaskMutationErrorSnackbar],
    );

    const debouncedHandleDescriptionUpdate = useDebouncedCallback(handleDescriptionUpdate, 500);

    const handleSubtaskDateUpdate = useCallback(async (taskId: string, newDateRange: [Date | null, Date | null]) => {
        const [startDate, endDate] = newDateRange;
        dispatch(
            updateSubtaskField({
                subtaskId: taskId,
                fields: {
                    start_date: startDate ? startDate.toISOString() : null,
                    due_date: endDate ? endDate.toISOString() : null,
                },
            }),
        );
        try {
            await handleUpdateSubtask(taskId, {
                start_date: startDate ? startDate.toISOString() : null,
                due_date: endDate ? endDate.toISOString() : null,
            });
        } catch {
            throwTaskMutationErrorSnackbar('Ошибка при обновлении дат.');
        }
    }, [dispatch, handleUpdateSubtask, throwTaskMutationErrorSnackbar]);

    const handleSubtaskStatusUpdate = useCallback(async (status: TaskStatusNew[], taskId: string) => {
        const [targetStatus] = status;
        dispatch(updateSubtaskField({ subtaskId: taskId, fields: { status: targetStatus } }));
        try {
            await handleUpdateSubtask(taskId, {
                status: targetStatus,
            });
        } catch {
            throwTaskMutationErrorSnackbar('Ошибка при обновлении статуса.');
        }
    }, [dispatch, handleUpdateSubtask, throwTaskMutationErrorSnackbar]);

    useEffect(() => {
        if (updateError) {
            throwTaskMutationErrorSnackbar(`Ошибка при обновлении таска: ${getErrorText(updateError)}`);
        }
    }, [updateError, throwTaskMutationErrorSnackbar]);

    if (!currentTask) return null;

    return (
        <StyledTaskInfo>
            <TaskNavigation task={currentTask} />
            <EditableHeading placeholder="Введите название задачи" />
            <TaskProperties />
            <TextEditorWrapper>
                <TextEditor value={localDescription} onBlur={handleDescriptionUpdate} onChange={debouncedHandleDescriptionUpdate} placeholder="Описание задачи" />
            </TextEditorWrapper>
            <TaskTabs
                task={currentTask}
                allUsers={users}
                assignableUsers={assignableUsers}
                onSubtaskDatesUpdated={handleSubtaskDateUpdate}
                onSubtaskStatusUpdated={handleSubtaskStatusUpdate}
            />
            {/* <Attachments /> */}
            {isUpdating && <UpdatingIndicator>Обновление...</UpdatingIndicator>}
        </StyledTaskInfo>
    );
};

const StyledTaskInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 32px 8px 32px;
    position: relative;
`;

const TextEditorWrapper = styled.div`
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 112px;
`;

const UpdatingIndicator = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
`;

export default TaskInfo;
