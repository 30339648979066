import { Menu } from '@mantine/core';
import { ReactNode } from 'react';

interface TaskActionsProps {
    children: ReactNode;
    onRename?: (event: React.MouseEvent) => void;
    onDelete?: (event: React.MouseEvent) => void;
    onMoveToList?: (event: React.MouseEvent) => void;
}

export function TaskActions({ children, onRename, onDelete, onMoveToList }: TaskActionsProps) {
    return (
        <Menu>
            <Menu.Target>{children}</Menu.Target>

            <Menu.Dropdown>
                {onRename && <Menu.Item onClick={onRename}>Переименовать</Menu.Item>}
                {onMoveToList && <Menu.Item onClick={onMoveToList}>Переместить в другой лист</Menu.Item>}
                {onDelete && (
                    <Menu.Item onClick={onDelete} color="red">
                        Удалить
                    </Menu.Item>
                )}
            </Menu.Dropdown>
        </Menu>
    );
}
