import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Modal, Button } from '@mantine/core';
import { SkeletonTaskList, SkeletonTask } from './ProjectList/TaskGroupSkeleton';
import { useGetProjectsQuery } from '../store/api/projectApi';

import styled from 'styled-components';
import ListIcon from '~/assets/icons/list.svg?react';
import EnterIcon from '~/assets/icons/enter.svg?react';
import { SearchInputWrapper, SearchForm, StyledTextInput, SearchButton, SearchHint, Separator } from './SearchModal/SearchModal';

import { useUpdateTaskMutation } from '../store/api/tasksApi';
import { enqueueSnackbar } from 'notistack';
import { searchPredicate } from '../utils/searchUtils';

interface TaskMoveModalProps {
    taskName: string;
    taskId: string;
    isOpen: boolean;
    onClose: () => void;
    onMoveSuccess?: () => void;
}

export const TaskMoveModal: React.FC<TaskMoveModalProps> = ({ taskName, taskId, isOpen, onClose, onMoveSuccess }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedProject, setSelectedProject] = useState<{ id: string; name: string } | null>(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [updateTask] = useUpdateTaskMutation();

    const { data: allProjects, isLoading: isProjectsLoading } = useGetProjectsQuery(undefined, {
        skip: !isOpen,
    });

    const filteredProjects = useMemo(() => {
        if (!allProjects) return [];
        if (!inputValue.trim()) return allProjects;
        return allProjects.filter(project => searchPredicate(inputValue, project.name));
    }, [allProjects, inputValue]);

    useEffect(() => {
        if (isOpen) {
            const timeoutId = setTimeout(() => {
                inputRef.current?.select();
                inputRef.current?.focus();
            }, 100);
            return () => clearTimeout(timeoutId);
        }
    }, [isOpen]);

    const performSearch = useCallback((term: string) => {
        setInputValue(term);
    }, []);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                performSearch(inputValue);
            }
        },
        [inputValue, performSearch],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
    };

    const handleProjectClick = (projectId: string, projectName: string) => {
        setSelectedProject({ id: projectId, name: projectName });
        setIsConfirmModalOpen(true);
    };

    const handleConfirmMove = async () => {
        if (selectedProject) {
            console.log(`Moving task ${taskId} to project ${selectedProject.id}`);
            setIsConfirmModalOpen(false);
            try {
                await updateTask({ id: taskId, input: { project_id: selectedProject.id } });
                onClose();
                if (onMoveSuccess) {
                    onMoveSuccess();
                }
                enqueueSnackbar('Задача перемещена', { variant: 'success' });
            } catch (error) {
                console.error('Error moving task:', error);
                enqueueSnackbar('Ошибка при перемещении задачи', { variant: 'error' });
            }
        }
    };

    return (
        <>
            <Modal
                opened={isOpen}
                onClose={onClose}
                size="lg"
                trapFocus={false}
                title="Переместить задачу"
                styles={{
                    body: {
                        minHeight: '200px',
                        paddingTop: '0px',
                        marginTop: '0px',
                    },
                    header: {
                        marginBottom: '0px',
                        paddingBottom: '0px',
                    },
                    title: {
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px',
                        marginTop: '32px',
                        marginLeft: '16px',
                    },
                }}
            >
                <Modal.Body>
                    <Description>
                        Сделать задачу <TaskName>{taskName}</TaskName> частью другого листа
                    </Description>
                    <SearchInputWrapper>
                        <SearchForm>
                            <StyledTextInput
                                ref={inputRef}
                                placeholder="Название листа"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                            />
                            <SearchButton rightSection={<EnterIcon />} onClick={() => performSearch(inputValue)}>
                                Найти
                            </SearchButton>
                        </SearchForm>
                    </SearchInputWrapper>
                    <SearchHint>Поиск чувствителен к регистру</SearchHint>
                    <Separator />

                    <ResultsContainer>
                        {isProjectsLoading ? (
                            <SkeletonTaskList>
                                <SkeletonTask />
                                <SkeletonTask />
                                <SkeletonTask />
                            </SkeletonTaskList>
                        ) : filteredProjects.length > 0 ? (
                            <>
                                <SearchResultTitle>Листы проектов</SearchResultTitle>
                                <ProjectsList>
                                    {filteredProjects.map(project => (
                                        <ProjectItem key={project.id}>
                                            <div onClick={() => handleProjectClick(project.id, project.name)}>
                                                <ListIcon />
                                                <span>{project.name}</span>
                                            </div>
                                            <SpaceName>в {project.space.name}</SpaceName>
                                        </ProjectItem>
                                    ))}
                                </ProjectsList>
                            </>
                        ) : (
                            <NoResultsWrapper>
                                <NoResultsTitle>Ничего не найдено</NoResultsTitle>
                                <NoResultsHint>Проверьте правильность введенного запроса и повторите попытку</NoResultsHint>
                            </NoResultsWrapper>
                        )}
                    </ResultsContainer>
                </Modal.Body>
            </Modal>

            <Modal
                opened={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                size="lg"
                title="Все правильно?"
                styles={{
                    body: {
                        paddingTop: '0px',
                        marginTop: '0px',
                    },
                    header: {
                        marginBottom: '0px',
                        paddingBottom: '0px',
                    },
                    title: {
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px',
                        marginTop: '32px',
                        marginLeft: '16px',
                    },
                }}
            >
                <Modal.Body>
                    <p>
                        Вы хотите переместить задачу <TaskName>{taskName}</TaskName> в список <TaskName>{selectedProject?.name}</TaskName>.
                        Все&nbsp;нормально?))
                    </p>
                    <ConfirmButtons>
                        <Button flex={1} variant="default" onClick={() => setIsConfirmModalOpen(false)}>
                            Передумал
                        </Button>
                        <Button flex={1} onClick={handleConfirmMove}>
                            Да
                        </Button>
                    </ConfirmButtons>
                </Modal.Body>
            </Modal>
        </>
    );
};

const Description = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #212529;
`;

const TaskName = styled.span`
    border-bottom: 1px dashed #adb5bd;
`;

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const SearchResultTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #adb5bd;
    margin: 0 0 8px 0;
`;

const ProjectsList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const ProjectItem = styled.li`
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
            text-decoration: underline;
        }
    }

    svg {
        width: 16px;
        height: 16px;
        color: #666;
    }
`;

const SpaceName = styled.span`
    margin-left: 6px;
    font-size: 14px;
    color: gray;
`;

const NoResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoResultsTitle = styled.h3`
    font-size: 16px;
    padding: 24px 0px 8px 0px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    color: #000;
`;

const NoResultsHint = styled.p`
    font-size: 12px;
    color: #868e96;
    font-weight: 400;
    line-height: 14px;
`;

const ConfirmButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: 24px;
`;
