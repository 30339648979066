import { forwardRef } from 'react';
import { Button, ButtonProps } from '@mantine/core';
import CalendarIcon from '~/assets/icons/calendar.svg?react';
import { SharedControlStyles } from '../shared/SharedControlStyles';

interface DatePickerButtonProps extends ButtonProps {
    value?: string;
}

export const DatePickerButton = forwardRef<HTMLButtonElement, DatePickerButtonProps>(({ value, ...props }, ref) => {
    return (
        <Button
            {...props}
            ref={ref}
            leftSection={<CalendarIcon />}
            styles={{
                root: {
                    ...SharedControlStyles,
                    fontSize: '14px',
                    fontWeight: '400',
                },
            }}
        >
            {value || 'Дедлайн'}
        </Button>
    );
});

DatePickerButton.displayName = 'DatePickerButton';
