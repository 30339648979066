import React, { useState, useEffect, useRef } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { match } from 'ts-pattern';
import styled from 'styled-components';

import { Avatar, Group, Flex, TextInput, Menu, Loader, Button, Text } from '@mantine/core';
import type { RenderTreeNodePayload as BaseRenderTreeNodePayload } from '@mantine/core';

import { TreeNodeData, ItemType } from './types';
import { openSharingModal } from '~/store/slices/sharingModalSlice';
import { useDispatch } from 'react-redux';

import ListIcon from '~/assets/icons/list.svg?react';
import FolderIcon from '~/assets/icons/folder.svg?react';
import FolderOpenIcon from '~/assets/icons/folder-open.svg?react';
import ChevronIcon from '~/assets/icons/chevron-right.svg?react';
import ChevronDownIcon from '~/assets/icons/chevron-down.svg?react';
import DotsIcon from '~/assets/icons/dots.svg?react';

const MoreVertIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => (
    <StyledMoreVertContainer {...props} ref={ref}>
        <DotsIcon style={{ width: '24px', height: '24px' }} />
    </StyledMoreVertContainer>
));

export interface RenderTreeNodePayload extends BaseRenderTreeNodePayload {
    node: TreeNodeData;
    onClick: (node: TreeNodeData) => void;
    onDelete: (node: TreeNodeData) => void;
    onAddTempItem: (parent: TreeNodeData, type: ItemType) => void;
    onSetEditing: (node: TreeNodeData, isEditing: boolean) => void;
    onAddItem: (node: TreeNodeData, label: string) => void;
    onUpdateLabel: (node: TreeNodeData, label: string) => void;
    currentProjectId: string | null;
}

export const SidebarLeaf: React.FC<RenderTreeNodePayload> = ({
    node,
    expanded,
    hasChildren,
    elementProps,
    tree,
    onClick,
    onDelete,
    onAddItem,
    onAddTempItem,
    onSetEditing,
    onUpdateLabel,
    currentProjectId,
}) => {
    const [iconRef] = useAutoAnimate({ duration: 75 });
    const [controlRef] = useAutoAnimate({ duration: 75 });
    const inputRef = useRef<HTMLInputElement>(null);

    const [inputValue, setInputValue] = useState(node.label);

    const { type, state } = node.nodeProps;

    const dispatch = useDispatch();

const icon = match(node.nodeProps)
    .with({ type: ItemType.Space }, props => (
        <Avatar 
            variant="filled" 
            radius="sm" 
            src={props.image}
            name={inputValue || 'Untitled'}
            color="initials" 
            size={24}
        >
            {elementProps['data-hovered'] && (
            expanded ? 
            <ChevronDownIcon className="sidebar-chevron" /> : 
            <ChevronIcon className="sidebar-chevron" />
            )}
        </Avatar>
    ))
    .with({ type: ItemType.Folder }, () => (
        <>
            <FolderIcon 
                style={{ 
                    opacity: (!hasChildren) || (!expanded && !elementProps['data-hovered']) ? 1 : 0 
                }} 
            />
            <FolderOpenIcon 
                style={{ 
                    opacity: hasChildren && (expanded || (!expanded && elementProps['data-hovered'])) ? 1 : 0 
                }} 
            />
        </>
    ))
    
    .with({ type: ItemType.Project }, () => <ListIcon />)
    .exhaustive();

    const handleClick = (callback?: () => void) => (e: React.MouseEvent) => {
        e.stopPropagation();
        callback?.();
    };

    const handleLabelKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    };

    const handleInputBlur = () => {
        const trimmedInput = inputValue.trim();

        if (state.isTemp) {
            onAddItem(node, trimmedInput);
        } else {
            if (trimmedInput === '') {
                setInputValue(node.label);
                return;
            }

            if (trimmedInput === node.label) {
                onSetEditing(node, false);
                return;
            }

            onUpdateLabel(node, trimmedInput);
        }
    };

    const onShare = (node: TreeNodeData) => {
        console.log('onShare', node);
        const props = node.nodeProps;
        const id = props.id;
        console.log('id', id);
        dispatch(openSharingModal(id));
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [node.nodeProps.state.isEditing]);

    const isActiveProject = node.nodeProps.type === ItemType.Project && currentProjectId === node.nodeProps.id;

    return (
        <ContentWrapper {...elementProps}>
            <SidebarGroup
                $isActive={isActiveProject}
                gap={8}
                onClick={e => {
                    elementProps.onClick?.(e);
                    onClick(node);
                }}
            >
                <ItemIconContainer>
                    <IconWrapper $hasChildren={hasChildren} ref={iconRef}>
                        {icon}
                    </IconWrapper>
                </ItemIconContainer>
                <Flex flex={1} ref={controlRef}>
                    {!state.isEditing && (
                        <Text size="14px" truncate="end" maw={170}>
                            {node.label}
                        </Text>
                    )}
                    {state.isEditing && (
                        <TextInput
                            ref={inputRef}
                            rightSection={state.isLoading && <Loader size={10} />}
                            variant="unstyled"
                            size="24px"
                            styles={{ root: { width: '100%' }, input: { fontSize: '14px', height: '24px' } }}
                            value={inputValue}
                            onChange={e => setInputValue(e.currentTarget.value)}
                            onBlur={handleInputBlur}
                            onKeyDown={handleLabelKeyDown}
                        />
                    )}
                </Flex>

                <Menu shadow="md" width={200} position="right-start">
                    <Menu.Target>
                        <MoreVertIcon onClick={handleClick()} />
                    </Menu.Target>
                    <Menu.Dropdown>
                        {type === ItemType.Space && (
                            <Menu.Item
                                onClick={handleClick(() => {
                                    tree.expand(node.value);
                                    onAddTempItem(node, ItemType.Folder);
                                })}
                            >
                                Создать папку
                            </Menu.Item>
                        )}
                        {(type === ItemType.Space || type === ItemType.Folder) && (
                            <Menu.Item
                                onClick={handleClick(() => {
                                    tree.expand(node.value);
                                    onAddTempItem(node, ItemType.Project);
                                })}
                            >
                                Создать лист
                            </Menu.Item>
                        )}
                        <Menu.Item onClick={handleClick(() => onSetEditing(node, true))}>Переименовать</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item color="red" onClick={handleClick(() => onDelete(node))}>
                            Удалить
                        </Menu.Item>
                        {type === ItemType.Project && (
                            <Menu.Item onClick={handleClick(() => onShare(node))}>
                                <Button fullWidth style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                                    Поделиться
                                </Button>
                            </Menu.Item>
                        )}
                    </Menu.Dropdown>
                </Menu>
            </SidebarGroup>
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
    &:focus,
    &:active {
        outline: none;
    }
    
    .sidebar-chevron path {
        stroke: white;
    }
`;

const SidebarGroup = styled(Group)<{ $isActive: boolean }>`
    padding: 8px 8px 8px 12px;
    border-radius: 6px;

    &:hover {
        background-color: #EDEDEB;
    }
        
    &:active {
        background-color: #E8E8E4;
    }

    ${props =>
        props.$isActive &&
        `
        background-color: #EDEDEB;
    `}
`;

const IconWrapper = styled.div<{ $hasChildren?: boolean }>`
    cursor: ${props => (props.$hasChildren ? 'pointer' : 'default')};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
        position: absolute;
        transition: all 0.15s ease-in-out;
    }
`;

const ItemIconContainer = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledMoreVertContainer = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-left: auto;
    width: 24px;
    height: 24px;
    color: #868E96;
    transition: all 0.15s ease-in-out;

    &:hover {
        color: #212529;
    }
`;
