import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { executeCommand } from './CommandExecutor';
import { Modal, TextInput, Button, ButtonProps } from '@mantine/core';
import { SkeletonTaskList, SkeletonTask } from '../ProjectList/TaskGroupSkeleton';
import { useGetTasksByNameQuery } from '../../store/api/tasksApi';
import { useGetProjectsByNameQuery } from '../../store/api/projectApi';

import styled from 'styled-components';
import './SearchModal.css';
import SearchTaskItem from './SearchTaskItem';
import ListIcon from '~/assets/icons/list.svg?react';
import EnterIcon from '~/assets/icons/enter.svg?react';

interface SearchModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SearchModal: React.FC<SearchModalProps> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [showPrays, setShowPrays] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOpen) {
            const timeoutId = setTimeout(() => {
                inputRef.current?.select();
                inputRef.current?.focus();
            }, 100);
            return () => clearTimeout(timeoutId);
        }
    }, [isOpen]);

    const {
        data: tasks,
        isLoading: isTasksLoading,
        refetch: refetchTasks,
    } = useGetTasksByNameQuery(searchTerm, {
        skip: searchTerm === '',
    });

    const {
        data: projects,
        isLoading: isProjectsLoading,
        refetch: refetchProjects,
    } = useGetProjectsByNameQuery(searchTerm, {
        skip: searchTerm === '',
    });

    const performSearch = useCallback(
        (term: string) => {
            setSearchTerm(term);
            if (term !== '') {
                refetchTasks();
                refetchProjects();
            }
        },
        [refetchTasks, refetchProjects],
    );

    const parseCommand = (input: string) => {
        const trimmed = input.trim();
        const command = trimmed.toLowerCase();
        if (command === '/prays') {
            setShowPrays(true);
            setSearchTerm('');
        } else if (command.startsWith('/enable')) {
            const result = executeCommand(trimmed);
            if (result) {
                onClose();
            }
        } else {
            console.log('Unknown command: ', command);
        }
    };

    const parseInput = useCallback(
        (input: string) => {
            const trimmed = input.trim();
            if (trimmed.startsWith('/')) {
                parseCommand(trimmed);
            } else {
                performSearch(trimmed);
            }
        },
        [performSearch, parseCommand],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                parseInput(inputValue);
            }
        },
        [inputValue, parseInput],
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setShowPrays(false);
    };

    const handleProjectClick = (projectId: string) => {
        navigate(`/projects/${projectId}`);
        onClose();
    };

    const hasProjects = projects && projects.length > 0;
    const hasTasks = tasks && tasks.length > 0;
    const hasResults = hasProjects || hasTasks;

    return (
        <Modal opened={isOpen} onClose={onClose} size="lg" trapFocus={false}>
            <ModalContent>
                <SearchInputWrapper>
                    <SearchForm>
                        <StyledTextInput
                            ref={inputRef}
                            placeholder="Название проекта или задачи"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <SearchButton rightSection={<EnterIcon />} onClick={() => parseInput(inputValue)}>
                            Найти
                        </SearchButton>
                    </SearchForm>
                </SearchInputWrapper>
                <SearchHint>Поиск чувствителен к регистру</SearchHint>
                {(searchTerm || showPrays) && (
                    <ResultsContainer>
                        <Separator />
                        {isProjectsLoading || isTasksLoading ? (
                            <ResultsTitle>Результаты поиска</ResultsTitle>
                        ) : hasResults ? (
                            <ResultsTitle>Результаты поиска</ResultsTitle>
                        ) : (
                            <NoResultsWrapper>
                                <NoResultsTitle>Ничего не найдено</NoResultsTitle>
                                <NoResultsHint>Проверьте правильность введенного запроса и повторите попытку</NoResultsHint>
                            </NoResultsWrapper>
                        )}
                        {showPrays && (
                            <div className="prays-container">
                                {Array(20)
                                    .fill('🙏')
                                    .map((emoji, index) => (
                                        <span key={index} className="pray-emoji">
                                            {emoji}
                                        </span>
                                    ))}
                            </div>
                        )}
                        {!showPrays && searchTerm && (
                            <Results>
                                {isProjectsLoading || isTasksLoading ? (
                                    <SkeletonTaskList>
                                        <SkeletonTask />
                                        <SkeletonTask />
                                        <SkeletonTask />
                                    </SkeletonTaskList>
                                ) : (
                                    <>
                                        {hasProjects && (
                                            <>
                                                <SearchResultTitle>Листы проектов</SearchResultTitle>
                                                <ProjectsList>
                                                    {projects.map(project => (
                                                        <ProjectItem key={project.id}>
                                                            <div onClick={() => handleProjectClick(project.id)}>
                                                                <ListIcon />
                                                                <span>{project.name}</span>
                                                            </div>
                                                            <SpaceName>в {project.space.name}</SpaceName>
                                                        </ProjectItem>
                                                    ))}
                                                </ProjectsList>
                                            </>
                                        )}
                                        {hasTasks && (
                                            <>
                                                <SearchResultTitle>Задачи</SearchResultTitle>
                                                <TasksList>
                                                    {tasks.map(task => (
                                                        <TaskItem key={task.id}>
                                                            <SearchTaskItem task={task} />
                                                        </TaskItem>
                                                    ))}
                                                </TasksList>
                                            </>
                                        )}
                                    </>
                                )}
                            </Results>
                        )}
                    </ResultsContainer>
                )}
            </ModalContent>
        </Modal>
    );
};

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 8px;
`;

export const SearchForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;
    width: 100%;
`;

export const StyledTextInput = styled(TextInput)`
    width: 100%;

    .mantine-TextInput-input {
        height: 36px;
        padding: 10px 12px;
        background: #ffffff;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 16px;

        &::placeholder {
            color: #adb5bd;
        }
    }

    .mantine-TextInput-rightSection {
        display: none; // Скрываем правую секцию, так как кнопка теперь отдельно
    }
`;

interface SearchButtonProps extends ButtonProps {
    onClick?: () => void;
}

export const SearchButton = styled(Button)<SearchButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 20px 4px 24px;
    gap: 8px;
    height: 36px;
    min-width: 117px;
    background: #4c6ef5;
    border: 1px solid #4c6ef5;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const SearchHint = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #adb5bd;
    margin: 0;
`;

const SearchResultTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #adb5bd;
    margin: 0 0 8px 0; // Добавляем нижний отступ в 8px
`;

export const SearchInputWrapper = styled.div`
    margin-bottom: 8px;
`;

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Separator = styled.div`
    height: 1px;
    background-color: #e9ecef;
    margin: 24px 0 0 0;
`;

const ResultsTitle = styled.p`
    font-size: 16px;
    padding: 8px 0px 8px 0px;
    font-weight: 600;
    line-height: 16px;
    color: #000;
`;

const NoResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoResultsTitle = styled.h3`
    font-size: 16px;
    padding: 24px 0px 8px 0px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    color: #000;
`;

const NoResultsHint = styled.p`
    font-size: 12px;
    color: #868e96;
    font-weight: 400;
    line-height: 14px;
    color: #000;
`;

const Results = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ProjectsList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 24px 0;
`;

const ProjectItem = styled.li`
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
            text-decoration: underline;
        }
    }

    svg {
        width: 16px;
        height: 16px;
        color: #666;
    }
`;

const TasksList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const TaskItem = styled.li`
    font-size: 14px;
    gap: 8px;
`;

const SpaceName = styled.span`
    margin-left: 6px;
    font-size: 14px;
    color: gray;
`;

export default SearchModal;
