export enum TaskGroupingType {
    ByStatus = 'ByStatus',
    ByAssignee = 'ByAssignee',
    ByPriority = 'ByPriority',
    ByTag = 'ByTag',
    ByDeadline = 'ByDeadline',
    ByTaskType = 'ByTaskType',
    ByManager = 'ByManager',
}

export const TaskGroupingTypeView = {
    [TaskGroupingType.ByStatus]: 'По статусу',
    [TaskGroupingType.ByAssignee]: 'По исполнителю',
    [TaskGroupingType.ByPriority]: 'По приоритету',
    [TaskGroupingType.ByTag]: 'По тегу',
    [TaskGroupingType.ByDeadline]: 'По дедлайну',
    [TaskGroupingType.ByTaskType]: 'По типу задачи',
    [TaskGroupingType.ByManager]: 'По менеджеру',
};
