import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface ConfirmModalProps {
    isOpen: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    message: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onConfirm, onCancel, message }) => {
    const [isLoading, setIsLoading] = useState(false);

    if (!isOpen) return null;

    const handleConfirm = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
    };

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalMessage>{message}</ModalMessage>
                <ModalButtons>
                    <ConfirmButton onClick={handleConfirm} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Да'}
                    </ConfirmButton>
                    <CancelButton onClick={onCancel} disabled={isLoading}>
                        Отменить
                    </CancelButton>
                </ModalButtons>
            </ModalContent>
        </ModalOverlay>
    );
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
`;

const ModalMessage = styled.p`
    margin-bottom: 20px;
    text-align: center;
    color: #212529;
    font-size: 16px;
`;

const ModalButtons = styled.div`
    display: flex;
    justify-content: space-around;
`;

const Button = styled.button`
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

const ConfirmButton = styled(Button)`
    background-color: #dc3545;
    color: white;

    &:hover:not(:disabled) {
        background-color: #c82333;
    }
`;

const CancelButton = styled(Button)`
    background-color: #6c757d;
    color: white;

    &:hover:not(:disabled) {
        background-color: #5a6268;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: ${spin} 1s linear infinite;
`;
