import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store/store';
import { addTaskAndCreate } from '~/store/slices/projectSlice';
import { CreateTaskParams } from '~/store/slices/taskCreationModalSlice';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { CustomDatePicker } from '~/components/CustomDatepicker';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { getBackendDateString, getDateRangeString, getColorByDate } from '~/utils/dateUtils';
import { UserAvatarGroup } from '../UserAvatarGroup';
import { Button, ButtonProps, TextInput } from '@mantine/core';
import { TaskIcon } from '~/components/TaskIcon';
import CalendarIcon from '~/assets/icons/calendar.svg?react';
import EnterIcon from '~/assets/icons/enter.svg?react';
import UsersIcon from '~/assets/icons/user-group.svg?react';

interface CreateTaskRowProps {
    projectId: string;
    userList: ShortUserInfo[];
    defaultStatus: TaskStatusNew;
    onClose: () => void;
    onBlur: () => void;
}

export const CreateTaskRow: React.FC<CreateTaskRowProps> = ({ projectId, userList, defaultStatus, onClose, onBlur }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [name, setName] = useState('');
    const [assignees, setAssignees] = useState<ShortUserInfo[]>([]);
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                // Проверяем, не является ли целевой элемент частью выпадающего списка
                const target = event.target as HTMLElement;
                const isDropdownPart = target.closest('.mantine-Popover-dropdown') !== null;

                if (!isDropdownPart) {
                    onBlur();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onBlur]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            onClose();
        }

        if (event.key === 'Enter') {
            await handleCreate();
        }
    };

    const handleCreate = async () => {
        if (name.trim()) {
            const newTask: CreateTaskParams = {
                name: name.trim(),
                project_id: projectId,
                due_date: dueDate ? getBackendDateString(dueDate) : null,
                start_date: null,
                status: defaultStatus,
                description: null,
                estimate: null,
                parent_id: null,
                assignees: assignees,
            };

            try {
                await dispatch(addTaskAndCreate(newTask)).unwrap();
                onClose();
            } catch (error) {
                enqueueSnackbar('Ошибка при создании задачи. Пожалуйста, попробуйте еще раз.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        } else {
            onClose();
        }
    };

    const handleCancelClick = () => {
        onClose();
    };

    const handleSaveClick = async () => {
        await handleCreate();
    };

    return (
        <InputWrapper ref={wrapperRef}>
            <TaskIcon size={24} color="#B7B9BD" />
            <StyledTextInput
                ref={inputRef}
                value={name}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder="Введите название задачи"
            />
            <ControlGroup>
                <ActionGroup>
                    {/* <WidgetButton leftSection={<TaskIcon size={16} color="#B7B9BD" />} variant="outline">
                        Задача
                    </WidgetButton> */}
                    <EnhancedPickUserCombobox position="bottom" data={userList} value={assignees} onChange={setAssignees}>
                        {({ toggleDropdown }) => (
                            <WidgetButton
                                variant="outline"
                                leftSection={<UsersIcon />}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    toggleDropdown();
                                }}
                            >
                                {assignees.length > 0 ? <UserAvatarGroup users={assignees} size="xs" onUserRemoved={setAssignees} /> : 'Исполнители'}
                            </WidgetButton>
                        )}
                    </EnhancedPickUserCombobox>

                    <WidgetButton variant="outline" $onlyIcon>
                        <CustomDatePicker
                            initialDateFrom={null}
                            initialDateTo={dueDate}
                            onDateRangeSelected={([_, newDueDate]) => setDueDate(newDueDate)}
                        >
                            {dueDate ? <DatesText color={getColorByDate(dueDate)}>{getDateRangeString(null, dueDate)}</DatesText> : <CalendarIcon />}
                        </CustomDatePicker>
                    </WidgetButton>
                </ActionGroup>
                <ActionGroup>
                    <SmallButton variant="outline" onClick={handleCancelClick}>
                        Отменить
                    </SmallButton>
                    <SmallButton rightSection={<EnterIcon />} onClick={handleSaveClick}>
                        Сохранить
                    </SmallButton>
                </ActionGroup>
            </ControlGroup>
        </InputWrapper>
    );
};

const WidgetButton = styled(Button)<ButtonProps & { $onlyIcon?: boolean; onClick?: (e: React.MouseEvent) => void }>`
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    border-color: #b7b9bd;
    color: #495057;
    padding: ${({ $onlyIcon }) => ($onlyIcon ? '4px' : '4px 8px')};

    .mantine-Button-section {
        &[data-position='left'] {
            margin-right: 4px;
        }

        &[data-position='right'] {
            margin-left: 4px;
        }
    }
`;

interface SmallButtonProps extends ButtonProps {
    onClick?: () => void;
}

const SmallButton = styled(Button)<SmallButtonProps>`
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 16px;

    background-color: #4c6ef5;

    &[data-variant='outline'] {
        background-color: transparent;
        border-color: #b7b9bd;
        color: #495057;
    }

    .mantine-Button-section {
        &[data-position='left'] {
            margin-right: 4px;
        }

        &[data-position='right'] {
            margin-left: 4px;
        }
    }
`;

const ControlGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ActionGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const InputWrapper = styled.div`
    padding: 0 4px 0 50px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    color: #b7b9bd;
`;

const StyledTextInput = styled(TextInput)`
    flex: 1;

    .mantine-TextInput-wrapper {
        width: 100%;
    }

    .mantine-TextInput-input {
        height: 48px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #212529;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: #adb5bd;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const DatesText = styled.div<{ color?: string }>`
    color: ${props => props.color || 'var(--Text-dimmed, #868e96)'};
    font:
        400 14px Inter,
        sans-serif;
`;
