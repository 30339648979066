import { SharingModal } from './SharingModal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { closeSharingModal } from '../../store/slices/sharingModalSlice';

export const SharingModalWrapper = () => {
    const isOpen = useSelector((state: RootState) => state.sharingModal.isOpen);
    const dispatch = useDispatch();

    return <SharingModal isOpen={isOpen} onClose={() => dispatch(closeSharingModal())} />;
};
