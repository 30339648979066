import { JSX } from 'react';
import { Monoid } from 'fp-ts/Monoid';

export const JSXMonoid: Monoid<JSX.Element> = {
    empty: <></>,
    concat: (x, y) => (
        <>
            {x}
            {y}
        </>
    ),
};
