import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { logout } from './slices/userSlice';
import { store } from './store';

export const graphqlBaseQuery = graphqlRequestBaseQuery({
    url: '/graphql', // Adjust this to your GraphQL endpoint
    prepareHeaders: headers => {
        headers.set('Accept', 'application/json');
        return headers;
    },
    customErrors: error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        }

        return error;
    },
});
