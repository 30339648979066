import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RootState } from '~/store/store.ts';

import { Condition, ConditionType } from '~/types/project/ProjectFilters.ts';
import { TaskGroupingType } from '~/types/TaskTypes.ts';
import { SortDirection } from '~/types/SortDirection.ts';
import { SortType } from '~/types/SortType.ts';

export interface ProjectPageFilters {
    groupBy: TaskGroupingType | null;
    sortOrder: SortDirection | null;
    selectedUserIds: string[];
    dueDateSort: SortType | null; // Добавьте это поле
    showClosed: boolean;
    showAsSeparateTasks: boolean;
    conditions: Condition[];
}

type ProjectFiltersState = Record<string, ProjectPageFilters>;

const filtersDefaultState = (): ProjectPageFilters => ({
    groupBy: null,
    sortOrder: null,
    selectedUserIds: [],
    dueDateSort: null,
    showClosed: false,
    showAsSeparateTasks: false,
    conditions: [],
});

const initialState: ProjectFiltersState = {};

const projectFiltersSlice = createSlice({
    name: 'projectFilters',
    initialState,
    reducers: {
        setGroupBy: (state, action: PayloadAction<{ projectId: string; payload: TaskGroupingType }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].groupBy = payload;
            if (state[projectId].sortOrder === null) {
                state[projectId].sortOrder = SortDirection.ASC;
            }
        },
        setSortOrder: (state, action: PayloadAction<{ projectId: string; payload: SortDirection }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].sortOrder = payload;
            if (state[projectId].groupBy === null) {
                state[projectId].groupBy = TaskGroupingType.ByStatus;
            }
        },
        removeSortAndGroup: (state, action: PayloadAction<{ projectId: string }>) => {
            const { projectId } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].groupBy = null;
            state[projectId].sortOrder = null;
        },
        setSelectedUserIds: (state, action: PayloadAction<{ projectId: string; payload: string[] }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].selectedUserIds = payload;
        },
        setDueDateSort: (state, action: PayloadAction<{ projectId: string; payload: SortType | null }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].dueDateSort = payload;
        },
        setShowClosed: (state, action: PayloadAction<{ projectId: string; payload: boolean }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].showClosed = payload;
        },
        setShowAsSeparateTasks: (state, action: PayloadAction<{ projectId: string; payload: boolean }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].showAsSeparateTasks = payload;
        },
        setFilterConditions: (state, action: PayloadAction<{ projectId: string; payload: Condition[] }>) => {
            const { projectId, payload } = action.payload;
            if (!state[projectId]) state[projectId] = filtersDefaultState();
            state[projectId].conditions = payload;
            if (payload.some(condition => condition.type === ConditionType.Status)) {
                state[projectId].showClosed = false;
            }
        },
    },
});

export const {
    setFilterConditions,
    setGroupBy,
    removeSortAndGroup,
    setSortOrder,
    setSelectedUserIds,
    setDueDateSort,
    setShowClosed,
    setShowAsSeparateTasks,
} = projectFiltersSlice.actions;

export const projectFiltersPersistConfig = {
    key: 'projectFilters',
    storage,
};

export default persistReducer(projectFiltersPersistConfig, projectFiltersSlice.reducer);

export const selectProjectFilters = (state: RootState, projectId: string | undefined): ProjectPageFilters => {
    if (!projectId) return filtersDefaultState();
    return state.projectFilters[projectId] || filtersDefaultState();
};
