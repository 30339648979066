import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HomeTaskGroup from './HomeTaskGroup';
import SkeletonTaskGroup from './SkeletonTaskGroup';
import { useGetMyTasksQuery } from '~/store/api/tasksApi';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '~/store/store';
import { setMyTasks, toggleCollapse, moveTask, moveTaskAsync } from '~/store/slices/tasksSlice';
import HomeTaskItem from './HomeTaskItem';
import { HomeTask } from '~/types/task/Task';
import { DndContext, DragOverlay, closestCorners, DragStartEvent, DragOverEvent, DragEndEvent } from '@dnd-kit/core';
import { useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { getErrorText } from '~/utils/ErrorHandler';

const Home: React.FC = () => {
    const user = useSelector((state: RootState) => state.user.currentUser);
    const { isLoading, data } = useGetMyTasksQuery(user?.id || '', {
        skip: !user?.id,
        refetchOnMountOrArgChange: true,
    });
    const taskGroups = useSelector((state: RootState) => state.tasks.groups);
    const dispatch = useDispatch<AppDispatch>();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
    );

    useEffect(() => {
        if (!data || !user?.id) return;
        dispatch(setMyTasks(data));
    }, [data, user?.id, dispatch]);

    const [activeTask, setActiveTask] = useState<HomeTask | null>(null);
    const [overTaskId, setOverTaskId] = useState<string | null>(null);
    const [overGroupTitle, setOverGroupTitle] = useState<string | null>(null);

    const handleDragStart = (event: DragStartEvent) => {
        const { active } = event;
        const activeTask = taskGroups.flatMap(group => group.tasks).find(task => task.id === active.id);
        setActiveTask(activeTask || null);
    };

    const handleDragOver = (event: DragOverEvent) => {
        const { over } = event;
        if (over) {
            const overGroup = taskGroups.find(group => group.title === over.id);
            const overTask = taskGroups.flatMap(group => group.tasks).find(task => task.id === over.id);
            if (overGroup) {
                setOverGroupTitle(overGroup.title);
                setOverTaskId(null);
            } else if (overTask) {
                const parentGroup = taskGroups.find(group => group.tasks.some(task => task.id === over.id));
                setOverGroupTitle(parentGroup?.title || null);
                setOverTaskId(over.id as string);
            } else {
                setOverTaskId(null);
                setOverGroupTitle(null);
            }
        } else {
            setOverTaskId(null);
            setOverGroupTitle(null);
        }
    };

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over) return;

        const activeTaskId = active.id as string;
        const overTaskId = over.id as string;

        const activeGroup = taskGroups.find(group => group.tasks.some(task => task.id === activeTaskId));
        const targetGroup = taskGroups.find(group => group.title === overTaskId || group.tasks.some(task => task.id === overTaskId));

        if (!activeGroup || !targetGroup) return;

        const newIndex = overTaskId === targetGroup.title ? targetGroup.tasks.length : targetGroup.tasks.findIndex(task => task.id === overTaskId);

        // Немедленно перемещаем задачу локально
        dispatch(
            moveTask({
                taskId: activeTaskId,
                fromGroup: activeGroup.title,
                toGroup: targetGroup.title,
                newIndex: newIndex === -1 ? targetGroup.tasks.length : newIndex,
            }),
        );

        // Отправляем запрос на сервер только если группа изменилась
        if (activeGroup.title !== targetGroup.title) {
            try {
                await dispatch(
                    moveTaskAsync({
                        taskId: activeTaskId,
                        fromGroup: activeGroup.title,
                        toGroup: targetGroup.title,
                        newIndex: newIndex === -1 ? targetGroup.tasks.length : newIndex,
                    }),
                );
                enqueueSnackbar('Задача успешно обновлена', {
                    variant: 'success',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            } catch (error) {
                enqueueSnackbar(`Ошибка при обновлении задачи: ${getErrorText(error)}`, {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        }

        setActiveTask(null);
        setOverTaskId(null);
        setOverGroupTitle(null);
    };

    return (
        <HomeWrapper>
            <TaskListWrapper>
                {isLoading ? (
                    // Отображение скелетонов во время загрузки
                    <>
                        <SkeletonTaskGroup />
                        <SkeletonTaskGroup />
                        <SkeletonTaskGroup />
                        <SkeletonTaskGroup />
                    </>
                ) : (
                    <DndContext
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragEnd={handleDragEnd}
                        collisionDetection={closestCorners}
                        sensors={sensors}
                    >
                        {taskGroups.map((group, index) => (
                            <HomeTaskGroup
                                key={group.title}
                                title={group.title}
                                tasks={group.tasks}
                                groupIndex={index}
                                collapsed={group.collapsed}
                                onToggleCollapse={() => {
                                    dispatch(toggleCollapse(group.title));
                                }}
                                overTaskId={overTaskId}
                                isOverGroup={overGroupTitle === group.title}
                            />
                        ))}
                        <DragOverlay>{activeTask ? <HomeTaskItem task={activeTask} /> : null}</DragOverlay>
                    </DndContext>
                )}
            </TaskListWrapper>
        </HomeWrapper>
    );
};

const HomeWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const TaskListWrapper = styled.div`
    background-color: #fff;
    flex: 1;
    padding: 0 32px;
    overflow-y: auto;
    @media (max-width: 991px) {
        padding: 0 20px;
    }
`;

export default Home;
