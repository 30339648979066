import { createApi } from '@reduxjs/toolkit/query/react';
import { SET_TASK_ASSIGNEES } from '../../graphql/mutations';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { graphqlBaseQuery } from '../graphqlBaseQuery';
import { tasksApi } from './tasksApi';
import { projectApi } from './projectApi';
import { Task } from '~/types/task/Task';
import { RootState } from '~/store/store.ts';
import { removeTaskFromGroups } from '~/store/slices/tasksSlice';
import { selectProjectFilters } from '~/store/slices/projectFiltersSlice.ts';

interface SetAssigneesUserDTO {
    id: string;
    name: string;
    avatar_url: string | null;
}

interface SetTaskAssigneesResponse {
    setTaskAssignees: {
        assignees: SetAssigneesUserDTO[];
        project_id: string;
    };
    errors?: Array<{ message: string }>;
}

interface SetTaskAssigneesResult {
    assignees: ShortUserInfo[];
    project_id: string;
}

export const assigneesApi = createApi({
    reducerPath: 'assigneesApi',
    baseQuery: graphqlBaseQuery,
    tagTypes: ['TaskAssignees'],
    endpoints: builder => ({
        setTaskAssignees: builder.mutation<SetTaskAssigneesResult, { task_id: string; user_ids: string[] }>({
            query: ({ task_id, user_ids }) => ({
                document: SET_TASK_ASSIGNEES,
                variables: { task_id, user_ids },
            }),
            transformErrorResponse: () => {
                throw new Error('An error occurred while setting task assignees');
            },
            transformResponse: (response: SetTaskAssigneesResponse) => {
                return {
                    assignees: response.setTaskAssignees.assignees.map(assignee => ({
                        id: assignee.id,
                        name: assignee.name,
                        avatarUrl: assignee.avatar_url,
                        guild: null,
                    })),
                    project_id: response.setTaskAssignees.project_id,
                };
            },
            invalidatesTags: ['TaskAssignees'],
            async onQueryStarted({ task_id }, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data } = await queryFulfilled;
                    const state = getState() as RootState;
                    const currentUserId = state.user.currentUser?.id;

                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: task_id }, draft => {
                            draft.assignee = data.assignees;
                        }),
                    );

                    if (currentUserId) {
                        // Обновляем RTK Query кэш
                        dispatch(
                            tasksApi.util.updateQueryData('getMyTasks', currentUserId, draft => {
                                return draft.filter(task => task.id !== task_id);
                            }),
                        );
                        // Обновляем Redux store
                        dispatch(removeTaskFromGroups(task_id));
                        // Принудительно инвалидируем кэш
                        dispatch(tasksApi.util.invalidateTags(['MyTasks']));
                    }

                    const projectFilters = selectProjectFilters(state, state.project.projectInfo.id);

                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectTasks',
                            { projectId: data.project_id, showClosed: projectFilters.showClosed, conditions: projectFilters.conditions },
                            draft => {
                                const updateAssignees = (tasks: Task[]) => {
                                    tasks.forEach(task => {
                                        if (task.id === task_id) {
                                            task.assignee = data.assignees;
                                        }
                                        if (task.children) {
                                            updateAssignees(task.children);
                                        }
                                    });
                                };

                                updateAssignees(draft);
                            },
                        ),
                    );
                } catch (error) {
                    console.error('Error updating task assignees:', error);
                }
            },
        }),
    }),
});

export const { useSetTaskAssigneesMutation } = assigneesApi;
