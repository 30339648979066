import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Group, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import TimeInput from '../TimeInput';
import { useCreateTimeRecordMutation, useUpdateTimeRecordMutation } from '~/store/api/timeTrackingApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskTimeSpent } from '~/store/slices/taskDetails/taskDetailsSlice';
import { RootState } from '~/store/store';
import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { TextInput } from '@mantine/core';
import { canLogForOthers } from '~/utils/projectUtils';
import { enqueueSnackbar } from 'notistack';

export interface EditMode {
    preselectedDate: Date;
    timeRecordId: string;
    timeRecordedInSeconds: number;
}

interface AddTimeComponentProps {
    className?: string;
    taskId: string;
    onClose: () => void;
    editMode?: EditMode;
}

const AddTimeComponent: React.FC<AddTimeComponentProps> = ({ className, taskId, onClose, editMode }) => {
    const [timeValue, setTimeValue] = useState<{ hours: number; minutes: number } | null>(() => {
        if (editMode?.timeRecordedInSeconds) {
            const hours = Math.floor(editMode.timeRecordedInSeconds / 3600);
            const minutes = Math.floor((editMode.timeRecordedInSeconds % 3600) / 60);
            return { hours, minutes };
        }
        return null;
    });
    const [date, setDate] = useState<Date | null>(editMode?.preselectedDate || new Date());
    const timeInputRef = useRef<HTMLInputElement>(null);
    const [selectedAssignees, setSelectedAssignees] = useState<ShortUserInfo[]>([]);
    const [selectedUser, setSelectedUser] = useState<ShortUserInfo | null>(null);

    const [createTimeRecord, { isLoading }] = useCreateTimeRecordMutation();
    const [updateTimeRecord] = useUpdateTimeRecordMutation();

    const dispatch = useDispatch();
    const { currentUser } = useSelector((state: RootState) => state.user);

    const { users } = useSelector((state: RootState) => state.taskDetails);
    const assignees = users;
    var editingRef: EditMode | undefined = editMode;

    useEffect(() => {
        if (timeInputRef.current) {
            timeInputRef.current.focus();
        }
    }, []);

    const handleTimeChange = (value: { hours: number; minutes: number } | { error: string }) => {
        if ('error' in value) {
            setTimeValue(null);
        } else {
            setTimeValue(value);
        }
    };

    const handleDateChange = (value: Date | null) => {
        setDate(value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && timeValue && date && !isLoading) {
            handleSave();
        }
    };

    const handleSave = async () => {
        const userToLog = canLogForOthers(currentUser) && selectedUser ? selectedUser : currentUser;

        if (!timeValue || !date || !userToLog) {
            return;
        }
        const timeInSeconds = timeValue.hours * 3600 + timeValue.minutes * 60;

        try {
            if (editingRef) {
                await updateTimeRecord({
                    id: editingRef.timeRecordId,
                    task_id: taskId,
                    time_in_seconds: timeInSeconds,
                    user_id: userToLog?.id,
                }).unwrap();
            } else {
                await createTimeRecord({
                    task_id: taskId,
                    time_in_seconds: timeInSeconds,
                    date: date.toISOString().split('T')[0], // Format date as YYYY-MM-DD
                    user: userToLog,
                }).unwrap();
            }

            editingRef = undefined;

            // Update the task details in the Redux store
            if (userToLog) {
                dispatch(updateTaskTimeSpent({ taskId, timeSpent: timeInSeconds, user: userToLog }));
            }

            onClose();
        } catch (error) {
            console.error('Failed to create time record:', error);
            enqueueSnackbar('Не удалось установить время', { variant: 'error' });
            // Handle error (e.g., show error message to user)
        }
    };

    const onAssigneesChange = (newAssignees: ShortUserInfo[]) => {
        // reset
        editingRef = undefined;

        setSelectedAssignees(newAssignees);
        setSelectedUser(newAssignees.length > 0 ? newAssignees[0] : null);
    };

    console.log('edit_mode', editingRef);

    return (
        <StyledAddTimeComponent className={className}>
            <Title>{editingRef ? 'Редактировать запись' : 'Добавить время'}</Title>
            <ContentWrapper>
                {canLogForOthers(currentUser) && (
                    <InputGroup>
                        <Label>Пользователь</Label>
                        <InputsWrapper>
                            <EnhancedPickUserCombobox data={assignees} value={selectedAssignees} onChange={onAssigneesChange} singleChoice={true}>
                                {({ toggleDropdown }) => (
                                    <StyledTextInput
                                        placeholder="Выберите пользователя"
                                        value={selectedUser ? selectedUser.name : currentUser?.name || ''}
                                        onClick={toggleDropdown}
                                        readOnly
                                    />
                                )}
                            </EnhancedPickUserCombobox>
                        </InputsWrapper>
                    </InputGroup>
                )}
                <InputGroup>
                    <Label>Время</Label>
                    <InputsWrapper>
                        <StyledTimeInput
                            ref={timeInputRef}
                            onChange={handleTimeChange}
                            placeholder="2h 30m, 2.5, 2:30"
                            onBlur={() => {}}
                            className=""
                            onKeyDown={handleKeyPress}
                            value={timeValue || undefined}
                        />
                        <StyledDateInput value={date} onChange={handleDateChange} locale="ru" />
                    </InputsWrapper>
                </InputGroup>
            </ContentWrapper>
            <ButtonGroup>
                <Button variant="outline" onClick={onClose} disabled={isLoading}>
                    Отменить
                </Button>
                <Button
                    color="green"
                    disabled={!timeValue || (timeValue.hours === 0 && timeValue.minutes === 0) || !date || isLoading}
                    onClick={handleSave}
                    loading={isLoading}
                >
                    Сохранить
                </Button>
            </ButtonGroup>
        </StyledAddTimeComponent>
    );
};

const StyledAddTimeComponent = styled.div`
    font-size: 14px;
    color: #868e96;
    font-weight: 400;
    line-height: 1;
`;

const Title = styled(Text)`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
    color: #333;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const InputGroup = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
`;

const Label = styled.label`
    width: 100px;
    font-weight: 500;
    color: #333;
    padding-top: 9px;
`;

const InputsWrapper = styled.div`
    display: flex;
    gap: 16px;
    flex: 1;
`;

const StyledTimeInput = styled(TimeInput)`
    flex: 1;
    height: 36px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 14px;
    color: #495057;
    background-color: #fff;

    &:focus {
        border-color: #228be6;
    }
`;

const StyledDateInput = styled(DateInput)`
    flex: 1;
`;

const StyledTextInput = styled(TextInput)`
    flex: 1;
    cursor: pointer;

    input {
        height: 36px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 0 12px;
        font-size: 14px;
        color: #495057;
        background-color: #fff;

        &:focus {
            border-color: #228be6;
        }
    }
`;

const ButtonGroup = styled(Group)`
    justify-content: space-between;
    margin-top: 24px;
`;

export default AddTimeComponent;
