import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HeadingState {
    name: string;
    isEditing: boolean;
}

const initialState: HeadingState = {
    name: '',
    isEditing: false,
};

const taskHeadingSlice = createSlice({
    name: 'taskHeading',
    initialState,
    reducers: {
        setHeadingEditing: (state, action: PayloadAction<boolean>) => {
            state.isEditing = action.payload;
        },
        setHeadingState: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
    },
});

export const { setHeadingEditing, setHeadingState } = taskHeadingSlice.actions;

export default taskHeadingSlice.reducer;
