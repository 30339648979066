import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { HomeTask } from '~/types/task/Task';
import { tasksApi } from '../api/tasksApi';
import { getBackendDateString } from '~/utils/dateUtils';

interface MyTasksState {
    groups: TaskGroup[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

interface TaskGroup {
    title: string;
    tasks: HomeTask[];
    collapsed: boolean;
}

const initialState: MyTasksState = {
    groups: [],
    status: 'idle',
    error: null,
};

export const groupIndexToApiDate = (groupIndex: number): string | null => {
    switch (groupIndex) {
        case 0:
            return getBackendDateString(new Date());
        case 1:
            return getBackendDateString(new Date(new Date().setDate(new Date().getDate() - 1)));
        case 2:
            return getBackendDateString(new Date(new Date().setDate(new Date().getDate() + 1)));
    }
    return null;
};

export const moveTaskAsync = createAsyncThunk(
    'tasks/moveTask',
    async (
        { taskId, fromGroup, toGroup, newIndex }: { taskId: string; fromGroup: string; toGroup: string; newIndex: number },
        { dispatch, getState },
    ) => {
        const state = getState() as { tasks: MyTasksState };
        const task = state.tasks.groups.flatMap(g => g.tasks).find(t => t.id === taskId);
        if (!task) throw new Error('Task not found');

        // Если группа не изменилась, не обновляем задачу на сервере
        if (fromGroup === toGroup) {
            return { taskId, fromGroup, toGroup, newIndex, updatedTask: task };
        }

        const toGroupIndex = state.tasks.groups.findIndex(group => group.title === toGroup);
        if (toGroupIndex == -1) throw new Error('Group not found');

        const apiDate = groupIndexToApiDate(toGroupIndex);

        try {
            const result = await dispatch(
                tasksApi.endpoints.updateTask.initiate({
                    id: taskId,
                    input: { due_date: apiDate },
                }),
            ).unwrap();

            return { taskId, fromGroup, toGroup, newIndex, updatedTask: result };
        } catch (error) {
            throw error;
        }
    },
);

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setMyTasks: (state, action: PayloadAction<HomeTask[]>) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const savedCollapsed = localStorage.getItem('taskGroupsCollapsed');
            const collapsedGroups= savedCollapsed ? JSON.parse(savedCollapsed) : {};
            const groupTitles = ['Сегодня', 'Просрочено', 'Следующие', 'Не запланировано'];
            const categorizedTasks: TaskGroup[] = groupTitles.map(title => ({
                title,
                tasks: [],
                collapsed: collapsedGroups[title] || false
            }));

            const tasks = action.payload;

            tasks.forEach(task => {
                const dueDate = task.dueDate ? new Date(task.dueDate) : null;

                // убрать потом как на бекенде поправят время
                dueDate?.setHours(0, 0, 0, 0); // Reset time to start of day

                if (!dueDate) {
                    categorizedTasks[3].tasks.push(task); // Незапланированно
                } else if (dueDate.getTime() === today.getTime()) {
                    categorizedTasks[0].tasks.push(task); // Сегодня
                } else if (dueDate < today) {
                    categorizedTasks[1].tasks.push(task); // Просрочено
                } else {
                    categorizedTasks[2].tasks.push(task); // Следующие
                }
            });

            state.groups = categorizedTasks;
        },
        toggleCollapse: (state, action: PayloadAction<string>) => {
            const groupTitle = action.payload;
            state.groups = state.groups.map(group => {
                if (group.title === groupTitle) {
                    const newCollapsed = !group.collapsed;
                    // Сохраняем состояние в localStorage
                    const savedCollapsed = localStorage.getItem('taskGroupsCollapsed');
                    const currentState = savedCollapsed ? JSON.parse(savedCollapsed) : {};
                    localStorage.setItem('taskGroupsCollapsed', JSON.stringify({
                        ...currentState,
                        [groupTitle]: newCollapsed
                    }));
                    return { ...group, collapsed: newCollapsed };
                }
                return group;
            });
        },
        moveTask: (state, action: PayloadAction<{ taskId: string; fromGroup: string; toGroup: string; newIndex: number }>) => {
            const { taskId, fromGroup, toGroup, newIndex } = action.payload;
            const fromGroupIndex = state.groups.findIndex(group => group.title === fromGroup);
            const toGroupIndex = state.groups.findIndex(group => group.title === toGroup);

            if (fromGroupIndex === -1 || toGroupIndex === -1) return;

            const taskIndex = state.groups[fromGroupIndex].tasks.findIndex(task => task.id === taskId);
            if (taskIndex === -1) return;

            const [movedTask] = state.groups[fromGroupIndex].tasks.splice(taskIndex, 1);

            if (fromGroupIndex === toGroupIndex && taskIndex < newIndex) {
                state.groups[toGroupIndex].tasks.splice(newIndex - 1, 0, movedTask);
            } else {
                state.groups[toGroupIndex].tasks.splice(newIndex, 0, movedTask);
            }
        },
        revertTaskMove: (state, action: PayloadAction<{ taskId: string; fromGroup: string; originalIndex: number }>) => {
            const { taskId, fromGroup, originalIndex } = action.payload;
            const currentGroup = state.groups.find(group => group.tasks.some(task => task.id === taskId));
            const fromGroupIndex = state.groups.findIndex(group => group.title === fromGroup);

            if (!currentGroup || fromGroupIndex === -1) return;

            const taskIndex = currentGroup.tasks.findIndex(task => task.id === taskId);
            if (taskIndex === -1) return;

            const [movedTask] = currentGroup.tasks.splice(taskIndex, 1);
            state.groups[fromGroupIndex].tasks.splice(originalIndex, 0, movedTask);
        },
        removeTaskFromGroups: (state, action: PayloadAction<string>) => {
            state.groups = state.groups.map(group => ({
                ...group,
                tasks: group.tasks.filter(task => task.id !== action.payload)
            }));
        },
    },
    extraReducers: builder => {
        builder
            .addCase(moveTaskAsync.pending, state => {
                state.status = 'loading';
            })
            .addCase(moveTaskAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                // Обновляем задачу с новыми данными от сервера
                const { taskId, toGroup, updatedTask } = action.payload;
                const groupIndex = state.groups.findIndex(group => group.title === toGroup);
                if (groupIndex !== -1) {
                    const taskIndex = state.groups[groupIndex].tasks.findIndex(task => task.id === taskId);
                    if (taskIndex !== -1) {
                        state.groups[groupIndex].tasks[taskIndex] = updatedTask as HomeTask;
                    }
                }
            })
            .addCase(moveTaskAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to move task';
            });
    },
});

export const { setMyTasks, toggleCollapse, moveTask, revertTaskMove, removeTaskFromGroups } = tasksSlice.actions;

export default tasksSlice.reducer;
