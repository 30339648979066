import { Modal, Button } from '@mantine/core';
import styled from 'styled-components';

export type TaskDeleteModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
};

export const TaskDeleteModal: React.FC<TaskDeleteModalProps> = ({ isOpen, onClose, onDelete }) => {
    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            title="Подтверждение удаления"
            size="lg"
            styles={{
                body: {
                    paddingTop: '0px',
                    marginTop: '0px',
                },
                header: {
                    marginBottom: '0px',
                    paddingBottom: '0px',
                },
                title: {
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '24px',
                    marginTop: '32px',
                    marginLeft: '16px',
                },
            }}
        >
            <Modal.Body>
                <p>Вы уверены, что хотите удалить эту задачу и все её подзадачи?</p>
                <ModalButtonsContainer>
                    <Button variant="outline" onClick={onClose}>
                        Отмена
                    </Button>
                    <Button color="red" onClick={onDelete}>
                        Удалить
                    </Button>
                </ModalButtonsContainer>
            </Modal.Body>
        </Modal>
    );
};

const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;
