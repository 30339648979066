export const getInitials = (name: string): string => {
    if (!name || typeof name !== 'string') {
        return '';
    }

    const trimmedName = name.trim();
    if (trimmedName.length === 0) {
        return '';
    }

    const initials = trimmedName
        .split(' ')
        .filter(word => word.length > 0)
        .map(word => word[0])
        .join('')
        .toUpperCase();

    return initials.length > 2 ? initials.slice(0, 2) : initials;
};

export const stringToDate = (date: string | null): Date | null => {
    if (!date) {
        return null;
    }

    return new Date(date);
};

export const formatDate = (date: string | null): string => {
    const inputDate = stringToDate(date);
    if (!inputDate) {
        return '';
    }

    const currentYear = new Date().getFullYear();
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
    };

    if (inputDate.getFullYear() !== currentYear) {
        options.year = 'numeric';
    }

    return inputDate.toLocaleString('ru-RU', options).replace(',', '');
};

export const formatDateTime = (date: string | null): string => {
    const inputDate = stringToDate(date);
    if (!inputDate) {
        return '';
    }

    // Get the user's local time zone from the browser
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the browser's time zone
    };

    return inputDate.toLocaleString('ru-RU', options).replace(',', '');
};

export function pluralizeRu(count: number, forms: [string, string, string]): string {
    const cases = [2, 0, 1, 1, 1, 2];
    const index = count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)];
    return `${count} ${forms[index]}`;
}

export const isNonEmptyString = (term: string) => term.length > 0;
