import React from 'react';
import styled from 'styled-components';

export const Loader: React.FC = () => {
    return <LoaderWrapper />;
};

const LoaderWrapper = styled.div`
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px 0;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
