import React from 'react';

import { absurd } from 'fp-ts/function';

import { ConditionNotEmpty, ConditionType } from '~/types/project/ProjectFilters.ts';

import { StatusCondition } from './components/StatusCondition.tsx';
import { AuthorCondition } from './components/AuthorCondition.tsx';
import { AssigneeCondition } from './components/AssigneeCondition.tsx';
import { DeadlineCondition } from './components/DeadlineCondition.tsx';
import { CreatedAtCondition } from './components/CreatedAtCondition.tsx';
import { SalesmanCondition } from './components/SalesmanCondition.tsx';
import { ManagerCondition } from './components/ManagerCondition.tsx';

interface ConditionValuePresenterProps {
    condition: ConditionNotEmpty;
    onChange: (condition: ConditionNotEmpty) => void;
}

export const ConditionValuePresenter: React.FC<ConditionValuePresenterProps> = ({ condition, onChange }) => {
    switch (condition.type) {
        case ConditionType.Status:
            return <StatusCondition condition={condition} onChange={onChange} />;
        case ConditionType.Assignee:
            return <AssigneeCondition condition={condition} onChange={onChange} />;
        case ConditionType.Author:
            return <AuthorCondition condition={condition} onChange={onChange} />;
        case ConditionType.Deadline:
            return <DeadlineCondition condition={condition} onChange={onChange} />;
        case ConditionType.CreatedAt:
            return <CreatedAtCondition condition={condition} onChange={onChange} />;
        case ConditionType.Salesman:
            return <SalesmanCondition condition={condition} onChange={onChange} />;
        case ConditionType.Manager:
            return <ManagerCondition condition={condition} onChange={onChange} />;
        default:
            absurd(condition);
    }
};
