import React from 'react';
import styled from 'styled-components';
import { EverhourWidget } from './EverhourWidget';
import { TaskInfo } from './TaskInfo';

interface DetailsProps {
    className?: string;
}

export const Details: React.FC<DetailsProps> = ({ className }) => {
    console.log('drawing details');
    return (
        <StyledDetails className={className}>
            <ContentWrapper>
                <EverhourWidget />
                <TaskInfo />
            </ContentWrapper>
        </StyledDetails>
    );
};

const StyledDetails = styled.section`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    justify-content: flex-start;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: break-word;
    word-break: break-word;
`;

const ContentWrapper = styled.div`
    max-width: 100%;
    box-sizing: border-box;
`;
