import { createApi } from '@reduxjs/toolkit/query/react';

import {
    CREATE_TASK,
    CreateTaskInput,
    DELETE_TASK,
    GET_MY_TASKS,
    GET_TASK_BY_ID,
    GET_TASKS_BY_NAME,
    GetTaskByIdVars,
    UPDATE_TASK_BY_ID,
    UpdateTaskInput,
    GET_TASK_TIME,
} from '~/graphql/tasks';
import { getTaskTypeBase } from '~/utils/taskUtils';

import { graphqlBaseQuery } from '~/store/graphqlBaseQuery';
import { setMyTasks } from '~/store/slices/tasksSlice';
import { RootState, store } from '~/store/store';

import { projectApi } from './projectApi';

import { TaskFull } from '~/types/task/TaskFull';
import { TaskType } from '~/types/task/TaskType';
import { HomeTask, Task, TimeRecorded } from '~/types/task/Task';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { CustomFieldType } from '~/types/task/CustomField';

import { colorPairs } from '~/utils/taskStatusUtils';
import { selectProjectFilters } from '~/store/slices/projectFiltersSlice.ts';

interface TaskDetailsUserDTO {
    id: string;
    name: string;
    avatar_url: string | null;
}

interface TaskDetailsSpaceDTO {
    id: string;
    name: string;
    is_private: boolean;
}

interface TaskDetailsProjectDTO {
    id: string;
    name: string;
    space: TaskDetailsSpaceDTO;
    allowedTaskStatuses: string[];
    is_private: boolean;
}

interface TaskDetailsCommentDTO {
    id: string;
    text: string;
    datetime: string;
    author: TaskDetailsUserDTO;
}

interface TaskDetailsTimeSpentDTO {
    total_time_in_seconds: number;
    user: TaskDetailsUserDTO;
}

interface HomeChildTaskDTO {
    id: string;
}

export interface HomeTaskDTO {
    id: string;
    name: string;
    status: string;
    start_date: string;
    due_date: string;
    estimate: number | null;
    total_time_recorded: number | null;
    project: {
        allowedTaskStatuses: string[];
    };
    children: HomeChildTaskDTO[];
    parent_id: string | null;
}

interface TaskDTO {
    id: string;
    name: string;
    description: string;
    start_date: string;
    due_date: string;
    status: string;
    created_at: string;
    estimate: number | null;
    total_time_recorded: number | null;
    assignees: TaskDetailsUserDTO[];
    project: TaskDetailsProjectDTO;
    parent: {
        id: string;
        name: string;
    } | null;
}

interface TaskDetailsDTO extends TaskDTO {
    comments: TaskDetailsCommentDTO[];
    children: TaskDTO[];
    time_recorded_by_users: TaskDetailsTimeSpentDTO[];
    customFields: CustomFieldDTO[];
    weeek_id: string | null;
    author: TaskDetailsUserDTO | null;
}

interface CustomFieldDTO {
    type: CustomFieldType;
    name: string;
    options: Array<{ label: string; value: string }>;
    value: string;
}

interface TaskDetailsResponse {
    tasks: {
        data: TaskDetailsDTO[];
    };
}

interface TaskUpdateResponse {
    updateTask: TaskDetailsDTO;
}

interface TaskCreateResponse {
    createTask: TaskDetailsDTO;
}

interface TimeRecordedDTO {
    id: string;
    user_id: string;
    time_in_seconds: number;
    date: string;
}

const mapChildTaskDTO = (child: TaskDTO, parentId: string, allowedTaskStatuses: string[]): Task => ({
    id: child.id.toString(),
    name: child.name,
    type: TaskType.DEFAULT,
    status: mapTaskStatus(child.status, allowedTaskStatuses),
    startDate: child.start_date || null,
    createdAt: child.created_at,
    dueDate: child.due_date || null,
    estimate: child.estimate || null,
    timeSpent: child.total_time_recorded || 0,
    totalTime: 0,
    assignee:
        child.assignees?.map(assignee => ({
            id: assignee.id,
            name: assignee.name,
            avatarUrl: assignee.avatar_url || '',
            guild: null,
        })) || [],
    children: [],
    parentId: parentId,
    allowedTaskStatuses: allowedTaskStatuses.map(status => mapTaskStatus(status, allowedTaskStatuses)),
    parent: child.parent
        ? {
              id: child.parent.id,
              name: child.parent.name,
          }
        : null,
});

const mapTaskStatus = (status: string, allowedStatuses: string[]): TaskStatusNew => {
    const normalizedStatus = status.trim().toLowerCase();
    const normalizedAllowedStatuses = allowedStatuses.map(s => s.trim().toLowerCase());

    const statusIndex = normalizedAllowedStatuses.indexOf(normalizedStatus);

    if (statusIndex === -1) {
        console.warn(`Unknown status: ${status}. Using default color.`);
        return {
            name: status,
            color: '#808080', // Серый цвет по умолчанию
            textColor: '#FFFFFF',
        };
    }

    const colorIndex = statusIndex % colorPairs.length;
    const color = colorPairs[colorIndex];

    const mappedStatus = {
        name: status,
        color: color[0],
        textColor: color[1],
    };

    return mappedStatus;
};

const homeTaskDTOToTask = (taskDTO: HomeTaskDTO): HomeTask => {
    return {
        id: taskDTO.id.toString(),
        name: taskDTO.name,
        totalTime: taskDTO.total_time_recorded,
        status: mapTaskStatus(taskDTO.status, taskDTO.project.allowedTaskStatuses),
        startDate: taskDTO.start_date,
        dueDate: taskDTO.due_date,
        estimate: taskDTO.estimate,
        allowedTaskStatuses: taskDTO.project.allowedTaskStatuses.map(status => mapTaskStatus(status, taskDTO.project.allowedTaskStatuses)),
        type: getTaskTypeBase(
            taskDTO.parent_id,
            taskDTO.children.map(child => child.id),
        ),
    };
};

const taskDetailsDTOToTaskFull = (taskDetailsDTO: TaskDetailsDTO): TaskFull => {
    return {
        id: taskDetailsDTO.id.toString(),
        name: taskDetailsDTO.name,
        type: TaskType.DEFAULT,
        description: taskDetailsDTO.description,
        dueDate: taskDetailsDTO.due_date,
        startDate: taskDetailsDTO.start_date,
        estimate: taskDetailsDTO.estimate,
        timeSpent: taskDetailsDTO.total_time_recorded || 0,
        author: taskDetailsDTO.author
            ? {
                  id: taskDetailsDTO.author.id,
                  name: taskDetailsDTO.author.name,
                  avatarUrl: taskDetailsDTO.author.avatar_url,
                  guild: null,
              }
            : null,
        createdAt: taskDetailsDTO.created_at,
        totalTime: 0,
        status: mapTaskStatus(taskDetailsDTO.status, taskDetailsDTO.project.allowedTaskStatuses),
        assignee: (taskDetailsDTO.assignees || []).map(assignee => ({
            id: assignee.id,
            name: assignee.name,
            avatarUrl: assignee.avatar_url || '',
            guild: null,
        })),
        project: {
            id: taskDetailsDTO.project.id,
            name: taskDetailsDTO.project.name,
            space: {
                id: taskDetailsDTO.project.space.id,
                name: taskDetailsDTO.project.space.name,
                isPrivate: taskDetailsDTO.project.space.is_private,
            },
            allowedTaskStatuses:
                taskDetailsDTO.project.allowedTaskStatuses.map(status => mapTaskStatus(status, taskDetailsDTO.project.allowedTaskStatuses)) ?? [],
            isPrivate: taskDetailsDTO.project.is_private,
        },
        comments: (taskDetailsDTO.comments || []).map(comment => ({
            id: comment.id,
            text: comment.text,
            datetime: comment.datetime,
            author: {
                id: comment.author.id,
                name: comment.author.name,
                avatarUrl: comment.author.avatar_url || '',
                guild: null,
            },
        })),
        children: (taskDetailsDTO.children || []).map(child =>
            mapChildTaskDTO(child, taskDetailsDTO.id.toString(), taskDetailsDTO.project.allowedTaskStatuses),
        ),
        weeekId: taskDetailsDTO.weeek_id || null,
        parentId: taskDetailsDTO.parent?.id || null,
        time_recorded_by_users: (taskDetailsDTO.time_recorded_by_users || []).map(timeSpent => ({
            total_time_in_seconds: timeSpent.total_time_in_seconds,
            user: {
                id: timeSpent.user.id,
                name: timeSpent.user.name,
                avatarUrl: timeSpent.user.avatar_url || '',
                guild: null,
            },
        })),
        parent: taskDetailsDTO.parent
            ? {
                  id: taskDetailsDTO.parent.id,
                  name: taskDetailsDTO.parent.name,
              }
            : null,
        allowedTaskStatuses: taskDetailsDTO.project.allowedTaskStatuses.map(status =>
            mapTaskStatus(status, taskDetailsDTO.project.allowedTaskStatuses),
        ),
        customFields: taskDetailsDTO.customFields,
    };
};

const formatRecordedTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += `${hours}h `;
    }
    if (minutes > 0 || (hours === 0 && minutes === 0)) {
        formattedTime += `${minutes}m`;
    }

    return formattedTime.trim();
};

export const tasksApi = createApi({
    reducerPath: 'tasksApi',
    baseQuery: graphqlBaseQuery,
    tagTypes: ['Task', 'Tasks', 'MyTasks'],
    endpoints: builder => ({
        getTaskById: builder.query<TaskFull, GetTaskByIdVars>({
            query: variables => ({
                document: GET_TASK_BY_ID,
                variables,
            }),
            transformResponse: (response: TaskDetailsResponse) => taskDetailsDTOToTaskFull(response.tasks.data[0]),
            providesTags: (_result, _error, arg) => [{ type: 'Task', id: arg.id }],
        }),
        updateTask: builder.mutation<TaskFull, { id: string; parentId?: string | null; input: UpdateTaskInput }>({
            query: ({ id, input }) => ({
                document: UPDATE_TASK_BY_ID,
                variables: { id: id, task: input },
            }),
            transformResponse: (response: TaskUpdateResponse) => taskDetailsDTOToTaskFull(response.updateTask),
            invalidatesTags: ['Task', 'Tasks', 'MyTasks'],
            async onQueryStarted({ id, parentId, input }, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        tasksApi.util.updateQueryData('getTaskById', { id: id }, draft => {
                            Object.assign(draft, data);
                        }),
                    );

                    if (parentId) {
                        dispatch(
                            tasksApi.util.updateQueryData('getTaskById', { id: parentId }, draft => {
                                if (draft.children) {
                                    const existingChildIndex = draft.children.findIndex(child => child.id === id);
                                    if (existingChildIndex !== -1) {
                                        // Replace existing child with updated data
                                        draft.children[existingChildIndex] = {
                                            ...draft.children[existingChildIndex],
                                            ...data,
                                        };
                                    }
                                }
                            }),
                        );
                    }

                    const currentUserId = store.getState().user.currentUser?.id;

                    if (currentUserId && data.assignee && data.assignee.some(assignee => assignee.id === currentUserId)) {
                        dispatch(
                            tasksApi.util.updateQueryData('getMyTasks', currentUserId, draft => {
                                const updateTask = (tasks: HomeTask[]): void => {
                                    for (let i = 0; i < tasks.length; i++) {
                                        if (tasks[i].id === id) {
                                            tasks[i] = {
                                                ...tasks[i],
                                                name: data.name,
                                                status: data.status,
                                                startDate: data.startDate,
                                                dueDate: data.dueDate,
                                                estimate: data.estimate,
                                            };
                                            return;
                                        }
                                    }
                                };
                                updateTask(draft);
                            }),
                        );
                    }

                    if (data.project && data.project.id) {
                        const state = getState() as RootState;
                        const projectFilters = selectProjectFilters(state, state.project.projectInfo.id);
                        const projectId = data.project.id;
                        const newProjectId = input.project_id;

                        dispatch(
                            projectApi.util.updateQueryData(
                                'getProjectTasks',
                                { projectId: data.project.id, showClosed: projectFilters.showClosed, conditions: projectFilters.conditions },
                                draft => {
                                    if (newProjectId && newProjectId !== projectId) {
                                        return draft.filter(task => task.id !== id);
                                    }

                                    const updateTask = (tasks: Task[]): void => {
                                        for (let i = 0; i < tasks.length; i++) {
                                            if (tasks[i].id === id) {
                                                tasks[i] = {
                                                    ...tasks[i],
                                                    name: data.name,
                                                    status: data.status,
                                                    startDate: data.startDate,
                                                    dueDate: data.dueDate,
                                                    estimate: data.estimate,
                                                    timeSpent: data.timeSpent,
                                                    assignee: data.assignee,
                                                };
                                                return;
                                            }
                                            if (tasks[i].children) {
                                                updateTask(tasks[i].children!);
                                            }
                                        }
                                    };

                                    updateTask(draft);
                                },
                            ),
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        }),
        getMyTasks: builder.query<HomeTask[], string>({
            query: assigneeId => ({
                document: GET_MY_TASKS,
                variables: { assignee_id: [assigneeId] },
            }),
            transformResponse: (response: { tasks: { data: HomeTaskDTO[] } }) => {
                return response.tasks.data.map(taskDTO => homeTaskDTOToTask(taskDTO));
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setMyTasks(data));
                } catch (error) {
                    // Handle error if needed
                    console.log(error);
                }
            },
            providesTags: ['Tasks', 'MyTasks'],
        }),
        createTask: builder.mutation<TaskFull, CreateTaskInput>({
            query: task => ({
                document: CREATE_TASK,
                variables: { task },
            }),
            transformResponse: (response: TaskCreateResponse) => taskDetailsDTOToTaskFull(response.createTask),
            invalidatesTags: ['Task', 'Tasks', 'MyTasks'],
            async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data } = await queryFulfilled;

                    const state = getState() as RootState;
                    const projectFilters = selectProjectFilters(state, state.project.projectInfo.id);

                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectTasks',
                            { projectId: data.project.id, showClosed: projectFilters.showClosed, conditions: projectFilters.conditions },
                            draft => {
                                draft.push(data);
                            },
                        ),
                    );

                    if (data.parentId) {
                        dispatch(
                            projectApi.util.updateQueryData(
                                'getProjectTasks',
                                { projectId: data.project.id, showClosed: projectFilters.showClosed, conditions: projectFilters.conditions },
                                draft => {
                                    const parentTask = draft.find(task => task.id === data.parentId);
                                    if (parentTask && parentTask.children) {
                                        parentTask.children.push(data);
                                    }
                                },
                            ),
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        }),
        deleteTask: builder.mutation<boolean, { id: string; projectId: string }>({
            query: ({ id }) => ({
                document: DELETE_TASK,
                variables: { id },
            }),
            transformResponse: (response: { deleteTask: boolean }) => response.deleteTask,
            invalidatesTags: ['Task', 'Tasks', 'MyTasks'],
            async onQueryStarted({ id, projectId }, { dispatch, queryFulfilled, getState }) {
                try {
                    await queryFulfilled;

                    const state = getState() as RootState;
                    const projectFilters = selectProjectFilters(state, state.project.projectInfo.id);

                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectTasks',
                            { projectId, showClosed: projectFilters.showClosed, conditions: projectFilters.conditions },
                            draft => {
                                return draft.filter(task => task.id !== id);
                            },
                        ),
                    );
                } catch (error) {
                    console.error('Error deleting task:', error);
                }
            },
        }),
        getTasksByName: builder.query<HomeTask[], string>({
            query: name => ({
                document: GET_TASKS_BY_NAME,
                variables: { name },
            }),
            transformResponse: (response: { tasks: { data: HomeTaskDTO[] } }) => {
                return response.tasks.data.map(taskDTO => homeTaskDTOToTask(taskDTO));
            },
        }),
        getTaskTime: builder.query<TimeRecorded[], string>({
            query: taskId => ({
                document: GET_TASK_TIME,
                variables: { task_id: taskId },
            }),
            transformResponse: (response: { userTaskTimeRecords: TimeRecordedDTO[] }) => {
                return response.userTaskTimeRecords.map(record => ({
                    userId: record.user_id,
                    timeInSeconds: record.time_in_seconds,
                    date: record.date,
                    formattedTime: formatRecordedTime(record.time_in_seconds),
                    id: record.id,
                }));
            },
        }),
    }),
});

export const {
    useGetTaskByIdQuery,
    useUpdateTaskMutation,
    useGetMyTasksQuery,
    useCreateTaskMutation,
    useDeleteTaskMutation,
    useGetTasksByNameQuery,
    useGetTaskTimeQuery,
} = tasksApi;

export { mapTaskStatus };
