import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ActionIcon } from '@mantine/core';

import { Tooltip } from '@mantine/core';
import { getStringStatusText } from '~/utils/taskStatusUtils';
import { Task } from '~/types/task/Task.ts';
import { TaskType } from '~/types/task/TaskType';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';

import { formatTaskTime, getTaskType } from '~/utils/taskUtils';

import { StatusCombobox } from '~/components/StatusCombobox.tsx';
import { CustomDatePicker } from '~/components/CustomDatepicker';
import { UserAvatarGroup } from '~/components/UserAvatarGroup';
import { AutosizeInput } from '~/components/shared/AutosizeInput';
import { TaskIconNew } from '~/components/TaskIcon';

import Collapse from '~/assets/icons/chevron-right.svg?react';
import Deadline from '~/assets/icons/date-plus.svg?react';
import Assign from '~/assets/icons/user-plus.svg?react';
import Dots from '~/assets/icons/dots.svg?react';
import ChildTask from '~/assets/icons/to-upper.svg?react';

import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { updateTaskAssignees } from '~/store/slices/projectSlice';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { getColorByDate, getDateRangeString } from '~/utils/dateUtils';
import { StatusArea } from '~/components/TaskList/StatusArea';
import { TaskStatus } from '~/types/task/TaskStatus';
import { TaskActions } from '../TaskActions';

interface TaskItemProps {
    userList: ShortUserInfo[];
    task: Task;
    parentInfo: { id: string; name: string } | null;
    onClick: () => void;
    onChildClick: (task: Task) => void;
    onDelete: () => void;
    onRename: (newName: string) => void;
    onMoveToList: () => void;
    onDateRangeChange: (taskId: string, startDate: Date | null, dueDate: Date | null) => void;
    onStatusChange: (value: TaskStatusNew[], taskId: string) => void;
}

const TaskItem: React.FC<TaskItemProps> = ({
    task,
    parentInfo,
    onClick,
    onChildClick,
    onRename,
    onDelete,
    userList,
    onDateRangeChange,
    onStatusChange,
    onMoveToList,
}) => {
    const { id, name, timeSpent, estimate, assignee } = task;
    const dispatch = useDispatch();

    const [showChildren, setShowChildren] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState(name);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isRenaming && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isRenaming]);

    const handleDelete = (event: React.MouseEvent) => {
        event.stopPropagation();
        onDelete();
    };

    const handleRename = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsRenaming(true);
    };

    const handleRenameConfirm = () => {
        if (newName.trim() !== '' && newName !== name) {
            onRename(newName);
        }
        setIsRenaming(false);
    };

    const handleRenameCancel = () => {
        setNewName(name);
        setIsRenaming(false);
    };

    const handleCollapse = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowChildren(!showChildren);
    };

    //В дропдауне не обновляются выбранные пользователи после их изменения из модалки таска, кажется проблема в юзеркомбобоксе
    const [localAssignees, setLocalAssignees] = useState(assignee);

    const debouncedUpdateAssignees = useMemo(
        () =>
            debounce(async (taskId: string, userIds: string[]) => {
                try {
                    await dispatch(updateTaskAssignees({ taskId, userIds }) as any).unwrap();
                } catch (error) {
                    enqueueSnackbar('Ошибка при обновлении исполнителей.', {
                        variant: 'error',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                }
            }, 500),
        [dispatch],
    );

    const areAssigneesEqual = (a: ShortUserInfo[], b: ShortUserInfo[]) => {
        if (a.length !== b.length) return false;
        const setA = new Set(a.map(user => user.id));
        return b.every(user => setA.has(user.id));
    };

    const handleAssigneesChange = useCallback(
        (newAssignees: ShortUserInfo[]) => {
            if (!areAssigneesEqual(newAssignees, localAssignees)) {
                setLocalAssignees(newAssignees);
                debouncedUpdateAssignees(
                    id,
                    newAssignees.map(user => user.id),
                );
            }
        },
        [id, debouncedUpdateAssignees, localAssignees],
    );

    useEffect(() => {
        if (!areAssigneesEqual(assignee, localAssignees)) {
            setLocalAssignees(assignee);
        }
    }, [assignee]);

    const [localStatus, setLocalStatus] = useState<TaskStatusNew>(task.status);
    const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const updateTaskStatus = async (newStatus: TaskStatusNew) => {
        try {
            onStatusChange([newStatus], task.id);
        } catch {
            setLocalStatus(localStatus);
            enqueueSnackbar('Не удалось изменить статус задачи', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            return;
        }

        enqueueSnackbar('Статус задачи изменен', {
            variant: 'success',
            action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
        });
    };

    const handleStatusChange = (value: TaskStatusNew[]) => {
        const [newStatus] = value;
        setLocalStatus(newStatus);

        if (closeTimeoutRef.current !== null) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
        }

        const isClosingTask = localStatus.name !== TaskStatus.CLOSED && newStatus.name === TaskStatus.CLOSED;

        if (!isClosingTask) {
            void updateTaskStatus(newStatus);
            return;
        }

        closeTimeoutRef.current = setTimeout(() => void updateTaskStatus(newStatus), 5000);
    };

    if (parentInfo != null) {
        console.log('Parent', parentInfo);
    }

    return (
        <TaskWrapper>
            <TaskItemContainer onClick={onClick}>
                <StatusArea onClick={e => e.stopPropagation()}>
                    <CollapseButton $hidden={task.type !== TaskType.MILESTONE} $isCollapsed={showChildren} onClick={handleCollapse}>
                        <Collapse />
                    </CollapseButton>
                    <StatusCombobox allowedStatuses={task.allowedTaskStatuses} value={[localStatus]} onChange={handleStatusChange}>
                        {({ toggleDropdown }) => (
                            <Tooltip
                                label={getStringStatusText(localStatus.name)}
                                withArrow
                                transitionProps={{ transition: 'fade', duration: 200 }}
                                position="top"
                            >
                                <ActionIcon
                                    variant="transparent"
                                    onClick={e => {
                                        e.stopPropagation();
                                        toggleDropdown();
                                    }}
                                >
                                    <TaskIconNew status={localStatus} type={getTaskType(task)} />
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </StatusCombobox>
                </StatusArea>
                <TaskInfo>
                    <TaskDetails>
                        {parentInfo ? <ChildTask /> : <></>}
                        {isRenaming ? (
                            <AutosizeInput
                                ref={inputRef}
                                value={newName}
                                onBlur={handleRenameConfirm}
                                onChange={e => setNewName(e.target.value)}
                                onClick={e => e.stopPropagation()}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') handleRenameConfirm();
                                    if (e.key === 'Escape') handleRenameCancel();
                                }}
                            />
                        ) : (
                            <TaskName title={name}>{name}</TaskName>
                        )}
                        <TaskTime>{formatTaskTime(timeSpent ? Math.floor(timeSpent / 60) : 0, estimate)}</TaskTime>
                    </TaskDetails>
                </TaskInfo>
                <NonClickableArea onClick={e => e.stopPropagation()}>
                    <AssigneeColumn>
                        <EnhancedPickUserCombobox
                            position="bottom"
                            data={userList}
                            value={localAssignees}
                            onChange={newAssignees => handleAssigneesChange(newAssignees)}
                        >
                            {({ toggleDropdown }) => (
                                <AssignButton
                                    onClick={e => {
                                        e.stopPropagation();
                                        toggleDropdown();
                                    }}
                                >
                                    {localAssignees.length > 0 ? (
                                        <UserAvatarGroup users={localAssignees} onUserRemoved={newAssignees => handleAssigneesChange(newAssignees)} />
                                    ) : (
                                        <Assign />
                                    )}
                                </AssignButton>
                            )}
                        </EnhancedPickUserCombobox>
                    </AssigneeColumn>
                    <DeadlineColumn>
                        <CustomDatePicker
                            initialDateFrom={task.startDate ? new Date(task.startDate) : null}
                            initialDateTo={task.dueDate ? new Date(task.dueDate) : null}
                            onDateRangeSelected={dateRange => {
                                onDateRangeChange(id, dateRange[0], dateRange[1]);
                            }}
                        >
                            {task.dueDate ? (
                                <DatesText color={getColorByDate(task.dueDate)}>{getDateRangeString(null, new Date(task.dueDate))}</DatesText>
                            ) : (
                                <AddDeadlineButton>
                                    <Deadline />
                                </AddDeadlineButton>
                            )}
                        </CustomDatePicker>
                    </DeadlineColumn>
                    <TaskActions onRename={handleRename} onDelete={handleDelete} onMoveToList={onMoveToList}>
                        <OptionsColumn>
                            <Dots />
                        </OptionsColumn>
                    </TaskActions>
                </NonClickableArea>
            </TaskItemContainer>
            <TaskChildrenWrapper>
                {showChildren &&
                    task.children?.map(child => (
                        <TaskItem
                            key={child.id}
                            parentInfo={null}
                            task={child}
                            onClick={() => onChildClick(child)}
                            onChildClick={() => {}}
                            onRename={() => {}}
                            onDelete={() => {}}
                            userList={userList}
                            onDateRangeChange={onDateRangeChange}
                            onStatusChange={onStatusChange}
                            onMoveToList={onMoveToList}
                        />
                    ))}
            </TaskChildrenWrapper>
        </TaskWrapper>
    );
};

const TaskWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TaskChildrenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    > * {
        border-top: 1px solid #e9ecef;
    }
`;

const CollapseButton = styled.div<{ $hidden: boolean; $isCollapsed: boolean }>`
    transition: transform 0.3s;
    transform: ${props => (props.$isCollapsed ? 'rotate(90deg)' : 'rotate(0deg)')};

    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
        props.$hidden &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

const TaskItemContainer = styled.div`
    border-color: #e9ecef;
    border-bottom-width: 1px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    gap: 8px;

    &:hover {
        background-color: #fbfbf9;
    }
`;

const TaskInfo = styled.div`
    align-self: stretch;
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex: 1;
    flex-basis: 12px;
    margin: auto 0;
    overflow: hidden;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 0;
`;

const TaskName = styled.div`
    color: var(--Text-default, #212529);
    font:
        400 14px Inter,
        sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;

const TaskTime = styled.div`
    color: var(--Text-dimmed, #868e96);
    font:
        400 14px Inter,
        sans-serif;
`;

const NonClickableArea = styled.div`
    display: flex;
    align-items: center;
`;

const AssigneeColumn = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 96px;
    margin: auto 0;
`;

const AssignButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DeadlineColumn = styled.div`
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 96px;
    margin: auto 0;
`;

const AddDeadlineButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const OptionsColumn = styled.div`
    align-self: stretch;
    color: var(--Text-dimmed, #868e96);
    white-space: nowrap;
    margin: auto 0;
    padding: 0 6px;
    font:
        400 14px/1 Inter,
        sans-serif;
    cursor: pointer;
    text-align: center;
`;

const DatesText = styled.div<{ color?: string }>`
    color: ${props => props.color || 'var(--Text-dimmed, #868e96)'};
    font:
        400 14px Inter,
        sans-serif;
`;

export default TaskItem;
