import { TaskStatusNew } from '~/types/task/TaskStatus';
import { StatusCombobox } from '~/components/StatusCombobox.tsx';
import { Button, ButtonProps } from '@mantine/core';
import React, { forwardRef } from 'react';
import { SharedControlStyles } from '~/components/shared/SharedControlStyles';
import { getStringStatusText } from '~/utils/taskStatusUtils';

interface TaskStatusButtonProps {
    status: TaskStatusNew;
    allowedStatuses: TaskStatusNew[];
    onChange: (newStatus: TaskStatusNew) => void;
}

export const NewTaskStatusButton: React.FC<TaskStatusButtonProps> = ({ status, allowedStatuses, onChange }) => {
    return (
        <StatusCombobox value={[status]} allowedStatuses={allowedStatuses} onChange={newStatus => onChange(newStatus[0])}>
            {({ toggleDropdown }) => <StatusButton status={status} onClick={toggleDropdown} />}
        </StatusCombobox>
    );
};

interface StatusButtonProps extends ButtonProps {
    status: TaskStatusNew;
    onClick: () => void;
}

const StatusButton = forwardRef<HTMLButtonElement, StatusButtonProps>(({ status, onClick, ...props }, ref) => {
    return (
        <Button
            ref={ref}
            onClick={onClick}
            styles={{
                root: {
                    ...SharedControlStyles,
                    fontSize: '12px',
                    fontWeight: '400',
                    color: status.textColor,
                    backgroundColor: status.color,
                },
            }}
            {...props}
        >
            {getStringStatusText(status.name).toUpperCase()}
        </Button>
    );
});
