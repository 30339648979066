import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';
import { HomeTask } from '../../types/task/Task';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { TaskIconNew } from '../TaskIcon';
import { formatTaskTime } from '~/utils/taskUtils';
import { formatDateString, getBackendDateString, getColorByDate } from '~/utils/dateUtils';
import { ActionIcon, Tooltip } from '@mantine/core';
import { getStringStatusText } from '~/utils/taskStatusUtils';
import { StatusCombobox } from '../StatusCombobox.tsx';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { useUpdateTaskMutation } from '~/store/api/tasksApi';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/store';

import Deadline from '~/assets/icons/date-plus.svg?react';
import { CustomDatePicker } from '../CustomDatepicker.tsx';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { TaskStatus } from '~/types/task/TaskStatus';

interface HomeTaskItemProps {
    task: HomeTask;
}

interface TaskContainerProps {
    isAirEnabled: boolean;
}

const HomeTaskItem: React.FC<HomeTaskItemProps> = ({ task }) => {
    const [localStatus, setLocalStatus] = useState<TaskStatusNew>(task.status);
    const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: task.id });

    const { getTaskRoute } = useTaskNavigation();
    const [updateTask] = useUpdateTaskMutation();

    const isAirEnabled = useSelector((state: RootState) => state.settings.isAirEnabled);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    const updateTaskStatus = async (newStatus: TaskStatusNew) => {
        const result = await updateTask({ id: task.id, input: { status: newStatus.name } });

        if (result.error) {
            setLocalStatus(localStatus);
            enqueueSnackbar('Не удалось изменить статус задачи', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }

        enqueueSnackbar('Статус задачи изменен', {
            variant: 'success',
            action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
        });
    };

    const handleStatusChange = async (value: TaskStatusNew[]) => {
        const [newStatus] = value;
        setLocalStatus(newStatus);

        if (closeTimeoutRef.current !== null) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
        }

        const isClosingTask = localStatus.name !== TaskStatus.CLOSED && newStatus.name === TaskStatus.CLOSED;

        if (!isClosingTask) {
            await updateTaskStatus(newStatus);
            return;
        }

        closeTimeoutRef.current = setTimeout(() => updateTaskStatus(newStatus), 5000);
    };

    const handleDateRangeChange = async (dateRange: [Date | null, Date | null]) => {
        const [startDate, endDate] = dateRange;
        const result = await updateTask({
            id: task.id,
            input: { start_date: getBackendDateString(startDate), due_date: getBackendDateString(endDate) },
        });
        if (result.error) {
            enqueueSnackbar('Не удалось изменить дедлайн задачи', { variant: 'error' });
        } else {
            enqueueSnackbar('Дедлайн задачи изменен', { variant: 'success' });
        }
    };

    return (
        <TaskWrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <DragHandle {...listeners} {...attributes}></DragHandle>
            <TaskContainer isAirEnabled={isAirEnabled}>
                <Tooltip
                    label={getStringStatusText(localStatus.name)}
                    withArrow
                    transitionProps={{ transition: 'fade', duration: 200 }}
                    position="top"
                >
                    <StatusCombobox allowedStatuses={task.allowedTaskStatuses} value={[localStatus]} onChange={handleStatusChange}>
                        {({ toggleDropdown }) => (
                            <Tooltip
                                label={getStringStatusText(localStatus.name)}
                                withArrow
                                transitionProps={{ transition: 'fade', duration: 200 }}
                                position="top"
                            >
                                <ActionIcon variant="transparent" size="md" onClick={toggleDropdown} style={{ height: '100%' }}>
                                    <TaskIconNew status={localStatus} type={task.type} />
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </StatusCombobox>
                </Tooltip>
                <TaskLink to={getTaskRoute(task.id)}>
                    <TaskInfo>
                        <TaskDetails>
                            <TaskName>{task.name}</TaskName>
                            <TaskTime>{formatTaskTime(task.totalTime ? Math.floor(task.totalTime / 60) : null, task.estimate)}</TaskTime>
                        </TaskDetails>
                    </TaskInfo>
                </TaskLink>
                <TaskDatePicker
                    initialDateFrom={task.startDate ? new Date(task.startDate) : null}
                    initialDateTo={task.dueDate ? new Date(task.dueDate) : null}
                    onDateRangeSelected={dateRange => {
                        handleDateRangeChange(dateRange);
                    }}
                >
                    {task.dueDate ? (
                        <DueDate color={getColorByDate(task.dueDate)}>{formatDateString(task.dueDate)}</DueDate>
                    ) : (
                        <AddDeadlineButton>
                            <Deadline />
                        </AddDeadlineButton>
                    )}
                </TaskDatePicker>
            </TaskContainer>
        </TaskWrapper>
    );
};

const TaskWrapper = styled.li`
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #e9ecef;
    transition:
        border-color 0.2s ease,
        transform 0.1s ease;

    &:hover {
        background-color: #f8f9fa;
        border-radius: 5px;
    }
`;

const DragHandle = styled.div`
    cursor: grab;
    display: flex;
    align-items: center;
    &:active {
        cursor: grabbing;
    }
`;

const TaskContainer = styled.div<TaskContainerProps>`
    flex-grow: 1;
    position: relative;
    align-items: center;
    display: flex;
    padding: ${props => (props.isAirEnabled ? '8px 4px' : '0')};
`;

const TaskLink = styled(Link)`
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline: none;

    &:focus {
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }

    &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: none;
    }
    padding-right: 8px;
`;

const TaskInfo = styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
`;

const TaskDetails = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

const TaskName = styled.span`
    color: #212529;
    font:
        400 14px/1 'Inter',
        sans-serif;
`;

const TaskTime = styled.span`
    color: #868e96;
    font:
        400 14px/1 'Inter',
        sans-serif;
`;

const TaskDatePicker = styled(CustomDatePicker)`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const DueDate = styled.span<{ color: string }>`
    font:
        400 14px/1 'Inter',
        sans-serif;
    color: ${({ color }) => color};
`;

const AddDeadlineButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default HomeTaskItem;
