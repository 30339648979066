import { enqueueSnackbar, SnackbarKey } from 'notistack';
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setHeadingEditing, setHeadingState } from '~/store/slices/taskDetails/taskHeadingSlice';

import { RootState } from '~/store/store';
import { CloseSnackbarAction } from '../shared/CloseSnackbarAction';
import { updateTaskDetails } from '~/store/slices/taskDetails/taskDetailsSlice';

interface EditableHeadingProps {
    placeholder: string;
}

export const EditableHeading: React.FC<EditableHeadingProps> = ({ placeholder }) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    const { name, isEditing } = useSelector((state: RootState) => state.taskHeading);
    const { taskId } = useSelector((state: RootState) => state.taskDetails);

    const handleTaskNameChange = (newName: string) => {
        dispatch(setHeadingState(newName));
    };

    const handleHeadingEditStart = () => {
        dispatch(setHeadingEditing(true));
    };

    const abortControllerRef = useRef<AbortController | null>(null);
    const handleUpdateTask = useCallback(
        async (updatedFields: string) => {
            if (!taskId) {
                return;
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();
            try {
                await dispatch(updateTaskDetails({ taskId: taskId, updatedFields: { name: updatedFields } }) as any);
            } catch (error: unknown) {
                if (error instanceof Error && error.name !== 'AbortError') {
                    enqueueSnackbar('Ошибка при обновлении таска.', {
                        variant: 'error',
                        action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                    });
                }
            }
        },
        [taskId, dispatch],
    );

    const handleHeadingEditEnd = async () => {
        dispatch(setHeadingEditing(false));
        try {
            await handleUpdateTask(name);
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении названия таска.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleTaskNameChange(e.target.value);
    };

    const handleInputBlur = () => {
        handleHeadingEditEnd();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleHeadingEditEnd();
        }
    };

    return (
        <HeadingWrapper onClick={handleHeadingEditStart}>
            {isEditing ? (
                <HeadingInput
                    ref={inputRef}
                    type="text"
                    value={name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                <Heading $isEmpty={!name.trim()}>{name.trim() || placeholder}</Heading>
            )}
        </HeadingWrapper>
    );
};

const HeadingWrapper = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
    cursor: text;
`;

const HeadingInput = styled.input`
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    border: none;
    background-color: transparent;
    padding: 0;

    &:focus {
        outline: none;
    }
`;

const Heading = styled.h1<{ $isEmpty: boolean }>`
    font-size: 24px;
    font-weight: 600;
    color: ${props => (props.$isEmpty ? '#ADB5BD' : '#000')};
    margin: 0;
    padding: 0;
`;
