import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import { useUpdateTaskMutation } from '~/store/api/tasksApi';

import { setInputVisible, toggleCollapse, updateTask, updateTaskStatus } from '~/store/slices/projectSlice';
import { AppDispatch, RootState } from '~/store/store';

import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { pluralizeRu } from '~/utils/stringUtils';

import { ProjectTaskGroup } from '~/types/project/ProjectTaskGroup';
import { TaskGroupingType } from '~/types/TaskTypes';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { SortType } from '~/types/SortType';

import { CreateTaskRow } from '../shared/CreateTaskRow';

import { TableHeader, ColumnHeader, AddTaskButton, TaskListContainer, TaskGroupContainer } from '../TaskList/Tables';
import TaskItem from './TaskItem';

import Collapse from '~/assets/icons/chevron-down.svg';
import Plus from '~/assets/icons/plus.svg';
import { formatTaskTime } from '~/utils/taskUtils';
import { getBackendDateString } from '~/utils/dateUtils';
import { TaskMoveModal } from '~/components/TaskMoveModal';
import { selectProjectFilters, setDueDateSort } from '~/store/slices/projectFiltersSlice.ts';

interface TaskGroupProps {
    data: ProjectTaskGroup;
    groupingType: TaskGroupingType | null;
    userList: ShortUserInfo[];
    onTaskDelete: (taskId: string) => void;
}

const TaskGroup: React.FC<TaskGroupProps> = ({ data, groupingType, userList, onTaskDelete }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { id: groupId, widget, tasks, props, timeInfo } = data;

    const isCollapsed = useSelector((state: RootState) => state.project.isCollapsed[groupId] || false);
    const isInputVisible = useSelector((state: RootState) => state.project.isInputVisible[groupId] || false);
    const projectInfo = useSelector((state: RootState) => state.project.projectInfo);
    const projectTasks = useSelector((state: RootState) => state.project.tasks);
    const { dueDateSort } = useSelector((state: RootState) => selectProjectFilters(state, projectInfo.id));

    const inputRef = useRef<HTMLInputElement>(null);

    const [isInputAtTop, setIsInputAtTop] = useState(false);
    const [moveToListModalOpened, setMoveToListModalOpened] = useState(false);

    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [selectedTaskName, setSelectedTaskName] = useState<string>('');

    const showInput = (atTop: boolean) => {
        setIsInputAtTop(atTop);
        dispatch(setInputVisible({ groupId, visible: true }));
    };

    const hideInput = () => {
        dispatch(setInputVisible({ groupId, visible: false }));
    };

    const toggleCollapseHandler = () => {
        dispatch(toggleCollapse({ groupId }));
    };

    const { navigateToTask } = useTaskNavigation();

    const handleTaskClick = (taskId: string) => {
        navigateToTask(taskId);
    };

    const [updateTaskApi] = useUpdateTaskMutation();

    const handleTaskRename = async (taskId: string, newName: string) => {
        const oldName = projectTasks.find(task => task.id === taskId)?.name;
        try {
            dispatch(updateTask({ taskId, updates: { name: newName } }));
            await updateTaskApi({ id: taskId, input: { name: newName } }).unwrap();
            enqueueSnackbar('Имя задачи успешно обновлено.', {
                variant: 'success',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        } catch (error) {
            dispatch(updateTask({ taskId, updates: { name: oldName } }));
            enqueueSnackbar('Ошибка при обновлении имени задачи. Пожалуйста, попробуйте еще раз.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleTaskDateRangeChange = async (taskId: string, startDate: Date | null, dueDate: Date | null) => {
        console.log(taskId, startDate, dueDate);
        dispatch(updateTask({ taskId, updates: { startDate: getBackendDateString(startDate), dueDate: getBackendDateString(dueDate) } }));
        try {
            await updateTaskApi({
                id: taskId,
                input: { start_date: getBackendDateString(startDate), due_date: getBackendDateString(dueDate) },
            }).unwrap();
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении даты задачи. Пожалуйста, попробуйте еще раз.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleStatusChange = useCallback(
        (value: TaskStatusNew[], taskId: string) => {
            const [status] = value;
            dispatch(updateTaskStatus({ taskId: taskId, status }) as any);
        },
        [dispatch],
    );

    const handleTaskDelete = (taskId: string) => {
        onTaskDelete(taskId);
    };

    const handleDueDateSortChange = () => {
        if (dueDateSort === null) {
            dispatch(setDueDateSort({ projectId: projectInfo.id, payload: SortType.ASC }));
        } else if (dueDateSort === SortType.ASC) {
            dispatch(setDueDateSort({ projectId: projectInfo.id, payload: SortType.DESC }));
        } else {
            dispatch(setDueDateSort({ projectId: projectInfo.id, payload: null }));
        }
    };

    const handleMoveToList = (taskId: string) => {
        const task = tasks.find(t => t.id === taskId);
        if (task) {
            setSelectedTaskId(taskId);
            setSelectedTaskName(task.name);
            setMoveToListModalOpened(true);
        }
    };

    const sortedTasks = useMemo(() => {
        return [...tasks].sort((a, b) => {
            if (dueDateSort === null) return 0;
            if (!a.dueDate) return 1;
            if (!b.dueDate) return -1;
            const comparison = new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
            return dueDateSort === SortType.ASC ? comparison : -comparison;
        });
    }, [tasks, dueDateSort]);

    useEffect(() => {
        setTimeout(() => inputRef.current?.focus(), 0);
    }, [isInputVisible]);
    return (
        <TaskGroupContainer>
            <GroupHeader>
                <CollapseButton onClick={toggleCollapseHandler}>
                    <img
                        src={Collapse}
                        alt="Collapse group"
                        style={{
                            transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
                        }}
                    />
                </CollapseButton>
                <GroupInfo>
                    {widget}
                    <TaskCount>{pluralizeRu(tasks.length, ['задача', 'задачи', 'задач'])}</TaskCount>
                    {timeInfo && <TotalTime>{formatTaskTime(Math.floor(timeInfo.timeSpent / 60), timeInfo.estimate)}</TotalTime>}
                </GroupInfo>
                <AddTaskButton onClick={() => showInput(true)}>
                    <img src={Plus} alt="Add task" />
                    <span>Добавить задачу</span>
                </AddTaskButton>
            </GroupHeader>
            {!isCollapsed && (
                <TaskListContainer>
                    <Separator />
                    <TableHeader>
                        <ColumnHeader style={{ flex: 1 }}>Название</ColumnHeader>
                        <ColumnHeader style={{ width: '96px' }}>Исполнитель</ColumnHeader>
                        <ColumnHeader
                            style={{ width: '96px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            onClick={handleDueDateSortChange}
                        >
                            Дедлайн
                            <SortIconContainer>
                                {dueDateSort === SortType.ASC ? (
                                    <IconArrowUp size={14} />
                                ) : dueDateSort === SortType.DESC ? (
                                    <IconArrowDown size={14} />
                                ) : null}
                            </SortIconContainer>
                        </ColumnHeader>
                        <OptionsHeader>{/* <AddTaskButtonShort isHome={false} /> */}</OptionsHeader>
                    </TableHeader>
                    <Separator />
                    <TaskList>
                        {isInputVisible && isInputAtTop && (
                            <CreateTaskRow
                                projectId={projectInfo.id}
                                userList={userList}
                                defaultStatus={
                                    groupingType === TaskGroupingType.ByStatus ? (props as TaskStatusNew) : projectInfo.allowedTaskStatuses[0]
                                }
                                onClose={hideInput}
                                onBlur={hideInput}
                            />
                        )}
                        {sortedTasks.map(task => (
                            <TaskItem
                                key={task.id}
                                parentInfo={task.parentId ? { id: task.parentId, name: '' } : null}
                                task={task}
                                onClick={() => handleTaskClick(task.id)}
                                onChildClick={child => handleTaskClick(child.id)}
                                onDelete={() => handleTaskDelete(task.id)}
                                onRename={newName => handleTaskRename(task.id, newName)}
                                userList={userList}
                                onDateRangeChange={(taskId, startDate, dueDate) => handleTaskDateRangeChange(taskId, startDate, dueDate)}
                                onStatusChange={handleStatusChange}
                                onMoveToList={() => handleMoveToList(task.id)}
                            />
                        ))}
                        {isInputVisible && !isInputAtTop && (
                            <CreateTaskRow
                                projectId={projectInfo.id}
                                userList={userList}
                                defaultStatus={
                                    groupingType === TaskGroupingType.ByStatus ? (props as TaskStatusNew) : projectInfo.allowedTaskStatuses[0]
                                }
                                onClose={hideInput}
                                onBlur={hideInput}
                            />
                        )}
                        {!isInputVisible && (
                            <AddTaskButton $asRowItem onClick={() => showInput(false)}>
                                <img src={Plus} alt="Add task" />
                                <span>Добавить задачу</span>
                            </AddTaskButton>
                        )}
                    </TaskList>
                </TaskListContainer>
            )}
            <TaskMoveModal
                taskId={selectedTaskId}
                taskName={selectedTaskName}
                isOpen={moveToListModalOpened}
                onClose={() => setMoveToListModalOpened(false)}
                onMoveSuccess={() => {
                    setMoveToListModalOpened(false);
                }}
            />
        </TaskGroupContainer>
    );
};

// #e9ecef

const TaskList = styled.div`
    display: flex;
    flex-direction: column;
    > :not(:last-child) {
        border-bottom: 1px solid #e9ecef;
    }
`;

const Separator = styled.div`
    height: 1px;
    background-color: #e9ecef;
    width: 100%;
`;

const GroupHeader = styled.header`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 4px;
`;

const CollapseButton = styled.button`
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    margin: auto 0;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
`;

const GroupInfo = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--Text-dimmed, #868e96);
    justify-content: flex-start;
    margin: auto 0;
    padding: 12px 12px 12px 0;
    font:
        400 14px/1 Inter,
        sans-serif;
`;

const TaskCount = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

const TotalTime = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

const OptionsHeader = styled.div`
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SortIconContainer = styled.span`
    display: inline-flex;
    width: 14px;
    height: 14px;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
`;

export default TaskGroup;
