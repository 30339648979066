import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../store/store.ts';

export const GuestRoute = () => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    if (isLoggedIn) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
};
