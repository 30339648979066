import React from 'react';
import { Avatar, Tooltip, AvatarProps } from '@mantine/core';

import { ShortUserInfo } from '~/types/user/ShortUserInfo.ts';
import { UserAvatar } from '~/components/UserAvatar.tsx';

import Remove from '~/assets/icons/clear.svg?react';
import styled from 'styled-components';

export const UserAvatarGroup: React.FC<{
    users: ShortUserInfo[];
    max?: number;
    size?: AvatarProps['size'];
    onUserRemoved?: (updatedUsers: ShortUserInfo[]) => void;
}> = ({ users, max = 3, size = 'sm', onUserRemoved = null }) => {
    return (
        <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
                {users.slice(0, max).map(user => (
                    <AvatarContainer key={user.id}>
                        <Tooltip label={user.name} withArrow position="top">
                            <div>
                                <UserAvatar src={user.avatarUrl} name={user.name} size={size} />
                            </div>
                        </Tooltip>
                        {onUserRemoved && (
                            <RemoveIcon
                                className="remove-icon"
                                onClick={e => {
                                    onUserRemoved(users.filter(u => u.id !== user.id));
                                    e.stopPropagation();
                                }}
                            />
                        )}
                    </AvatarContainer>
                ))}
                {users.length > max && (
                    <Tooltip
                        withArrow
                        label={
                            <div style={{ textAlign: 'center' }}>
                                {users.slice(max).reduce((acc, user, index, array) => {
                                    acc.push(
                                        <React.Fragment key={user.id}>
                                            {index > 0 && index % 2 === 0 && <br />}
                                            {user.name}
                                            {index < array.length - 1 && ', '}
                                        </React.Fragment>,
                                    );
                                    return acc;
                                }, [] as React.ReactNode[])}
                            </div>
                        }
                    >
                        <Avatar radius="xl" size={size}>
                            +{users.length - max}
                        </Avatar>
                    </Tooltip>
                )}
            </Avatar.Group>
        </Tooltip.Group>
    );
};

const AvatarContainer = styled.div`
    position: relative;

    &:hover {
        z-index: 1; // Увеличиваем zIndex при наведении
    }

    &:hover .remove-icon {
        display: flex; // Показываем иконку при наведении
    }
`;

// Создаем стилизованный компонент для иконки Remove
const RemoveIcon = styled(Remove)`
    position: absolute;
    top: -2px;
    right: -2px;
    background: rgba(128, 128, 128, 0.7);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    cursor: pointer;
    display: none; // Скрываем по умолчанию
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    color: white;
    padding: 1px;
`;
