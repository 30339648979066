import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommandsState {
    isVacationsEnabled: boolean;
}

const initialState: CommandsState = {
    isVacationsEnabled: false,
};

export const commandsSlice = createSlice({
    name: 'commands',
    initialState,
    reducers: {
        setVacationsEnabled: (state, action: PayloadAction<boolean>) => {
            state.isVacationsEnabled = action.payload;
        },
    },
});

export const { setVacationsEnabled } = commandsSlice.actions;

export default commandsSlice.reducer;
