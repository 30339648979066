import { gql } from '@apollo/client';

const SPACE_FIELDS = `
    id
    name
    is_private
`;

export const UPDATE_SPACE = gql`
  mutation UpdateSpace($id: String!, $input: UpdateSpaceInput!) {
    updateSpace(id:$id, input:$input) {
      ${SPACE_FIELDS}
    }
  }
`;

export const CREATE_SPACE = gql`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      ${SPACE_FIELDS}
    }
  }
`;

export const DELETE_SPACE = gql`
    mutation DeleteSpace($id: String!) {
        deleteSpace(id: $id)
    }
`;

export const GET_PROJECTS_WITH_FOLDERS = gql`
  query GetProjectsWithFolders {
    spaces(all: true) {
      data {
        ${SPACE_FIELDS}
        folders {
          id
          name
          projects {
            id
            name
          }
        }
        projects(withoutFolder: true) {
          id
          name
        }
      }
    }
  }
`;
