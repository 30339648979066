import React from 'react';
import styled from 'styled-components';
import { TaskFull } from '~/types/task/TaskFull';
import { Breadcrumbs } from '../shared/Breadcrumbs';

interface ModalHeaderProps {
    onClose: () => void;
    task: TaskFull | null;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ onClose, task }) => {
    const pathItems = React.useMemo(() => {
        if (!task) return [];
        const items = [
            { name: task.project.space.name, link: null },
            { name: task.project.name, link: `/projects/${task.project.id}` },
        ];

        if (task.parent) {
            items.push({ name: task.parent.name, link: `?taskId=${task.parent.id}` }); // TODO: Use navigateToTask instead
        }

        return items;
    }, [task]);

    return <StyledModalHeader>{task && <Breadcrumbs pathItems={pathItems} onDismiss={onClose} />}</StyledModalHeader>;
};

const StyledModalHeader = styled.header`
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
`;
