export enum TaskStatus {
    OPEN = 'Open',
    PAUSE = 'Pause',
    TODO = 'Todo',
    IN_PROGRESS = 'InProgress',
    MERGE_REQUEST = 'MergeRequest',
    QUALITY_CONTROL = 'QualityControl',
    DONE = 'Done',
    REVIEW = 'Review',
    CLOSED = 'Closed',
}

export interface TaskStatusNew {
    name: string;
    color: string;
    textColor: string;
}

export const TaskStatusView = {
    [TaskStatus.OPEN]: 'Open',
    [TaskStatus.PAUSE]: 'Pause',
    [TaskStatus.TODO]: 'To Do',
    [TaskStatus.IN_PROGRESS]: 'In Progress',
    [TaskStatus.MERGE_REQUEST]: 'Merge Request',
    [TaskStatus.QUALITY_CONTROL]: 'Quality Control',
    [TaskStatus.DONE]: 'Done',
    [TaskStatus.REVIEW]: 'Review',
    [TaskStatus.CLOSED]: 'Closed',
};

export const TaskStatusOrder = [
    TaskStatus.OPEN,
    TaskStatus.PAUSE,
    TaskStatus.TODO,
    TaskStatus.IN_PROGRESS,
    TaskStatus.MERGE_REQUEST,
    TaskStatus.QUALITY_CONTROL,
    TaskStatus.DONE,
    TaskStatus.REVIEW,
    TaskStatus.CLOSED,
];

export const TaskStatusGroupInfo = [
    {
        title: 'Не начато',
        statuses: [TaskStatus.OPEN, TaskStatus.PAUSE],
    },
    {
        title: 'В работе',
        statuses: [TaskStatus.TODO, TaskStatus.IN_PROGRESS, TaskStatus.MERGE_REQUEST, TaskStatus.QUALITY_CONTROL],
    },
    {
        title: 'Завершено',
        statuses: [TaskStatus.DONE, TaskStatus.REVIEW],
    },
    {
        title: 'Закрыто',
        statuses: [TaskStatus.CLOSED],
    },
] satisfies Array<{
    title: string;
    statuses: TaskStatus[];
}>;
