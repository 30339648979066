import { TaskStatus, TaskStatusView } from '~/types/task/TaskStatus.ts';
import { getUniqueColorAndContrastColor } from './colorUtils';

export const colorsTable = {
    Open: '#565859',
    OpenNumbered: '#565859',
    Pause: '#B6B9BD',
    ToDo: '#E78945',
    InProgress: '#3FB1B2',
    InProgressNumbered: '#3FB1B2',
    MergeRequest: '#DB6898',
    Checkup: '#6985FF',
    QcOnFeature: '#6985FF',
    QcOnStage: '#6985FF',
    QcOnProd: '#6985FF',
    DeployToTest: '#6985FF',
    DeployToProd: '#6985FF',
    Review: '#008844',
    ReadyNumbered: '#008844',
    Closed: '#008844',
    EstimateNumbered: '#565859',
    PendingEstimateNumbered: '#565859',
    OfferNumbered: '#565859',
    PendingOfferNumbered: '#565859',
    ContractNumbered: '#565859',
    PendingContractNumbered: '#565859',
    Handoff: '#565859',
    Fail: '#B6B9BD',
    Clarify: '#B6B9BD',
    Pending: '#B6B9BD',
    Approved: '#008844',
    Complete: '#008844',
    Executed: '#008844',
    QualityControl: '#6985FF',
    NewEmployee: '#565859',
    HrManager: '#565859',
    ProjectManager: '#565859',
    TechLead: '#565859',
};

export const colorPairs: [string, string][] = [
    ['#0000FF', '#FFFFFF'], // Синий контейнер, белый текст
    ['#4CAF50', '#FFFFFF'], // Ярко-зеленый контейнер, белый текст
    ['#9C27B0', '#FFFFFF'], // Фиолетовый контейнер, белый текст
    ['#FF9800', '#000000'], // Ярко-оранжевый контейнер, черный текст
    ['#00BCD4', '#000000'], // Голубой контейнер, черный текст
    ['#E91E63', '#FFFFFF'], // Ярко-розовый контейнер, белый текст
    ['#9E9E9E', '#000000'], // Светло-серый контейнер, черный текст
    ['#388E3C', '#FFFFFF'], // Темно-зеленый контейнер, белый текст
    ['#303F9F', '#FFFFFF'], // Темно-синий контейнер, белый текст
    ['#FBC02D', '#000000'], // Золотистый контейнер, черный текст
    ['#795548', '#FFFFFF'], // Коричневый контейнер, белый текст
    ['#C2185B', '#FFFFFF'], // Темно-розовый контейнер, белый текст
    ['#B3E5FC', '#000000'], // Светло-голубой контейнер, черный текст
    ['#512DA8', '#FFFFFF'], // Индиго контейнер, белый текст
    ['#FF5722', '#FFFFFF'], // Ярко-оранжевый контейнер, белый текст
    ['#00796B', '#FFFFFF'], // Темно-бирюзовый контейнер, белый текст
    ['#7B1FA2', '#FFFFFF'], // Темно-фиолетовый контейнер, белый текст
    ['#FF0000', '#FFFFFF'], // Красный контейнер, белый текст
    ['#FFEB3B', '#000000'], // Ярко-желтый контейнер, черный текст
    ['#FFCDD2', '#000000'], // Светло-коралловый контейнер, черный текст
];

// Определим тип для ключей colorsTable
type ColorTableKey = keyof typeof colorsTable;

export function getStatusColor(status: TaskStatus): string {
    return getBgStatusColor(status);
}

export function getBgStatusColor(status: string): string {
    const color = colorsTable[status as ColorTableKey] ?? '#B6B9BD';
    return color;
}

export const getStringStatusText = (status: string): string => {
    const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
    return TaskStatusView[status as TaskStatus] ?? formattedStatus;
};

// legacy call
export const getStatusTextColor = (status: string): string => {
    return getStatusStringTextColor(status);
};

export const getStatusStringTextColor = (status: string): string => {
    const bgColor = colorsTable[status as ColorTableKey] ?? '#B6B9BD';
    return getContrastColor(bgColor);
};

export const getNewStatusColor = (status: string): { backgroundColor: string; textColor: string } => {
    return getUniqueColorAndContrastColor(status);
};

// Новая вспомогательная функция для определения контрастного цвета
function getContrastColor(hexColor: string): string {
    // Преобразуем HEX в RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Вычисляем яркость цвета
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Возвращаем черный для светлых цветов и белый для темных
    return brightness > 128 ? '#000000' : '#FFFFFF';
}
