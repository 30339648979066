import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { tasksApi } from '../api/tasksApi';
import { assigneesApi } from '../api/assigneesApi';

import { ProjectInfo } from '~/types/project/ProjectInfo';
import { Task } from '~/types/task/Task';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { TaskType } from '~/types/task/TaskType';
import { TaskFull } from '~/types/task/TaskFull';
import { CreateTaskParams } from './taskCreationModalSlice';
import { TaskStatusNew } from '~/types/task/TaskStatus';

import { CreateTaskInput } from '~/graphql/tasks';

interface ProjectState {
    projectInfo: ProjectInfo;
    tasks: Task[];
    members: ShortUserInfo[];
    newTaskName: Record<string, string>;
    isCollapsed: Record<string, boolean>;
    isInputVisible: Record<string, boolean>;
    users: ShortUserInfo[];
    isCreatingTask: boolean;
}

const initialState: ProjectState = {
    projectInfo: {
        id: '',
        name: '',
        isPrivate: false,
        space: { id: '', name: '', isPrivate: false },
        folder: { id: '', name: '' },
        allowedTaskStatuses: [],
        members: [],
    },
    members: [],
    tasks: [],
    newTaskName: {},
    isCollapsed: {},
    isInputVisible: {},
    users: [],
    isCreatingTask: false,
};

export const deleteTaskAsync = createAsyncThunk(
    'project/deleteTaskAsync',
    async ({ taskId, projectId }: { taskId: string; projectId: string }, { dispatch, getState }) => {
        const state = getState() as RootState;
        const taskToDelete = state.project.tasks.find(task => task.id === taskId);

        if (!taskToDelete) {
            throw new Error('Задача не найдена');
        }

        // Рекурсивная функция для удаления задачи и всех её подзадач
        const deleteTaskAndChildren = async (task: Task) => {
            // Удаляем все дочерние задачи
            for (const childTask of task.children || []) {
                await deleteTaskAndChildren(childTask);
            }

            // Удаляем текущую задачу
            const result = await dispatch(tasksApi.endpoints.deleteTask.initiate({ id: task.id, projectId }));

            if ('error' in result) {
                throw new Error('Не удалось удалить задачу');
            }

            dispatch(deleteTask({ taskId: task.id }));
        };

        await deleteTaskAndChildren(taskToDelete);

        return taskId;
    },
);

// Добавляем новый Thunk
export const addTaskAndCreate = createAsyncThunk('project/addTaskAndCreate', async (task: CreateTaskParams, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (state.project.isCreatingTask) {
        return;
    }
    dispatch(setIsCreatingTask(true));
    const fakeId = Date.now(); // Используем временную метку для уникального fakeId

    const fakeTask: Task = {
        id: fakeId.toString(),
        name: task.name,
        status: task.status,
        type: TaskType.DEFAULT,
        startDate: null,
        dueDate: null,
        estimate: null,
        createdAt: null,
        timeSpent: 0,
        totalTime: 0,
        assignee: task.assignees,
        children: [],
        parentId: null,
        parent: null,
        allowedTaskStatuses: [],
    };

    dispatch(addTask({ task: fakeTask }));

    const input: CreateTaskInput = {
        name: task.name,
        project_id: task.project_id,
        description: task.description,
        status: task.status.name,
        due_date: task.due_date,
        start_date: task.start_date,
        estimate: task.estimate,
        parent_id: task.parent_id,
    };

    const result = await dispatch(tasksApi.endpoints.createTask.initiate(input));

    if ('error' in result) {
        dispatch(setIsCreatingTask(false));
        dispatch(deleteTask({ taskId: fakeId.toString() }));
        throw new Error('Failed to create task on server');
    } else {
        const assignTaskResult = await dispatch(
            assigneesApi.endpoints.setTaskAssignees.initiate({ task_id: result.data.id, user_ids: task.assignees.map(user => user.id) }),
        );
        if ('error' in assignTaskResult) {
            dispatch(replaceTask({ fakeId, realTask: result.data }));
            dispatch(setIsCreatingTask(false));
            throw new Error('Failed to assign task to users');
        }
    }
    dispatch(
        replaceTask({
            fakeId,
            realTask: {
                ...result.data,
                assignee: task.assignees,
            },
        }),
    );
    dispatch(setIsCreatingTask(false));
    return result.data;
});

// Add this new async thunk
export const updateTaskAssignees = createAsyncThunk(
    'project/updateTaskAssignees',
    async ({ taskId, userIds }: { taskId: string; userIds: string[] }, { dispatch }) => {
        const result = await dispatch(assigneesApi.endpoints.setTaskAssignees.initiate({ task_id: taskId, user_ids: userIds }));

        if ('error' in result) {
            throw new Error('Failed to update task assignees');
        }

        return { taskId, assignees: result.data.assignees };
    },
);

export const updateTaskStatus = createAsyncThunk(
    'project/updateTaskStatus',
    async ({ taskId, status }: { taskId: string; status: TaskStatusNew }, { dispatch }) => {
        const result = await dispatch(tasksApi.endpoints.updateTask.initiate({ id: taskId, input: { status: status.name } }));

        if ('error' in result) {
            throw new Error('Failed to update task status');
        }
    },
);

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjectData: (state, action: PayloadAction<{ projectInfo: ProjectInfo; tasks: Task[]; users: ShortUserInfo[] }>) => {
            state.tasks = action.payload.tasks;
            state.projectInfo = action.payload.projectInfo;
            state.users = action.payload.users;
            state.members = action.payload.projectInfo.members;
        },
        toggleCollapse: (state, action: PayloadAction<{ groupId: string }>) => {
            state.isCollapsed[action.payload.groupId] = !state.isCollapsed[action.payload.groupId];
        },
        setInputVisible: (state, action: PayloadAction<{ groupId: string; visible: boolean }>) => {
            state.isInputVisible[action.payload.groupId] = action.payload.visible;
        },
        addTask: (state, action: PayloadAction<{ task: Task }>) => {
            state.tasks.push(action.payload.task);
        },
        updateTask: (
            state,
            action: PayloadAction<{
                taskId: string;
                updates: Partial<Task>;
            }>,
        ) => {
            const task = state.tasks.find((t: Task) => t.id === action.payload.taskId);
            if (task) {
                Object.assign(task, action.payload.updates);
            }
        },
        deleteTask: (state, action: PayloadAction<{ taskId: string }>) => {
            const deleteTaskRecursively = (tasks: Task[], taskId: string): Task[] => {
                return tasks.filter(task => {
                    if (task.id === taskId) {
                        return false;
                    }
                    if (task.children && task.children.length > 0) {
                        task.children = deleteTaskRecursively(task.children, taskId);
                    }
                    return true;
                });
            };

            state.tasks = deleteTaskRecursively(state.tasks, action.payload.taskId);
        },
        replaceTask: (state, action: PayloadAction<{ fakeId: number; realTask: TaskFull }>) => {
            const index = state.tasks.findIndex(t => t.id === action.payload.fakeId.toString());
            if (index !== -1) {
                state.tasks[index] = action.payload.realTask;
            }
        },
        setIsCreatingTask: (state, action: PayloadAction<boolean>) => {
            state.isCreatingTask = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(updateTaskAssignees.fulfilled, (state, action) => {
            const task = state.tasks.find(t => t.id === action.payload.taskId);
            if (task) {
                task.assignee = action.payload.assignees;
            }
        });
    },
});

export const { setProjectData, toggleCollapse, setInputVisible, addTask, updateTask, deleteTask, replaceTask, setIsCreatingTask } =
    projectSlice.actions;

export default projectSlice.reducer;
