import React from 'react';
import styled from 'styled-components';

interface UserTaggedProps {
    id: string;
    name: string;
}

export const UserTagged: React.FC<UserTaggedProps> = ({ id, name }) => {
    const handleClick = () => {
        console.log(id);
    };

    return <StyledUserTagged onClick={handleClick}>@{name}</StyledUserTagged>;
};

const StyledUserTagged = styled.span`
    color: #0066cc;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    background: #e9ecef;
    border-radius: 4px;
    padding: 5px 8px;
    color: #495057;
`;
