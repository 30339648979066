import { ISODate } from '~/utils/opaque';

export enum OperatorSet {
    In = 'IN',
    NotIn = 'NOT_IN',
}

export enum OperatorComparison {
    Equal = 'EQ',
    NotEqual = 'NEQ',
    Greater = 'GT',
    Less = 'LT',
    GreaterOrEqual = 'GTE',
    LessOrEqual = 'LTE',
}

export interface TaskStatusFilter {
    operator: OperatorSet;
    values: string[];
}

export interface DateFilter {
    operator: OperatorComparison;
    date: ISODate;
}

export interface IntFilter {
    operator: OperatorComparison;
    value: number;
}

export interface TaskFiltersDto {
    // Search API
    name?: string;
    description?: string;
    // Filter API
    project_id?: string[];
    status?: TaskStatusFilter;
    due_date?: DateFilter;
    start_date?: DateFilter;
    estimate?: IntFilter;
    // User API
    author_id?: string[];
    assignee_id?: string[];
    cf_people_salesman?: string[];
    cf_people_manager?: string[];
}
