import React from 'react';
import styled from 'styled-components';

const ErrorHeading = styled.h2`
    color: #d32f2f;
    margin-bottom: 16px;
    margin-top: 0;
`;

const ErrorDetails = styled.pre`
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    max-height: 300px;
    overflow-y: auto;
`;

export const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
    return (
        <>
            <ErrorHeading>Все сломалось...</ErrorHeading>
            <button onClick={() => window.location.reload()}>Обновить страницу</button>
            <h5>Ошибка</h5>
            <ErrorDetails>{error.stack}</ErrorDetails>
        </>
    );
};
