export function getUniqueColorAndContrastColor(input: string): { backgroundColor: string; textColor: string } {
    // Compute a hash code from the input string
    const hash = hashString(input);

    // Map the hash to a value between 0 and 1
    const normalizedHash = (hash % 1000000) / 1000000;

    // Use the golden ratio conjugate to spread hues evenly
    const goldenRatioConjugate = 0.61803398875;

    // Compute the hue value
    const hue = (normalizedHash + goldenRatioConjugate) % 1;

    // Convert hue to degrees
    const hueDegrees = hue * 360;

    // Use fixed saturation and lightness values for vibrant colors
    const saturation = 65; // 0 to 100%
    const lightness = 55; // 0 to 100%

    // Generate the background color in HSL
    const backgroundColor = `hsl(${hueDegrees}, ${saturation}%, ${lightness}%)`;

    // Compute luminance to decide text color
    const luminance = getLuminanceFromHSL(hueDegrees, saturation, lightness);

    // Choose text color based on luminance
    const textColor = luminance > 0.5 ? '#000000' : '#FFFFFF';

    return { backgroundColor, textColor };
}

function hashString(str: string): number {
    // Simple hash function (djb2 algorithm)
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }
    return hash >>> 0; // Convert to unsigned 32-bit integer
}

function getLuminanceFromHSL(h: number, s: number, l: number): number {
    // Convert HSL to RGB
    const rgb = hslToRgb(h, s, l);

    // Convert RGB to luminance
    return getLuminance(rgb.r, rgb.g, rgb.b);
}

function hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
    h = h % 360;
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const hPrime = h / 60;
    const x = c * (1 - Math.abs((hPrime % 2) - 1));
    let r1 = 0,
        g1 = 0,
        b1 = 0;

    if (0 <= hPrime && hPrime < 1) {
        r1 = c;
        g1 = x;
        b1 = 0;
    } else if (1 <= hPrime && hPrime < 2) {
        r1 = x;
        g1 = c;
        b1 = 0;
    } else if (2 <= hPrime && hPrime < 3) {
        r1 = 0;
        g1 = c;
        b1 = x;
    } else if (3 <= hPrime && hPrime < 4) {
        r1 = 0;
        g1 = x;
        b1 = c;
    } else if (4 <= hPrime && hPrime < 5) {
        r1 = x;
        g1 = 0;
        b1 = c;
    } else if (5 <= hPrime && hPrime < 6) {
        r1 = c;
        g1 = 0;
        b1 = x;
    }

    const m = l - c / 2;

    return {
        r: Math.round((r1 + m) * 255),
        g: Math.round((g1 + m) * 255),
        b: Math.round((b1 + m) * 255),
    };
}

export function shouldApplySpecialGradient(text: string): boolean {
    return text.toLowerCase().includes('ios') || text.toLowerCase().includes('android');
}

export function getSpecialGradientStyle(text: string): React.CSSProperties {
    const lowerText = text.toLowerCase();
    if (lowerText.includes('ios')) {
        return {
            background: 'linear-gradient(to right, red, orange, #FFD700, green, blue, indigo, violet)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent',
        };
    } else if (lowerText.includes('android')) {
        return {
            background: 'linear-gradient(to right, #3DDC84, #0F9D58, #4285F4)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent',
        };
    }
    return {};
}

export function getGuildNameWithEmoji(name: string): string {
    const lowerName = name.toLowerCase();
    if (lowerName.includes('ios')) {
        return `${name} 🍎`;
    } else if (lowerName.includes('android')) {
        return `${name} 🤖`;
    }
    return name;
}

function getLuminance(r: number, g: number, b: number): number {
    // Convert RGB to sRGB and compute luminance
    const a = [r, g, b].map(v => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    // Calculate luminance
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}
