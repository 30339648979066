import { gql } from '@apollo/client';

const FOLDER_FIELDS = `
  id
  space_id
  name
`;

export const CREATE_FOLDER = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      ${FOLDER_FIELDS}
    }
  }
`;

export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($id: String!, $input: UpdateFolderInput!) {
    updateFolder(id: $id, input: $input) {
      ${FOLDER_FIELDS}
    }
  }
`;

export const DELETE_FOLDER = gql`
    mutation DeleteFolder($id: String!) {
        deleteFolder(id: $id)
    }
`;
