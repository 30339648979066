import React from 'react';
import styled from 'styled-components';

import AddIcon from '~/assets/icons/plus.svg?react';

interface NestedFilterButtonProps {
    onClick?: () => void;
}

const NestedFilterButton: React.FC<NestedFilterButtonProps> = ({ onClick }) => {
    return (
        <StyledButton onClick={onClick}>
            <AddIcon style={{ width: 16, height: 16 }} />
            <ButtonText>Вложенный фильтр</ButtonText>
        </StyledButton>
    );
};

const StyledButton = styled.button`
    border-radius: 4px;
    align-self: start;
    display: flex;
    min-height: 24px;
    align-items: center;
    gap: 4px;
    color: rgba(134, 142, 150, 1);
    text-align: center;
    justify-content: center;
    font:
        500 12px/1 Inter,
        sans-serif;
    background: none;
    border: none;
    cursor: pointer;
`;

const ButtonText = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

export default NestedFilterButton;
