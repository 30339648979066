import React from 'react';
import styled from 'styled-components';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <HeaderWrapper>
            <PageTitle>{title}</PageTitle>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
    background-color: #fff;
    padding: 28px 32px 16px;
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

const PageTitle = styled.h1`
    color: #000;
    font:
        600 24px/1 Inter,
        sans-serif;
`;

export default Header;
