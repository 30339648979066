import React from 'react';
import styled from 'styled-components';

const SkeletonTaskGroup: React.FC = () => {
    return (
        <SkeletonWrapper>
            <SkeletonHeader />
            <SkeletonTask />
            <SkeletonTask />
            <SkeletonTask />
        </SkeletonWrapper>
    );
};

const SkeletonWrapper = styled.div`
    margin-bottom: 20px;
`;

const SkeletonHeader = styled.div`
    height: 24px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
    border-radius: 4px;
`;

const SkeletonTask = styled.div`
    height: 60px;
    background-color: #f0f0f0;
    margin-bottom: 8px;
    border-radius: 4px;
`;

export default SkeletonTaskGroup;
