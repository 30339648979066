import { store } from '../../store/store';
import { setVacationsEnabled } from '../../store/slices/commandsSlice';

export const executeCommand = (command: string): boolean => {
    const trimmed = command.trim();
    const param = trimmed.replace('/enable ', '');
    switch (param) {
        case 'vacations':
            const currentState = store.getState().commands.isVacationsEnabled;
            store.dispatch(setVacationsEnabled(!currentState));
            console.log('Vacations toggled: ', !currentState);
            return true;
        default:
            console.log('Unknown command: ', command);
            return false;
    }
};
