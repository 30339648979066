import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { ProjectInfo } from '~/types/project/ProjectInfo';
import { RootState } from '../store';
import { tasksApi } from '../api/tasksApi';
import { format } from 'date-fns';
import { CreateTaskInput } from '~/graphql/tasks';
import { assigneesApi } from '../api/assigneesApi';
import { enqueueSnackbar } from 'notistack';

interface TaskCreationModalState {
    isOpen: boolean;
    selectedUsers: ShortUserInfo[];
    allUsers: ShortUserInfo[];
    name: string;
    description: string;
    startDate: string | null;
    dueDate: string | null;
    status: TaskStatusNew | null;
    selectedProject: ProjectInfo | null;
    allProjects: ProjectInfo[];
    isLoading: boolean;
    allowedStatuses: TaskStatusNew[];
}

const initialState: TaskCreationModalState = {
    isOpen: false,
    selectedUsers: [],
    allUsers: [],
    name: '',
    description: '',
    startDate: null,
    dueDate: null,
    status: null,
    selectedProject: null,
    allProjects: [],
    isLoading: false,
    allowedStatuses: [],
};

export interface CreateTaskParams {
    name: string;
    project_id: string;
    description: string | null;
    status: TaskStatusNew;
    due_date: string | null;
    start_date: string | null;
    estimate: string | null;
    parent_id: string | null;
    assignees: ShortUserInfo[];
}

export const createTask = createAsyncThunk('taskCreationModal/createTask', async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { name, description, dueDate, status, selectedUsers, selectedProject } = state.taskCreation;

    if (!selectedProject || !status) {
        throw new Error('Project and status are required');
    }

    const createTaskInput: CreateTaskInput = {
        name: name,
        project_id: selectedProject.id,
        description: description || null,
        status: status.name,
        due_date: dueDate || null,
        start_date: state.taskCreation.startDate || null,
        estimate: null,
        parent_id: null,
    };

    const result = await dispatch(
        tasksApi.endpoints.createTask.initiate({
            ...createTaskInput,
            status: status.name,
        }),
    );
    if (result && result.data) {
        const assignTaskResult = await dispatch(
            assigneesApi.endpoints.setTaskAssignees.initiate({ task_id: result.data.id, user_ids: selectedUsers.map(user => user.id) }),
        );
        if ('data' in assignTaskResult) {
            dispatch(closeCreationModal());
            return result.data;
        } else {
            enqueueSnackbar('Ошибка при назначении исполнителей.', { variant: 'error' });
            return result.data;
        }
    } else {
        throw new Error('Failed to create task');
    }
});

const taskCreationModalSlice = createSlice({
    name: 'taskCreationModal',
    initialState,
    reducers: {
        openCreationModal: (state, action: PayloadAction<{ dueDate: Date | null; selectedUsers: ShortUserInfo[] | null }>) => {
            console.log('openCreationModal', action.payload);
            state.isOpen = true;
            state.dueDate = action.payload.dueDate ? format(action.payload.dueDate, 'yyyy-MM-dd') : null;
            state.selectedUsers = action.payload.selectedUsers || [];
        },
        closeCreationModal: state => {
            state.isOpen = false;
            state.name = '';
            state.description = '';
            state.dueDate = null;
            state.status = null;
            state.selectedUsers = [];
            state.selectedProject = null;
        },
        setSelectedUsers: (state, action: PayloadAction<ShortUserInfo[]>) => {
            state.selectedUsers = action.payload;
        },
        setAllUsers: (state, action: PayloadAction<ShortUserInfo[]>) => {
            state.allUsers = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.description = action.payload;
        },
        setSelectedProject: (state, action: PayloadAction<ProjectInfo | null>) => {
            state.selectedProject = action.payload;
            state.allowedStatuses = action.payload?.allowedTaskStatuses || [];
            if (state.status && state.allowedStatuses.includes(state.status)) {
                state.status = state.status;
            } else {
                state.status = state.allowedStatuses[0] || null;
            }
        },
        setAllProjects: (state, action: PayloadAction<ProjectInfo[]>) => {
            state.allProjects = action.payload.map(project => ({
                ...project,
                id: project.id,
            }));
        },
        setDates: (state, action: PayloadAction<{ startDate: string | null; dueDate: string | null }>) => {
            state.startDate = action.payload.startDate;
            state.dueDate = action.payload.dueDate;
        },
        setStatus: (state, action: PayloadAction<TaskStatusNew>) => {
            state.status = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createTask.pending, state => {
                state.isLoading = true;
            })
            .addCase(createTask.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(createTask.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {
    openCreationModal,
    closeCreationModal,
    setSelectedUsers,
    setAllUsers,
    setName,
    setDescription,
    setSelectedProject,
    setAllProjects,
    setDates,
    setStatus,
} = taskCreationModalSlice.actions;

export default taskCreationModalSlice.reducer;
