import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeNodeData } from '@mantine/core';

interface SidebarState {
    menuPosition: {
        top: number;
        left: number;
    } | null;
    selectedItem: TreeNodeData | null;
    isRenameModalOpen: boolean;
}

const initialState: SidebarState = {
    menuPosition: null,
    selectedItem: null,
    isRenameModalOpen: false,
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        openMenu: (state, action: PayloadAction<{ top: number; left: number }>) => {
            state.menuPosition = {
                top: action.payload.top,
                left: action.payload.left,
            };
        },
        setSelectedItem: (state, action: PayloadAction<TreeNodeData | null>) => {
            state.selectedItem = action.payload;
        },
        closeMenu: state => {
            state.menuPosition = null;
            state.selectedItem = null;
        },
    },
});

export const { setSelectedItem } = sidebarSlice.actions;
export default sidebarSlice.reducer;
