import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState } from '~/store/store.ts';

import { makeMyWeekWorkLink } from '~/utils/metabase.ts';

import ClipboardIcon from '~/assets/icons/clipboard.svg?react';
import ClockIcon from '~/assets/icons/clock.svg?react';

interface NavigationProps {
    isCollapsed: boolean;
    currentPath: string;
}

const Navigation: React.FC<NavigationProps> = ({ isCollapsed, currentPath }) => {
    const userName = useSelector((state: RootState) => state.user.currentUser!.name);

    return (
        <Nav>
            <NavItem as={Link} to="/home" $active={currentPath === '/home'} $isCollapsed={isCollapsed}>
                <NavIcon>
                    <ClipboardIcon />
                </NavIcon>
                {!isCollapsed && <NavLabel>Мои задачи</NavLabel>}
            </NavItem>
            <NavItem as={Link} to={makeMyWeekWorkLink(userName)} target="_blank" $isCollapsed={isCollapsed}>
                <NavIcon>
                    <ClockIcon />
                </NavIcon>
                {!isCollapsed && <NavLabel>Моё время (Metabase)</NavLabel>}
            </NavItem>
        </Nav>
    );
};

const Nav = styled.nav`
    border-bottom: 1px solid #e9ecef;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px 0;
    font:
        400 14px/1 Inter,
        sans-serif;
`;

const NavItem = styled(Link)<{ $active?: boolean; $isCollapsed: boolean }>`
    border-radius: 8px;
    background-color: ${props => (props.$active ? '#EDEDEB' : 'transparent')};
    display: flex;
    align-items: center;
    gap: 8px;
    padding: ${props => (props.$isCollapsed ? '8px' : '8px 12px')};
    color: #212529;
    text-decoration: none;
    transition: background-color 0.2s ease;
    justify-content: ${props => (props.$isCollapsed ? 'center' : 'flex-start')};
    position: relative;

    &:hover {
        background-color: #EDEDEB;
    }

    &:active {
        background-color: #E8E8E4;
    }

    &[data-tooltip]:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        z-index: 1;
    }
`;

const NavIcon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NavLabel = styled.span`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
`;

export default Navigation;
