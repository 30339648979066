import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchModal from './SearchModal';
import { RootState } from '../../store/store';
import { closeSearchModal } from '../../store/slices/searchModalSlice';

export const SearchModalWrapper: React.FC = () => {
    const isOpen = useSelector((state: RootState) => state.searchModal.isOpen);
    const dispatch = useDispatch();

    return <SearchModal isOpen={isOpen} onClose={() => dispatch(closeSearchModal())} />;
};
