import { Iso } from 'monocle-ts';

import { flow } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

declare const ISODateTag: unique symbol;

export type ISODate = Opaque<string, typeof ISODateTag>;

export const ISODateIso = new Iso<Date, ISODate>(
    date => date.toISOString() as ISODate,
    date => new Date(date),
);

export const NullableISODateIso = new Iso<Nullable<Date>, Nullable<ISODate>>(
    flow(O.fromNullable, O.map(ISODateIso.to), O.toNullable),
    flow(O.fromNullable, O.map(ISODateIso.from), O.toNullable),
);
