import React from 'react';
import styled from 'styled-components';
import logoSvg from '../../assets/feip-copyright.svg';

interface DeveloperInfoProps {}

export const DeveloperInfo: React.FC<DeveloperInfoProps> = () => {
    return (
        <StyledDeveloperInfo>
            <DeveloperLogo loading="lazy" src={logoSvg} alt="Developer Logo" />
        </StyledDeveloperInfo>
    );
};

const StyledDeveloperInfo = styled.div`
    display: flex;
    margin-top: 280px;
    width: 138px;
    max-width: 100%;
    color: #000;
    font:
        10px PT Root UI,
        sans-serif;
`;

const DeveloperLogo = styled.img`
    aspect-ratio: 2.3;
    object-fit: contain;
    object-position: center;
    width: 138px;
`;
