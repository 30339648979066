import React, { useImperativeHandle, forwardRef } from 'react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor, BubbleMenu } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Placeholder from '@tiptap/extension-placeholder';
import styled from 'styled-components';
import { composeHotKeys, shiftEnter } from '~/utils/keyboard';

interface TextEditorProps {
    value: string;
    placeholder?: string;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    handleKeyDown?: ((event: React.KeyboardEvent<HTMLDivElement>) => void);
    autoFocus?: boolean;
}

export interface TextEditorRef {
    clearContent: () => void;
}

export const TextEditor = forwardRef<TextEditorRef, TextEditorProps>(({ value, onChange, onBlur, placeholder, handleKeyDown = () => {}, autoFocus }, ref) => {
    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                hardBreak: false,
            }),
            Underline,
            Link.configure({
                openOnClick: true,
                HTMLAttributes: {
                    style: 'cursor: pointer',
                },
            }),
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Placeholder.configure({ placeholder: placeholder }),
        ],
        content: value,
        onUpdate: ({ editor }) => {
            onChange?.(editor.getHTML());
        },
        onBlur: ({ editor }) => {
            onBlur?.(editor.getHTML());
        },
        autofocus: autoFocus,
    });

    useImperativeHandle(ref, () => ({
        clearContent: () => {
            editor?.commands.setContent('');
        },
    }));

    const onKeyDown = composeHotKeys(
        handleKeyDown,
        shiftEnter(() => editor?.commands.enter()),
    );

    return (
        <StyledRichTextEditor>
            <RichTextEditor
                editor={editor}
                onKeyDown={onKeyDown}
            >
                {editor && (
                    <BubbleMenu
                        editor={editor}
                        tippyOptions={{
                            popperOptions: {
                                strategy: 'fixed',
                            },
                        }}
                    >
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>
                    </BubbleMenu>
                )}
                <RichTextEditor.Content />
            </RichTextEditor>
        </StyledRichTextEditor>
    );
});

const StyledRichTextEditor = styled.div`
    height: 100%;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;

    .mantine-RichTextEditor-root {
        border: none;
        background-color: transparent;
    }

    .mantine-RichTextEditor-content {
        background-color: transparent;
    }

    .ProseMirror {
        outline: none;
    }
`;
