import React from 'react';
import styles from './TaskGroup.module.css';
import HomeTaskItem from './HomeTaskItem';
import CollapseIcon from '~/assets/icons/chevron-down.svg?react';
import { ActionIcon } from '@mantine/core';
import { Collapse } from '@mantine/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { HomeTask } from '../../types/task/Task';
import { AddTaskButtonShort } from '../AddTaskButtonShort';
import { useDispatch } from 'react-redux';
import { openCreationModal } from '../../store/slices/taskCreationModalSlice';
import { groupIndexToApiDate } from '../../store/slices/tasksSlice';

interface HomeTaskGroupProps {
    title: string;
    tasks: HomeTask[];
    groupIndex: number;
    collapsed: boolean;
    onToggleCollapse: () => void;
    overTaskId: string | null;
    isOverGroup: boolean;
}

const HomeTaskGroup: React.FC<HomeTaskGroupProps> = ({ title, tasks, collapsed, onToggleCollapse, overTaskId, isOverGroup, groupIndex }) => {
    const { setNodeRef } = useDroppable({
        id: title,
    });

    const count = tasks.length;
    const dispatch = useDispatch();

    const handleAddTask = () => {
        const apiDate = groupIndexToApiDate(groupIndex);
        dispatch(
            openCreationModal({
                dueDate: apiDate ? new Date(apiDate) : null,
                selectedUsers: null,
            }),
        );
    };

    return (
        <div className={styles.groupWrapper} ref={setNodeRef}>
            <div className={styles.groupHeader}>
                <div className={styles.groupInfo} onClick={onToggleCollapse}>
                    <ActionIcon 
                        variant="transparent" 
                        size="md"
                    >
                        <CollapseIcon className={`${styles.collapseIcon} ${collapsed ? styles.collapsed : ''}`} />
                    </ActionIcon>
                    <h2 className={styles.groupTitle}>{title}</h2>
                    <span className={styles.taskCount}>{count}</span>
                </div>
                <AddTaskButtonShort isHome={true} className={styles.addTaskButton} onClick={handleAddTask} />
            </div>
            <Collapse in={!collapsed}>
                <div className={styles.taskListWrapper}>
                    <SortableContext items={tasks.map(task => task.id)} strategy={verticalListSortingStrategy}>
                        <ul className={styles.taskList}>
                            {tasks.map((task, index) => (
                                <React.Fragment key={task.id}>
                                    {overTaskId === task.id && <li className={styles.dropIndicator} />}
                                    <HomeTaskItem task={task} />
                                    {index === tasks.length - 1 && isOverGroup && <li className={styles.dropIndicator} />}
                                </React.Fragment>
                            ))}
                            {tasks.length === 0 && isOverGroup && <li className={styles.dropIndicator} />}
                        </ul>
                    </SortableContext>
                </div>
            </Collapse>
        </div>
    );
};

export default HomeTaskGroup;
