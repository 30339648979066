import { gql } from '@apollo/client';

export const CREATE_COMMENT = gql`
    mutation CreateComment($input: CreateCommentInput!) {
        createComment(input: $input) {
            id
            text
            datetime
            author {
                id
                name
                avatar_url
            }
        }
    }
`;

export const UPDATE_COMMENT = gql`
    mutation UpdateComment($id: String!, $input: UpdateCommentInput) {
        updateComment(id: $id, input: $input) {
            id
            text
            datetime
            author {
                id
                name
                avatar_url
            }
        }
    }
`;

export const DELETE_COMMENT = gql`
    mutation DeleteComment($id: String!) {
        deleteComment(id: $id)
    }
`;
