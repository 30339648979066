import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

interface CloseSnackbarActionProps {
    snackbarKey: SnackbarKey;
}

export const CloseSnackbarAction: React.FC<CloseSnackbarActionProps> = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <ActionIcon
            onClick={() => closeSnackbar(snackbarKey)}
            size="sm"
            variant="transparent"
            style={{ color: 'white' }}
        >
            <IconX size={16} />
        </ActionIcon>
    );
};