import { Switch } from '@mantine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Home from '~/components/Home/Home';
import Header from '~/components/shared/Header';
import { setAirEnabled } from '~/store/slices/settingsSlice';
import { AppDispatch } from '~/store/store';
import { RootState } from '~/store/store';

const MainContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const HomePage: React.FC = () => {
    const isAirEnabled = useSelector((state: RootState) => state.settings.isAirEnabled);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <MainContent>
            <HomePageHeader>
                <HeaderLeft>
                    <Header title="Мои задачи"></Header>
                </HeaderLeft>
                <HeaderRight>
                    <Switch
                        label="«Воздух»"
                        style={{ padding: '28px 32px 16px' }}
                        checked={isAirEnabled}
                        onChange={() => dispatch(setAirEnabled(!isAirEnabled))}
                    />
                </HeaderRight>
            </HomePageHeader>
            <ScrollableContent>
                <Home />
            </ScrollableContent>
        </MainContent>
    );
};

export const HomePageHeader = styled.header`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
`;

export default HomePage;
