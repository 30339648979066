import { Combobox, useCombobox, Flex, Text, CheckIcon } from '@mantine/core';
import { ReactElement, useCallback } from 'react';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { TaskIconNew } from '~/components/TaskIcon';
import { getStringStatusText } from '~/utils/taskStatusUtils';
import styled from 'styled-components';

interface StatusComboboxProps {
    value: TaskStatusNew[];
    allowedStatuses: TaskStatusNew[];
    singleChoice?: boolean;
    withinPortal?: boolean;
    onChange: (value: TaskStatusNew[]) => void;
    children: (args: { toggleDropdown: () => void }) => ReactElement;
}

export const StatusCombobox = ({ value, allowedStatuses, onChange, children, withinPortal = true, singleChoice = true }: StatusComboboxProps) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: eventSource => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });

    const handleValueSelect = useCallback(
        (statusName: string) => {
            const selectedUser = allowedStatuses.find(status => status.name === statusName) || value.find(status => status.name === statusName);

            if (!selectedUser) return;

            if (singleChoice) {
                onChange([selectedUser]);
            } else {
                const newValue = value.some(status => status.name === statusName)
                    ? value.filter(status => status.name !== statusName)
                    : [...value, selectedUser];
                onChange(newValue);
            }

            combobox.closeDropdown();
        },
        [singleChoice, onChange, value, allowedStatuses, combobox],
    );

    return (
        <Combobox
            store={combobox}
            keepMounted={false}
            width="300px"
            size="xs"
            position="bottom-start"
            resetSelectionOnOptionHover
            withinPortal={withinPortal}
            onOptionSubmit={handleValueSelect}
        >
            <Combobox.Target>{children({ toggleDropdown: combobox.toggleDropdown })}</Combobox.Target>
            <StatusComboboxDropdown>
                {allowedStatuses.map(status => {
                    const isActive = value.some(s => s.name === status.name);
                    return (
                        <Combobox.Option key={status.name} value={status.name} active={isActive}>
                            <Flex align="center" gap="xs">
                                <TaskIconNew status={status} />
                                <Text size="xs" flex={1}>
                                    {getStringStatusText(status.name)}
                                </Text>
                                {isActive && <CheckIcon style={{ width: '12px', height: '12px', color: '#228BE6' }} />}
                            </Flex>
                        </Combobox.Option>
                    );
                })}
            </StatusComboboxDropdown>
        </Combobox>
    );
};

const StatusComboboxDropdown = styled(Combobox.Dropdown)`
    overflow-y: auto;
    max-height: 35vh;
`;
