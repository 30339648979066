import React from 'react';
import styled, { css } from 'styled-components';
import { TextInput } from '@mantine/core';

import { CustomFieldType, CustomFieldViewModel } from '~/types/task/CustomField';

import { EnhancedCustomFieldDropdown } from './CustomFieldDropdown';
import { getEnhancedCustomFieldLabel } from './utils';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { EnhancedPickUserCombobox } from '~/components/EnhancedPickUserCombobox';
import { UserAvatarGroup } from '~/components/UserAvatarGroup';

interface EnhancedCustomFieldControlProps {
    field: CustomFieldViewModel;
    onChange: (fieldName: string, value: string) => void;
    onBlur?: (fieldName: string, value: string) => void;
}

export const EnhancedCustomFieldControl: React.FC<EnhancedCustomFieldControlProps> = ({ field, onChange, onBlur }) => {
    switch (field.type) {
        case CustomFieldType.Dropdown:
            return (
                <EnhancedCustomFieldDropdown
                    field={field}
                    value={field.value as string}
                    onChange={value => {
                        onChange(field.name, value);
                        onBlur?.(field.name, value);
                    }}
                    placeholder={`Поиск в "${getEnhancedCustomFieldLabel(field)}"`}
                >
                    {({ toggleDropdown }) => <ValueContainer onClick={toggleDropdown}>{field.label}</ValueContainer>}
                </EnhancedCustomFieldDropdown>
            );
        case CustomFieldType.People:
            return (
                <EnhancedPickUserCombobox
                    data={field.options as ShortUserInfo[]}
                    value={field.value as ShortUserInfo[]}
                    onChange={users => {
                        const userIds = users.map(user => user.id.toString());
                        onChange(field.name, JSON.stringify(userIds));
                    }}
                    onDismiss={users => {
                        const userIds = users.map(user => user.id.toString());
                        onBlur?.(field.name, JSON.stringify(userIds));
                    }}
                >
                    {({ toggleDropdown }) => (
                        <FilterButton $active={(field.value as ShortUserInfo[]).length > 0} onClick={() => toggleDropdown()}>
                            {(field.value as ShortUserInfo[]).length > 0 ? (
                                <UserAvatarGroup
                                    users={field.value as ShortUserInfo[]}
                                    max={3}
                                    onUserRemoved={users => {
                                        const userIds = users.map(user => user.id.toString());
                                        const json = JSON.stringify(userIds);
                                        onChange(field.name, json);
                                        onBlur?.(field.name, json);
                                    }}
                                />
                            ) : (
                                'Не выбрано'
                            )}
                        </FilterButton>
                    )}
                </EnhancedPickUserCombobox>
            );
        case CustomFieldType.Money:
        case CustomFieldType.Number:
            return (
                <ValueInput
                    value={field.value as string}
                    onChange={e => onChange(field.name, e.target.value)}
                    onBlur={e => onBlur?.(field.name, e.target.value)}
                    variant="unstyled"
                    type="number"
                />
            );
        case CustomFieldType.Email:
        case CustomFieldType.ShortText:
        case CustomFieldType.Url:
        case CustomFieldType.Phone:
        default:
            return (
                <ValueInput
                    value={field.value as string}
                    onChange={e => onChange(field.name, e.target.value)}
                    onBlur={e => onBlur?.(field.name, e.target.value)}
                    variant="unstyled"
                />
            );
    }
};

const ValueContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    padding: 0px 12px;
    align-items: center;
    cursor: pointer;
`;

const ValueInput: typeof TextInput = styled(TextInput)`
    padding: 12px;

    input {
        --input-height: 16px;
        --input-font-size: 14px;
        --input-line-height: 1;

        height: var(--input-height);

        font-size: var(--input-font-size);
        line-height: var(--input-line-height);
    }
`;

interface FilterButtonProps {
    $active?: boolean;
}

const FilterButton = styled.div<FilterButtonProps>`
    display: flex;
    align-items: center;
    padding: 12px;
    height: 40px; // 16px (content) + 24px (padding)
    width: 100%;
    font-size: 14px;
    line-height: 1;
    background-color: transparent;
    color: #495057;
    cursor: pointer;
    white-space: nowrap;

    &:not(:disabled) {
        &:hover {
            color: rgba(76, 110, 245, 0.8);
        }

        ${({ $active }) =>
            $active &&
            css`
                color: rgba(76, 110, 245, 1);
            `}
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;
