import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_USER_FOR_PROJECT, GET_USERS } from '~/graphql/users.ts';
import { graphqlBaseQuery } from '../graphqlBaseQuery';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { UserDTO } from '~/types/UserTypes';

export const userListApi = createApi({
    reducerPath: 'userListApi',
    baseQuery: graphqlBaseQuery,
    endpoints: builder => ({
        getUsers: builder.query<ShortUserInfo[], void>({
            query: () => ({
                document: GET_USERS,
            }),
            transformResponse: (response: {
                users: {
                    data: UserDTO[];
                };
            }) => {
                return response.users.data.map((user: UserDTO) => ({
                    id: user.id,
                    name: user.name,
                    avatarUrl: user.avatar_url || null,
                    guild: user.guild,
                }));
            },
        }),
        getUsersForProject: builder.query<ShortUserInfo[], string>({
            query: projectId => ({
                document: GET_USER_FOR_PROJECT,
                variables: { projectId },
            }),
            transformResponse: (response: {
                projects: {
                    data: [
                        {
                            members: {
                                id: string;
                                name: string;
                                avatar_url: string | null;
                                guild: {
                                    id: string;
                                    name: string;
                                } | null;
                            }[];
                        },
                    ];
                };
            }) => {
                return response.projects.data[0].members.map(member => ({
                    id: member.id,
                    name: member.name,
                    avatarUrl: member.avatar_url,
                    guild: member.guild,
                }));
            },
        }),
    }),
});

export const { useGetUsersQuery, useGetUsersForProjectQuery } = userListApi;
