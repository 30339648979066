import React, { ReactElement, useState, useMemo, useCallback } from 'react';
import { useCombobox, Combobox, Text, Flex, ScrollArea, ComboboxProps } from '@mantine/core';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { UserAvatar } from './UserAvatar';

import SearchIcon from '~/assets/icons/search.svg?react';

import { RootState } from '../store/store.ts';
import { useSelector } from 'react-redux';

interface EnhancedPickUserComboboxProps {
    data: ShortUserInfo[];
    value: ShortUserInfo[];
    position?: ComboboxProps['position'];
    singleChoice?: boolean;
    maxHeight?: number;
    onChange: (value: ShortUserInfo[]) => void;
    onDismiss?: (value: ShortUserInfo[]) => void;
    withinPortal?: boolean;
    children: (args: { toggleDropdown: () => void }) => ReactElement;
}

/*
    Этот комбобокс учитывает ситуации, когда в value есть пользователи, которых нет в data.
Решает потенциальную проблему "юзер заасигнен на таск, но таск в приватном проекте из которого этого юзера удалили"
*/
export const EnhancedPickUserCombobox: React.FC<EnhancedPickUserComboboxProps> = ({
    data,
    value: externalValue,
    singleChoice = false,
    children,
    onChange,
    onDismiss = null,
    position = 'bottom-start',
    maxHeight = 250,
    withinPortal = true,
}) => {
    const [search, setSearch] = useState('');
    const currentUser = useSelector((state: RootState) => state.user.currentUser);

    const handleValueSelect = useCallback(
        (selectedId: string) => {
            const selectedUser =
                data.find(user => user.id.toString() === selectedId) || externalValue.find(user => user.id.toString() === selectedId);

            if (!selectedUser) return;

            if (singleChoice) {
                onChange([selectedUser]);
            } else {
                const newValue = externalValue.some(user => user.id.toString() === selectedId)
                    ? externalValue.filter(user => user.id.toString() !== selectedId)
                    : [...externalValue, selectedUser];
                onChange(newValue);
            }
        },
        [singleChoice, onChange, externalValue, data],
    );

    const combobox = useCombobox({
        onDropdownOpen: () => {
            combobox.focusSearchInput();
        },
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            setSearch('');
            onDismiss?.(externalValue);
        },
    });

    const { toggleDropdown } = combobox;

    const combinedData = useMemo(() => {
        const dataMap = new Map(data.map(user => [user.id.toString(), user]));
        externalValue.forEach(user => {
            if (!dataMap.has(user.id.toString())) {
                dataMap.set(user.id.toString(), user);
            }
        });
        return Array.from(dataMap.values());
    }, [data, externalValue]);

    const filteredData = useMemo(() => {
        return search ? combinedData.filter(item => item.name.toLowerCase().includes(search.toLowerCase().trim())) : combinedData;
    }, [combinedData, search]);

    const sortedData = useMemo(() => {
        return [...filteredData].sort((a, b) => {
            const aSelected = externalValue.some(user => user.id === a.id);
            const bSelected = externalValue.some(user => user.id === b.id);
            // Проверка на наличие currentUser перед использованием
            if (currentUser) {
                if (a.id === currentUser.id) return -1; // "Я" на первое место
                if (b.id === currentUser.id) return 1; // "Я" на первое место
            }
            if (aSelected && !bSelected) return -1;
            if (!aSelected && bSelected) return 1;
            return 0;
        });
    }, [filteredData, externalValue, currentUser]); // currentUser остается в зависимостях

    return (
        <Combobox
            width="240px"
            position={position}
            store={combobox}
            onOptionSubmit={handleValueSelect}
            keepMounted={false}
            withinPortal={withinPortal}
        >
            <Combobox.Target>{children({ toggleDropdown })}</Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Search
                    value={search}
                    onChange={event => setSearch(event.currentTarget.value)}
                    placeholder="Поиск пользователя"
                    leftSection={<SearchIcon style={{ width: '14px', height: '14px' }} />}
                />
                <Combobox.Options>
                    <ScrollArea.Autosize type="scroll" mah={maxHeight} mih={maxHeight}>
                        {sortedData.map(item => {
                            const isSelected = externalValue.some(user => user.id === item.id);
                            return (
                                <Combobox.Option key={item.id} value={item.id.toString()} active={isSelected}>
                                    <Flex align="center" gap="sm">
                                        <UserAvatar active={isSelected} src={item.avatarUrl} name={item.name} />
                                        <Text size="xs">{item.id === currentUser?.id ? 'Я' : item.name}</Text>
                                    </Flex>
                                </Combobox.Option>
                            );
                        })}
                        {sortedData.length === 0 && <Combobox.Empty>Ничего не найдено</Combobox.Empty>}
                    </ScrollArea.Autosize>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
