import React from 'react';
import styled, { keyframes } from 'styled-components';

const TaskGroupSkeleton: React.FC = () => {
    return (
        <SkeletonContainer>
            <SkeletonHeader>
                <SkeletonTitle />
                <SkeletonCount />
            </SkeletonHeader>
            <SkeletonTaskList>
                <SkeletonTask />
                <SkeletonTask />
                <SkeletonTask />
            </SkeletonTaskList>
        </SkeletonContainer>
    );
};

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonContainer = styled.div`
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 16px;
`;

const SkeletonHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const SkeletonTitle = styled.div`
    width: 150px;
    height: 24px;
    background: #f0f0f0;
    border-radius: 4px;
    animation: ${shimmer} 2s ease-in-out infinite;
    background-image: linear-gradient(90deg, #f0f0f0 0px, #f8f8f8 40px, #f0f0f0 80px);
    background-size: 600px 100%;
`;

const SkeletonCount = styled.div`
    width: 50px;
    height: 24px;
    background: #f0f0f0;
    border-radius: 4px;
    animation: ${shimmer} 2s ease-in-out infinite;
    background-image: linear-gradient(90deg, #f0f0f0 0px, #f8f8f8 40px, #f0f0f0 80px);
    background-size: 600px 100%;
`;

export const SkeletonTaskList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SkeletonTask = styled.div`
    height: 40px;
    background: #f0f0f0;
    border-radius: 4px;
    animation: ${shimmer} 2s ease-in-out infinite;
    background-image: linear-gradient(90deg, #f0f0f0 0px, #f8f8f8 40px, #f0f0f0 80px);
    background-size: 600px 100%;
`;

export default TaskGroupSkeleton;
