import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { useNavigate } from 'react-router-dom';

import { Tree, Group, Modal, Button, /*TextInput, /*ActionIcon,*/ useTree } from '@mantine/core';

import { useGetSpacesQuery } from '~/store/api/spacesApi';

import { useCreateSpaceMutation, useUpdateSpaceMutation, useDeleteSpaceMutation } from '~/store/api/spacesApi';
import { useCreateFolderMutation, useUpdateFolderMutation, useDeleteFolderMutation } from '~/store/api/foldersApi';
import { useCreateProjectMutation, useRenameProjectMutation, useDeleteProjectMutation } from '~/store/api/projectApi';

import FullSizeLoader from '~/components/Loader';

import { SidebarLeaf, RenderTreeNodePayload } from './SidebarLeaf';
import { TreeNodeData, ItemType } from './types';
import { foldSpaces, createTreeNodeManipulator } from './utils';

// import SearchIcon from '~/assets/icons/search.svg?react';
// import AddIcon from '~/assets/icons/plus.svg?react';
import { getErrorText } from '~/utils/ErrorHandler';
import { searchPredicate } from '~/utils/searchUtils.ts';

interface AppendTempNodePayload {
    type: ItemType.Folder | ItemType.Project;
    parent: TreeNodeData;
}

interface AppendTempSpaceNodePayload {
    type: ItemType.Space;
}

interface SidebarTreeProps {
    currentProjectId: string | null;
}

const SidebarTree: React.FC<SidebarTreeProps> = ({ currentProjectId }) => {
    const navigate = useNavigate();

    const { data: rawData, isLoading, error } = useGetSpacesQuery();

    const [createSpaceMutation] = useCreateSpaceMutation();
    const [createFolderMutation] = useCreateFolderMutation();
    const [createProjectMutation] = useCreateProjectMutation();

    const [updateSpaceMutation] = useUpdateSpaceMutation();
    const [updateFolderMutation] = useUpdateFolderMutation();
    const [renameProjectMutation] = useRenameProjectMutation();

    const [deleteSpaceMutation, { isLoading: isDeleteSpaceLoading }] = useDeleteSpaceMutation();
    const [deleteFolderMutation, { isLoading: isDeleteFolderLoading }] = useDeleteFolderMutation();
    const [deleteProjectMutation, { isLoading: isDeleteProjectLoading }] = useDeleteProjectMutation();

    const isDeleteLoading = isDeleteSpaceLoading || isDeleteFolderLoading || isDeleteProjectLoading;

    const [data, setData] = useState<TreeNodeData[]>([]);
    const dataRef = useRef(data);
    dataRef.current = data;

    const [searchTerm /*setSearchTerm*/] = useState<string>('');
    const [previousExpandedState, setPreviousExpandedState] = useState<Record<string, boolean>>({});
    const [isSearchActive, setIsSearchActive] = useState(false);

    const tree = useTree({ initialExpandedState: {} });

    const treeNodeManipulator = useMemo(() => createTreeNodeManipulator(data, setData), [data]);

    const [itemToDelete, setItemToDelete] = useState<TreeNodeData | null>(null);

    const [isInitialExpansionDone, setIsInitialExpansionDone] = useState(false);

    useEffect(() => {
        if (!rawData) return;
        setData(foldSpaces(rawData));
        setIsInitialExpansionDone(false); // Сбрасываем флаг при обновлении данных
    }, [rawData]);

    const expandPathToCurrentProject = useCallback(() => {
        if (!currentProjectId || !data.length) return;
        const path = treeNodeManipulator.findPath(node => {
            return node.nodeProps.id === currentProjectId && node.nodeProps.type === ItemType.Project;
        });
        if (path.length === 0) return;

        const newExpandedState = path.reduce((acc, node) => ({ ...acc, [node.value]: true }), {});
        tree.setExpandedState(prevState => ({ ...prevState, ...newExpandedState }));
    }, [currentProjectId, treeNodeManipulator, tree, data]);

    useEffect(() => {
        if (data.length > 0 && !isInitialExpansionDone) {
            expandPathToCurrentProject();
            setIsInitialExpansionDone(true);
        }
    }, [data, currentProjectId, expandPathToCurrentProject, isInitialExpansionDone]);

    useEffect(() => {
        if (searchTerm.length > 0 && !isSearchActive) {
            setPreviousExpandedState(tree.expandedState);
            tree.expandAllNodes();
            setIsSearchActive(true);
        } else if (searchTerm.length === 0 && isSearchActive) {
            tree.setExpandedState(previousExpandedState);
            setIsSearchActive(false);
        }
    }, [searchTerm, isSearchActive, tree, previousExpandedState]);

    const filteredData = useMemo(() => {
        if (searchTerm.length === 0) return data;

        const recursiveFilter = (nodes: TreeNodeData[]): TreeNodeData[] => {
            return nodes.reduce((acc: TreeNodeData[], node) => {
                if (searchPredicate(searchTerm, node.label)) {
                    acc.push(node);
                } else if (node.children) {
                    const filteredChildren = recursiveFilter(node.children);
                    if (filteredChildren.length > 0) {
                        acc.push({ ...node, children: filteredChildren });
                    }
                }
                return acc;
            }, []);
        };

        return recursiveFilter(data);
    }, [data, searchTerm]);

    const handleProjectClick = (node: TreeNodeData) => {
        if (node.nodeProps.type !== ItemType.Project) return;
        navigate(`/projects/${node.nodeProps.id}`);
    };

    const setIsEditingByNodeValue = (props: TreeNodeData, isEditing: boolean) => {
        treeNodeManipulator.updateNode(
            node => node.nodeProps.id === props.nodeProps.id && node.nodeProps.type === props.nodeProps.type,
            node => {
                node.nodeProps.state.isEditing = isEditing;
                return node;
            },
        );
    };

    const handleCreateItem = async (node: TreeNodeData, label: string) => {
        if (label.length === 0) {
            treeNodeManipulator.removeNode(node => node.nodeProps.state.isTemp);
            return;
        }

        treeNodeManipulator.updateNode(
            node => node.nodeProps.state.isTemp,
            node => {
                node.nodeProps.state.isLoading = true;
                return node;
            },
        );

        const bindId = async (id: string) => {
            treeNodeManipulator.updateNode(
                n => n.nodeProps.state.isTemp,
                n => ({
                    ...n,
                    label,
                    value: `${n.nodeProps.type}-${id}`,
                    nodeProps: {
                        ...n.nodeProps,
                        id,
                        state: {
                            ...n.nodeProps.state,
                            isTemp: false,
                            isLoading: false,
                            isEditing: false,
                        },
                    },
                }),
            );
        };

        try {
            if (node.nodeProps.type === ItemType.Space) {
                const response = await createSpaceMutation({ name: label }).unwrap();
                bindId(response.id);
            } else if (node.nodeProps.type === ItemType.Folder) {
                const response = await createFolderMutation({
                    space_id: node.nodeProps.parent.spaceId,
                    name: label,
                }).unwrap();
                bindId(response.id);
            } else if (node.nodeProps.type === ItemType.Project) {
                const response = await createProjectMutation(
                    node.nodeProps.parent.type === ItemType.Space
                        ? {
                            type: 'space',
                            name: label,
                            space_id: node.nodeProps.parent.spaceId,
                        }
                        : {
                            type: 'folder',
                            name: label,
                            folder_id: node.nodeProps.parent.folderId,
                        },
                ).unwrap();
                bindId(response.id);
            } else {
                throw new Error('Unknown item type');
            }

            const successMessage = label
                ? `${label} создан успешно`
                : 'Элемент создан успешно';

            enqueueSnackbar(successMessage, {
                variant: 'success',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        } catch (error) {
            treeNodeManipulator.removeNode(node => node.nodeProps.state.isTemp);
            const errorMessage = label
                ? `Ошибка при создании ${label}: ${getErrorText(error)}`
                : `Ошибка при создании элемента: ${getErrorText(error)}`;

            enqueueSnackbar(errorMessage, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleDelete = async () => {
        if (!itemToDelete) return;

        try {
            switch (itemToDelete.nodeProps.type) {
                case ItemType.Space:
                    await deleteSpaceMutation({ id: itemToDelete.nodeProps.id }).unwrap();
                    break;
                case ItemType.Folder:
                    await deleteFolderMutation({ id: itemToDelete.nodeProps.id }).unwrap();
                    break;
                case ItemType.Project:
                    await deleteProjectMutation({ id: itemToDelete.nodeProps.id }).unwrap();
                    break;
            }

            enqueueSnackbar('Удалено успешно', {
                variant: 'success',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
            setItemToDelete(null);
        } catch (error) {
            enqueueSnackbar(`Ошибка при удалении: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleRename = async (props: TreeNodeData, label: string) => {
        if (props.label === label) {
            setIsEditingByNodeValue(props, false);
            return;
        }

        const payload = {
            id: props.nodeProps.id,
            name: label.trim(),
        };

        treeNodeManipulator.updateNode(
            node => node.nodeProps.id === props.nodeProps.id && node.nodeProps.type === props.nodeProps.type,
            node => {
                node.nodeProps.state.isLoading = true;
                return node;
            },
        );

        try {
            switch (props.nodeProps.type) {
                case ItemType.Space:
                    await updateSpaceMutation(payload).unwrap();
                    break;
                case ItemType.Folder:
                    await updateFolderMutation(payload).unwrap();
                    break;
                case ItemType.Project:
                    await renameProjectMutation(payload).unwrap();
                    break;
            }

            enqueueSnackbar('Переименовано успешно', {
                variant: 'success',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        } catch (error) {
            enqueueSnackbar(`Ошибка при переименовании: ${getErrorText(error)}`, {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        } finally {
            treeNodeManipulator.updateNode(
                node => node.nodeProps.id === props.nodeProps.id && node.nodeProps.type === props.nodeProps.type,
                node => {
                    node.nodeProps.state.isEditing = false;
                    node.nodeProps.state.isLoading = false;
                    node.label = label;
                    return node;
                },
            );
        }
    };

    const appendTempNode = (payload: AppendTempNodePayload | AppendTempSpaceNodePayload) => {
        if (payload.type === ItemType.Space) {
            treeNodeManipulator.addRoot({
                label: '',
                value: `temp-${payload.type}`,
                nodeProps: {
                    id: '0',
                    type: ItemType.Space,
                    state: {
                        isTemp: true,
                        isEditing: true,
                        isLoading: false,
                    },
                },
            }); // Add to root
        } else if (payload.type === ItemType.Folder) {
            treeNodeManipulator.addChild(node => node.nodeProps.id === payload.parent.nodeProps.id, {
                label: '',
                value: `temp-${payload.type}`,
                nodeProps: {
                    id: '0',
                    type: ItemType.Folder,
                    state: {
                        isTemp: true,
                        isEditing: true,
                        isLoading: false,
                    },
                    parent: {
                        type: ItemType.Space,
                        spaceId: payload.parent.nodeProps.id,
                    },
                },
            });
        } else if (payload.type === ItemType.Project) {
            treeNodeManipulator.addChild(node => node.nodeProps.id === payload.parent.nodeProps.id, {
                label: '',
                value: `temp-${payload.type}`,
                nodeProps: {
                    id: '0',
                    type: ItemType.Project,
                    state: {
                        isTemp: true,
                        isEditing: true,
                        isLoading: false,
                    },
                    parent:
                        payload.parent.nodeProps.type === ItemType.Space
                            ? {
                                type: ItemType.Space,
                                spaceId: payload.parent.nodeProps.id,
                            }
                            : {
                                type: ItemType.Folder,
                                spaceId: payload.parent.nodeProps.parent.spaceId,
                                folderId: payload.parent.nodeProps.id,
                            },
                },
            });
        }
    };

    if (isLoading) {
        return <FullSizeLoader />;
    }

    if (error) {
        return <div>Error loading spaces: {error.message}</div>;
    }

    return (
        <ProjectListWrapper>
            <ProjectListHeader>
                {/* <SearchBar>
                    <TextInput
                        styles={{ root: { flex: 1 } }}
                        variant="unstyled"
                        placeholder="Поиск…"
                        leftSection={<SearchIcon />}
                        value={searchTerm}
                        onChange={e => {
                            setSearchTerm(e.target.value);
                            if (e.target.value.length === 0) {
                                tree.setExpandedState(previousExpandedState);
                            }
                        }}
                    />
                </SearchBar> */}
                {/* <ActionIcon variant="transparent" onClick={() => appendTempNode({ type: ItemType.Space })}>
                    <AddIcon />
                </ActionIcon> */}
            </ProjectListHeader>
            <Tree
                data={filteredData}
                tree={tree}
                styles={{
                    root: {},
                    node: {
                        paddingTop: '4px',
                    },
                    label: {
                        fontSize: '14px',
                        fontWeight: '400',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                }}
                renderNode={payload => (
                    <SidebarLeaf
                        {...(payload as unknown as RenderTreeNodePayload)}
                        onSetEditing={setIsEditingByNodeValue}
                        onUpdateLabel={handleRename}
                        onClick={handleProjectClick}
                        onDelete={setItemToDelete}
                        onAddItem={handleCreateItem}
                        onAddTempItem={(parent, type) => appendTempNode({ parent, type })}
                        currentProjectId={currentProjectId}
                    />
                )}
            />

            <Modal opened={itemToDelete !== null} onClose={() => setItemToDelete(null)} title="Подтверждение удаления">
                <p>Вы уверены, что хотите удалить "{itemToDelete?.label}"?</p>
                <Group>
                    <Button onClick={handleDelete} color="red" loading={isDeleteLoading} disabled={isDeleteLoading}>
                        Удалить
                    </Button>
                    <Button onClick={() => setItemToDelete(null)} variant="outline" disabled={isDeleteLoading}>
                        Отмена
                    </Button>
                </Group>
            </Modal>
        </ProjectListWrapper>
    );
};

const ProjectListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 0;
`;

const ProjectListHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

// const SearchBar = styled.div`
//     border-radius: 8px;
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     flex: 1;
// `;

export default SidebarTree;
