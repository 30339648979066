import { CustomField, CustomFieldViewModel } from '~/types/task/CustomField';

export const CustomFieldNameMap: Record<string, string> = {
    cf_dropdown_client_novelty: 'Client Novelty',
    cf_dropdown_fail_reason: 'Fail Reason',
    cf_dropdown_outcome: 'Outcome',
    cf_dropdown_project_novelty: 'Project Novelty',
    cf_dropdown_project_type: 'Project Type',
    cf_dropdown_source: 'Source',
    cf_dropdown_okr_type: 'OKR Type',
    cf_dropdown_department: 'Department',
    cf_dropdown_origin: 'Origin',
    cf_dropdown_scope: 'Scope',
    cf_dropdown_task_type: 'Task Type',

    cf_people_salesman: 'Salesman',
    cf_people_manager: 'Manager',

    cf_money_amount: 'Amount',
    cf_email_email: 'Email',
    cf_phone_phone: 'Phone',
    cf_short_text_position: 'Position',
    cf_short_text_telegram: 'Telegram',
    cf_short_text_full_name: 'Full Name',
    cf_url_test_task_description: 'Test Task Description',
    cf_url_calculation: 'Calculation',
    cf_url_cost: 'Cost',
    cf_url_feature_set: 'Feature Set',
    cf_number_initial_estimate: 'Initial Estimate',
    cf_number_release: 'Release',
};

export const getCustomFieldLabel = (field: CustomField) => {
    return CustomFieldNameMap[field.name] || field.name;
};

export const getEnhancedCustomFieldLabel = (field: CustomFieldViewModel) => {
    return CustomFieldNameMap[field.name] || field.name;
};
