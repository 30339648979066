import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AvatarSwitcher } from '../shared/AvatarSwitcher';
import EditIcon from '../../assets/icons/edit.svg?react';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import { UserTagged } from './UserTagged';
import { ConfirmModal } from './ConfirmModal';
import { formatDateTime } from '../../utils/stringUtils';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import DOMPurify from 'dompurify';

// Add this import
import './CommentCard.css';
import { TaskComment } from '~/types/task/TaskFull';

// Добавьте импорт TextEditor
import { TextEditor } from '../shared/TextEditor';
import { composeHotKeys, multiPlatformSpecialBackspace, multiPlatformSpecialEnter } from '~/utils/keyboard';

// Удалите импорт Spinner, так как мы создадим его здесь
// import Spinner from "../shared/Spinner";

// Добавьте этот новый компонент Spinner
const Spinner = styled.div`
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

interface CommentCardProps {
    authorId: string;
    comment: TaskComment;
    canEdit: boolean;
    canDelete: boolean;
    onEdit: (id: string, newContent: string) => void;
    onDelete: (id: string) => void;
}

export const CommentCard: React.FC<CommentCardProps> = ({ authorId, comment, canEdit, canDelete, onEdit, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (comment && comment.text) {
            setEditedContent(comment.text);
        }
    }, [comment]);

    if (!comment) {
        return null; // или можно вернуть заглушку, например <div>Комментарий не найден</div>
    }

    const handleSave = async () => {
        if (editedContent.trim() !== '') {
            setIsSaving(true);
            try {
                await onEdit(comment.id, editedContent);
                setIsEditing(false);
            } catch {
                enqueueSnackbar('Ошибка при сохранении комментария.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            } finally {
                setIsSaving(false);
            }
        } else {
            enqueueSnackbar('Комментарий не может быть пустым.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleCancel = () => {
        setEditedContent(comment.text || '');
        setIsEditing(false);
    };

    const handleDeleteClick = (commentId: string) => {
        setCommentToDelete(commentId);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (commentToDelete) {
            await onDelete(commentToDelete);
            setDeleteModalOpen(false);
            setCommentToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setDeleteModalOpen(false);
        setCommentToDelete(null);
    };

    const renderContent = () => {
        if (!comment.text) return null;

        const sanitizedContent = DOMPurify.sanitize(comment.text, {
            ADD_ATTR: ['target', 'rel'], // Разрешаем атрибуты target и rel
        });

        // Создаем временный div для парсинга HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = sanitizedContent;

        // Находим все ссылки и добавляем им нужные атрибуты
        const links = tempDiv.getElementsByTagName('a');
        for (let i = 0; i < links.length; i++) {
            links[i].setAttribute('target', '_blank');
            links[i].setAttribute('rel', 'noopener noreferrer');
        }

        const processedContent = tempDiv.innerHTML;
        const parts = processedContent.split(/(@[А-Яа-яA-Za-z]+ [А-Яа-яA-Za-z]+)/g);

        return parts.map((part: string, index: number) => {
            if (part.startsWith('@')) {
                return <UserTagged id={authorId} name={part.slice(1)} key={index} />;
            }
            return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
        });
    };

    const handleTextEditorKeyDown = composeHotKeys(multiPlatformSpecialEnter(handleSave), multiPlatformSpecialBackspace(handleCancel));

    return (
        <StyledCommentCard>
            <UserContainer>
                <AvatarSwitcher avatar={comment.author.avatarUrl} author={comment.author.name} />
                <AuthorInfo>
                    <AuthorName>{comment.author.name}</AuthorName>
                </AuthorInfo>
                <TimeContainer>
                    <CommentDate>{formatDateTime(comment.datetime)}</CommentDate>
                </TimeContainer>
            </UserContainer>
            <CommentContent>
                {isEditing ? (
                    <>
                        <TextEditorPlaceholder>
                            <TextEditor
                                value={editedContent}
                                onChange={setEditedContent}
                                placeholder="Комментарий"
                                handleKeyDown={handleTextEditorKeyDown}
                            />
                        </TextEditorPlaceholder>
                        <EditActions>
                            <Button onClick={handleCancel} disabled={isSaving}>
                                Отмена
                            </Button>
                            <Button primary onClick={handleSave} disabled={isSaving}>
                                {isSaving ? <Spinner /> : 'Сохранить'}
                            </Button>
                        </EditActions>
                    </>
                ) : (
                    <>
                        <CommentText>{renderContent()}</CommentText>
                        <ActionButtons>
                            {canEdit && (
                                <ActionButton onClick={() => setIsEditing(true)}>
                                    <EditIcon />
                                </ActionButton>
                            )}
                            {canDelete && (
                                <ActionButton onClick={() => handleDeleteClick(comment.id)}>
                                    <DeleteIcon />
                                </ActionButton>
                            )}
                        </ActionButtons>
                    </>
                )}
                {/* {comment.attachments && comment.attachments.length > 0 && (
          <AttachmentsList>
            {comment.attachments.map((attachment) => (
              <AttachmentItem key={attachment.id}>
                <img src={`/assets/icons/${attachment.type}.svg`} alt={attachment.name} />
                <span>{attachment.name}</span>
              </AttachmentItem>
            ))}
          </AttachmentsList>
        )} */}
            </CommentContent>
            <ConfirmModal
                isOpen={deleteModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                message="Вы действительно хотите удалить комментарий?"
            />
        </StyledCommentCard>
    );
};

// ... (стили)

const TextEditorPlaceholder = styled.div`
    background-color: #f8f9fa;
    padding: 8px;
    padding-top: 8px;
    border-radius: 8px;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ActionButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    margin-left: 8px;
`;

const EditActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;

const Button = styled.button<{ primary?: boolean }>`
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    background-color: ${props => (props.primary ? '#007bff' : '#6c757d')};
    color: white;

    &:hover {
        background-color: ${props => (props.primary ? '#0056b3' : '#5a6268')};
    }
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px; // Добавьте это, чтобы кнопка не меняла размер при появлении спиннера
    min-height: 32px; // Добавьте это, чтобы кнопка не меняла высоту при появлении спиннера

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

// const AttachmentsList = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
//   margin-top: 8px;
// `;

// const AttachmentItem = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #f1f3f5;
//   border-radius: 4px;
//   padding: 4px 8px;
//   font-size: 12px;

//   img {
//     width: 16px;
//     height: 16px;
//     margin-right: 8px;
//   }

//   span {
//     color: #212529;
//     font-weight: 500;
//   }
// `;

const TimeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto; // Add this line
`;

const UserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%; // Add this line
`;

const StyledCommentCard = styled.div`
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    padding: 16px;
    border: 1px solid #e9ecef;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    gap: 8px;
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const CommentContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const AuthorName = styled.span`
    font-weight: 500;
    font-size: 14px;
    color: #212529;
`;

const CommentDate = styled.span`
    color: #868e96;
    font-weight: 400;
    font-size: 14px;
`;

const CommentText = styled.div`
    padding-top: 12px;
    margin: 0;
    font-size: 14px;
    color: #495057;
    line-height: 1.8;

    p {
        margin: 0;
    }

    /* Добавьте стили для HTML-контента */
    a {
        color: #007bff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    ul,
    ol {
        padding-left: 20px;
    }

    /* Добавьте другие стили по необходимости */
`;
