import { gql } from '@apollo/client';

export const GET_GOOGLE_REDIRECT_URL = gql`
    query GetGoogleRedirectUrl {
        googleSSORedirect {
            redirect_url
        }
    }
`;

export const GOOGLE_SSO_AUTH = gql`
    mutation GoogleSSOAuth($code: String!) {
        googleSSOAuth(code: $code) {
            id
            name
            avatar_url
            guild {
                id
                name
            }
        }
    }
`;
