import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Center, Modal, Code } from '@mantine/core';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { fetchTaskDetails, setTaskId } from '../store/slices/taskDetails/taskDetailsSlice';

import Loader from '~/components/Loader';

import { ModalHeader } from '~/components/TaskScreen/ModalHeader';
import { Activity } from '~/components/TaskScreen/Activity';
import { Details } from '~/components/TaskScreen/Details';
import { TaskActions } from '../components/TaskActions';
import { TaskMoveModal } from '~/components/TaskMoveModal';
import { TaskDeleteModal } from '~/components/shared/TaskDeleteModal';

import Dots from '~/assets/icons/dots.svg?react';
import { useDeleteTaskMutation } from '~/store/api/tasksApi';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

const TaskPage: React.FC = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(search);
    const taskId = queryParams.get('taskId');

    const [moveToListModalOpened, setMoveToListModalOpened] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState<string | null>(null);

    const [deleteTask] = useDeleteTaskMutation();

    useEffect(() => {
        dispatch(setTaskId(taskId));
    }, [taskId]);

    const dispatch = useDispatch<AppDispatch>();
    const { currentTask, isLoading, error } = useSelector((state: RootState) => state.taskDetails);

    useEffect(() => {
        if (taskId) {
            dispatch(fetchTaskDetails(taskId));
        }
    }, [taskId, dispatch]);

    const handleClose = useCallback(() => {
        queryParams.delete('taskId');
        const newSearch = queryParams.toString();
        const newPath = newSearch ? `${pathname}?${newSearch}` : pathname;
        navigate(newPath);
    }, [navigate, pathname, queryParams]);

    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [handleClose]);

    const confirmDelete = async () => {
        if (currentTask) {
            try {
                await deleteTask({ id: currentTask.id, projectId: currentTask.project?.id ?? '' });
                enqueueSnackbar('Задача и все её подзадачи успешно удалены', {
                    variant: 'success',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
                handleClose();
            } catch (error) {
                console.error('Error deleting task:', error);
                enqueueSnackbar('Ошибка при удалении задачи', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
            setTaskToDelete(null);
        }
    };

    if (!taskId) return null;

    return (
        <Modal.Root
            opened={true}
            onClose={handleClose}
            fullScreen
            radius={24}
            styles={{
                inner: {
                    padding: '20px',
                    inset: '0px',
                },
                content: {
                    height: 'calc(100vh - 40px)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                },
                header: {
                    marginBottom: 0,
                },
                body: {
                    flex: 1,
                    padding: 0,
                    overflow: 'hidden',
                },
            }}
        >
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header>
                    <ModalHeader onClose={handleClose} task={currentTask} />
                    <ButtonsContainer>
                        <TaskActions
                            onDelete={() => {
                                setTaskToDelete(currentTask?.id ?? '');
                            }}
                            onMoveToList={() => {
                                setMoveToListModalOpened(true);
                            }}
                        >
                            <DotsButton>
                                <Dots color="gray" />
                            </DotsButton>
                        </TaskActions>

                        <Modal.CloseButton color="gray" />
                    </ButtonsContainer>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading && error && (
                        <Center>
                            Ошибка: <Code maw="80vw">{error}</Code>
                        </Center>
                    )}
                    {isLoading && <Loader />}
                    {!isLoading && !error && currentTask && (
                        <StyledTaskContent>
                            <DetailsWrapper>
                                <Details />
                            </DetailsWrapper>
                            <ActivityWrapper>
                                <Activity task={currentTask} />
                            </ActivityWrapper>
                        </StyledTaskContent>
                    )}
                </Modal.Body>
            </Modal.Content>
            <TaskMoveModal
                taskId={currentTask?.id ?? ''}
                taskName={currentTask?.name ?? ''}
                isOpen={moveToListModalOpened}
                onClose={() => setMoveToListModalOpened(false)}
                onMoveSuccess={() => {
                    if (taskId) {
                        dispatch(fetchTaskDetails(taskId));
                    }
                }}
            />
            <TaskDeleteModal isOpen={taskToDelete !== null} onClose={() => setTaskToDelete(null)} onDelete={confirmDelete} />
        </Modal.Root>
    );
};

const StyledTaskContent = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden; // Добавляем это свойство
`;

const DetailsWrapper = styled.div`
    flex: 3;
    overflow: hidden;
`;

const ActivityWrapper = styled.div`
    flex: 2; /* Takes up less space */
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden; /* Ensures content stays within bounds */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; /* Allows content to wrap if necessary */
    max-width: 100%; /* Ensures it cannot exceed its container */
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const DotsButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

export default TaskPage;
