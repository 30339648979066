import React, { useState } from 'react';

import { Combobox, ScrollArea, Group, CheckIcon, useCombobox } from '@mantine/core';

import { CustomFieldType, CustomFieldViewModel } from '~/types/task/CustomField';

interface EnhancedCustomFieldDropdownProps {
    field: CustomFieldViewModel;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    children: (args: { toggleDropdown: () => void }) => React.ReactNode;
}

export const EnhancedCustomFieldDropdown: React.FC<EnhancedCustomFieldDropdownProps> = ({
    field,
    onChange,
    children,
    value,
    placeholder = 'Поиск',
}) => {
    if (field.type !== CustomFieldType.Dropdown) {
        console.error('EnhancedCustomFieldDropdown received non-Dropdown field type');
        return null;
    }

    const options = field.options as Array<{ label: string; value: string }>;

    const [search, setSearch] = useState('');
    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            setSearch('');
        },
        onDropdownOpen: () => {
            combobox.focusSearchInput();
        },
    });

    const filteredOptions = options
        .filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
        .map(option => (
            <Combobox.Option key={option.value} value={option.value} active={option.value === value}>
                <Group gap="xs">
                    <span style={{ flex: 1 }}>{option.label}</span>
                    {option.value === value && <CheckIcon size={12} />}
                </Group>
            </Combobox.Option>
        ));

    return (
        <Combobox
            width={300}
            store={combobox}
            position="bottom-start"
            onOptionSubmit={value => {
                onChange(value);
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target>{children({ toggleDropdown: combobox.toggleDropdown })}</Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Search value={search} onChange={event => setSearch(event.currentTarget.value)} placeholder={placeholder} />
                <Combobox.Options>
                    <ScrollArea.Autosize type="scroll" mah={250}>
                        {filteredOptions.length > 0 ? filteredOptions : <Combobox.Empty>Nothing found</Combobox.Empty>}
                    </ScrollArea.Autosize>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
