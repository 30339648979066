import { CustomFieldType } from '~/types/task/CustomField';

import DropdownIcon from '~/assets/icons/dropdown.svg?react';
import TextareaIcon from '~/assets/icons/textarea.svg?react';
import HashIcon from '~/assets/icons/hash.svg?react';
import LinkIcon from '~/assets/icons/link.svg?react';
import PeopleIcon from '~/assets/icons/user.svg?react';

interface CustomFieldIconProps {
    type: CustomFieldType;
    size?: number;
    color?: string;
}

export const CustomFieldIcon: React.FC<CustomFieldIconProps> = ({ type, size = 16, color = 'currentColor' }) => {
    const iconStyle = {
        width: `${size}px`,
        height: `${size}px`,
        color: color,
    };

    switch (type) {
        case CustomFieldType.Dropdown:
            return <DropdownIcon style={iconStyle} />;
        case CustomFieldType.Phone:
        case CustomFieldType.Number:
        case CustomFieldType.Money:
            return <HashIcon style={iconStyle} />;
        case CustomFieldType.Url:
            return <LinkIcon style={iconStyle} />;
        case CustomFieldType.ShortText:
        case CustomFieldType.Email:
            return <TextareaIcon style={iconStyle} />;
        case CustomFieldType.People:
            return <PeopleIcon style={iconStyle} />;
    }
};
