import { gql } from '@apollo/client';
import { TaskFull } from '../types/task/TaskFull';
import { TaskStatusNew } from '~/types/task/TaskStatus';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';

const TASK_LIST_FIELDS = `
  id
  name
  status
  start_date
  due_date
  estimate
  total_time_recorded
  project {
    allowedTaskStatuses
  }
  children {
    id
  }
  total_time_recorded
  parent_id
`;

const CHILDREN_FIELDS = `
  id
  name
  status
  start_date
  due_date
  estimate
  assignees {
    id
    name
    avatar_url
  }
`;

const TASK_FIELDS = `
  id
  name
  created_at
  description
  start_date
  due_date
  status
  estimate
  assignees {
    id
    name
    avatar_url
  }
  comments {
    id
    text
    datetime
    author {
      id
      name
      avatar_url
    }
  }
  project {
    id
    name
    space {
      id
      name
      is_private
    }
    allowedTaskStatuses
    is_private
  }
  children {
    ${CHILDREN_FIELDS}
  }
  time_recorded_by_users {
    user {
      id
      avatar_url
      name
    }
    total_time_in_seconds
  }
  parent {
    id
    name
  }
  total_time_recorded
  weeek_id
  customFields {
    name
    type
    value
    options {
      label
      value
    }
  }
  author {
    id
    name
    avatar_url
  }
`;

export const GET_TASK_BY_ID = gql`
query GetTaskById($id: String!) {
  tasks(id: $id) {
    data {
      ${TASK_FIELDS}
    }
  }
}
`;

export const UPDATE_TASK_BY_ID = gql`
  mutation UpdateTaskById($id: String!, $task: UpdateTaskInput!) {
    updateTask(id: $id, input: $task) {
      ${TASK_FIELDS}
    }
  }
`;

export const GET_MY_TASKS = gql`
  query GetMyTasks($assignee_id: [String]) {
    tasks(
      all: true,
      filters: {
        assignee_id: $assignee_id,
        status: {
          operator: NOT_IN,
          values: [Closed]
        }
      },
      sort: [{ due_date: ASC }]
    ) {
      data {
        ${TASK_LIST_FIELDS}
      }
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask($task: CreateTaskInput!) {
    createTask(input: $task) {
      ${TASK_FIELDS}
    }
  }
`;

export const DELETE_TASK = gql`
    mutation DeleteTask($id: String!) {
        deleteTask(id: $id)
    }
`;

export const GET_TASK_TIME = gql`
    query GetTaskTime($task_id: String!) {
        userTaskTimeRecords(task_id: $task_id) {
            id
            user_id
            time_in_seconds
            date
        }
    }
`;

export interface CreateTaskInput {
    name: string;
    project_id: string;
    description: string | null;
    status: string;
    due_date: string | null;
    start_date: string | null;
    estimate: string | null;
    parent_id: string | null;
}

export const GET_TASKS_BY_NAME = gql`
  query GetTasksByName($name: String!) {
    tasks(filters: { name: $name }) {
      data {
        ${TASK_LIST_FIELDS}
      }
    }
  }
`;

export interface GetTaskByIdData {
    tasks: {
        data: TaskFull[];
    };
}

export interface GetTaskByIdVars {
    id: string;
}

export interface UpdateTaskFields {
    name?: string;
    description?: string;
    due_date?: string | null;
    start_date?: string | null;
    status?: TaskStatusNew;
    estimate?: number | null;
}

export interface UpdateDetailsState {
    status: TaskStatusNew;
    dates?: [string | null, string | null];
    assignees?: ShortUserInfo[];
    estimate?: number | null;
}

export interface UpdateTaskInput {
    name?: string;
    description?: string;
    due_date?: string | null;
    start_date?: string | null;
    status?: string | null;
    estimate?: number | null;
    project_id?: string | null;
}
