import React from 'react';
import { Container, Title, Text, Button, Group, Center } from '@mantine/core';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <Center h="100vh">
            <Container size="sm" ta="center">
                <Title order={1}>404 - Page Not Found</Title>
                <Text mt="md">The page you are looking for doesn't exist or has been moved.</Text>
                <Group mt="xl" justify="center">
                    <Button component={Link} to="/home">
                        Go to Home
                    </Button>
                </Group>
            </Container>
        </Center>
    );
};

export default NotFound;
