import React from 'react';
import styled from 'styled-components';

import { ActionIcon } from '@mantine/core';

import { match } from 'ts-pattern';

import {
    Condition,
    ConditionEmpty,
    ConditionComparisonCreatedAt,
    ConditionComparisonDeadline,
    ConditionSetAssignee,
    ConditionSetStatus,
    ConditionType,
    ConditionSetAuthor,
    ConditionSetSalesman,
    ConditionSetManager,
} from '~/types/project/ProjectFilters.ts';

import { ConditionValuePresenter } from './components/ConditionValuePresenter/ConditionValuePresenter.tsx';
import { ConditionLogicOperand } from './components/ConditionLogicOperand.tsx';
import { ConditionTypeSelect } from './components/ConditionTypeSelect.tsx';

import DeleteIcon from '~/assets/icons/delete.svg?react';
import { OperatorComparison, OperatorSet } from '~/store/dto/task-filters.dto.ts';

interface ConditionRowProps {
    isFirst?: boolean;
    data: Condition;
    selectedConditions: ConditionType[];
    onRemove: (id: string) => void;
    onChange: (condition: Condition) => void;
}

const ConditionRow: React.FC<ConditionRowProps> = props => {
    const { data, selectedConditions, onRemove, onChange, isFirst = false } = props;

    const onTypeChange = (type: ConditionType | null) => {
        const payload = match(type)
            .with(null, (): ConditionEmpty => ({ id: data.id, type: null }))
            .with(ConditionType.Status, (): ConditionSetStatus => ({ id: data.id, type: ConditionType.Status, operator: OperatorSet.In, value: [] }))
            .with(ConditionType.Author, (): ConditionSetAuthor => ({ id: data.id, type: ConditionType.Author, operator: null, value: [] }))
            .with(ConditionType.Assignee, (): ConditionSetAssignee => ({ id: data.id, type: ConditionType.Assignee, operator: null, value: [] }))
            .with(
                ConditionType.Deadline,
                (): ConditionComparisonDeadline => ({ id: data.id, type: ConditionType.Deadline, operator: OperatorComparison.Equal, value: null }),
            )
            .with(
                ConditionType.CreatedAt,
                (): ConditionComparisonCreatedAt => ({ id: data.id, type: ConditionType.CreatedAt, operator: OperatorComparison.Equal, value: null }),
            )
            .with(ConditionType.Salesman, (): ConditionSetSalesman => ({ id: data.id, type: ConditionType.Salesman, operator: null, value: [] }))
            .with(ConditionType.Manager, (): ConditionSetManager => ({ id: data.id, type: ConditionType.Manager, operator: null, value: [] }))
            .run();

        onChange(payload);
    };

    return (
        <RowContainer>
            <RowContent>
                <ConditionLogicOperand isFirst={isFirst} />
                <ConditionTypeSelect selected={selectedConditions} value={data.type} onChange={onTypeChange} />
                {data.type !== null && <ConditionValuePresenter condition={data} onChange={onChange} />}
            </RowContent>
            <ActionIcon variant="transparent" onClick={() => onRemove?.(data.id)}>
                <DeleteIcon style={{ width: 16, height: 16 }} />
            </ActionIcon>
        </RowContainer>
    );
};

const RowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
`;

const RowContent = styled.div`
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 8px;
    justify-content: start;
    flex-wrap: wrap;
    flex: 1;
    flex-basis: 0;
    margin: auto 0;
`;

export default ConditionRow;
