import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { AvatarSwitcher } from '../shared/AvatarSwitcher';
import AddTimeComponent, { EditMode } from './AddTimeComponent';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/store';
import { Popover, ScrollArea, Table, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useGetTaskTimeQuery } from '~/store/api/tasksApi';
import { TimeRecorded } from '~/types/task/Task';
import { selectUsersTimeSpent, selectMyTimeSpent } from '~/store/slices/taskDetails/taskDetailsSlice';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { UserAvatar } from '../UserAvatar';
import { formatDateString } from '~/utils/dateUtils';
import Loader from '../Loader';
import EditIcon from '../../assets/icons/edit.svg?react';

export const EverhourWidget: React.FC = () => {
    const [showAddTimeSection, setShowAddTimeSection] = useState(false);
    const [opened, { close, open }] = useDisclosure(false);
    const [editMode, setEditMode] = useState<EditMode | undefined>(undefined);

    const { currentTask } = useSelector((state: RootState) => state.taskDetails);
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    const usersTimeSpent = useSelector(selectUsersTimeSpent);
    const myTimeSpentTotal = useSelector(selectMyTimeSpent);

    const { data: taskTime, isLoading, error } = useGetTaskTimeQuery(currentTask?.id || '');

    const users = currentTask?.time_recorded_by_users.reduce(
        (acc, entry) => {
            acc[entry.user.id] = entry.user;
            return acc;
        },
        {} as Record<string, ShortUserInfo>,
    );

    const myTimeSpent = useMemo(() => {
        return taskTime?.filter((time: TimeRecorded) => time.userId === currentUser?.id) || [];
    }, [taskTime, currentUser]);

    if (isLoading)
        return (
            <LoaderContainer>
                <StyledLoader />
            </LoaderContainer>
        );
    if (error) return <ErrorContainer>Error loading task time</ErrorContainer>;
    if (!currentTask || !currentUser || !users) return null;

    const handleEditClick = (date: Date, timeRecordId: string, timeRecordedInSeconds: number) => {
        setEditMode({
            preselectedDate: date,
            timeRecordId: timeRecordId,
            timeRecordedInSeconds: timeRecordedInSeconds,
        });
        setShowAddTimeSection(true);
    };

    const renderTimeTable = (records: TimeRecorded[]) => {
        return (
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th colSpan={3} style={{ fontWeight: '400', fontSize: '12px', color: '#868e96' }}>
                            Мое время
                        </Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {records.map((timeRecord, index) => {
                        const user = users[timeRecord.userId];
                        return (
                            <Table.Tr key={`${timeRecord.userId}-${timeRecord.date}-${index}`}>
                                <Table.Td style={{ display: 'flex', alignItems: 'center' }}>
                                    <EditIconContainer
                                        onClick={() => handleEditClick(new Date(timeRecord.date), timeRecord.id, timeRecord.timeInSeconds)}
                                    >
                                        <EditIcon width={16} height={16} />
                                    </EditIconContainer>
                                    {timeRecord.formattedTime}
                                </Table.Td>
                                <Table.Td>{formatDateString(timeRecord.date)}</Table.Td>
                                <Table.Td>
                                    <UserAvatar name={user?.name || 'Unknown'} src={user?.avatarUrl || null} size={24} />
                                </Table.Td>
                            </Table.Tr>
                        );
                    })}
                </Table.Tbody>
            </Table>
        );
    };

    return (
        <StyledEverhourWidget>
            <Main>
                {myTimeSpentTotal && (
                    <PersonalTime>
                        <AvatarSwitcher avatar={myTimeSpentTotal.user?.avatarUrl || null} author={myTimeSpentTotal.user?.name || ''} size={32} />
                        <Content>
                            <UserName>{myTimeSpentTotal.user?.name}</UserName>
                            <Popover width={400} position="bottom" withArrow shadow="md">
                                <Popover.Target>
                                    <TimeSpent>{myTimeSpentTotal.formattedTime || '0h 0m'}</TimeSpent>
                                </Popover.Target>
                                <StyledPopoverDropdown>
                                    <ScrollArea h={300} offsetScrollbars type="auto">
                                        {renderTimeTable(myTimeSpent)}
                                    </ScrollArea>
                                </StyledPopoverDropdown>
                            </Popover>
                        </Content>
                    </PersonalTime>
                )}
                <WidgetControls>
                    <StartButton onClick={() => setShowAddTimeSection(true)}>Добавить время</StartButton>
                </WidgetControls>
            </Main>
            {usersTimeSpent && usersTimeSpent.length > 0 && (
                <>
                    <Details>
                        {usersTimeSpent.length <= 3 ? (
                            usersTimeSpent.map(userTime => (
                                <UserTime key={userTime.user.id}>
                                    <AvatarSwitcher avatar={userTime.user.avatarUrl || null} author={userTime.user.name} size={32} />
                                    <UserTimeInfo>
                                        <UserName>{userTime.user.name}</UserName>
                                        <TimeSpent>{userTime.formattedTime}</TimeSpent>
                                    </UserTimeInfo>
                                </UserTime>
                            ))
                        ) : (
                            <Popover width={350} position="bottom" withArrow shadow="md" opened={opened}>
                                <Popover.Target>
                                    <OverlappingAvatarRowWrapper onMouseEnter={open} onMouseLeave={close}>
                                        <OverlappingAvatarRow>
                                            {usersTimeSpent.slice(0, 5).map((userTime, index) => (
                                                <SmallAvatar key={userTime.user.id} index={index}>
                                                    <AvatarSwitcher avatar={userTime.user.avatarUrl} author={userTime.user.name} size={24} />
                                                </SmallAvatar>
                                            ))}
                                            {usersTimeSpent.length > 5 && <AdditionalUsersCount>+{usersTimeSpent.length - 5}</AdditionalUsersCount>}
                                        </OverlappingAvatarRow>
                                    </OverlappingAvatarRowWrapper>
                                </Popover.Target>
                                <Popover.Dropdown style={{ pointerEvents: 'none' }} bg="#212529">
                                    {usersTimeSpent.map(userTime => (
                                        <UserListItem key={userTime.user.id}>
                                            <Text size="xs" c="white">
                                                {userTime.user.name} - {userTime.formattedTime}
                                            </Text>
                                        </UserListItem>
                                    ))}
                                </Popover.Dropdown>
                            </Popover>
                        )}
                    </Details>
                    <Divider />
                </>
            )}
            {showAddTimeSection && (
                <StyledAddTimeSection
                    onClose={() => {
                        setShowAddTimeSection(false);
                        setEditMode(undefined);
                    }}
                    taskId={currentTask.id}
                    editMode={editMode}
                />
            )}
        </StyledEverhourWidget>
    );
};

const StyledPopoverDropdown = styled(Popover.Dropdown)`
    padding: 16px;
    overflow: hidden;
`;

const StyledEverhourWidget = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Main = styled.div`
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    gap: 24px 32px;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 32px;
`;

const PersonalTime = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    justify-content: flex-start;
    margin: auto 0;
`;

const Content = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto 0;
    gap: 4px;
`;

const UserName = styled.div`
    color: #212529;
    font-weight: 500; // Make username bold
`;

const TimeSpent = styled.div`
    color: #868e96;
    font-size: 0.9em;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const WidgetControls = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    justify-content: flex-start;
    margin: auto 0;
`;

const StartButton = styled.button`
    align-self: stretch;
    border-radius: 4px;
    background-color: #12b886;
    color: #fff;
    margin: auto 0;
    padding: 5px 16px;
    border: 1px solid #80c95b;
    cursor: pointer;
`;

const Details = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 12px 32px;

    @media (max-width: 991px) {
        max-width: 100%;
        padding: 12px 20px;
    }
`;

const UserTime = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
`;

const StyledAddTimeSection = styled(AddTimeComponent)`
    padding: 20px;
    width: 500px;
`;

const SmallAvatar = styled.div<{ index: number }>`
    margin-right: -10px;
    border-radius: 50%;
    border: 2px solid #f0f0ee;
    background-color: #fff;
    z-index: ${props => props.index};

    &:last-child {
        margin-right: 0;
    }

    .avatar-switcher {
        width: 24px;
        height: 24px;
    }
`;

const OverlappingAvatarRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const AdditionalUsersCount = styled.div`
    margin-left: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #868e96;
`;

const OverlappingAvatarRowWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const UserListItem = styled.div`
    padding: 4px 0;
    display: flex;
    align-items: center;

    &::before {
        content: '•';
        margin-right: 6px;
        color: #868e96;
    }
`;

const UserTimeInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Divider = styled.div`
    height: 1px;
    background-color: #e9ecef;
    width: 100%;
`;

const EditIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
`;

const LoaderContainer = styled.div`
    height: 56px; // Примерная высота основного контента виджета
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 12px 32px;
`;

const StyledLoader = styled(Loader)`
    width: 24px;
    height: 24px;
`;

const ErrorContainer = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 12px 32px;
    color: #dc3545;
    font-size: 14px;
`;

export default EverhourWidget;
