import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface AutosizeInputProps {
    value: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const AutosizeInput = forwardRef<HTMLInputElement, AutosizeInputProps>((props, ref) => {
    return (
        <Parent>
            <Wrapper>
                <SizeWrapper>{props.value}</SizeWrapper>
                <Input ref={ref} {...props} />
            </Wrapper>
        </Parent>
    );
});

AutosizeInput.displayName = 'AutosizeInput';

const Parent = styled.div`
    /* TODO: provide width */
    max-width: 600px;
    display: inline-flex;
    align-items: center;
`;

const Wrapper = styled.div`
    position: relative;

    line-height: 1;
    font-size: 14px;
    padding: 4px 0;
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
`;

const SizeWrapper = styled.span`
    position: relative;
    opacity: 0;
    min-width: 2rem;

    white-space: pre;

    line-height: inherit;
    font-family: inherit;
    font-size: inherit;

    user-select: none;
`;

const Input = styled.input`
    text-overflow: ellipsis;
    font-family: inherit;
    background: none;
    color: inherit;
    top: 0;
    left: 0;
    font-size: inherit;
    line-height: inherit;
    padding: inherit;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    border: none;

    &:focus {
        outline: none;
    }
`;
