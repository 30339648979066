import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';

import { useLazyGetGoogleRedirectUrlQuery } from '~/store/api/authApi';

import { LoginScreen } from '~/components/LoginScreen/LoginScreen';

export const Login: React.FC = () => {
    const navigate = useNavigate();

    const [trigger, { data: redirectUrl, isLoading, isError }] = useLazyGetGoogleRedirectUrlQuery();

    useEffect(() => {
        if (redirectUrl) window.location.href = redirectUrl;
    }, [redirectUrl, navigate]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Ошибка при SSO авторизации.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    }, [isError, enqueueSnackbar]);

    return <LoginScreen onLogin={() => trigger()} isLoading={isLoading} />;
};
