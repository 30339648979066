import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@mantine/core';
import { ShortUserInfo } from '~/types/user/ShortUserInfo';
import { getSpecialGradientStyle, getGuildNameWithEmoji, shouldApplySpecialGradient } from '~/utils/colorUtils';

import SidebarCollapseIcon from '~/assets/icons/sidebar-collapse.svg?react';

interface UserProfileProps {
    isCollapsed: boolean;
    onToggle: () => void;
    user: ShortUserInfo;
}

const UserProfile: React.FC<UserProfileProps> = ({ isCollapsed, onToggle, user }) => {
    const guildName = user.guild?.name || 'Не указана';
    const guildNameStyle = getSpecialGradientStyle(guildName);
    const guildNameWithEmoji = getGuildNameWithEmoji(guildName);
    const hasSpecialGradient = shouldApplySpecialGradient(guildName);

    return (
        <ProfileWrapper $isCollapsed={isCollapsed}>
            {!isCollapsed ? (
                <>
                    <UserInfo>
                        <Avatar color="initials" variant="filled" name={user.name} size={32} src={user.avatarUrl} />
                        <UserDetails>
                            <UserName>{user.name}</UserName>
                            <UserRole>
                                {hasSpecialGradient ? (
                                    <>
                                        <GradientSpan style={guildNameStyle}>{guildName}</GradientSpan>
                                        <EmojiSpan>{guildNameWithEmoji.slice(guildName.length)}</EmojiSpan>
                                    </>
                                ) : (
                                    guildName
                                )}
                            </UserRole>
                        </UserDetails>
                    </UserInfo>
                    <CollapseButton onClick={onToggle} aria-label="Свернуть боковую панель">
                        <CollapseIconWrapper>
                            <SidebarCollapseIcon />
                        </CollapseIconWrapper>
                    </CollapseButton>
                </>
            ) : (
                <CollapseButton onClick={onToggle} aria-label="Развернуть боковую панель" $isCollapsed>
                    <CollapseIconWrapper $isCollapsed>
                        <SidebarCollapseIcon />
                    </CollapseIconWrapper>
                </CollapseButton>
            )}
        </ProfileWrapper>
    );
};

const ProfileWrapper = styled.div<{ $isCollapsed: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: ${props => (props.$isCollapsed ? 'center' : 'space-between')};
    gap: 16px;
    padding: 24px 8px 8px ${props => (props.$isCollapsed ? '2px' : '8px')};
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    font:
        14px/1 Inter,
        sans-serif;
    position: relative;

    &:hover::after {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        z-index: 1;
    }
`;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserName = styled.span`
    color: #212529;
    font-weight: 500;
`;

const UserRole = styled.span`
    color: #868e96;
    margin-top: 4px;
    display: flex;
    align-items: center;
`;

const GradientSpan = styled.span`
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

const EmojiSpan = styled.span`
    margin-left: 4px;
`;

const CollapseButton = styled.button<{ $isCollapsed?: boolean }>`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: ${props => (props.$isCollapsed ? '-2px' : '0')};
`;

const CollapseIconWrapper = styled.div<{ $isCollapsed?: boolean }>`
    width: 24px;
    height: 24px;
    transform: ${props => (props.$isCollapsed ? 'rotate(180deg)' : 'none')};
    transition: transform 0.3s ease;
`;

export default UserProfile;
