import React from 'react';
import styled from 'styled-components';

interface FilterButtonProps {
    iconSrc: string;
    onClick: () => void | null;
}

export const FilterButton: React.FC<FilterButtonProps> = ({ iconSrc, onClick }) => {
    return (
        <StyledButton type="button" aria-label="Filter" onClick={onClick}>
            <Icon src={iconSrc} alt="" />
        </StyledButton>
    );
};

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-height: 36px;
    padding: 0 8px;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
`;
