import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '~/store/graphqlBaseQuery';
import { CREATE_FOLDER, UPDATE_FOLDER, DELETE_FOLDER } from '~/graphql/folders';
import { SpaceFolder } from '~/store/api/spacesApi';

import { spacesApi } from './spacesApi';

export const foldersApi = createApi({
    baseQuery: graphqlBaseQuery,
    reducerPath: 'foldersApi',
    tagTypes: ['Folders'],
    endpoints: builder => ({
        createFolder: builder.mutation<SpaceFolder, { space_id: string; name: string }>({
            query: ({ space_id, name }) => ({
                document: CREATE_FOLDER,
                variables: { input: { space_id, name, is_archived: false } },
            }),
            transformResponse: (response: { createFolder: SpaceFolder }) => response.createFolder,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags(['Spaces']));
            },
        }),
        updateFolder: builder.mutation<SpaceFolder, { id: string; name: string }>({
            query: ({ id, name }) => ({
                document: UPDATE_FOLDER,
                variables: { id, input: { name } },
            }),
            transformResponse: (response: { updateFolder: SpaceFolder }) => response.updateFolder,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags(['Spaces']));
            },
        }),
        deleteFolder: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                document: DELETE_FOLDER,
                variables: { id },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(spacesApi.util.invalidateTags(['Spaces']));
            },
        }),
    }),
});

export const { useCreateFolderMutation, useUpdateFolderMutation, useDeleteFolderMutation } = foldersApi;
