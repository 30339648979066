import React from 'react';
import styled from 'styled-components';

import { Select } from '@mantine/core';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store/store';
import { removeSortAndGroup, selectProjectFilters, setGroupBy, setSortOrder } from '~/store/slices/projectFiltersSlice.ts';

import { TaskGroupingType, TaskGroupingTypeView } from '~/types/TaskTypes';
import { SortDirection, SortDirectionView } from '~/types/SortDirection';

import { FilterButton } from './FilterButton';

import DeleteIcon from '~/assets/icons/delete.svg';

const AvailableGroupBy = [TaskGroupingType.ByStatus, TaskGroupingType.ByAssignee];

export const GroupByForm: React.FC = () => {
    const dispatch = useDispatch();

    const projectId = useSelector((state: RootState) => state.project.projectInfo.id);
    const { groupBy, sortOrder } = useSelector((state: RootState) => selectProjectFilters(state, projectId));

    const groupByOptions = Object.entries(TaskGroupingTypeView).map(([value, label]) => ({
        value,
        label,
        disabled: !AvailableGroupBy.includes(value as TaskGroupingType),
    }));
    const sortOrderOptions = Object.entries(SortDirectionView).map(([value, label]) => ({ value, label }));

    const handleGroupByChange = (value: string | null) => {
        if (value === null) return;
        dispatch(setGroupBy({ projectId, payload: value as TaskGroupingType }));
    };

    const handleSortOrderChange = (value: string | null) => {
        if (value === null) return;
        dispatch(setSortOrder({ projectId, payload: value as SortDirection }));
    };

    const handleRemoveSortAndGroup = () => {
        dispatch(removeSortAndGroup({ projectId }));
    };

    return (
        <>
            <DropdownContainer>
                <Select
                    label="Группировать"
                    placeholder="Выберите группировку"
                    data={groupByOptions}
                    value={groupBy}
                    onChange={handleGroupByChange}
                    w={230}
                    checkIconPosition="right"
                />
                <Select
                    label="Сортировка"
                    placeholder="Выберите сортировку"
                    data={sortOrderOptions}
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                    w={180}
                    checkIconPosition="right"
                />
                <StyledFilterButton iconSrc={DeleteIcon} onClick={handleRemoveSortAndGroup} />
            </DropdownContainer>
        </>
    );
};

const DropdownContainer = styled.div`
    display: flex;
    gap: 12px;
    text-align: left;
    align-items: flex-end;
`;

const StyledFilterButton = styled(FilterButton)`
    align-self: flex-end;
    margin-bottom: 4px;
`;
