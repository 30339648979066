import { ShortUserInfo } from '~/types/user/ShortUserInfo';

export enum ProjectUserType {
    MEMBER = 'member',
    ALL = 'all',
}

export function getProjectAvailableUsersType(isPrivateProject: boolean, isPrivateSpace: boolean) {
    return !isPrivateProject && !isPrivateSpace ? ProjectUserType.ALL : ProjectUserType.MEMBER;
}

export function canLogForOthers(user: ShortUserInfo | null) {
    if (!user) {
        console.error('User is null');
        return false;
    }
    const guildName = user.guild?.name?.trim() ?? '';
    return guildName === 'Project Managers' || guildName === 'Digital Project Managers';
}
