import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader, ColumnHeader, TaskListContainer, AddTaskButton, TaskGroupContainer } from '../TaskList/Tables';
import { Task } from '~/types/task/Task';
import TaskItem from '~/components/ProjectList/TaskItem';
import Plus from '~/assets/icons/plus.svg';
import { AppDispatch, RootState } from '~/store/store';
import { setSubtaskInputVisible, addSubtaskAndCreate, deleteSubtaskAsync, renameSubtaskAndUpdate } from '~/store/slices/taskDetails/taskDetailsSlice';
import { TextInput } from '@mantine/core';
import { useTaskNavigation } from '~/hooks/useTaskNavigation';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { CloseSnackbarAction } from '~/components/shared/CloseSnackbarAction';
import { TaskStatusNew } from '~/types/task/TaskStatus';

interface SubtasksProps {
    subtasks: Task[];
    canAddSubtasks: boolean;
    onSubtaskDatesUpdated: (taskId: string, newDateRange: [Date | null, Date | null]) => void;
    onSubtaskStatusUpdated: (status: TaskStatusNew[], taskId: string) => void;
}

export const Subtasks: React.FC<SubtasksProps> = ({ subtasks, canAddSubtasks, onSubtaskDatesUpdated, onSubtaskStatusUpdated }) => {
    const dispatch = useDispatch<AppDispatch>();
    const inputRef = useRef<HTMLInputElement>(null);
    const { navigateToTask } = useTaskNavigation();

    // Локальные состояния вместо Redux
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const userList = useSelector((state: RootState) => state.taskDetails.assignableUsers);

    useEffect(() => {
        if (isInputVisible) {
            inputRef.current?.focus();
        }
    }, [isInputVisible]);

    const handleShowInput = () => {
        setIsInputVisible(true);
        dispatch(setSubtaskInputVisible(true));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValue.trim()) {
            try {
                // При создании подзадачи передаем значение из локального состояния
                await dispatch(addSubtaskAndCreate(inputValue)).unwrap();
                // Очищаем поле ввода после успешного создания
                setInputValue('');
                setIsInputVisible(false);
            } catch (error) {
                enqueueSnackbar('Ошибка при добавлении подзадачи.', {
                    variant: 'error',
                    action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
                });
            }
        } else if (event.key === 'Escape') {
            setIsInputVisible(false);
            setInputValue('');
            dispatch(setSubtaskInputVisible(false));
        }
    };

    const handleInputBlur = () => {
        setIsInputVisible(false);
        setInputValue('');
        dispatch(setSubtaskInputVisible(false));
    };

    const handleTaskClick = (taskId: string) => {
        navigateToTask(taskId);
    };

    const handleTaskRename = async (taskId: string, newName: string) => {
        try {
            await dispatch(renameSubtaskAndUpdate({ subtaskId: taskId, subtaskName: newName })).unwrap();
        } catch (error) {
            enqueueSnackbar('Ошибка при переименовании подзадачи.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    const handleTaskDelete = async (taskId: string) => {
        const result = await dispatch(deleteSubtaskAsync(taskId)).unwrap();
        if (!result) {
            enqueueSnackbar('Ошибка при удалении подзадачи.', {
                variant: 'error',
                action: (key: SnackbarKey) => <CloseSnackbarAction snackbarKey={key} />,
            });
        }
    };

    return (
        <TaskGroupContainer style={{ paddingTop: '32px' }}>
            <TaskListContainer>
                <SubtasksOuterContainer>
                    <SubtasksContainer>
                        <TableHeader>
                            <ColumnHeader style={{ flex: 1 }}>Название</ColumnHeader>
                            <ColumnHeader style={{ width: '96px' }}>Исполнитель</ColumnHeader>
                            <ColumnHeader style={{ width: '96px' }}>Дедлайн</ColumnHeader>
                        </TableHeader>
                        <Separator />
                        {subtasks.map(subtask => (
                            <React.Fragment key={subtask.id}>
                                <TaskItem
                                    task={subtask}
                                    parentInfo={null}
                                    onClick={() => handleTaskClick(subtask.id)}
                                    onDelete={() => handleTaskDelete(subtask.id)}
                                    onRename={newName => handleTaskRename(subtask.id, newName)}
                                    onChildClick={() => {}}
                                    userList={userList}
                                    onDateRangeChange={(taskId, startDate, dueDate) => onSubtaskDatesUpdated(taskId, [startDate, dueDate])}
                                    onStatusChange={onSubtaskStatusUpdated}
                                    onMoveToList={() => {}}
                                />
                                <Separator />
                            </React.Fragment>
                        ))}
                        {canAddSubtasks && (
                            <>
                                {isInputVisible ? (
                                    <InputWrapper>
                                        <StyledTextInput
                                            ref={inputRef}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            onKeyDown={handleInputKeyDown}
                                            placeholder="Введите название подзадачи"
                                        />
                                    </InputWrapper>
                                ) : (
                                    <AddTaskButton $asRowItem onClick={handleShowInput}>
                                        <img src={Plus} alt="Add task" />
                                        <span>Добавить подзадачу</span>
                                    </AddTaskButton>
                                )}
                                <Separator />
                            </>
                        )}
                    </SubtasksContainer>
                </SubtasksOuterContainer>
            </TaskListContainer>
        </TaskGroupContainer>
    );
};

const SubtasksContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Separator = styled.div`
    height: 1px;
    background-color: #e9ecef;
    width: 100%;
`;

const InputWrapper = styled.div`
    padding: 0 4px 0 46px;
    width: 100%;
    box-sizing: border-box;
`;

const StyledTextInput = styled(TextInput)`
    width: 100%;
    .mantine-TextInput-wrapper {
        width: 100%;
    }
    .mantine-TextInput-input {
        height: 48px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #212529;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;
        &::placeholder {
            color: #adb5bd;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const SubtasksOuterContainer = styled.div`
    border: 1px solid #e9ecef;
    border-radius: 8px;
    overflow: hidden;
`;
