import { ReactElement, useState, useRef } from 'react';
import styled from 'styled-components';

import { TextInput, Button, Popover, Text, Select, Group, Stack, Checkbox } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

import Calendar from '~/assets/icons/calendar.svg?react';
import ClearIcon from '~/assets/icons/clear.svg?react';

const ButtonsConfig = [
    {
        label: 'Сегодня',
        info: new Date().toLocaleString('ru', { weekday: 'short' }),
        days: 0,
    },
    {
        label: 'Завтра',
        info: new Date(Date.now() + 86400000).toLocaleString('ru', { weekday: 'short' }),
        days: 1,
    },
    {
        label: 'Выходные',
        info: new Date(Date.now() + 86400000 * (6 - new Date().getDay())).toLocaleString('ru', { weekday: 'short' }),
        days: 6 - new Date().getDay(),
    },
    {
        label: 'Следующая неделя',
        info: new Date(Date.now() + 86400000 * 7).toLocaleString('ru', { weekday: 'short' }),
        days: 7,
    },
    {
        label: 'Следующие выходные',
        info: new Date(Date.now() + 86400000 * (13 - new Date().getDay())).toLocaleString('ru', { day: 'numeric', month: 'short' }),
        days: 13 - new Date().getDay(),
    },
    {
        label: '2 недели',
        info: new Date(Date.now() + 86400000 * 14).toLocaleString('ru', { day: 'numeric', month: 'short' }),
        days: 14,
    },
    {
        label: '4 недели',
        info: new Date(Date.now() + 86400000 * 28).toLocaleString('ru', { day: 'numeric', month: 'short' }),
        days: 28,
    },
];

type Flow = 'buttons' | 'recurrence';

interface CustomDatePickerProps {
    children: ReactElement;
    initialDateFrom: Date | null;
    initialDateTo: Date | null;
    onDateRangeSelected: (dateRange: [Date | null, Date | null]) => void;
    className?: string;
}

export function CustomDatePicker({ children, initialDateFrom, initialDateTo, onDateRangeSelected, className }: CustomDatePickerProps) {
    const [dateFrom, setDateFrom] = useState<Date | null>(initialDateFrom);
    const [dateTo, setDateTo] = useState<Date | null>(initialDateTo);
    const [isOpen, setIsOpen] = useState(false);
    const dateToInputRef = useRef<HTMLInputElement>(null);
    // Добавляем ref для поля начальной даты
    const dateFromInputRef = useRef<HTMLInputElement>(null);

    const [flow, setFlow] = useState<Flow>('buttons');

    const [createNewTask, setCreateNewTask] = useState(false);
    const [repeatAlways, setRepeatAlways] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);

    const handleQuickSelect = (days: number) => {
        const today = new Date();
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + days);
        setDateTo(endDate);
        setDateFrom(null);
    };

    const handleClearDateFrom = () => setDateFrom(null);
    const handleClearDateTo = () => setDateTo(null);
    const [activeField, setActiveField] = useState<'from' | 'to'>('to');

    const handlePopoverClose = () => {
        onDateRangeSelected([dateFrom, dateTo]);
        setIsOpen(false);
    };

    const handlePopoverOpen = (e: React.MouseEvent) => {
        e.stopPropagation();
        // Reset dates to initial values when opening
        setDateFrom(initialDateFrom);
        setDateTo(initialDateTo);
        setIsOpen(true);
        // Focus the date input on next render
        setTimeout(() => dateToInputRef.current?.focus(), 0);
    };

    return (
        <Popover position="bottom-start" opened={isOpen} onChange={setIsOpen} onClose={handlePopoverClose}>
            <Popover.Target>
                <div onClick={handlePopoverOpen} className={className}>{children}</div>
            </Popover.Target>
            <StyledPopoverDropdown $customPadding onClick={e => e.stopPropagation()}>
                <Container>
                    <InputWrapper>
                        <TextInput
                            ref={dateFromInputRef}
                            onFocus={() => setActiveField('from')}
                            data-focused={activeField === 'from'}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onDateRangeSelected([dateFrom, dateTo]);
                                    setIsOpen(false);
                                }
                            }}
                            leftSection={<Calendar />}
                            rightSection={
                                dateFrom && (
                                    <ClearButton onClick={handleClearDateFrom}>
                                        <ClearIcon width={16} height={16} />
                                    </ClearButton>
                                )
                            }
                            placeholder="Дата начала"
                            value={dateFrom?.toLocaleDateString() || ''}
                            readOnly
                        />
                        <TextInput
                            ref={dateToInputRef}
                            onFocus={() => setActiveField('to')}
                            data-focused={activeField === 'to'}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onDateRangeSelected([dateFrom, dateTo]);
                                    setIsOpen(false);
                                }
                            }}
                            leftSection={<Calendar />}
                            rightSection={
                                dateTo && (
                                    <ClearButton onClick={handleClearDateTo}>
                                        <ClearIcon width={16} height={16} />
                                    </ClearButton>
                                )
                            }
                            placeholder="Дата окончания"
                            value={dateTo?.toLocaleDateString() || ''}
                            readOnly
                        />
                    </InputWrapper>
                    <WorkArea>
                        <ControlWrapper>
                            {flow === 'buttons' && (
                                <>
                                    <ButtonWrapper>
                                        <Button.Group orientation="vertical">
                                            {ButtonsConfig.map(button => (
                                                <Button
                                                    key={button.label}
                                                    justify="space-between"
                                                    variant="datepicker"
                                                    fullWidth
                                                    rightSection={<ButtonInfo>{button.info}</ButtonInfo>}
                                                    onClick={() => handleQuickSelect(button.days)}
                                                >
                                                    {button.label}
                                                </Button>
                                            ))}
                                        </Button.Group>
                                    </ButtonWrapper>
                                    <RecurrenceButtonWrapper />
                                </>
                            )}
                            {flow === 'recurrence' && (
                                <RecurrenceWrapper>
                                    <Text size="xs" fw={700}>
                                        Рекуррентность
                                    </Text>
                                    <Stack gap={6}>
                                        <Select
                                            placeholder="Выберите период"
                                            data={[
                                                { value: 'day', label: 'День' },
                                                { value: 'week', label: 'Неделя' },
                                                { value: 'month', label: 'Месяц' },
                                                { value: 'year', label: 'Год' },
                                            ]}
                                        />
                                        <Select
                                            placeholder="Выберите условие"
                                            data={[
                                                { value: 'never', label: 'Никогда' },
                                                { value: 'after', label: 'После' },
                                                { value: 'on_date', label: 'В дату' },
                                            ]}
                                        />
                                    </Stack>
                                    <Stack gap={12}>
                                        <Checkbox
                                            size="xs"
                                            label="Создавать новую задачу"
                                            checked={createNewTask}
                                            onChange={event => setCreateNewTask(event.currentTarget.checked)}
                                        />
                                        <Checkbox
                                            size="xs"
                                            label="Повторять всегда"
                                            checked={repeatAlways}
                                            onChange={event => setRepeatAlways(event.currentTarget.checked)}
                                        />
                                        <Checkbox
                                            size="xs"
                                            label="Обновлять статус:"
                                            checked={updateStatus}
                                            onChange={event => setUpdateStatus(event.currentTarget.checked)}
                                        />
                                    </Stack>
                                    <Group gap={8} justify="flex-end" color="gray">
                                        <Button size="xs" variant="transparent" onClick={() => setFlow('buttons')}>
                                            Отменить
                                        </Button>
                                        <Button size="xs">Сохранить</Button>
                                    </Group>
                                </RecurrenceWrapper>
                            )}
                        </ControlWrapper>
                        <DatePickerWrapper onMouseDown={e => e.preventDefault()}>
                            <DatePicker
                                highlightToday={true}
                                size="xs"
                                type="default"
                                key={activeField} // Это заставит календарь пересоздаваться при смене поля
                                defaultDate={activeField === 'from' ? dateFrom || new Date() : dateTo || new Date()}
                                value={activeField === 'from' ? dateFrom : dateTo}
                                onChange={date => {
                                    if (activeField === 'from') {
                                        setDateFrom(date);
                                    } else {
                                        setDateTo(date);
                                    }
                                }}
                            />
                        </DatePickerWrapper>
                    </WorkArea>
                </Container>
            </StyledPopoverDropdown>
        </Popover>
    );
}

const StyledPopoverDropdown = styled(Popover.Dropdown)<{
    $customPadding?: boolean;
}>`
    display: flex;
    gap: 12px;
    padding: ${({ $customPadding }) => (!$customPadding ? '16px' : '0')};
    border-radius: 8px;
    border: 1px solid var(--Gray-30, #e9ecef);
    background: var(--Background-body, #fff);
    box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.05),
        0px 10px 15px -5px rgba(0, 0, 0, 0.1),
        0px 7px 7px -5px rgba(0, 0, 0, 0.04);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const DatePickerWrapper = styled.div`
    padding: 16px;

    button[data-first-in-range='true'],
    button[data-last-in-range='true'] {
        border-radius: var(--mantine-radius-default);
    }
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 16px;

    & > div {
        flex: 1;
    }
`;

const ControlWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 240px;
    background: #f8f9fa;
`;

const ButtonWrapper = styled.div`
    padding: 10px 8px 0;
    flex: 1;
`;

const WorkArea = styled.div`
    display: flex;
`;

const ButtonInfo = styled.span`
    color: #868e96;
    font-size: 12px;
    text-transform: capitalize;
`;

const RecurrenceButtonWrapper = styled.div`
    border-top: 1px solid #e9ecef;
`;

const ClearButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RecurrenceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 16px;
`;
