import React from 'react';
import styled from 'styled-components';

import { Select } from '@mantine/core';

interface FilterHeaderProps {
    onClear?: () => void;
}

const FilterHeader: React.FC<FilterHeaderProps> = ({ onClear }) => {
    return (
        <HeaderContainer>
            <HeaderTitle>Фильтры</HeaderTitle>
            <HeaderActions>
                <ClearButton onClick={onClear}>Очистить</ClearButton>
                <Select display="none" placeholder="Сохраненные фильтры" />
            </HeaderActions>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px 100px;
`;

const HeaderTitle = styled.h2`
    color: var(--Text-default, #212529);
    margin: auto 0;
    font:
        600 14px/1 Roboto,
        sans-serif;
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font:
        10px Inter,
        sans-serif;
`;

const ClearButton = styled.button`
    align-self: stretch;
    border-radius: 4px;
    color: rgba(134, 142, 150, 1);
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    line-height: 1.4;
    margin: auto 0;
    background: none;
    border: none;
    cursor: pointer;
`;

export default FilterHeader;
