import React from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import UserProfile from './UserProfile';
import Navigation from './Navigation';
import SidebarTree from './SidebarTree';

import { ShortUserInfo } from '~/types/user/ShortUserInfo';

interface SidebarProps {
    user: ShortUserInfo;
    isCollapsed: boolean;
    onToggle: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, onToggle, user }) => {
    const location = useLocation();
    const projectMatch = useMatch('/projects/:id');
    console.log('[MATCH]', projectMatch);
    const currentProjectId = projectMatch?.params?.id ?? null;

    return (
        <SidebarWrapper $isCollapsed={isCollapsed}>
            <FixedContent>
                <UserProfile isCollapsed={isCollapsed} onToggle={onToggle} user={user} />
                <Navigation isCollapsed={isCollapsed} currentPath={location.pathname} />
            </FixedContent>
            {!isCollapsed && (
                <ScrollableContent>
                    <SidebarTree currentProjectId={currentProjectId} />
                </ScrollableContent>
            )}
        </SidebarWrapper>
    );
};

const SidebarWrapper = styled.aside<{ $isCollapsed: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: ${props => (props.$isCollapsed ? '64px' : '320px')};
    min-width: ${props => (props.$isCollapsed ? '64px' : '320px')};
    padding: ${props => (props.$isCollapsed ? '0 8px' : '0 12px')};
    box-sizing: border-box;
    transition:
        width 0.3s ease,
        padding 0.3s ease;
    background-color: #f7f7f5;
`;

const FixedContent = styled.div``;

const ScrollableContent = styled.div`
    flex-grow: 1;
    overflow: auto;
    min-height: 0;

    /* Customize scrollbar */
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

export default Sidebar;
