import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';

interface TimeValue {
    hours: number;
    minutes: number;
}

interface TimeInputProps {
    value?: TimeValue;
    onChange: (value: TimeValue | { error: string }) => void;
    onBlur?: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    placeholder?: string;
}

const TimeInput: React.ForwardRefRenderFunction<HTMLInputElement, TimeInputProps> = (
    { value, onChange, onBlur, onKeyDown, className, placeholder = null },
    ref,
) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [, setError] = useState<string | undefined>(undefined);
    const initialValueUsed = useRef(false);

    useEffect(() => {
        if (!initialValueUsed.current) {
            if (value && (value.hours > 0 || value.minutes > 0)) {
                setInputValue(`${value.hours}h ${value.minutes}m`);
            }
            initialValueUsed.current = true;
        }
    }, [value]);

    const parseTime = (input: string): TimeValue | null => {
        const trimmedValue = input.trim();

        // Проверка на ввод, начинающийся с нуля
        if (trimmedValue.startsWith('0') && trimmedValue.length > 1 && !trimmedValue.includes(':') && !trimmedValue.includes('h')) {
            return null;
        }

        const floatMatch = trimmedValue.match(/^(\d+(?:\.\d+)?)$/);
        const timeMatch = trimmedValue.match(/^(\d+)(?:[:h]\s*(\d+)?)?(?:m)?$/);
        const fullTimeMatch = trimmedValue.match(/^(\d+)h\s*(\d+)m$/);
        const minutesOnlyMatch = trimmedValue.match(/^(\d+)m$/);

        let hours = 0;
        let minutes = 0;

        if (floatMatch) {
            const floatValue = parseFloat(floatMatch[1]);
            hours = Math.floor(floatValue);
            minutes = Math.round((floatValue - hours) * 60);
        } else if (fullTimeMatch) {
            hours = parseInt(fullTimeMatch[1], 10);
            minutes = parseInt(fullTimeMatch[2], 10);
        } else if (minutesOnlyMatch) {
            minutes = parseInt(minutesOnlyMatch[1], 10);
        } else if (timeMatch) {
            hours = parseInt(timeMatch[1], 10);
            minutes = timeMatch[2] ? parseInt(timeMatch[2], 10) : 0;
        } else {
            return null;
        }

        // Проверка на нулевое значение
        if (hours === 0 && minutes === 0) {
            return null;
        }

        // Adjust hours and minutes if minutes exceed 60
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        return { hours, minutes };
    };

    const handleInputChange = (input: string) => {
        const value = input.replace('ь', 'm').replace('р', 'h').replace('ю', '.');
        setInputValue(value);
        setError(undefined);

        if (!input.trim()) {
            onChange({ hours: 0, minutes: 0 });
            return;
        }

        const result = parseTime(value);
        if (result) {
            onChange(result);
        } else {
            setError('Invalid input format');
            onChange({ error: 'Invalid input format' });
        }
    };

    const handleBlur = () => {
        if (!inputValue.trim()) {
            setInputValue('');
            onChange({ hours: 0, minutes: 0 });
        } else {
            const result = parseTime(inputValue);
            if (result) {
                const { hours, minutes } = result;
                setInputValue(`${hours}h ${minutes}m`);
                onChange({ hours, minutes });
                setError(undefined);
            } else {
                setError('Invalid input format');
                onChange({ error: 'Invalid input format' });
            }
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <StyledInput
            ref={ref}
            value={inputValue}
            onChange={event => handleInputChange(event.currentTarget.value)}
            onKeyDown={onKeyDown}
            onBlur={handleBlur}
            placeholder={placeholder || ''}
            className={className}
        />
    );
};

const StyledInput = styled.input`
    width: 100%;
    height: 36px;
    padding: 0 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #495057;
    line-height: 36px;
    text-align: left;

    &:focus {
        outline: none;
        border-color: #228be6;
    }
`;

export default forwardRef(TimeInput);
